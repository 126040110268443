import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  makeStyles,
  Button,
  IconButton,
  Icon
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import SuccessAlert from '../../../../utility/successAlert/successAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(5),
    },
    textAlign: "center"
  },
  input: {
    display: 'none',
  },
}));

const ImportCSV = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [importCsv, setCsv] = useState(null);

  const handleImport = async () => {
    if (!importCsv) {
      console.error('No file selected for import.');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', importCsv);
  
    try {
      const checkResponse = await props.importCheckFrontline(formData);
      debugger
      if(checkResponse?.value?.data?.code === 404)
      {debugger
        const fileContent = JSON.stringify(checkResponse?.value?.data?.message , null, 2);

        // Create a Blob for the text file
        const blob = new Blob([fileContent], { type: 'text/plain' });
      
        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);
      
        // Create a temporary link element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'FrontLine_Error.csv'; 
      
        // Trigger the download
        a.click();
      
        // Cleanup
        URL.revokeObjectURL(url);

        setOpen(false);
        setCsv(null);
      }
      if (checkResponse?.value?.data?.success && checkResponse?.value?.status === 200) {
        const importResponse = await props?.importFrontline(formData);
        if (importResponse?.value?.status === 200) {
          const size = props.frontLineRows
          const page = 1;
          SuccessAlert('CSV imported successfully');
          setOpen(false);
          setCsv(null);
          dispatch(props.getFrontlineList({ size, page }));
        }
      }
    } catch (error) {
      console.error('Error during import process:', error);
    }
  };

  const handleClose = () => {
    setCsv(null);
    setOpen(false);
  };
  const handleChange = (e) => {
    setCsv(e.target.files[0])
  };
  return (
    <>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-left accounts_btn_wra theme_col_px">
        <button data-test="import_csv" type="button" className="btn cst_btn btn_danger mr-2 float-right" onClick={() => setOpen(true)} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#associateUser">Import CSV</button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <h4 id="form-dialog-title" data-test="import_csv_title" className="p-2" style={{ textAlign: "left", fontSize: '13', color: '#ab385e' }}>
          Import CSV
        </h4>
        <DialogContent className="pl-5 pr-5">
          <div className={classes.root}>
            <input
              data-test="upload_csv"
              accept=".csv"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleChange}
            />

            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
            <label htmlFor="contained-button-file">
              <Button variant="contained" component="span">
                UPLOAD CSV
              </Button>
            </label>
          </div>
          <h6 className="text-center" data-test="import_file_name">{importCsv && importCsv.name}</h6>
        </DialogContent>
        <DialogActions>
          <button data-test="import_btn" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleImport()} disabled={props.isLoading} className={`${importCsv ? "" : "disabled pointer-none"} ${props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"}`} id="delete" type="delete">{props.isLoading ? "" : "Import"}</button>
          <button data-test="cancel_button" style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleClose()} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
        </DialogActions>
      </Dialog>
    </>
  )
}


export default ImportCSV;