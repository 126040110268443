import { AuthActionTypes } from './authActionType';
import storage from '../../../utility/storage';
import SuccessAlert from '../../../utility/successAlert/successAlert';
import { closeModel, convertedQuarterlyDate } from '../../../utility/utility';
import { userRoles, routes, quarterCheckDates } from '../../../utility/constants/constants';
import * as API from '../../../API/api';
import history from '../../../router/history';
import errorHandler from '../../../utility/errorHandler/errorHandler';
import cloneDeep from 'clone-deep';
import React, { Component } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@material-ui/core';
import { checkOwnerInfo, getAccountAdminList, getChiSurveyQuarterlyCheckChattels, openCameraClicked, getPositions, getinspectorJobOwner } from '../AdminOrSuperAdminActions/AdminOrSuperAdminActions';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../App';
import mixpanel from 'mixpanel-browser';
mixpanel.init('3f0db59ea5a88d250644645440cc0b76', { debug: true });

const refreshPage = () => { window.location.reload(false); }

export const getUpdatedCurrentUser = (id) => dispatch => dispatch({
    type: AuthActionTypes.GET_LATEST_PROFILE,
    payload: API.getEditUser(id)
        .then(response => {
            const user = response.data.user
            if (user && !user.inventory_access?.length) {
                storage.remove('InventoryAccessRole');
            }
            else{ 
                const inventoryAccess = storage.get('InventoryAccessRole');
                if(!inventoryAccess){
                    let filteredUser = user.inventory_access && user.inventory_access.filter((role) => {
                        return role !== "admin"
                    });
                    storage.set('InventoryAccessRole', filteredUser[0]);
                }
            }
            
            storage.set('user', response.data.user);
            return response.data.user;
        })
        .catch(error => {
            console.log(error);
        })
});

export const onProfileClick = () => {
    return {
        type: AuthActionTypes.OPEN_PROFILE_MODEL
    }
}

export const updateAuthUser = (user) => {
    return {
        type: AuthActionTypes.UPDATE_USER,
        payload: user
    }
}

export const closeProfileModel = () => {
    return {
        type: AuthActionTypes.CLOSE_PROFILE_MODEL
    }
}
export const closeModeModel = () => {
    return {
        type: AuthActionTypes.CLOSE_MODE_MODAL
    }
}

export const signInClicked = (user,is_facial) => {
    return (dispatch, getState) => {
        dispatch({
            type: AuthActionTypes.SIGN_IN,
            payload: API.SignIn(user)
                .then((response) => {
                    
                    if (response.data.success) {debugger
                        let user = response.data.user
                        user.is_facial=Boolean(is_facial)
                         dispatch(getinspectorJobOwner(response.data.user.id))
                        // smartlook analytics
                        if (process.env.REACT_APP_ENV == 'production') {

                            const eventName = 'Logged In';
                            const eventProperties = {
                                'User CreatedAt': user.created_at,
                                'User UpdatedAt': user.updated_at,
                                'User TimeZone': user.account_timezone,
                                'User Position': user.position && user.position.name ? user.position.name : null,
                                'User Region': user.region && user.region.name ? user.region.name : null,
                                'User Mode': user.selected_mode,
                            };
                            window.smartlook('track', eventName, eventProperties);

                        }

                        // For inventory access initial role of user will be committed
                        if (user && !(user.inventory_access == null)) {
                            let filteredUser = user.inventory_access && user.inventory_access.filter((role) => {
                                return role !== "admin"
                            });
                            storage.set('InventoryAccessRole', filteredUser[0]);
                        }

                        // FOR CHI SURVEY FEEDBACK - FOR 4 QUARTERS IN A YEAR
                        if (user.role === userRoles.CLIENT) {
                            if ((convertedQuarterlyDate == quarterCheckDates.QUARTER1) || (convertedQuarterlyDate == quarterCheckDates.QUARTER2) ||
                                (convertedQuarterlyDate == quarterCheckDates.QUARTER3) || (convertedQuarterlyDate == quarterCheckDates.QUARTER4)) {
                                storage.set('chisurveyStarted', true);
                                dispatch(getChiSurveyQuarterlyCheckChattels());
                            }
                        }

                        dispatch(checkOwnerInfo());
                        dispatch(getPositions({ hide: false }));
                    }
                    if (response.data.user.mode === "all" && !response.data.user.selected_mode) {
                        // return {
                        //     type: AuthActionTypes.OPEN_MODE_MODAL
                        // }
                        dispatch({
                            type: AuthActionTypes.OPEN_MODE_MODAL,
                        })

                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};
export const authorizeUser = (user) => {
    storage.set('token', user.access_token);
    storage.set('refresh_token', user.refresh_token);
    storage.set('user', user);
    storage.set("is_facial",user?.is_facial)
    const token = user.access_token;
    const refresh_token = user.refresh_token;

    // if (user.role === userRoles.ADMIN || user.role === userRoles.SUPER_ADMIN) {
    // history.push(routes.DASHBORD);
    // } else {

    // }

    return {
        type: AuthActionTypes.AUTHORIZE,
        payload: {
            token,
            user,
            refresh_token
        }
    }
}

export const forgetPassword = (user) => dispatch => dispatch({
    type: AuthActionTypes.FORGET_PASSWORD,
    payload: API.ForgetPassowrd(user)
        .then(response => {
            if (response.data.success === 'true') {
                SuccessAlert("A reset password link has been sent to you mail.")
            }

        })
        .catch(error => {
            console.log(error);
        })
});

export const resetPassword = (user) => dispatch => dispatch({
    type: AuthActionTypes.RESET_PASSWORD,
    payload: API.resetPassword(user)
        .then(response => {

            if (response.data.success === true) {
                SuccessAlert("Your password has been changed sucessfully.")
                history.push(routes.SIGNIN)
            }
        })
        .catch(error => {
            console.log(error);
        })
});

export const updateUserPassword = (id, user) => dispatch =>
    dispatch({
        type: AuthActionTypes.UPDATE_USER_PASSWORD,
        payload: API.updateUserPassword(id, user)
            .then(response => {
                if (response.data.success === true) {
                    SuccessAlert("Password has been changed sucessfully.")
                    closeModel();
                }
            })
            .catch(error => {
                console.log(error);
            })
    });

export const logout = (message) => dispatch => {
    const user = storage.get('user')
    logEvent(analytics, 'logout_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id });
    let viewedPages = [];
    if (sessionStorage && sessionStorage.viewedPages) {
        viewedPages = sessionStorage.getItem('viewedPages')
    }
    mixpanel.track('Logged Out', {
        'user role': `${user.role}`,
        'user email': `${user.email}`,
        'viewed pages': viewedPages,
    });
    // smartlook analytics
    // const eventName = 'Logged Out';
    // const eventProperties = {
    //     'User CreatedAt' : user.created_at,
    //     'User UpdatedAt' : user.updated_at,
    //     'User TimeZone' : user.account_timezone,
    //     'User Position' : user.position && user.position.name ? user.position.name : null,
    //     'User Region' : user.region && user.region.name ? user.region.name : null,
    //     'User Mode' : user.selected_mode,
    // };
    // window.smartlook('track', eventName, eventProperties);
    storage.remove('token');
    storage.remove('user');
    storage.remove('refresh_token');
    storage.remove('step');
    storage.remove('UpdatedTotalSale');
    storage.remove('inspectionFormData');

    /* ClockIn ClockOut Storage materials */
    storage.remove('isClockInEngaged');
    storage.remove('clockInTimer');
    // storage.remove('chronometer');
    storage.remove('envReloadOccurs');
    storage.remove('isTimeTravel');
    storage.remove('shiftScheduleDetailId');
    
    let chronometer = localStorage.getItem('chronometer')
    const isMealBreakInitiated = storage.get('isMealBreakInitiated')
    localStorage.clear();
    if(chronometer){
        const parsedTime = JSON.parse(chronometer);
        const {seconds, minutes, hours} = parsedTime
        localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }))
    }
    if(isMealBreakInitiated){
        storage.set('isMealBreakInitiated', isMealBreakInitiated)
    }
    
    // const history = getHistory();
    dispatch({


        type: AuthActionTypes.LOGOUT,
        payload: API.logout()
            .then(response => {

                window.location.replace(routes.SIGNIN);
            })
            .catch(error => {
                console.log(error);
            })

        // clear();

        //  ''
        // return {
        //     type: AuthActionTypes.LOGOUT,
        // }
    });
}

// export const updateUserPassword = (id, user) => dispatch =>
//     dispatch({
//         type: AuthActionTypes.UPDATE_USER_PASSWORD,
//         payload: API.updateUserPassword(id, user)
//         .then(response => {
//             if (response.data.success === true) {
//                 SuccessAlert("Password updated sucessfully.")
//                 closeModel();
//             }
//         })
//         .catch(error => {
//             console.log(error);
//         })
// });


export const uploadUserImageToS3 = (url, arrayBuffer, user) => {
    return (dispatch, getState) => {
        dispatch({
            type: AuthActionTypes.UPLOAD_USER_IMAGE_TO_S3,
            payload: API.uploadImageToS3(url, arrayBuffer)
                .then(response => {
                    dispatch({
                        type: AuthActionTypes.UPDATE_PROFILE,
                        payload: API.updateProfile(user)
                            .then(response => {
                                closeModel();
                                
                                storage.set('user', response.data.user);
                                SuccessAlert("Your profile has been successfully updated.");
                                return response.data.user;
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    })
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const updateProfile = (user, arrayBuffer, extension) => {
    return (dispatch) => {
        if (arrayBuffer !== null && arrayBuffer !== undefined) {
            dispatch({
                type: AuthActionTypes.GET_PROFILE_PRESIGNED_URL,
                payload: API.getUserPresignedUrl(extension)
                    .then(response => {

                        user.user['photo_path'] = response.data.photo_path;
                        dispatch(uploadUserImageToS3(response.data.presigned_url, arrayBuffer, user))
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
        } else {
            dispatch({
                type: AuthActionTypes.UPDATE_PROFILE,
                payload: API.updateProfile(user)
                    .then(response => {
                        if (!response.data.error) {
                            
                            storage.set('user', response.data.user);
                            closeModel();
                            SuccessAlert("Your profile has been successfully updated.");
                            return response.data.user;
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
        }
    }
}

export const changePassword = (user) => dispatch => dispatch({

    type: AuthActionTypes.CHANGE_PASSWORD,
    payload: API.changePassword(user)
        .then(response => {

            if (!response.data.error) {
                closeModel();
                SuccessAlert("Your password has been successfully updated.");
            }
            // return response.data.user
        })
        .catch(error => {
            console.log(error);
        })
})

export const updatePreferences = (id, user) => {
    return (dispatch, getState) => {
        dispatch({
            type: AuthActionTypes.UPDATE_PREFERENCES,
            payload: API.updatePreferences(id, user)
                .then(response => {
                    if (!response.data.error) {
                        SuccessAlert("Successfully updated.");
                        const user = cloneDeep(getState().authReducer.user);
                        user['inspections'] = response.data.inspections;
                        user['summaries'] = response.data.summaries;
                        user['tickets'] = response.data.tickets;
                        user['pegassure_activity'] = response.data.pegassure_activity;
                        storage.set('user', user);
                        return user;
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

