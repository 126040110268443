import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm, isDirty, change } from 'redux-form';
import { Input } from '../../UI/InputElement/InputElement';
import isEmpty from '../../../../utility/IsEmptyValidator';
import SignatureCanvas from 'react-signature-canvas'
import { makeFirstLetterCapital } from '../../../../utility/utility';
import cloneDeep from 'clone-deep';
import { DateTimeField } from '../../UI/DateTimeField/DateTimeField';
import {  convertDateTimezoneFormAudit, tzDateConverterTool_V3, tzTimeConversionTool_V1, tzTimeConversionTool_V2, tzUTCDateConverterTool_V2 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import TimecardCorrectionFormSkeleton from './TimecardCorrectionFormSkeleton';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import RenderingDateField from './RenderingDateField';
import { sort_TimeCard_Based_Upon_status_and_Time } from '../../../../utility/timeCardUtility';
import { components } from "react-select";
import useSelection from 'antd/es/table/hooks/useSelection';

var pickShiftTimeMessageHandler = 'Please select required Shift date and Job'

let TimeCardCorrectionForm = (props) => {

    const { handleSubmit, isLoading, isTriggerLoader, isShiftDetailId, singleShiftScheduleData, handleCancel } = props;
    let { empSignShade } = useRef({});

    const dispatch = useDispatch()
    const getJobAccountSitesList = (jobAccountSitesList) => {
        if (props.jobAccountSitesList && props.jobAccountSitesList.length > 0) {
            return jobAccountSitesList && jobAccountSitesList.map((data) => {
                return {
                    value: data.id,
                    label: data.name
                }
            })
        } else {
            return []
        }
    };



    const getFrontlineUsersList = (frontlineUsersList) => {
        if (props.frontlineUsersList && props.frontlineUsersList.length > 0) {
            return frontlineUsersList && frontlineUsersList.map((data) => {
                return {
                    value: data.id,
                    label: data.first_name + ' ' + data.last_name,
                }
            })
        } else {
            return []
        }
    };

    const getCurrentDateShiftList = (currentShiftList) => {
        if (props?.getShiftListByparams?.length > 0) {
            return currentShiftList && currentShiftList.map((data) => {
                // if (data.is_time_card == true) {
                //     pickShiftTimeMessageHandler = 'Time card already created for this shift'
                //     errorHandler('Time card already created for this shift');
                //     setTimeout(() => {
                //         refreshPage();
                //     }, 1500);
                return {
                    value: data.id,
                    label: tzTimeConversionTool_V1(data?.shift?.start_time, data?.shift?.start_date?.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(data?.shift?.end_time, data?.shift?.end_date?.split('T')[0]),
                    isDisabled: data.is_time_card,
                }
            })
        } else {
            return [];
        }
    }

    const [jobSitesList, setJobSitesList] = useState(getJobAccountSitesList(props.jobAccountSitesList));
    const [frontlineUsersList, setFrontlineUsersList] = useState(getFrontlineUsersList(props.frontlineUsersList));
    const [shiftList, setShiftList] = useState(getCurrentDateShiftList(props.getShiftListByparams));
    const [clickOutside, setClickOutside] = useState(0)
    const selectRef = useRef(null)



    useEffect(() => {
        setJobSitesList(getJobAccountSitesList(props.jobAccountSitesList))
    }, [props.jobAccountSitesList]);

    useEffect(() => {
        setFrontlineUsersList(getFrontlineUsersList(props.frontlineUsersList))
    }, [props.frontlineUsersList]);

    useEffect(() => {
        setShiftList(getCurrentDateShiftList(props.getShiftListByparams))
    }, [props.getShiftListByparams]);



    const handleSignature = () => {
        if (props?.formStates?.timecard?.empId !== null) {
            if (empSignShade.isEmpty() === false) {
                let empSignUrl = empSignShade.getTrimmedCanvas().toDataURL("image/png");
                let baseFile = { empSignUrl };
                props.handleEmployeeSignature(baseFile);
            }
        }
    };




    const handleChangeDateTimeField = useCallback((account_id) => (newTime) => {

        let start_end = "start_at"
        let currentDate = props.initialValues.timecard.jobHours[account_id]
        const timeString = newTime
        const dateString = currentDate[start_end].split("T")[0]
        const isoDateTime = moment(`${dateString} ${timeString}`, "YYYY-MM-DD hh:mm A").toISOString();

        //i need to change the start and endTime of the next remaining sections 

        let item = { ...props.formStates?.timecard.shiftDetailJobs[account_id], end_at: tzTimeConversionTool_V2(isoDateTime.split('T')[1].split('.')[0], isoDateTime.split('T')[0]), }
        let previousEndTime = isoDateTime;

        let data = props.formStates?.timecard.shiftDetailJobs.reduce((acc, curr, index) => {
            if (index == account_id) {
                acc.push({
                    ...item,
                })
            }
            else if (index < account_id) {

                acc.push(curr)
                //Ni change happen in dates
            }
            else {
                let currentJob = props.formStates.timecard.shiftDetailJobs[index]
                let actualDuration = props.initialValues.timecard.jobHours[index]
                if (currentJob.status == "clockedOut") {
                    let duration = actualDuration.total_minutes
                    let endTime = addTimebyMinutes(duration, previousEndTime)

                    acc.push({
                        ...currentJob,
                        start_at: moment(previousEndTime).format("hh:mm A"),
                        end_at: endTime.format("hh:mm A")
                    })
                    previousEndTime = moment(endTime.format()).format()
                } else if (currentJob.status == "clockedIn") {
                    let duration = actualDuration.total_minutes
                    let endTime = addTimebyMinutes(duration, previousEndTime)

                    acc.push({
                        ...currentJob,
                        start_at: moment(previousEndTime).format("hh:mm A"),
                        end_at: ""
                    })
                    previousEndTime = moment(endTime.format()).format()
                }
                else {
                    let duration = actualDuration.total_minutes
                    let endTime = addTimebyMinutes(duration, previousEndTime)

                    acc.push({
                        ...currentJob,
                        start_at: "",
                        end_at: ""
                    })
                    previousEndTime = moment(endTime.format()).format()
                }

            }
            return acc
        }, [])

        dispatch(change('newTimeCardCorrectionForm', `timecard[shiftDetailJobs]`, data));


    }, [dispatch, props.initialValues.timecard])



    const handleChangeDateTimeFieldStartTime = useCallback((account_id) => (newTime) => {

        let start_end = "end_at"
        let currentDate = props.initialValues.timecard.jobHours[account_id];
        const timeString = newTime
        const dateString = currentDate[start_end] ? currentDate[start_end].split("T")[0] : currentDate["start_at"].split("T")[0]
        const isoDateTime = moment(`${dateString} ${timeString}`, "YYYY-MM-DD hh:mm A").toISOString();
        let d = currentDate.total_minutes
        let endTimeAt = addTimebyMinutes(d, isoDateTime);
        let mm = endTimeAt.toISOString()
        let hh = mm.split("T")
        mm.replace(hh[0], dateString)

        // endTimeAt.format("hh:mm A")
        //i need to change the start and endTime of the next remaining sections 

        let item = { ...props.formStates?.timecard.shiftDetailJobs[account_id], start_at: tzTimeConversionTool_V2(isoDateTime.split('T')[1].split('.')[0], isoDateTime.split('T')[0]), end_at: tzTimeConversionTool_V2(mm.split('T')[1].split('.')[0], mm.split('T')[0]) }
        let previousEndTime = mm;

        let data = props.formStates?.timecard.shiftDetailJobs.reduce((acc, curr, index) => {
            if (index == account_id) {
                if (curr.status == "clockedIn") {

                    acc.push({
                        ...item,
                        end_at: null
                    })
                }
                else {
                    acc.push({
                        ...item,
                    })
                }
            }
            else if (index < account_id) {

                acc.push(curr)
                //Ni change happen in dates
            }
            else {
                let currentJob = props.formStates.timecard.shiftDetailJobs[index]
                let actualDuration = props.initialValues.timecard.jobHours[index]
                if (currentJob.status == "clockedOut") {

                    let duration = actualDuration.total_minutes
                    let endTime = addTimebyMinutes(duration, previousEndTime)
                    acc.push({
                        ...currentJob,
                        start_at: moment(previousEndTime).format("hh:mm A"),
                        end_at: endTime.format("hh:mm A")
                    })
                    previousEndTime = moment(endTime.format()).format()
                } else if (currentJob.status == "clockedIn") {
                    let duration = actualDuration.total_minutes
                    let endTime = addTimebyMinutes(duration, previousEndTime)
                    acc.push({
                        ...currentJob,
                        start_at: moment(previousEndTime).format("hh:mm A"),
                        end_at: ""
                    })
                    previousEndTime = moment(endTime.format()).format()
                }
                else {
                    let duration = actualDuration.total_minutes
                    let endTime = addTimebyMinutes(duration, previousEndTime)
                    acc.push({
                        ...currentJob,
                        start_at: "",
                        end_at: ""
                    })
                    previousEndTime = moment(endTime.format()).format()
                }


            }
            return acc
        }, [])

        dispatch(change('newTimeCardCorrectionForm', `timecard[shiftDetailJobs]`, data));

    }, [dispatch, props.initialValues.timecard])


    function addTimebyMinutes(minutes, time) {
        const originalDateTime = moment(time);
        // Adding one hour
        const newDateTime = originalDateTime.add(minutes, 'minutes');
        return newDateTime
    }


    function settingTimeConstraints(previousEndTime,) {
        let constraints = { minutes: { step: 1 } }
        if (previousEndTime !== "null" || previousEndTime !== undefined || previousEndTime) {
            if (!previousEndTime) {
                return constraints;
            }
            constraints["hours"] = previousEndTime.hours
            constraints["minutes"] = { ...constraints.minutes, ...previousEndTime.minutes }
        }

        return constraints;
        //return { minutes: { step: 1 },hours: { min: 9, max: 17 }
    }

    const handleWrapperClick = (event) => {

        if (selectRef.current !== null) {
            event.stopPropagation();
        }

    };

    function handleClosePopUp(e) {
        e.preventDefault();
        handleCloseTimePopup()
    }

    function handleCloseTimePopup() {
        setClickOutside(true)
        selectRef.current = null
        setTimeout(() => {
            setClickOutside(false)
        }, 100)
    }


    // const disableStyle={
    // background-color: transparent;
    // color: hsl(0,0%,80%);
    // cursor: default;
    // display: block;
    // font-size: inherit;
    // padding: 8px 12px;
    // width: 100%;

    // }

    const CustomOption = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <span style={props?.isDisabled ? { backgroundColor: "transparent", color: "hsl(0,0%,80%)", cursor: "default", display: "block", fontSize: "inherit", padding: "8px 12px", width: "100%" } : {}}>
                        {props.data.label} <br />
                        {props.isDisabled ? <span style={{ color: "#f55353" }}>already timecard created </span> : null}

                    </span>
                </components.Option>
            </div>
        );
    };


    const MomentStringToHour = (str, format = "hh:mm A",) => {
        let time = moment(str, format);
        return time
    }




    function createTimeConstraints(endTime, startTime) {
        const DECIDE = {
            AM: 0,
            PM: 12
        }
        let startH = null
        let endH = null
        let startM = null
        let endM = null
        if (endTime && startTime) {
            startH = +startTime.split(":")[0]
            // endH=endTime.split(":")[0]
            startM = +startTime.split(":")[1].split(" ")[0]
            // endM=endTime.split(":")[1].split(" ")[0]
        }
        if (endTime) {
            const timeArray = endTime.split(":")
            const decidetime = DECIDE[timeArray[1].split(" ")[1]]
            const hinNumber = +timeArray[0]
            const mInNumber = +timeArray[1].split(" ")[0]
            const h = hinNumber / decidetime == 1 ? hinNumber + (decidetime - hinNumber) : hinNumber + decidetime

            if (mInNumber > startM && startH > hinNumber) {
                return {
                    hours: { min: h + 1, max: 23 },
                    minutes: { min: 0, max: 59 }
                }
            }
            if (startH > hinNumber) {
                return {
                    hours: { min: h, max: 23 },
                    minutes: { min: 0, max: 59 }
                }
            }
            return {
                hours: { min: h, max: 23 },
                minutes: { min: mInNumber, max: 59 }
            }
        }
    }


    if (isTriggerLoader) {
        return (
            <TimecardCorrectionFormSkeleton />
        )
    } else {
        return (
            <>
                <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluids">
                            <div className="quote_form ticket_form" style={{ maxWidth: '1200px' }}>

                                <div className="row">
                                    <div className="col-sm-12 text-center"> <h2 className="md_title" > Create Timecard Correction </h2></div>
                                </div>

                                <form className="form_content" onSubmit={handleSubmit((values, dispatch) => {
                                    props.onSubmit(values, props.initialValues)

                                })} >
                                    <div className="addQuote_form mb-3">
                                        <div className="cst_tab_content">

                                            {!(isShiftDetailId) && (
                                                <div className="addQuote_form mt-3 mb-3">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="form_title fw-bold"> Shift Date <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                    <div style={{ width: '100%' }}>
                                                                        <Input
                                                                            name="timecard[selectedShiftDate]"
                                                                            type="date"
                                                                            datePickerType={"react-datepicker"}
                                                                            maxDate={new Date()}
                                                                            dateFormat="MM-dd-yyyy"
                                                                            className=" form-control input-modifier"
                                                                            placeholder="Select Date"
                                                                            onChange={(e) => {
                                                                                let userId = props?.user?.id;
                                                                                let date = tzUTCDateConverterTool_V2(e, 'to');
                                                                                props.getShiftListByParams({ userId, date });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                        <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="form_title fw-bold"> Job <sup className="cst_sup_txt text_danger">*</sup>  </label>
                                                                <Input name="timecard[accountId]" dataTest="select_leave_type" type="inputSelect" options={jobSitesList} placeholder="Please select" onChange={(e) => {
                                                                    let userId = props?.user?.id;
                                                                    let accountId = e?.value;
                                                                    let date = tzUTCDateConverterTool_V2(props?.formStates?.timecard?.selectedShiftDate, 'none');
                                                                    props.getShiftListByParams({ userId, accountId, date });
                                                                }} />
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="form_title fw-bold"> Shift Time <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                {shiftList && shiftList.length > 0 && shiftList[0] !== undefined ? (
                                                                    <Input name="timecard[selectedShiftId]]" dataTest="select_leave_type" type="inputSelect" options={shiftList} onChange={(e) => {
                                                                        props.getSingleShifSchedule(e.value);

                                                                    }} placeholder="Please select" CustomOption={CustomOption} />
                                                                ) : (
                                                                    <Input name="" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder={pickShiftTimeMessageHandler} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!(singleShiftScheduleData?.id) && (
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <p className="form_title fw-bold text-muted"> ***Before proceeding to create the Timecard correction form, make sure to fill out the three fields above. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {singleShiftScheduleData?.id && (
                                                // {true && (
                                                <>
                                                    <div className="addQuote_form mt-3 mb-3">

                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Employee Name </label>
                                                                    <Input name="timecard[empName]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Type here" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Employee ID# </label>
                                                                    <Input name="timecard[empMail]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="Type here" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="addQuote_form mt-3 mb-3" onClick={handleClosePopUp}>

                                                        <label className="form_title font-weight-bold mb-4"> STEP 1 </label>

                                                        {
                                                            props.formStates?.timecard.shiftDetailJobs?.map((acc, index) => {
                                                                //start_at end_at
                                                                let previousEndTime = index == 0 ? null : createTimeConstraints(props.formStates?.timecard.shiftDetailJobs[index - 1]?.end_at, acc?.start_at)
                                                                return (
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label className="form_title fw-bold"> Job Name </label>
                                                                                <Input name={`timecard[shiftDetailJobs][${index}].job`} dataTest="select_leave_type" type="inputSelect" options={jobSitesList} placeholder="Please select" readOnly={true} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-4" onClick={handleWrapperClick}>
                                                                            <div className="form-group" >
                                                                                <label className="form_title fw-bold"> Start Time </label>
                                                                                <RenderingDateField disabled={!Boolean(acc?.start_at)} name={`timecard[shiftDetailJobs][${index}][start_at]`} timeConstraints={settingTimeConstraints(previousEndTime)} datePickerType='react-datetime' pickerType="timePickerTimecard" dataFormat="hh:mm A" className="form-control Input-modifier" id="" placeholder="" onChange={handleChangeDateTimeFieldStartTime(index)} clickOutside={clickOutside} index={index} isSelect={selectRef} close={handleCloseTimePopup} inputValue={MomentStringToHour(acc?.start_at)} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-4" >
                                                                            <div className="form-group" onClick={handleWrapperClick}>
                                                                                <label className="form_title fw-bold"> End Time </label>


                                                                                {/* <Input name={`timecard[shiftDetailJobs][${index}][end_at]`} type="text" readOnly={false} className="form-control input-modifier" id="" placeholder="" /> */}
                                                                                <RenderingDateField disabled={!Boolean(acc?.end_at)} timeConstraints={settingTimeConstraints(createTimeConstraints(acc?.start_at, acc?.end_at))} name={`timecard[shiftDetailJobs][${index}][end_at]`} datePickerType='react-datetime' pickerType="timePickerTimecard" dataFormat="hh:mm A" className="form-control Input-modifier" id="" placeholder="" onChange={handleChangeDateTimeField(index)} clickOutside={clickOutside} index={index} isSelect={selectRef} close={handleCloseTimePopup} inputValue={MomentStringToHour(acc?.end_at)} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }



                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Shift Time </label>
                                                                    <Input name="timecard[shiftTime]" type="text" readOnly={true} className="form-control input-modifier" id="" placeholder="" />
                                                                </div>
                                                            </div>

                                                            {singleShiftScheduleData?.breaks?.map((time, index) => (
                                                                <>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form_title fw-bold"> Meal In {`(${index + 1})`} </label>
                                                                            <DateTimeField name={`timecard[breakIn][${index}][mealIn]`} timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="mealIn" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form_title fw-bold"> Meal Out {`(${index + 1})`} </label>
                                                                            <DateTimeField name={`timecard[breakOut][${index}][mealOut]`} timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="mealOut" />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}

                                                            {/* ClockIn ClockOut Time */}

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Clock In </label>
                                                                    <DateTimeField name={`timecard.shiftDetailJobs[0].start_at`} timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="clockIn" disabled={true} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Clock Out </label>
                                                                    <Input type="text" name={`timecard.shiftDetailJobs.[${props?.formStates?.timecard?.shiftDetailJobs?.filter((item) => item.status == 'clockedOut')?.length -1}].[end_at]`} timeConstraints={{ minutes: { step: 5 } }} datePickerType='react-datetime' pickerType="timePicker" dataFormat="hh:mm A" className="form-control Input-modifier" id="clockOut" disabled={true} />
                                                                </div>
                                                            </div>



                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Date of Error </label>
                                                                    <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                        <div style={{ width: '100%' }}>
                                                                            <Input
                                                                                name="timecard[errorDate]"
                                                                                type="date"
                                                                                datePickerType={"react-datepicker"}
                                                                                minDate={new Date()}
                                                                                dateFormat="MM-dd-yyyy"
                                                                                className="form-control input-modifier"
                                                                                placeholder="Select Date"
                                                                                disabled={true}
                                                                            />
                                                                        </div>
                                                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Reason for Error <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                    <Input name="timecard[errorReason]" type="text" className="form-control input-modifier" id="" placeholder="Please enter your reason" />
                                                                </div>
                                                            </div>

                                                        </div>



                                                    </div>

                                                    <div className="addQuote_form mt-3 mb-3">

                                                        <label className="form_title font-weight-bold mb-4"> STEP 2 </label>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold">
                                                                        I {makeFirstLetterCapital(singleShiftScheduleData?.assignedTo?.first_name) + makeFirstLetterCapital(singleShiftScheduleData?.assignedTo?.last_name)} certify that I agree with the {`error(s)`} above, and I agree to have my payroll/record changed to reflect these corrections. I understand that failing to Following the Peg Time policy will result in discipline, up to and including termination.
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="addQuote_form mt-3 mb-3">

                                                        <label className="form_title font-weight-bold mb-4"> STEP 3 </label>

                                                        <div className="row">
                                                            <div className="col-md-12 monthly_tab">
                                                                <div className="form-group box_input">
                                                                    <label className="form_title fw-bold">
                                                                        *** For a clock Out error, the employee must complete the attestation question***
                                                                    </label>
                                                                    <label className="form_title fw-bold">
                                                                        1. I certify I was able to take my meal and rest break(s) today in accordance with state law, I performed my work today without sustaining any work injury and that my work hours are complete and accurate. (click the applicable answer)
                                                                    </label>
                                                                    <Input type="radio" labelClass="custom-control-label" forLable={"customTrue"} value={"true"} label={"Yes"} className="custom-control-input" id="customTrue" name="timecard[clkAgreement]" />
                                                                    <Input type="radio" labelClass="custom-control-label" forLable={"customFalse"} value={"false"} label={"No"} className="custom-control-input" id="customFalse" name="timecard[clkAgreement]" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="addQuote_form mt-3 mb-3">
                                                        <label className="form_title font-weight-bold mb-4"> STEP 4 </label>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Employee Name </label>
                                                                    <Input name="timecard[empId]" dataTest="select_leave_type" type="inputSelect" options={frontlineUsersList} placeholder="Please select" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label className="form_title fw-bold"> Employee Signature <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                <div className="form-group">
                                                                    <SignatureCanvas
                                                                        ref={(ref) => { empSignShade = ref; }}
                                                                        penColor='black' backgroundColor='white'
                                                                        canvasProps={{ width: 500, height: 50, className: 'form-control textarea_modifier' }}
                                                                    />
                                                                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}><button type="button" onClick={clearSign} className="btn mr-2 btn-outline-secondary">Clear</button></div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form_title fw-bold"> Date Signed <sup className="cst_sup_txt text_danger">*</sup> </label>
                                                                    <div className="input-group cst_input_group col-lg-14 pl-0">
                                                                        <div style={{ width: '100%' }}>
                                                                            <Input
                                                                                name="timecard[empSignDate]"
                                                                                type="date"
                                                                                datePickerType={"react-datepicker"}
                                                                                minDate={new Date()}
                                                                                dateFormat="MM-dd-yyyy"
                                                                                className="form-control input-modifier"
                                                                                placeholder="Select Date"
                                                                            />
                                                                        </div>
                                                                        <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }}>
                                                                            <span class="input-group-text" id="basic-addon2"> <i class="fa fa-calendar"></i> </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        {singleShiftScheduleData.id && <button data-test="submit_button" type="submit" disabled={isLoading} className={isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger w-25"} onClick={handleSignature}>{isLoading ? "" : "Submit"}</button>}
                                        {isShiftDetailId && <button data-test="cancel_button" type="button" className="btn cst_btn btn-outline-secondary cancel_btnnew w-25" onClick={handleCancel}> Back </button>}
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }



};

const mapStateToProps = (state, props) => {

    let timecard = {};


    /* For fetching Job Name */
    const getJobObject = (user) => {

        return user.map((acc, index) => {
            let fetchedJobObject = null;
            fetchedJobObject = {
                value: acc.id,
                label: makeFirstLetterCapital(acc.name)
            }
            return fetchedJobObject;
        })

    };


    const getJobObjectFromShiftDetail = (user) => {
        return sort_TimeCard_Based_Upon_status_and_Time(user).map((acc, index) => {
            let fetchedJobObject = null;
            let account = acc.account
            fetchedJobObject = {
                value: account.id,
                label: makeFirstLetterCapital(account.name)
            }
            return {
                job: fetchedJobObject,
                ...acc,
                start_at: acc?.start_at ? tzTimeConversionTool_V2(acc?.start_at.split('T')[1].split('.')[0], acc.start_at.split('T')[0]) : "",
                end_at: acc?.end_at ? tzTimeConversionTool_V2(acc?.end_at.split('T')[1].split('.')[0], acc.end_at.split('T')[0]) : "",
            };
        })




    };

    /* For fetching  Name */
    const getAllocatedShiftObject = (shift) => {
        let fetchedShiftObject = null;
        props.getShiftListByparams.map((mapedItem) => {
            if (mapedItem?.shift?.id == shift.id) {
                fetchedShiftObject = {
                    value: shift.id,
                    label: shift.time
                }
            }
        });
        return fetchedShiftObject;
    };

    const findDuration = (start_at, end_at) => {
        if (start_at == null || end_at == null) return 0
        let start = moment(start_at);
        let end = moment(end_at);

        // Remove seconds and milliseconds
        start = start.seconds(0).milliseconds(0);
        end = end.seconds(0).milliseconds(0);

        // Calculate the difference in minutes
        const duration = end.diff(start, 'minutes');
        return duration

    }

    function CalculateWorkingHours(jobsDetails) {
        let results = {}

        if (!jobsDetails.length || !jobsDetails || !Array.isArray(jobsDetails)) return results

        return jobsDetails.reduce((acc, curr, index) => {

            let account_id = curr.account_id
            //let total_minutes = curr.total_minutes
            let end_at = curr.end_at
            let start_at = curr.start_at

            // start_at = `2024-02-15T${13 + index}:00:00.000Z`
            // end_at = `2024-02-15T${13 + (index + 1)}:15:00.000Z`
            let total_minutes = findDuration(start_at, end_at)
            acc[index] = {
                total_minutes: total_minutes, // calculated total minutes 
                end_at,
                start_at,
            }

            return acc;
        }, results);


    }


    function convertIntoObject(jobs) {
        return jobs.reduce((acc, curr) => {
            let Work_id = curr.id
            let value = { end_at: curr.end_at, start_at: curr.start_at }
            acc[Work_id] = value
            return acc
        }, {})
    }



    if (isEmpty(state.TimeTrackerModReducer.singleShiftScheduleData)) {

        timecard = {

        }
        
    } else {
        let date_of_error = state?.TimeTrackerModReducer?.singleShiftScheduleData?.date ? convertDateTimezoneFormAudit(state?.TimeTrackerModReducer?.singleShiftScheduleData?.date) : "";
        let catchedFormValues = state?.form?.newTimeCardCorrectionForm?.values?.timecard;
        const value = cloneDeep(state.TimeTrackerModReducer.singleShiftScheduleData);
        debugger
        let breakInSlots = value.breaks.map((mealBreak) => {
            return { id: mealBreak?.id, mealIn: mealBreak?.start ? tzTimeConversionTool_V2(mealBreak?.start.split('T')[1].split('.')[0], mealBreak?.start.split('T')[0]) : '' }
        });

        let breakOutSlots = value.breaks.map((mealBreak) => {
            return { id: mealBreak?.id, mealOut: mealBreak?.end ? tzTimeConversionTool_V2(mealBreak?.end.split('T')[1].split('.')[0], mealBreak?.end.split('T')[0]) : '' }
        });

        let ConvertedJobs = getJobObjectFromShiftDetail(value?.shiftDetailJobs ?? [])

        timecard = {
            selectedShiftDate: catchedFormValues?.selectedShiftDate ? catchedFormValues.selectedShiftDate : '',
            selectedShiftId: value?.shift?.id ? getAllocatedShiftObject({ id: value.shift.id, time: tzTimeConversionTool_V1(value?.shift?.start_time, value?.shift?.start_date?.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(value?.shift?.end_time, value?.shift?.end_date?.split('T')[0]) }) : '',
            empName: value?.assignedTo?.id ? makeFirstLetterCapital(value.assignedTo.first_name) + makeFirstLetterCapital(value.assignedTo.last_name) : '',
            empMail: value?.assignedTo?.employee_id ? value?.assignedTo.employee_id : '',
            accountId: value?.accounts?.length ? getJobObject(value.accounts) : '',
            shiftTime: value?.shift?.id ? tzTimeConversionTool_V1(value?.shift?.start_time, value?.shift?.start_date?.split('T')[0]) + ' − ' + tzTimeConversionTool_V1(value?.shift?.end_time, value?.shift?.end_date?.split('T')[0]) : '',
            clockIn: value?.check_int_at !== null ? tzTimeConversionTool_V2(value.check_int_at.split('T')[1].split('.')[0], value.date.split('T')[0]) : '',
            clockOut: value?.check_out_at !== null ? tzTimeConversionTool_V2(value.check_out_at.split('T')[1].split('.')[0], value.date.split('T')[0]) : '',
            breakIn: breakInSlots?.length > 0 ? breakInSlots : [],
            breakOut: breakOutSlots?.length > 0 ? breakOutSlots : [],
            errorDate: catchedFormValues?.selectedShiftDate ? catchedFormValues.selectedShiftDate : date_of_error,
            empSignDate: value?.date ? tzDateConverterTool_V3(value.date) : '',
            clkAgreement: 'true',
            empId: value?.assignedTo?.id ? { value: `${value.assignedTo.id}`, label: `${value.assignedTo.first_name + ' ' + value.assignedTo.last_name}` } : '',
            shiftDetailJobs: value.shiftDetailJobs?.length ? getJobObjectFromShiftDetail(value.shiftDetailJobs) : null,
            jobHours: value?.shiftDetailJobs?.length > 0 ? CalculateWorkingHours(value?.shiftDetailJobs) : null,
            is_night_shift: "",
            initValues: ConvertedJobs.length ? convertIntoObject(ConvertedJobs) : {}
            // empSignDate: value?.id ? tzDateConverterTool_V3(moment().format('YYYY-MM-DDTHH:mm:ss')) : null,
        }

    }

    return {
        initialValues: { timecard },
        formStates: getFormValues('newTimeCardCorrectionForm')(state)
    }

}

TimeCardCorrectionForm = reduxForm({
    form: 'newTimeCardCorrectionForm',
    // validate,
    enableReinitialize: true,
})(TimeCardCorrectionForm);

TimeCardCorrectionForm = connect(mapStateToProps)(TimeCardCorrectionForm)

export default TimeCardCorrectionForm;