import storage from '../../../utility/storage';
import SuccessAlert from '../../../utility/successAlert/successAlert';
import { userRoles, routes, Status, TicketStatus } from '../../../utility/constants/constants';
import { AdminOrSuperAdminActionTypes } from './AdminOrSuperAdminActionType';
import * as API from '../../../API/api';
import {
    closeModel,
    toggleSidebar,
    toggleOpportunities,
    setTotalSales,
    toggleSettings,
    sortInspectionFormArrayOfObject,
    sortArrayOfObject,
    getUser,
    convertInspectionInventaryData,
    convertAreaLogsDataToCsvType,
    convertLogbookLogDataToCsvType,
    convertDateToDifferentTZ,
    convertTicketLogsDataToCsvType
} from '../../../utility/utility';
import history from '../../../router/history';
import { extractFileExtentions } from '../../../utility/extractFileExtentions';
import { AuthActionTypes } from '../AuthActions/authActionType';
import { updateAuthUser } from '../AuthActions/authAction'
import $ from "jquery";
import errorHandler from '../../../utility/errorHandler/errorHandler';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../App';
import store from '../../../redux/store/store';
import { reset } from 'redux-form';
import { resetFloor } from '../TimeTrackerModAction/TimeTrackerModAction';
const cloneDeep = require('clone-deep');
var moment = require('moment');
const user = storage.get('user');
const refreshPage = () => { window.location.reload(false); }

export const newUserClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.NEW_USER_CLICKED,
    };
};

export const emptyObjects = () => {
    return {
        type: AdminOrSuperAdminActionTypes.MAKE_OBJECTS_EMPTY,
    };
};

export const sideBarClicked = () => {
    toggleSidebar();
    return {
        type: AdminOrSuperAdminActionTypes.SIDE_BAR_CLICKED,
    };
};

export const settingsClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.SETTINGS_CLICKED,
    };
};


export const kpiClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.KPI_CLICKED
    }
}

export const updateData = () => {
    return {
        type: AdminOrSuperAdminActionTypes.GET_UPDATED_DATA,
    };
};

export const getCategories = (data) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_CATEGORIES,
        payload: API.getCategories(data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const addNewCategory = (values) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_CATEGORY,
            payload: API.addNewCategory(values)
                .then((response) => {
                    if (response.data.success) {
                        closeModel();
                        const Category = {};
                        SuccessAlert('Category successfully added.');
                        if (getState().adminOrSuperAdminReducer.categoriesList) {
                            let CategoriesList = cloneDeep(getState().adminOrSuperAdminReducer.categoriesList);

                            Category['category'] = response.data;
                            if (CategoriesList) {
                                CategoriesList.unshift(Category);
                            }

                            CategoriesList.sort(function (a, b) {
                                if (a.category.name < b.category.name) {
                                    return -1;
                                }
                                if (a.category.name > b.category.name) {
                                    return 1;
                                }
                                return 0;
                            });
                            return { CategoriesList: CategoriesList, count: response.data.count };
                        }
                    } else {
                        closeModel();
                        let CategoriesList = cloneDeep(getState().adminOrSuperAdminReducer.categoriesList);
                        let CategoriesCount = cloneDeep(getState().adminOrSuperAdminReducer.totalCategories);
                        return { CategoriesList: CategoriesList, count: CategoriesCount };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editCategoryClicked = (category) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_CATEGORY_CLICKED,
        payload: category,
    };
};

export const updateCategory = (values, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_CATEGORY,
            payload: API.updateCategory(values, id)
                .then((response) => {
                    if (response.data.success) {
                        // dispatch(getCategories({hide: false}))
                        closeModel();
                        if (getState().adminOrSuperAdminReducer.categoriesList) {
                            let CategoriesList = cloneDeep(getState().adminOrSuperAdminReducer.categoriesList);
                            const index = CategoriesList.findIndex((c) => c.category.id === response.data.id);

                            CategoriesList[index]['category'] = response.data;
                            SuccessAlert('Category Successfully Updated');

                            CategoriesList.sort(function (a, b) {
                                if (a.category.name < b.category.name) {
                                    return -1;
                                }
                                if (a.category.name > b.category.name) {
                                    return 1;
                                }
                                return 0;
                            });
                            return CategoriesList;
                        }
                    } else {
                        closeModel();
                        let CategoriesList = cloneDeep(getState().adminOrSuperAdminReducer.categoriesList);
                        return CategoriesList;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteCategory = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_CATEGORY,
            payload: API.deleteCategory(id)
                .then((response) => {
                    let CategoriesList = cloneDeep(getState().adminOrSuperAdminReducer.categoriesList);
                    let Count = cloneDeep(getState().adminOrSuperAdminReducer.totalCategories);
                    if (response.data.success) {
                        let searchIndex = CategoriesList.findIndex(function (c) {
                            return c.category.id === id;
                        });
                        CategoriesList.splice(searchIndex, 1);
                        return { CategoriesList: CategoriesList, count: response.data.count };
                    } else {
                        return { CategoriesList: CategoriesList, count: Count };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getPositions = (data) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_POSITIONS,
        payload: API.getPositions(data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getInspectionDashboardData = (DataType, date) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_INSPECTION_DASHBOARD_DATA,
        payload: API.getInspectionDashboardData(DataType, date)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });
export const getTicketDashboardData = (DataType, date) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_TICKET_DASHBOARD_DATA,
        payload: API.getTicketDashboardData(DataType, date)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });
export const getQuoteDashboardData = (DataType, date) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_QUOTE_DASHBOARD_DATA,
        payload: API.getQuoteDashboardData(DataType, date)
            .then((response) => {
                debugger;
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getLogbookDashboardData = (DataType, date) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_LOGBOOK_DASHBOARD_DATA,
        payload: API.getLogbookDashboardData(DataType, date)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getLogbookHistoryDashboard = () => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_LOGBOOK_HISTORY_DASHBOARD,
        payload: API.getLogbookHistoryDashboard()
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getClientDashboardData = (DataType, date) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_DASHBOARD_DATA,
        payload: API.getClientDashboardData(DataType, date)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const updateCurrentDate = (date) => {
    return {
        type: AdminOrSuperAdminActionTypes.UPDATE_CURRENT_DATE,
        payload: date,
    };
};

export const updateWeekMonthYear = (isWeek, isMonth, isYear, is90Days, isCustomDate) => {
    return {
        type: AdminOrSuperAdminActionTypes.UPDATE_WEEK_MONTH_YEAR,
        payload: { isWeek, isMonth, isYear, is90Days, isCustomDate }
    }
}

export const getUpcomingScheduleOnArea = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.FUTURE_SCHEDULES_ON_AREA,
        payload: API.getUpcomingScheduleOnArea(id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const resetObjects = () => {
    return {
        type: AdminOrSuperAdminActionTypes.RESET_OBJECTS,
    };
};

export const openRejectModel = () => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_REJECT_MODEL,
    };
};

export const closeRejectModel = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_REJECT_MODEL,
    };
};

export const updateTotalSalesArray = (totalSales) => {
    return {
        type: AdminOrSuperAdminActionTypes.UPDATE_TOTAL_SALE_ARRAY,
        payload: totalSales,
    };
};

export const getInspectionForms = () => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_INSPECTION_FORMS,
            payload: API.getInspectionForms()
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getInspectionFormsForJob = () => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_INSPECTION_FORMS_FOR_JOB,
            payload: API.getInspectionFormsForJob()
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addNewInspectionForm = (newInspectionForm, { isDublicate }) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_INSPECTION_FORM,
            payload: API.addNewInspectionForm(newInspectionForm)
                .then((response) => {
                    if (response.data.success) {
                        storage.remove('inspectionFormData');
                        const InspectionForm = {};
                        SuccessAlert('Inspection Form successfully added.');
                        let ele = document.getElementById('close_duplicate_inspection_button');
                        // let field = document.getElementById('duplicate_inspection_name')
                        if (ele) {
                            ele.click();
                            // field.value = '';
                        }
                        const user = getUser();

                        if (getState().adminOrSuperAdminReducer.inspectionFormsList) {
                            let inspectionFormsList = cloneDeep(
                                getState().adminOrSuperAdminReducer.inspectionFormsList
                            );

                            InspectionForm['inspection_form'] = response.data;

                            // if (!isDublicate) {
                            history.push(routes.INSPECTION_FORMS);
                            // } else {

                            //     dispatch(getEditInspectionForm(InspectionForm.inspection_form.id));
                            //     history.push(`/settings/inspection_forms/${InspectionForm.inspection_form.id}`);
                            // }
                            if (inspectionFormsList && user.mode === response.data.mode) {
                                inspectionFormsList.unshift(InspectionForm);
                            }
                            sortInspectionFormArrayOfObject(inspectionFormsList);
                            return { inspectionFormsList };
                        } else {
                            // if (!isDublicate) {
                            history.push(routes.INSPECTION_FORMS);
                            // } else {

                            //     dispatch(getEditInspectionForm(InspectionForm.inspection_form.id));
                            //     history.push(`/settings/inspection_forms/${response.data.id}`);
                            // }
                            return { inspectionFormsList: null };
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editInspectionFormClicked = (inspcetionForm) => (dispatch) => {
    history.push(`/settings/inspection_forms/${inspcetionForm.inspection_form.id}`);
    // dispatch(getEditInspectionForm(inspcetionForm.inspection_form.id));
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_INSPECTION_FORM_CLICKED,
        // payload: response
    }
}

export const updateInspectionForm = (inspectionForm) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FORM,
            payload: API.updateInspectionForm(inspectionForm)
                .then((response) => {
                    if (!response.data.error) {
                        const InspectionForm = {};

                        // if (history.location.pathname !== `/inspections/performInspection/${inspectionForm.inspection_form.id}`) {

                        // }
                        if (getState().adminOrSuperAdminReducer.inspectionFormsList) {
                            let InspectionFormsList = cloneDeep(
                                getState().adminOrSuperAdminReducer.inspectionFormsList
                            );
                            const index = InspectionFormsList.findIndex(
                                (i) => i.inspection_form.id === response.data.id
                            );

                            InspectionFormsList[index]['inspection_form'] = response.data;
                            // if (history.location.pathname !== `/inspections/performInspection/${inspectionForm.inspection_form.id}`) {
                            history.push(routes.INSPECTION_FORMS);
                            SuccessAlert('Inspection Form Successfully Updated');
                            // } else {
                            InspectionForm['inspection_form'] = response.data;
                            // }
                            sortInspectionFormArrayOfObject(InspectionFormsList);
                            return { InspectionFormsList, InspectionForm, isLoading: false };
                        } else {
                            SuccessAlert('Inspection Form Successfully Updated');
                            // if (history.location.pathname !== `/inspections/performInspection/${inspectionForm.inspection_form.id}`) {
                            history.push(routes.INSPECTION_FORMS);
                            return {
                                InspectionFormsList: null,
                                InspectionForm: (InspectionForm['inspection_form'] = response.data),
                                isLoading: true,
                            };
                            // }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteInspectionFormSection = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_INSPECTION_FORM_SECTION,
        payload: API.deleteInspectionFormSection(id)
            .then((response) => { })
            .catch((error) => {
                console.log(error);
            }),
    });

export const deleteInspectionFormLineItem = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_INSPECTION_FORM_LINE_ITEM,
        payload: API.deleteInspectionFormLineItem(id)
            .then((response) => { })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getInspectionReports = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_INSPECTION_REPORTS,
            payload: API.getInspectionReports(data)
                .then((response) => {
                    if (!response.data.error) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getInspectionReportsCsv = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_INSPECTION_REPORTS_CSV,
            payload: API.getInspectionReportsCsv(data)
                .then((response) => {
                    if (!response.data.error) {
                        SuccessAlert('Please check your email for export results');
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getEditInspectionForm = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_EDIT_INSPECTION_FORM,
        payload: API.getEditInspectionForm(id)
            .then((response) => {
                const inspection_form = {};

                inspection_form['inspection_form'] = response.data;
                return inspection_form;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const deleteInspectionForm = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_INSPECTION_FORM,
            payload: API.deleteInspectionForm(id)
                .then((response) => {
                    let inspectionFormsList = cloneDeep(getState().adminOrSuperAdminReducer.inspectionFormTableList);
                    let SearchedInspectionFormsList = cloneDeep(
                        getState().adminOrSuperAdminReducer.searchedInspectionFormsList
                    );
                    if (response.data.success) {
                        if (SearchedInspectionFormsList) {
                            let searchIndex = SearchedInspectionFormsList.findIndex(function (i) {
                                return i.inspection_form.id === id;
                            });
                            SearchedInspectionFormsList.splice(searchIndex, 1);
                        }

                        let index = inspectionFormsList.findIndex(function (i) {
                            return i.inspection_form.id === id;
                        });

                        inspectionFormsList.splice(index, 1);
                        SuccessAlert(`Inspection Form Successfully deleted`);
                        return { inspectionFormsList, SearchedInspectionFormsList };
                    } else {
                        return { inspectionFormsList, SearchedInspectionFormsList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getRatings = (data) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_RATINGS,
        payload: API.getRatings(data)
            .then((response) => {
                return { ratingList: response.data, hideRating: !data.hide };
            })
            .catch((error) => {
                console.log(error);
            }),
    });


export const changeHideStatus = (status) => {
    return {
        type: AdminOrSuperAdminActionTypes.CHANGE_HIDE_STATUS,
        payload: status,
    };
};


export const addNewRating = (newRating) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_RATING,
            payload: API.addNewRating(newRating)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Rating successfully added.');
                        if (getState().adminOrSuperAdminReducer.ratingList) {
                            let ratingList = cloneDeep(getState().adminOrSuperAdminReducer.ratingList);

                            history.push(routes.RATINGS);
                            if (ratingList[0].hide === response.data.hide) {
                                ratingList.unshift(response.data);
                            }
                            sortArrayOfObject(ratingList);
                            return { ratingList: ratingList, count: response.data.count };
                        } else {
                            history.push(routes.RATINGS);
                        }
                    } else {
                        if (getState().adminOrSuperAdminReducer.ratingList) {
                            let ratingList = cloneDeep(getState().adminOrSuperAdminReducer.ratingList);
                            let count = cloneDeep(getState().adminOrSuperAdminReducer.totalRatings);
                            return { ratingList: ratingList, count: count };
                        } else {
                            return { ratingList: [], count: '' };
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editRatingClicked = (rating) => {
    history.push(`/settings/rating/${rating.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_RATING_CLICKED,
        payload: rating,
    };
};

export const getEditRating = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_EDIT_RATING,
        payload: API.getEditRating(id)
            .then((response) => {
                let values = cloneDeep(response.data);
                if (values.rating_options_attributes.filter((r) => r.default === true).length === 1) {
                    let index = values.rating_options_attributes.findIndex((r) => r.default === true);
                    values.rating_options_attributes[index]['defaultTrue'] = true;
                }
                return values;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const updateRating = (rating) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_RATING,
            payload: API.updateRating(rating)
                .then((response) => {
                    if (!response.data.error) {
                        const Rating = {};
                        SuccessAlert('Rating Successfully Updated');
                        if (getState().adminOrSuperAdminReducer.ratingList) {
                            let RatingList = cloneDeep(getState().adminOrSuperAdminReducer.ratingList);
                            const index = RatingList.findIndex((r) => r.id === response.data.id);

                            if (RatingList[index].hide === response.data.hide) {
                                RatingList[index] = response.data;
                            } else {
                                delete RatingList[index]
                            }
                            history.push(routes.RATINGS);

                            sortArrayOfObject(RatingList);
                            return { RatingList: RatingList, hideRating: !RatingList[index].hide };
                        } else {
                            history.push(routes.RATINGS);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteRatingLineItem = (rating, IdsToDelete) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_RATING_LINE_ITEM,
            payload: API.deleteRatingLineItem(IdsToDelete)
                .then((response) => {
                    if (!response.data.error) {
                        if (getState().adminOrSuperAdminReducer.ratingList) {
                            let RatingsList = cloneDeep(getState().adminOrSuperAdminReducer.ratingList);
                            IdsToDelete.map((rat) => {
                                const index = RatingsList.findIndex((r) => r.id === rat.ratingId);
                                const ratingOptionIndex = RatingsList[index].rating_options_attributes.findIndex(
                                    (rop) => rop.id === rat.lineItemId
                                );
                                RatingsList[index].rating_options_attributes.splice(ratingOptionIndex, 1);
                            });

                            dispatch(updateRating(rating));
                            return RatingsList;
                        } else {
                            dispatch(updateRating(rating));
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteRating = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_RATING,
            payload: API.deleteRating(id)
                .then((response) => {
                    let ratingList = cloneDeep(getState().adminOrSuperAdminReducer.ratingList);
                    let SearchedRatingList = cloneDeep(getState().adminOrSuperAdminReducer.searchedRatingList);
                    if (response.data.success) {
                        if (SearchedRatingList) {
                            let searchIndex = SearchedRatingList.findIndex(function (r) {
                                return r.id === id;
                            });
                            SearchedRatingList.splice(searchIndex, 1);
                        }

                        let index = ratingList.findIndex(function (r) {
                            return r.id === id;
                        });

                        ratingList.splice(index, 1);
                        SuccessAlert('Rating Successfully deleted');
                        return { ratingList, SearchedRatingList, count: response.data.count };
                    } else {
                        let ratingCount = cloneDeep(getState().adminOrSuperAdminReducer.totalRatings);
                        return { ratingList, SearchedRatingList, count: ratingCount };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getCompanies = (values, company_no_of_rows, page, sorted, filtered) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_COMPANIES,
    payload: API.getCompanies(values, company_no_of_rows, page, sorted, filtered)
        .then(response => {
            if (response.data.success) {
                // const companies = response.data.companies.reverse();
                // const companies_rows = response.data.no_of_rows
                return {
                    companies: response.data.company,
                    company_page: response.data.total_no_of_pages,
                    company_rows: response.data.no_of_rows,
                    company_cur_page: page ? page - 1 : 0,
                    companySearchValues: values,
                    totalCompanies: response.data.company_tol_count
                }
            }
        })
        .catch((error) => {
            console.log(error);
        }),
});

export const getUserPaginationCompanies = (values, company_no_of_rows, page, sorted, filtered, userId) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_COMPANIES,
        payload: API.getUserPaginationCompanies(values, company_no_of_rows, page, sorted, filtered, userId)
            .then((response) => {
                if (!response.data.error && !response.data.code) {
                    // const companies = response.data.companies.reverse();
                    // const companies_rows = response.data.no_of_rows
                    return {
                        companies: response.data.companies,
                        company_page: response.data.total_no_of_pages,
                        company_rows: response.data.no_of_rows,
                        totalCompanies: response.data.companies_tol_count,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getCompaniesDropDownList = () => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_DROPDOWN_COMPANIES,
            payload: API.getCompaniesDropDownList()
                .then((response) => {
                    return response.data.companies;
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const searchCompanies = (values) => {
    return (dispatch, getState) => {
        const company_rows = getState().adminOrSuperAdminReducer.company_rows;
        // const page = getState().adminOrSuperAdminReducer.company_cur_page;
        // const account_order = getState().adminOrSuperAdminReducer.account_order;

        dispatch({
            type: AdminOrSuperAdminActionTypes.SEARCH_COMPANIE,
            payload: API.getCompanies(values, company_rows, 1)
                .then((response) => {
                    if (!response.data.error) {
                        return {
                            companies: response.data.company,
                            company_page: response.data.total_no_of_pages,
                            company_rows: response.data.no_of_rows,
                            totalCompanies: response.data.company_tol_count,
                            companySearchValues: values,
                            // sort_ticket_column: columnName ? columnName : '',
                            // ticket_order: desc !== '' ? desc : ''
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getNonAssociatedCompanyAccounts = () => {
    return (dispatch, getState) => {
        // const Accounts = getState().adminOrSuperAdminReducer.dropDownAccountsList;

        // if (!Accounts) {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_NON_ASSOCIATED_COMPANY_ACCOUNTS,
            payload: API.getDropDownAccountsForCompanies().then((response) => {
                const non_associated_company_accounts = response.data.accounts.filter((a) => !a.company_associated);

                return non_associated_company_accounts;
            }),
        });
        // } else {

        //     const non_associated_company_accounts = Accounts.filter(a => !a.company_associated);

        //     dispatch({
        //         type: AdminOrSuperAdminActionTypes.GET_ALL_NON_ASSOCIATED_COMPANY_ACCOUNTS,
        //         payload: non_associated_company_accounts
        //     })
        // }
    };
};

export const uploadCompanyImageToS3 = (url, arrayBuffer, company, isNewCompany) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
            payload: API.uploadImageToS3(url, arrayBuffer)
                .then(r => {
                    delete company.company.ext;
                    // delete company.company.id;
                    // if (!company.account_ids || company.account_ids.length === 0) {
                    //     company['account_ids'] = company.accounts.map((a, i) => {
                    //         return a.id
                    //     });
                    // }
                    if (company.company.id) {

                        dispatch({
                            type: AdminOrSuperAdminActionTypes.UPDATE_COMPANY,
                            payload: API.updateCompany(company)
                                .then(response => {

                                    if (!response.data.error) {
                                        // smartlook analytics
                                        // let data = cloneDeep(response.data.company);
                                        // const eventName = 'Edited_Customer';
                                        // const eventProperties = {
                                        //     'edited_customer_id' : data.id,
                                        //     'edited_customer_name' : data.name,
                                        //     'edited_customer_company_number' : data.company_number,
                                        //     'edited_customer_active_status' : data.active_status,
                                        //     'edited_customer_created_at' : data.created_at,
                                        //     'edited_customer_updated_at' : data.updated_at,
                                        //     'edited_customer_mode' : data.mode,
                                        // };
                                        // window.smartlook('track', eventName, eventProperties);
                                        if (isNewCompany) {
                                            SuccessAlert('Company successfully Added.');
                                        } else {
                                            SuccessAlert('Company successfully Updated.');
                                        }

                                        history.push(routes.COMPANIES);
                                        // let CompaniesList = null;
                                        return { isLoading: false }

                                        // if (getState().adminOrSuperAdminReducer.companiesList) {
                                        //     let CompaniesList = cloneDeep(getState().adminOrSuperAdminReducer.companiesList);
                                        //     const index = CompaniesList.findIndex(c => c.company.id === response.data.id);
                                        //     CompaniesList[index]['company'] = response.data;
                                        //     history.push(routes.COMPANIES);
                                        //     return { CompaniesList };
                                        // } else {
                                        //     history.push(routes.COMPANIES);
                                        //     let CompaniesList = null;
                                        //     return { CompaniesList, isLoading: true }
                                        // }
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        })
                    } else {
                        dispatch({

                            type: AdminOrSuperAdminActionTypes.ADD_NEW_COMPANY,
                            payload: API.addNewCompany(company)
                                .then(response => {
                                    if (!response.data.error) {
                                        // smartlook analytics
                                        // let data = cloneDeep(response.data.company);
                                        // const eventName = 'Created_New_Customer';
                                        // const eventProperties = {
                                        //     'customer_id' : data.id,
                                        //     'customer_name' : data.name,
                                        //     'customer_company_number' : data.company_number,
                                        //     'customer_active_status' : data.active_status,
                                        //     'customer_created_at' : data.created_at,
                                        //     'customer_mode' : data.mode,
                                        // };
                                        // window.smartlook('track', eventName, eventProperties);
                                        const Company = {};
                                        SuccessAlert('Company successfully added.');

                                        if (getState().adminOrSuperAdminReducer.companiesList) {
                                            let CompaniesList = cloneDeep(getState().adminOrSuperAdminReducer.companiesList);

                                            Company['company'] = response.data;
                                            history.push(routes.COMPANIES);
                                            if (CompaniesList) {
                                                CompaniesList.unshift(Company);
                                            }

                                            return { CompaniesList, isLoading: false };
                                        }
                                        else {
                                            history.push(routes.COMPANIES);
                                            let CompaniesList = null;
                                            return { CompaniesList, isLoading: true };
                                        }
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addNewCompany = (company, arrayBuffer, extension, isNewCompany) => {

    return (dispatch, getState) => {
        if (arrayBuffer !== null && arrayBuffer !== undefined) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_COMPANY_PRESIGNED_URL,
                payload: API.getCompanyPresignedUrl(extension)
                    .then(response => {
                        if (response.data.success) {
                            company.company['photo_path'] = response.data.photo_path;
                            dispatch(uploadCompanyImageToS3(response.data.presigned_url, arrayBuffer, company, isNewCompany));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.ADD_NEW_COMPANY,
                payload: API.addNewCompany(company)
                    .then(response => {
                        if (response.data.success) {
                            // smartlook analytics
                            // let data = cloneDeep(response.data.company);
                            // const eventName = 'Created_New_Customer';
                            // const eventProperties = {
                            //     'customer_id' : data.id,
                            //     'customer_name' : data.name,
                            //     'customer_company_number' : data.company_number,
                            //     'customer_active_status' : data.active_status,
                            //     'customer_created_at' : data.created_at,
                            //     'customer_mode' : data.mode,
                            // };
                            // window.smartlook('track', eventName, eventProperties);
                            const Company = {};
                            SuccessAlert('Company successfully added.');

                            if (getState().adminOrSuperAdminReducer.companiesList) {
                                let CompaniesList = cloneDeep(getState().adminOrSuperAdminReducer.companiesList);

                                Company['company'] = response.data;
                                history.push(routes.COMPANIES);
                                if (CompaniesList) {
                                    CompaniesList.unshift(Company);
                                }

                                return { CompaniesList, isLoading: false };
                            }
                            else {
                                history.push(routes.COMPANIES);
                                let CompaniesList = null;
                                return { CompaniesList, isLoading: true };
                            }
                        } else {
                            let CompaniesList = cloneDeep(getState().adminOrSuperAdminReducer.companiesList);
                            return { CompaniesList, isLoading: false };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            })
        }
    }
}

// export const getCompanyPresignedUrl = (company, extention, arrayBuffer, idsToDelete) => { 
//     return (dispatch, getState) => {
//         dispatch({
//             type: AdminOrSuperAdminActionTypes.GET_COMPANY_PRESIGNED_URL,
//             payload: API.getCompanyPresignedUrl(company, extention)
//                 .then(response => {
//                     company['photo_path'] = response.data.photo_path;
//                     dispatch(uploadCompanyImageToS3(response.data.presigned_url, arrayBuffer, company));
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 }),
//         });
//     };
// }

export const updateCompany = (company, arrayBuffer, extension) => {
    return (dispatch, getState) => {
        if (arrayBuffer === null || arrayBuffer === undefined) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.UPDATE_COMPANY,
                payload: API.updateCompany(company)
                    .then((response) => {
                        if (response.data.success) {
                            // smartlook analytics
                            // let data = cloneDeep(response.data.company);
                            // const eventName = 'Edited_Customer';
                            // const eventProperties = {
                            //     'edited_customer_id' : data.id,
                            //     'edited_customer_name' : data.name,
                            //     'edited_customer_company_number' : data.company_number,
                            //     'edited_customer_active_status' : data.active_status,
                            //     'edited_customer_created_at' : data.created_at,
                            //     'edited_customer_updated_at' : data.updated_at,
                            //     'edited_customer_mode' : data.mode,
                            // };
                            // window.smartlook('track', eventName, eventProperties);
                            SuccessAlert('Company Successfully Updated');
                            history.push(routes.COMPANIES);
                            // if (getState().adminOrSuperAdminReducer.companiesList) {
                            //     let CompaniesList = cloneDeep(getState().adminOrSuperAdminReducer.companiesList);
                            //     const index = CompaniesList.findIndex(c => c.id === response.data.company.id);
                            //     CompaniesList[index] = response.data.company;
                            //     history.push(routes.COMPANIES);

                            //     return { CompaniesList };
                            // } else {
                            //     const CompaniesList = null;
                            //     history.push(routes.COMPANIES);
                            //     return { CompaniesList, isLoading: true }
                            // }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_COMPANY_PRESIGNED_URL,
                payload: API.getCompanyPresignedUrl(extension)
                    .then(response => {
                        company.company['photo_path'] = response.data.photo_path;
                        dispatch(uploadCompanyImageToS3(response.data.presigned_url, arrayBuffer, company));
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        }
    };
};

export const editCompanyClicked = (company) => {
    history.push(`/customers/${company.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_COMPANY_CLICKED,
        // payload: company
    }
}

export const getEditCompany = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_EDIT_COMPANY,
        payload: API.getEditCompany(id)
            .then((response) => {
                return response.data.company;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const deleteCompany = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_COMPANY,
            payload: API.deleteCompany(id)
                .then((response) => {
                    let CompaniesList = cloneDeep(getState().adminOrSuperAdminReducer.companiesList);
                    let SearchedCompaniesList = cloneDeep(getState().adminOrSuperAdminReducer.searchedCompaniesList);
                    // let AccountsList = cloneDeep(getState().adminOrSuperAdminReducer.accountsList);

                    let UsersList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);

                    if (!response.data.error) {
                        const SearchValue = getState().form.searchCompanieForm.values;
                        const company_rows = getState().adminOrSuperAdminReducer.company_rows;
                        dispatch(updateData());
                        SearchValue.active = getState().adminOrSuperAdminReducer.activeCompanies
                        dispatch(getCompanies(SearchValue, company_rows, 1, []))
                        SuccessAlert('Company is Successfully Deleted');

                        if (SearchedCompaniesList) {
                            let searchIndex = SearchedCompaniesList.findIndex(function (sc) {
                                return sc.company.id === id;
                            });
                            SearchedCompaniesList.splice(searchIndex, 1);
                        }

                        if (UsersList) {
                            UsersList.map((user) => {
                                user.user.companies &&
                                    user.user.companies.map((company, i) => {
                                        if (company.id === id) {
                                            user.user.companies.splice(i, 1);
                                        }
                                    });
                            });
                        }
                        let index = CompaniesList.findIndex(function (c) {
                            return c.id === id
                        });
                        CompaniesList.splice(index, 1);

                        // let AssociatedAccounts = CompaniesList[index].company.accounts.map(user => {
                        //     user.company_associated = false;
                        //     return { user };
                        // });
                        //
                        return { CompaniesList, SearchedCompaniesList, UsersList };
                    } else {
                        return { CompaniesList, SearchedCompaniesList, UsersList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const previewCompanyUsers = (company) => {
    return {
        type: AdminOrSuperAdminActionTypes.PREVIEW_COMPANY_USERS_CLICKED,
        payload: company,
    };
};

export const openAssociateUserClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_COMPANY_USER_CLICKED,
    };
};

export const closeCompanyModalClicked = () => {
    // closeModel();
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_COMPANY_MODAL,
    };
};

export const getFilteredCompanyUsers = (companyId) => {
    return (dispatch, getState) => {
        // const Users = getState().adminOrSuperAdminReducer.usersList;
        // if (!Users) {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_FILTERED_COMPANY_USERS,
            payload: API.getAllUsers()
                .then((response) => {
                    const users = [];
                    response.data.users.forEach((u) => {
                        if (!u.companies) {
                            users.push(u);
                        } else if (u.companies.filter((c) => c.id === parseInt(companyId)).length === 0) {
                            users.push(u);
                        }
                    });
                    const user_rows = response.data.no_of_rows;
                    return { users, user_rows };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
        // } else {
        //     let users = [];

        //     Users.forEach((u) => {
        //         if (!u.user.companies) {
        //             users.push(u)
        //         } else if (u.user.companies.filter((c) => (c.id === parseInt(companyId))).length === 0) {
        //             users.push(u)
        //         }
        //     });

        //     dispatch({
        //         type: AdminOrSuperAdminActionTypes.UPDATE_FILTERED_COMPANY_USERS,
        //         payload: { users }
        //     })
        // }
    };
};
// export const associateCompanyModalClicked = () => {
//     return {
//         type: AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_COMPANY_MODAL
//     }
// }

export const searchCompanyUsers = (companyId, values) => {
    return (dispatch, getState) => {
        const company_users_row = getState().adminOrSuperAdminReducer.company_users_row;
        dispatch({
            type: AdminOrSuperAdminActionTypes.SEARCH_COMPANY_USERS,
            payload: API.getCompanyUsers(companyId, values, company_users_row)
                .then((response) => {
                    if (!response.data.error) {
                        return {
                            // company: response.data.company,
                            companyUsersList: response.data.accounts,
                            total_company_users: response.data.total_count,
                            total_no_of_pages: response.data.page,
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getCompanyUsers = (companyId, values, company_user_no_of_rows, page, sorted, filtered) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_COMPANY_USERS,
            payload: API.getCompanyUsers(companyId, values, company_user_no_of_rows, page, sorted)
                .then((response) => {
                    // smartlook analytics
                    // let data = cloneDeep(response.data.company);
                    // let filteredJobName = response.data && response.data.accounts.map((e) => {
                    //     return {JobId : e.id, JobName : e.name, JobAddress : e.address.formatted_address}
                    // });
                    // const eventName = 'Preview_Customer_Info';
                    // const eventProperties = {
                    //     'preview_customer_id' : data.id,
                    //     'preview_customer_name' : data.name,
                    //     'preview_customer_job_list' : filteredJobName,
                    //     'preview_customer_company_number' : data.company_number,
                    //     'preview_customer_active_status' : data.active_status,
                    //     'preview_customer_created_at' : data.created_at,
                    //     'preview_customer_updated_at' : data.updated_at,
                    //     'preview_customer_mode' : data.mode,
                    // };
                    // window.smartlook('track', eventName, eventProperties);
                    // return response.data.account;
                    return {
                        // company: response.data.company,
                        companyUsersList: response.data.accounts,
                        total_company_users: response.data.total_count,
                        total_no_of_pages: response.data.page,
                        companyName: response.data.company.name,
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
    // const Users = getState().adminOrSuperAdminReducer.usersList;
    // if (!Users) {
    //     dispatch({
    //         type: AdminOrSuperAdminActionTypes.GET_COMPANY_USERS,
    //         payload: API.getUsers()
    //             .then(response => {
    //                 const companyUsers = [];
    //                 response.data.users.forEach((u) => {
    //                     if (u.user.companies && (u.user.companies.filter((c) => (c.id === parseInt(companyId))).length > 0)) {
    //                         companyUsers.push(u)
    //                     }
    //                 });
    //                 const user_rows = response.data.no_of_rows;

    //                 return { companyUsers, user_rows };
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             })
    //     })
    // } else {
    //     let companyUsers = [];

    //     Users.forEach((u) => {
    //         if (u.user.companies && (u.user.companies.filter((c) => (c.id === parseInt(companyId))).length > 0)) {
    //             companyUsers.push(u)
    //         }
    //     });

    //     dispatch({
    //         type: AdminOrSuperAdminActionTypes.GET_ALL_COMPANY_USERS,
    //         payload: { companyUsers }
    //     })
    // }
};

export const addCompanyUsers = (id, body) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_COMPANY_USERS,
            payload: API.addCompanyUsers(id, body)
                .then((response) => {
                    closeModel();
                    if (!response.data.error) {
                        const company_users_row = getState().adminOrSuperAdminReducer.company_users_row;
                        dispatch(updateData());
                        dispatch(getCompanyUsers(id, {}, company_users_row));
                        let usersList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);
                        if (usersList && history.location.pathname === `/customers/${id}/new_customer_user`) {
                            usersList.unshift({ user: response.data.user });
                            // const userListIndex = userList.findIndex(a => a.user.id === response.data.user.id);
                            // userList[userListIndex]['user'] = response.data.user;
                        } else if (usersList) {
                            const index = usersList.findIndex((user) => user.user.id === response.data.user.id);
                            usersList[index] = response.data;
                        }

                        history.push(`/customers/${id}/customer_users`);
                        let companyUsersList = cloneDeep(getState().adminOrSuperAdminReducer.companyUsersList);
                        if (companyUsersList) {
                            companyUsersList.unshift(response.data.user);
                            return { companyUsersList, usersList };
                        } else {
                            return { companyUsersList, usersList, isLoading: true };
                        }
                    } else {
                        return { isLoading: false };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteCompanyUser = (userId, companyId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_COMPANY_USER,
            payload: API.deleteCompanyUser(userId, companyId)
                .then((response) => {
                    let CompanyUsersList = cloneDeep(getState().adminOrSuperAdminReducer.companyUsersList);
                    let UsersList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);
                    let SearchedCompanyUsersList = cloneDeep(
                        getState().adminOrSuperAdminReducer.searchedCompanyUsersList
                    );

                    if (!response.data.error) {
                        const company_users_row = getState().adminOrSuperAdminReducer.company_users_row;
                        const SearchValue = getState().form.searchCompanieUsersForm.values;
                        dispatch(updateData());
                        dispatch(getCompanyUsers(companyId, SearchValue, company_users_row));
                        SuccessAlert('User Successfully Deleted');

                        if (SearchedCompanyUsersList) {
                            let searchIndex = SearchedCompanyUsersList.findIndex(function (cu) {
                                return cu.id === userId;
                            });
                            SearchedCompanyUsersList.splice(searchIndex, 1);
                        }

                        if (UsersList) {
                            let userIndex = UsersList.findIndex(function (cu) {
                                return cu.user.id === userId;
                            });
                            let companyIndex = UsersList[userIndex].user.companies.findIndex(function (c) {
                                return c.id === parseInt(companyId);
                            });
                            UsersList[userIndex].user.companies.splice(companyIndex, 1);
                        }

                        let index = CompanyUsersList.findIndex(function (cu) {
                            return cu.id === userId;
                        });
                        CompanyUsersList.splice(index, 1);
                        return { CompanyUsersList, SearchedCompanyUsersList, UsersList };
                    } else {
                        return { CompanyUsersList, SearchedCompanyUsersList, UsersList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const quotesSearch = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        const quotes_rows = getState().adminOrSuperAdminReducer.quotes_rows;
        const sort_quotes_column = getState().adminOrSuperAdminReducer.sort_quotes_column;
        const quotes_order = getState().adminOrSuperAdminReducer.quotes_order;

        dispatch({
            type: AdminOrSuperAdminActionTypes.SEARCH_QUOTES,
            payload: API.getQuotes(values, rows ? rows : quotes_rows, page ? page : 1, sorted)
                .then((response) => {
                    // const quotes = response.data.quotes;
                    // .sort((a, b) => (a.status === Status.REQUESTED) ? 1 : -1).reverse();
                    // const quotes_rows = response.data.no_of_rows;
                    //
                    return {
                        quotes: response.data.quotes,
                        quotes_rows: response.data.no_of_rows,
                        quotes_page: response.data.total_no_of_pages,
                        totalQuotes: response.data.quotes_tol_count,
                        quote_page_rows: response.data.no_of_rows,
                        searchQuoteValues: values,
                        // sort_quotes_column: columnName ? columnName : '',
                        // quotes_order: desc !== '' ? desc : ''
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const resendEmailCliked = (quote) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.RESEND_QUOTE_EMAIL_CLICKED,
            payload: quote,
        });
        dispatch({
            type: AdminOrSuperAdminActionTypes.RESEND_QUOTE_EMAIL,
            payload: API.resendEmailCliked(quote.id)
                .then((response) => {
                    if (!response.data.error) {
                        SuccessAlert('Email Sent Successfully.');
                    }

                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getQuoteLog = (quote) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_QUOTE_LOG,
            payload: API.getQuoteLog(quote)
                .then((response) => {
                    if (response.data.success) {
                        return { data: response.data };
                        // SuccessAlert('Email Sent Successfully.');
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getQuotes = (values, quotes_no_of_rows, page, sorted) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_QUOTES,
        payload: API.getQuotes(values, quotes_no_of_rows, page, sorted)
            .then((response) => {
                if (!response.data.code || !response.data.error) {
                    // const quotes = response.data.quotes;
                    // .sort((a, b) => (a.status === Status.REQUESTED) ? 1 : -1).reverse();
                    // const quotes_rows = response.data.no_of_rows;
                    const user = storage.get('user');
                    if (user.role === 'inspector') {
                        let openProfile = new URLSearchParams(history.location.search, { ignoreQueryPrefix: true }).get(
                            'edit_profile'
                        );
                        if (openProfile === 'true') {
                            let ele = document.getElementById('edit_button_click');
                            if (ele) {
                                ele.click();
                            }
                        }
                        history.push(`/quotes`);
                    }
                    history.push(`/quotes`);
                }
                return {
                    quotes: response.data.quotes,
                    quotes_rows: response.data.no_of_rows,
                    quotes_page: response.data.total_no_of_pages,
                    totalQuotes: response.data.quotes_tol_count,
                    quoteCurPage: page - 1,
                    quote_page_rows: quotes_no_of_rows,
                    dashboardQuoteCount: response.data.dashboard_count
                    // sort_quotes_column: columnName ? columnName : '',
                    // quotes_order: desc !== '' ? desc : ''
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getQuote = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_REFRESH_QUOTE,
            payload: API.getQuote(id)
                .then((response) => {
                    const quote = response.data;

                    return quote;
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const onQuotesPageSizeChange = (rows) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.UPDATE_QUOTES_PAGE_SIZE,
        payload: API.onQuotesPageSizeChange(rows)
            .then((response) => {
                if (!response.data.error) {
                    return rows.quote_no_of_rows; // SuccessAlert('Succe')
                } else {
                    console.log(response.data.error);
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const goClicked = (values) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.SEARCH_QUOTES,
        payload: API.searchQuote(values.status, values.name)
            .then((response) => {
                return response.data.quotes;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getDropDownAccounts = () => (dispatch) => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ACCOUNTS_DROP_DOWN_LIST,
        payload: API.getDropDownAccounts()
            .then((response) => {
                return response.data.accounts;
            })
            .catch((error) => {
                console.log(error);
            }),
    });
};

export const getCustomers = (id) => (dispatch) => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_CUSTOMERS,
        payload: API.getCustomers(id)
            .then((response) => {
                return response.data.user_info;
            })
            .catch((error) => {
                console.log(error);
            }),
    });
};

export const toggleTagOpportunities = () => {
    toggleOpportunities();
};

export const addQuoteManually = (quote) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD,
        });
    };
};

export const addNewQuote = (quote, arrayBuffer, extentions, openQuoteModal, idsToDelete) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_QUOTE,
            payload: API.addNewQuote(quote)
                .then((response) => {
                    if (response.data.success === true) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data);
                        // let customerName = data.customer_quote && data.customer_quote.map((value) => {
                        //     return {id : value.user.id, name : `${value.user.first_name} ${value.user.last_name}`};
                        // })
                        // const eventName = 'Created_New_Quote';
                        // const eventProperties = {
                        //     'quote_id' : data.id,
                        //     'quote_created_by' : `${data.requestor ? data.requestor.first_name : null} ${data.requestor ? data.requestor.lasst_name : null}`,
                        //     'quote_number' : data.quote_number,
                        //     'quote_job_id' : data.account.id,
                        //     'quote_job_name' : data.account.name,
                        //     'quote_customer_id' : data.confirmed.id ? data.confirmed.id : customerName[0].id,
                        //     'quote_customer_name' : data.confirmed.id ? `${data.confirmed.first_name} ${data.confirmed.last_name}` : customerName[0].name,
                        //     'quote_region_name' : data.region.name,
                        //     'quote_amount' : `$ ${data.total_sale}`,
                        //     'quote_created_at' : data.quote_created_at ? data.quote_created_at : data.created_at,
                        //     'quote_status' : data.status,
                        //     'quote_mode' : quote.quote.mode,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        if (arrayBuffer === undefined || arrayBuffer.length === 0) {
                            const Inspection = cloneDeep(getState().adminOrSuperAdminReducer.inspection);
                            if (openQuoteModal) {
                                Inspection.inspection_form.sections_attributes.map((section, seci) => {
                                    section.line_items_attributes.map((line, li) => {
                                        if (line.id === response.data.line_item_id) {
                                            line.quote_created_for_inspection = response.data.quote_created_for_inspection;
                                        }
                                    })
                                })
                            }
                            $('#addnew_qoutes_button').trigger({ type: 'click' });
                            SuccessAlert('Quote Successfully Added');

                            if (
                                history.location.pathname === routes.NEW_QUOTES ||
                                history.location.pathname === routes.MANUALL_QUOTE
                            ) {
                                history.push(routes.QUOTES);
                            } else if (history.location.pathname === routes.PERFORM_INSPECTION) {
                                history.push(routes.PERFORM_INSPECTION);
                            }
                            return { QuotesList: null, openQuotesModal: false, inspection: Inspection };
                        } else {
                            $('#addnew_qoutes_button').trigger({ type: 'click' });
                            response.data['total_sale'] = quote.quote.total_sale;
                            dispatch(
                                getQuotePresignedUrl(
                                    response.data,
                                    extentions,
                                    arrayBuffer,
                                    idsToDelete,
                                    openQuoteModal
                                )
                            );
                            return { QuotesList: null, openQuotesModal: false };
                        }
                        // let Inspection = storage.get("performInspectionData", null);
                        // cloneDeep(getState().adminOrSuperAdminReducer.inspection);
                        const Inspection = cloneDeep(getState().adminOrSuperAdminReducer.inspection);
                        if (openQuoteModal) {
                            Inspection.inspection_form.sections_attributes.map((section, seci) => {
                                section.line_items_attributes.map((line, li) => {
                                    if (line.id === response.data.line_item_id) {
                                        line.quote_created_for_inspection = response.data.quote_created_for_inspection;
                                    }
                                })
                            })
                        }

                        // dispatch(editInspectionClicked(Inspection));
                        // closeModel();
                        // if (getState().adminOrSuperAdminReducer.quotesList) {
                        //     let quotesList = cloneDeep(getState().adminOrSuperAdminReducer.quotesList);
                        //     if (quotesList) {
                        //         quotesList.push(response.data);
                        //     }

                        //     if (history.location.pathname === routes.NEW_QUOTES) {
                        //         history.push(routes.QUOTES);
                        //     }
                        //     const QuotesList = quotesList.sort((a, b) => (a.status === Status.REQUESTED) ? 1 : -1).reverse();
                        //     return { QuotesList, openQuotesModal: false }
                        // } else {
                        // }
                    } else {
                        return { QuotesList: null, openQuotesModal: true };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateQuote = (quote, setEditCommentObject, openQuoteModal, idsToDelete) => {
    return (dispatch, getState) => {
        if (idsToDelete && idsToDelete.length !== 0) {
            dispatch(deleteQuoteImages(idsToDelete, quote));
        }

        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_QUOTE,
            payload: API.updateQuote(quote)
                .then((response) => {
                    if (response.data.success) {
                        if (openQuoteModal) {
                            var Inspection = cloneDeep(getState().adminOrSuperAdminReducer.inspection);
                            Inspection.inspection_form.sections_attributes.map((section, seci) => {
                                section.line_items_attributes.map((line, li) => {
                                    if (line.id === response.data.line_item_id) {
                                        line.quote_created_for_inspection = response.data.quote_created_for_inspection;
                                    }
                                })
                            })
                            SuccessAlert('Quote Successfully Added');
                        } else {
                            SuccessAlert('Quote Successfully Updated');
                        }
                        if (!openQuoteModal) {
                            history.push(routes.QUOTES);
                        }
                        if (quote.quote.isApprovedQuote || quote.quote.isApproved) {
                            const body = {
                                status: 'approved',
                                total_sale: quote.quote.total_sale,
                                description: quote.quote.description,
                            };
                            dispatch(handelApproveOrRejectClicked(quote.quote.id, body));
                        }
                        if (setEditCommentObject) {
                            setEditCommentObject(null);
                        }
                    }
                    return { QuotesList: null, openQuotesModal: openQuoteModal, inspection: Inspection };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteQuote = (id) => {
    return (dispatch, getState) => {
        // const quotesList = cloneDeep(getState().adminOrSuperAdminReducer.quotesList);
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_QUOTE,
            payload: API.deleteQuote(id)
                .then((response) => {
                    let SearchQuotesList = cloneDeep(getState().adminOrSuperAdminReducer.searchedQuoteList);
                    // const QuotesList = cloneDeep(response.data.quotes);
                    const QuotesList = cloneDeep(getState().adminOrSuperAdminReducer.quotesList);

                    if (!response.data.error) {
                        if (SearchQuotesList) {
                            let searchIndex = SearchQuotesList.findIndex(function (q) {
                                return q.id === id;
                            });
                            SearchQuotesList.splice(searchIndex, 1);
                        }

                        if (QuotesList) {
                            let Index = QuotesList.findIndex(function (q) {
                                return q.id === id;
                            });
                            QuotesList.splice(Index, 1);
                        }

                        SuccessAlert('Quote Successfully deleted');
                        return { QuotesList, SearchQuotesList };
                    } else {
                        return { QuotesList, SearchQuotesList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateTotalSaleLists = (values) => {
    return (dispatch, getState) => {
        const quotesList = cloneDeep(getState().adminOrSuperAdminReducer.quotesList);

        const updatedQuoteList = quotesList.map((q) => {
            let index = values.findIndex((v) => v[0] === q.id);
            if (index > -1) {
                q['total_sale'] = values[index][1];
                return q;
            } else {
                return q;
            }
        });

        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_TOTAL_SALE_LISTS,
            payload: updatedQuoteList,
        });
    };
};

export const handelApproveOrRejectClicked = (id, body) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.APPROVE_OR_REJECT_QUOTE,
            payload: API.approveOrRejectQuote(id, body)
                .then((response) => {
                    if (!response.data.error) {
                        const quotes_rows = getState().adminOrSuperAdminReducer.quotes_rows;
                        dispatch(updateData());
                        dispatch(getQuotes({}, quotes_rows, 1));
                        if (
                            response.data.status === Status.REJECTED_BY_ADMIN ||
                            response.data.status === Status.REJECTED_BY_SUPERADMIN
                        ) {
                            SuccessAlert('Quote successfully Rejected.');
                        } else {
                            // SuccessAlert('Quote successfully Approved.');
                        }

                        closeModel();

                        const quotesList = cloneDeep(getState().adminOrSuperAdminReducer.quotesList);

                        if (
                            response.data.status === Status.APPROVED_BY_ADMIN ||
                            response.data.status === Status.APPROVED_BY_SUPERADMIN
                        ) {
                            history.push(routes.QUOTES);
                        }
                        if (quotesList) {
                            const index = quotesList.findIndex((quote) => quote.id === response.data.id);
                            quotesList[index] = response.data;
                            return quotesList.sort((a, b) => (a.status === Status.REQUESTED ? 1 : -1)).reverse();
                        } else {
                            return quotesList;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateQuoteFormClient = (accessToken, quoteId, body) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.UPDATE_QUOTE_CLINET,
        payload: API.updateQuoteFormClient(accessToken, quoteId, body)
            .then((response) => {
                let quoteApproved;
                let errorMessage
                if (response.data.success) {
                    SuccessAlert('Your quote has been confirmed.');
                    errorMessage = '';
                    quoteApproved = true;
                } else {
                    // SuccessAlert('Your quote has been confirmed.');
                    errorMessage = response.data.error.message;
                    quoteApproved = false;
                }
                return { quoteApproved: quoteApproved, errorMessage: errorMessage }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const loginUserModeSelection = (user_id, mode) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.LOGIN_USER_MODE_SELECTION,
        payload: API.loginUserModeSelection(user_id, mode)
            .then((response) => {
                const user = storage.get('user');
                storage.set('user', user);
                dispatch(updateAuthUser(user));
                // SuccessAlert("Your mode is successfully set to " + mode);
                // window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const SidebarUserModeSelection = (user_id, mode) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.LOGIN_USER_MODE_SELECTION,
        payload: API.loginUserModeSelection(user_id, mode)
            .then((response) => {
                const user = storage.get('user');
                user['selected_mode'] = mode;
                storage.set('user', user);
                // SuccessAlert("Your mode is successfully set to " + mode);
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getModeAccounts = (mode) => {
    return {
        type: AdminOrSuperAdminActionTypes.GET_MODE_ACCOUNTS,
        payload: API.getModeAccounts(mode)
            .then((response) => {
                if (response.data.success) {
                    return {
                        accounts: response.data.accounts,
                    };
                }
                // SuccessAlert("Your mode is successfully set to " + mode);
            })
            .catch((error) => {
                console.log(error);
            })
    }
}
export const getCompanyAccounts = (mode) => {
    return {
        type: AdminOrSuperAdminActionTypes.GET_COMPANY_ACCOUNTS,
        payload: API.getCompanyAccounts(mode)
            .then(response => {
                if (response.data.success) {
                    return {
                        companyAccounts: response.data.account_list,
                    }
                }
                // SuccessAlert("Your mode is successfully set to " + mode);
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export const getAllModeAccountUsers = (accountId, mode) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_MODE_ACCOUNT_USERS,
        payload: API.getAllModeAccountUsers(mode)
            .then((response) => {
                // const users = response.data.users;
                // return {users};
                const users = [];
                response.data.users.forEach((u) => {
                    if (!u.accounts) {
                        users.push(u);
                    } else if (u.accounts.filter((a) => a.id === accountId).length === 0) {
                        users.push(u);
                    }
                });
                const user_rows = response.data.no_of_rows;
                return { users, user_rows, usersList: response.data.users };
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getModeAccountUsers = (mode) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_MODE_ACCOUNT_USERS,
        payload: API.getAllModeAccountUsers(mode)
            .then((response) => {
                const users = response.data.users;
                const user_rows = response.data.no_of_rows;
                return { users, user_rows, usersList: response.data.users };
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getInspectionFormForAccount = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_INSPECTION_FORM_FOR_ACCOUNT,
        payload: API.getInspectionFormForAccount(id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const checkSchedule = (body) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.CHECK_SCHEDULE,
        payload: API.checkSchedule(body)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const goToApprovedQuote = (quote, totalSales) => {
    const editedQuote = setTotalSales(quote, totalSales);
    history.push(`/quotes/${quote.id}/quoteapproved`);
    return {
        type: AdminOrSuperAdminActionTypes.GOTO_APPROVE_QUOTE,
        payload: editedQuote,
    };
};

export const viewWeeklyFilterQuotes = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        const quotes_rows = getState().adminOrSuperAdminReducer.quotes_rows;
        const sort_quotes_column = getState().adminOrSuperAdminReducer.sort_quotes_column;
        const quotes_order = getState().adminOrSuperAdminReducer.quotes_order;

        // if (!quotesList) {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_QUOTES,
            payload: API.getQuotes(values, rows ? rows : quotes_rows, page ? page : 1, sorted)
                .then((response) => {
                    return {
                        quotes: response.data.quotes,
                        quotes_rows: response.data.no_of_rows,
                        quotes_page: response.data.total_no_of_pages,
                        totalQuotes: response.data.quotes_tol_count,
                        dashboardQuoteCount: response.data.dashboard_count
                        // sort_quotes_column: columnName ? columnName : '',
                        // quotes_order: desc !== '' ? desc : ''
                    };

                    // history.push({
                    //     pathname: routes.VIEW_FILTERED_QUOTES,
                    //     state: {
                    //         Status: status,
                    //         isWeek: true,
                    //         isMonth: false,
                    //         isYear: false,
                    //         currentDate: currentDate,
                    //     }
                    // });
                    // return { filteredQuotesList, Quotes }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
        // } else {
        //     const Quotes = cloneDeep(quotesList);
        //     const filteredQuotesList = [];
        //     Quotes.forEach(quote => {
        //         // let createdAt = new Date(quote.created_at).setHours(0, 0, 0, 0);
        //         let createdAt = moment(quote.created_at).startOf('day');
        //         if (status.filter(s => (s === quote.status && (createdAt >= startDate && createdAt <= endDate))).length > 0) {
        //             filteredQuotesList.push(quote);
        //         }
        //     })
        // history.push({
        //     pathname: routes.VIEW_FILTERED_QUOTES,
        //     state: {
        //         Status: status,
        //         isWeek: true,
        //         isMonth: false,
        //         isYear: false,
        //         currentDate: currentDate,
        //     }
        // });
        //     dispatch({
        //         type: AdminOrSuperAdminActionTypes.VIEW_FILTERED_QUOTES,
        //         payload: { filteredQuotesList, Quotes }
        //     })
        // }
    };
};

export const viewMonthlyAndYearlyFilterQuotes = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        // const quotesList = cloneDeep(getState().adminOrSuperAdminReducer.quotesList);
        const quotes_rows = getState().adminOrSuperAdminReducer.quotes_rows;
        const sort_quotes_column = getState().adminOrSuperAdminReducer.sort_quotes_column;
        const quotes_order = getState().adminOrSuperAdminReducer.quotes_order;

        // if (!quotesList) {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_QUOTES,
            payload: API.getQuotes(values, rows ? rows : quotes_rows, page ? page : 1, sorted)
                .then((response) => {
                    return {
                        quotes: response.data.quotes,
                        quotes_rows: response.data.no_of_rows,
                        quotes_page: response.data.total_no_of_pages,
                        totalQuotes: response.data.quotes_tol_count,
                        dashboardQuoteCount: response.data.dashboard_count
                        // sort_quotes_column: columnName ? columnName : '',
                        // quotes_order: desc !== '' ? desc : ''
                    };
                    // const Quotes = cloneDeep(response.data.quotes);
                    // const filteredQuotesList = [];
                    // response.data.quotes.forEach(quote => {
                    //     // let createdAt = new Date(quote.created_at);
                    //     let createdAt = moment(quote.created_at).startOf('day');
                    //     if (isMonth) {
                    //         let createdMonth = createdAt.format('M');
                    //         let createdYear = createdAt.format('YYYY');
                    //         if (status.filter(s => (s === quote.status && (createdMonth === month && createdYear === year))).length > 0) {
                    //             filteredQuotesList.push(quote);
                    //         }
                    //     } else if (isYear) {
                    //         let createdYear = createdAt.format('YYYY');
                    //         if (status.filter(s => (s === quote.status && createdYear === year)).length > 0) {
                    //             filteredQuotesList.push(quote);
                    //         }
                    //     }
                    // })

                    // history.push({
                    //     pathname: routes.VIEW_FILTERED_QUOTES,
                    //     state: {
                    //         Status: status,
                    //         isWeek: false,
                    //         isMonth: isMonth,
                    //         isYear: isYear,
                    //         currentDate: currentDate,
                    //     }
                    // });
                    // return { filteredQuotesList, Quotes }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
        // } else {
        //     const Quotes = cloneDeep(quotesList);
        //     const filteredQuotesList = [];
        //     Quotes.forEach(quote => {
        //         // let createdAt = new Date(quote.created_at);
        //         let createdAt = moment(quote.created_at).startOf('day');
        //         if (isMonth) {
        //             let createdMonth = createdAt.format('M');
        //             let createdYear = createdAt.format('YYYY');
        //             if (status.filter(s => (s === quote.status && (createdMonth === month && createdYear === year))).length > 0) {
        //                 filteredQuotesList.push(quote);
        //             }
        //         } else if (isYear) {
        //             let createdYear = createdAt.format('YYYY');
        //             if (status.filter(s => (s === quote.status && createdYear === year)).length > 0) {
        //                 filteredQuotesList.push(quote);
        //             }
        //         }
        //     })

        // history.push({
        //     pathname: routes.VIEW_FILTERED_QUOTES,
        //     state: {
        //         Status: status,
        //         isWeek: false,
        //         isMonth: isMonth,
        //         isYear: isYear,
        //         currentDate: currentDate,
        //     }
        // });
        // dispatch({
        //     type: AdminOrSuperAdminActionTypes.VIEW_FILTERED_QUOTES,
        //     payload: { filteredQuotesList, Quotes }
        // })
        // }
    };
};

export const SearchUsers = (values, users_no_of_rows, page, sorted) => {
    return (dispatch, getState) => {
        const users_no_of_rows = getState().adminOrSuperAdminReducer.users_no_of_rows;
        const sort_users_column = getState().adminOrSuperAdminReducer.sort_users_column;
        const users_order = getState().adminOrSuperAdminReducer.users_order;

        dispatch({
            type: AdminOrSuperAdminActionTypes.SEARCH_USERS,
            payload: API.getUsers(
                values,
                users_no_of_rows ? users_no_of_rows : users_no_of_rows,
                page ? page : 1,
                sorted
            )
                .then((response) => {
                    // const users = response.data.users;
                    // const user_rows = response.data.no_of_rows;
                    return {
                        users: response.data.users,
                        users_page: response.data.total_no_of_pages,
                        users_no_of_rows: response.data.no_of_rows,
                        totalUsers: response.data.users_tot_count,
                        user_rows: response.data.no_of_rows,
                        searchUserValues: values
                        // sort_users_column: columnName ? columnName : '',
                        // users_order: desc !== '' ? desc : ''
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAllUsers = () => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_DROPDOWN_USERS_LIST,
        payload: API.getAllUsers()
            .then((response) => {
                if (!response.data.error) {
                    return response.data.users;
                } else {
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getUsers = (values, users_no_of_rows, page, sorted) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_USERS,
    payload: API.getUsers(values, users_no_of_rows, page ? page : 1, sorted)
        .then(response => {
            if (!response.data.error || !response.data.code) {
                // const users = response.data.users;
                // const user_rows = response.data.no_of_rows;
                return {
                    users: response.data.users,
                    users_page: response.data.total_no_of_pages,
                    users_no_of_rows: response.data.no_of_rows,
                    totalUsers: response.data.users_tot_count,
                    userCurPage: page - 1,
                    user_rows: users_no_of_rows,
                    searchUserValues: values
                    // sort_users_column: columnName ? columnName : '',
                    // users_order: desc !== '' ? desc : ''
                }
            }
        })
        .catch((error) => {
            console.log(error);
        }),
});

export const onUserPageSizeChange = (rows) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.UPDATE_USER_PAGE_SIZE_CAHANGE,
    payload: API.updateUserPageSizeChange(rows)
        .then(response => {
            if (!response.data.error) {
                return rows.no_of_rows// SuccessAlert('Succe')
            } else {
                console.log(response.data.error);
            }
        })
        .catch(error => {
            console.log(error);
        })
})

export const searchUserClicked = (values) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.SEARCH_USERS,
    payload: API.searchUser(values.name, values.email, values.role)
        .then(response => {

            return response.data.users;
        })
        .catch(error => {
            console.log(error);
        })
})

export const addNewUser = (user, arrayBuffer, extension, accountId, companyId, userNotifications) => {
    let newUser = { ...user }
    if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2).trim() === "1") {
        user.user.phone_number = "+" + newUser.user.phone_number
    } else if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2) !== "+1") {
        user.user.phone_number = "+1 " + newUser.user.phone_number
    }
    return (dispatch, getState) => {
        if (arrayBuffer !== null && arrayBuffer !== undefined) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_USER_PRESIGNED_URL,
                payload: API.getUserPresignedUrl(extension)
                    .then((response) => {
                        user.user['photo_path'] = response.data.photo_path;
                        dispatch(
                            uploadUserImageToS3(
                                response.data.presigned_url,
                                arrayBuffer,
                                user,
                                accountId,
                                companyId,
                                userNotifications
                            )
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            if (accountId) {
                dispatch(addAccountUsers(parseInt(accountId), user));
            } else if (companyId) {
                dispatch(addCompanyUsers(parseInt(companyId), user));
            } else {
                dispatch({
                    type: AdminOrSuperAdminActionTypes.ADD_NEW_USER,
                    payload: API.AddNewUser(user)
                        .then((response) => {
                            if (response.data.success) {
                                // smartlook analytics
                                // let data = cloneDeep(response.data.user);
                                // const eventName = 'Created_New_User';
                                // const eventProperties = {
                                //     'new_user_id' : data.id,
                                //     'new_user_name' : `${data.first_name} ${data.last_name}`,
                                //     'new_user_contact_name' : data.accounts && data.accounts.map((e)=>{return `${e.first_name} ${e.last_name}`}) ,
                                //     'new_user_created_at' : data.created_at,
                                //     'new_user_email' : data.email,
                                //     'new_user_active_status' : data.active_status,
                                //     'new_user_role' : data.role,
                                //     'new_user_phone_number' : data.phone_number,
                                //     'new_user_position_name' : data.position && data.position.name ? data.position.name : null,
                                //     'new_user_region_name' : data.region && data.region.name ? data.region.name : null,
                                //     'new_user_sms_notification' : data.sms_send,
                                //     'new_user_selected_mode' : data.selected_mode,
                                // };
                                // window.smartlook('track', eventName, eventProperties);
                                if (userNotifications) {
                                    dispatch(updateUserPreferences(response.data.user.id, userNotifications));
                                }
                                SuccessAlert('User successfully added.');
                                let ele = document.getElementById('close_duplicate_user_button');
                                if (ele) {
                                    ele.click();
                                }
                                if (getState().adminOrSuperAdminReducer.usersList) {
                                    let userList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);

                                    if (userList) {
                                        userList.unshift(response.data);
                                    }

                                    // else {
                                    history.push(routes.USER);
                                    // }

                                    return userList;
                                } else {
                                    // if (accountId) {
                                    //     dispatch(addAccountUsers(parseInt(accountId), response.data.user.email, [accountId]));
                                    //     history.push(`/accounts/${accountId}/account_users`);
                                    // } else if (companyId) {
                                    //     dispatch(addCompanyUsers(parseInt(companyId), response.data.user.email, [companyId]))
                                    //     history.push(`/companies/${companyId}/company_users`);
                                    // } else {
                                    history.push(routes.USER);
                                    // }
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        }),
                });
            }
        }
    };
};

export const editUserClicked = (user) => {

    let newUser = { ...user }
    if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2).trim() === "1") {
        user.user.phone_number = "+" + newUser.user.phone_number
    } else if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2) !== "+1") {
        user.user.phone_number = "+1 " + newUser.user.phone_number
    }
    history.push(`/user/${user.user.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_USER_CLICKED,
        payload: user,
    };
};

export const emailPreferencesClicked = (user) => {
    history.push(`/settings/preferences/${user.user.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.EMAIL_PREFERENCES_CLICKED,
        payload: user,
    };
};

export const updateUser = (user, arrayBuffer, extension) => {
    let newUser = { ...user }

    if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2).trim() === "1") {
        user.user.phone_number = "+" + newUser.user.phone_number
    } else if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2) !== "+1") {
        user.user.phone_number = "+1 " + newUser.user.phone_number
    }
    return (dispatch, getState) => {
        if (arrayBuffer === null || arrayBuffer === undefined) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.UPDATE_USER,
                payload: API.updateUser(user)
                    .then((response) => {
                        if (response.data.success) {
                            // smartlook analytics
                            // let data = cloneDeep(response.data.user);
                            // const eventName = 'Edited_New_User';
                            // const eventProperties = {
                            //     'edited_user_id' : data.id,
                            //     'edited_user_name' : `${data.first_name} ${data.last_name}`,
                            //     'edited_user_contact_name' : data.accounts && data.accounts.map((e)=>{return `${e.first_name} ${e.last_name}`}) ,
                            //     'edited_user_created_at' : data.created_at,
                            //     'edited_user_updated_at' : data.updated_at,
                            //     'edited_user_email' : data.email,
                            //     'edited_user_active_status' : data.active_status,
                            //     'edited_user_role' : data.role,
                            //     'edited_user_phone_number' : data.phone_number,
                            //     'edited_user_position_name' : data.position && data.position.name ? data.position.name : null,
                            //     'edited_user_region_name' : data.region && data.region.name ? data.region.name : null,
                            //     'edited_user_sms_notification' : data.sms_send,
                            //     'edited_user_selected_mode' : data.selected_mode,
                            // };
                            // window.smartlook('track', eventName, eventProperties);
                            SuccessAlert('User Successfully Updated');
                            if (getState().adminOrSuperAdminReducer.usersList) {
                                let userList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);
                                const index = userList.findIndex((a) => a.user.id === response.data.user.id);
                                debugger;
                                userList[index]['user'] = { form_access: { ...user.user.form_access }, ...response.data.user };
                                history.push(routes.USER);
                                return userList;
                            } else {
                                history.push(routes.USER);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_USER_PRESIGNED_URL,
                payload: API.getUserPresignedUrl(extension)
                    .then((response) => {
                        user.user['photo_path'] = response.data.photo_path;

                        dispatch(uploadUserImageToS3(response.data.presigned_url, arrayBuffer, user));
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        }
    };
};

export const getEditUser = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_EDIT_USER,
        payload: API.getEditUser(id)
            .then((response) => {
                //  ;
                const requestor = {};
                requestor['user'] = response.data.user;
                return requestor;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const updateUserPreferences = (id, user, from) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_USER_PREFERENCES,
            payload: API.updatePreferences(id, user)
                .then((response) => {
                    if (response.data.success) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data);
                        // const eventName = 'User_Email_Summary_Notification';
                        // const eventProperties = {
                        //     'user_id' : data.id,
                        //     'user_name' : `${data.first_name} ${data.last_name}`,
                        //     'user_contact_name' : data.accounts && data.accounts.map((e)=>{return `${e.first_name} ${e.last_name}`}) ,
                        //     'user_created_at' : data.created_at,
                        //     'user_email' : data.email,
                        //     'user_active_status' : data.active_status,
                        //     'user_role' : data.role,
                        //     'user_phone_number' : data.phone_number,
                        //     'user_position_name' : data.position && data.position.name ? data.position.name : null,
                        //     'user_region_name' : data.region && data.region.name ? data.region.name : null,
                        //     'user_sms_notification' : data.sms_send,
                        //     'user_selected_mode' : data.selected_mode,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        if (from !== 'clone') {
                            history.push(routes.USER);
                            SuccessAlert("Successfully updated.");
                        }
                        // const user = cloneDeep(getState().authReducer.user);
                        // user['inspections'] = response.data.inspections;
                        // user['summaries'] = response.data.summaries;
                        // user['tickets'] = response.data.tickets;

                        // return user;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteUser = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_USER,
            payload: API.deleteUser(id)
                .then((response) => {
                    let userList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);
                    let SearchUserList = cloneDeep(getState().adminOrSuperAdminReducer.searchedUsersList);

                    if (!response.data.error) {
                        const users_no_of_rows = getState().adminOrSuperAdminReducer.users_no_of_rows;
                        const SearchValue = getState().form.searchUserForm.values;
                        dispatch(updateData());
                        dispatch(getUsers(SearchValue, users_no_of_rows));
                        if (SearchUserList) {
                            let searchIndex = SearchUserList.findIndex(function (u) {
                                return u.user.id === id;
                            });
                            SearchUserList.splice(searchIndex, 1);
                        }

                        let index = userList.findIndex(function (u) {
                            return u.user.id === id;
                        });

                        userList.splice(index, 1);
                        SuccessAlert('User Successfully deleted');
                        return { userList, SearchUserList };
                    } else {
                        return { userList, SearchUserList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAccounts = (values, users_no_of_rows, page, sorted, filtered, userId) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ACCOUNTS,
        payload: API.getAccounts(values, users_no_of_rows, page, sorted, filtered, userId)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        accounts: response.data.users,
                        account_page: response.data.total_no_of_pages,
                        accounts_rows: response.data.no_of_rows,
                        totalAccounts: response.data.accounts_tol_count,
                        accountCurPage: page - 1,
                        job_rows: users_no_of_rows,
                        searchaccountValues: values,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getUserPaginationAccounts = (values, users_no_of_rows, page, sorted, filtered, userId) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ACCOUNTS,
        payload: API.getUserPaginationAccounts(values, users_no_of_rows, page, sorted, filtered, userId)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        accounts: response.data.accounts,
                        searchaccountValues: values,
                        account_page: response.data.total_no_of_pages,
                        accounts_rows: response.data.no_of_rows,
                        totalAccounts: response.data.accounts_tol_count,
                        job_rows: users_no_of_rows,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const searchAccount = (values, user) => {
    return (dispatch, getState) => {
        const accounts_rows = getState().adminOrSuperAdminReducer.accounts_rows;
        // const sort_account_column = getState().adminOrSuperAdminReducer.sort_account_column;
        // const account_order = getState().adminOrSuperAdminReducer.account_order;
        if (user.role === userRoles.SUPER_ADMIN) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.SEARCH_ACCOUNTS,
                payload: API.getAccounts(values, accounts_rows, 1)
                    .then((response) => {
                        if (!response.data.error) {
                            return {
                                accounts: response.data.users,
                                account_page: response.data.total_no_of_pages,
                                accounts_rows: response.data.no_of_rows,
                                totalAccounts: response.data.accounts_tol_count,
                                job_rows: response.data.no_of_rows,
                                searchaccountValues: values,
                                // sort_ticket_column: columnName ? columnName : '',
                                // ticket_order: desc !== '' ? desc : ''
                            };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.SEARCH_ACCOUNTS,
                payload: API.getUserPaginationAccounts(values, accounts_rows, 1, [], [], user.id)
                    .then((response) => {
                        if (!response.data.error) {
                            return {
                                accounts: response.data.accounts,
                                account_page: response.data.total_no_of_pages,
                                accounts_rows: response.data.no_of_rows,
                                totalAccounts: response.data.accounts_tol_count,
                                job_rows: response.data.no_of_rows,
                                searchaccountValues: values,
                                // sort_ticket_column: columnName ? columnName : '',
                                // ticket_order: desc !== '' ? desc : ''
                            };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        }
    };
};

export const getUserAccounts = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_DROPDOWN_ACCOUNTS,
        payload: API.getUserAccounts(id)
            .then((response) => {
                let accounts = cloneDeep(response.data.accounts);
                sortArrayOfObject(accounts);
                return { accounts };
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const searchAccountClicked = (values) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.SEARCH_ACCOUNT,
        payload: API.searchAccounts(values.name, values.email)
            .then((response) => {
                return response.data.users;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const uploadUserImageToS3 = (url, arrayBuffer, user, accountId, companyId, userNotifications) => {
    debugger
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPLOAD_USER_IMAGE_TO_S3,
            payload: API.uploadImageToS3(url, arrayBuffer)
                .then((response) => {
                    if (user.user.id) {
                        dispatch({
                            type: AdminOrSuperAdminActionTypes.UPDATE_USER,
                            payload: API.updateUser(user)
                                .then((response) => {
                                    if (response.data.success) {
                                        // smartlook analytics
                                        // let data = cloneDeep(response.data.user);
                                        // const eventName = 'Edited_New_User';
                                        // const eventProperties = {
                                        //     'edited_user_id' : data.id,
                                        //     'edited_user_name' : `${data.first_name} ${data.last_name}`,
                                        //     'edited_user_contact_name' : data.accounts && data.accounts.map((e)=>{return `${e.first_name} ${e.last_name}`}) ,
                                        //     'edited_user_created_at' : data.created_at,
                                        //     'edited_user_updated_at' : data.updated_at,
                                        //     'edited_user_email' : data.email,
                                        //     'edited_user_active_status' : data.active_status,
                                        //     'edited_user_role' : data.role,
                                        //     'edited_user_phone_number' : data.phone_number,
                                        //     'edited_user_position_name' : data.position && data.position.name ? data.position.name : null,
                                        //     'edited_user_region_name' : data.region && data.region.name ? data.region.name : null,
                                        //     'edited_user_sms_notification' : data.sms_send,
                                        //     'edited_user_selected_mode' : data.selected_mode,
                                        // };
                                        // window.smartlook('track', eventName, eventProperties);
                                        SuccessAlert('User Successfully Updated');
                                        if (getState().adminOrSuperAdminReducer.usersList) {
                                            let userList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);
                                            const index = userList.findIndex((a) => a.user.id === response.data.user.id);
                                            userList[index]['user'] = response.data.user;
                                            history.push(routes.USER);
                                            return userList;
                                        } else {
                                            history.push(routes.USER);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    }
                    else if (user['user']['id']) {
                        dispatch({
                            type: AdminOrSuperAdminActionTypes.ADD_NEW_USER,
                            payload: API.AddNewUser(user).then(response => {
                                if (response.data.success) {
                                    // smartlook analytics
                                    // let data = cloneDeep(response.data.user);
                                    // const eventName = 'Edited_New_User';
                                    // const eventProperties = {
                                    //     'edited_user_id' : data.id,
                                    //     'edited_user_name' : `${data.first_name} ${data.last_name}`,
                                    //     'edited_user_contact_name' : data.accounts && data.accounts.map((e)=>{return `${e.first_name} ${e.last_name}`}) ,
                                    //     'edited_user_created_at' : data.created_at,
                                    //     'edited_user_updated_at' : data.updated_at,
                                    //     'edited_user_email' : data.email,
                                    //     'edited_user_active_status' : data.active_status,
                                    //     'edited_user_role' : data.role,
                                    //     'edited_user_phone_number' : data.phone_number,
                                    //     'edited_user_position_name' : data.position && data.position.name ? data.position.name : null,
                                    //     'edited_user_region_name' : data.region && data.region.name ? data.region.name : null,
                                    //     'edited_user_sms_notification' : data.sms_send,
                                    //     'edited_user_selected_mode' : data.selected_mode,
                                    // };
                                    // window.smartlook('track', eventName, eventProperties);
                                    if (userNotifications) {
                                        dispatch(updateUserPreferences(response.data.user.id, userNotifications, 'clone'))
                                    }
                                    let ele = document.getElementById('close_duplicate_user_button')
                                    if (ele) {
                                        ele.click()
                                    }
                                    SuccessAlert('User successfully added.');
                                    if (getState().adminOrSuperAdminReducer.usersList) {
                                        let userList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);
                                        const index = userList.findIndex((a) => a.user.id === response.data.user.id);
                                        userList[index]['user'] = response.data.user;
                                        history.push(routes.USER);
                                        return userList;
                                    } else {
                                        history.push(routes.USER);
                                    }
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    } else {
                        if (accountId) {
                            dispatch(addAccountUsers(parseInt(accountId), user));
                            // history.push(`/accounts/${accountId}/account_users`);
                        } else if (companyId) {
                            dispatch(addCompanyUsers(parseInt(companyId), user));
                            // history.push(`/companies/${companyId}/company_users`);
                        } else {
                            dispatch({
                                type: AdminOrSuperAdminActionTypes.ADD_NEW_USER,
                                payload: API.AddNewUser(user)
                                    .then((response) => {
                                        if (response.data.success) {
                                            // smartlook analytics
                                            // let data = cloneDeep(response.data.user);
                                            // const eventName = 'Created_New_User';
                                            // const eventProperties = {
                                            //     'new_user_id' : data.id,
                                            //     'new_user_name' : `${data.first_name} ${data.last_name}`,
                                            //     'new_user_contact_name' : data.accounts && data.accounts.map((e)=>{return `${e.first_name} ${e.last_name}`}) ,
                                            //     'new_user_created_at' : data.created_at,
                                            //     'new_user_email' : data.email,
                                            //     'new_user_active_status' : data.active_status,
                                            //     'new_user_role' : data.role,
                                            //     'new_user_phone_number' : data.phone_number,
                                            //     'new_user_position_name' : data.position && data.position.name ? data.position.name : null,
                                            //     'new_user_region_name' : data.region && data.region.name ? data.region.name : null,
                                            //     'new_user_sms_notification' : data.sms_send,
                                            //     'new_user_selected_mode' : data.selected_mode,
                                            // };
                                            // window.smartlook('track', eventName, eventProperties);
                                            if (userNotifications) {
                                                dispatch(
                                                    updateUserPreferences(response.data.user.id, userNotifications)
                                                );
                                            }
                                            let ele = document.getElementById('close_duplicate_user_button');
                                            if (ele) {
                                                ele.click();
                                            }
                                            SuccessAlert('User successfully added.');
                                            if (getState().adminOrSuperAdminReducer.usersList) {
                                                let userList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);

                                                if (userList) {
                                                    userList.unshift(response.data);
                                                }

                                                // else {
                                                history.push(routes.USER);
                                                // }

                                                return userList;
                                            } else {
                                                // if (accountId) {
                                                //     dispatch(addAccountUsers(parseInt(accountId), response.data.user.email, [accountId]));
                                                //     history.push(`/accounts/${accountId}/account_users`);
                                                // } else if (companyId) {

                                                //     dispatch(addCompanyUsers(parseInt(companyId), response.data.user.email, [companyId]))
                                                //     history.push(`/companies/${companyId}/company_users`);
                                                // } else {
                                                history.push(routes.USER);
                                                // }
                                            }
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    }),
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const uploadImageToS3 = (url, arrayBuffer, account, isAccountUpdate) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
            payload: API.uploadImageToS3(url, arrayBuffer)
                .then((response) => {
                    dispatch({
                        type: AdminOrSuperAdminActionTypes.UPDATE_ACCOUNT,
                        payload: API.updateAccount(account)
                            .then((response) => {
                                if (!response.data.error) {
                                    if (isAccountUpdate) {
                                        SuccessAlert('Job successfully Added.');
                                    } else {
                                        SuccessAlert('Job successfully Updated.');
                                    }
                                    if (getState().adminOrSuperAdminReducer.accountsList) {
                                        let AccountsList = cloneDeep(getState().adminOrSuperAdminReducer.accountsList);
                                        const index = AccountsList.findIndex((a) => a.user.id === response.data.id);
                                        AccountsList[index]['user'] = response.data;
                                        history.push(routes.ACCOUNTS);
                                        return AccountsList;
                                    } else {
                                        history.push(routes.ACCOUNTS);
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            }),
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addNewAccount = (account, arrayBuffer) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_ACCOUNT,
            payload: API.addNewAccount(account)
                .then((response) => {
                    if (!response.data.error && !response.data.success === false) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data);
                        // const eventName = 'Created_New_Job';
                        // const eventProperties = {
                        //     'mode' : data.mode,
                        //     'job_account_id' : data.id,
                        //     'job_account_number' : data.account_number,
                        //     'job_account_name' : data.name,
                        //     'job_creation' : data.created_at,
                        //     'job_address' : `${data.address && data.address.formatted_address ? data.address.formatted_address : null}, ${data.address && data.address.zip ? data.address.zip : null}`,
                        //     'job_timezone' : data.timezone,
                        //     'assigned_region_id' : data.region && data.region.id ? data.region.id : null,
                        //     'assigned_region_name' : data.region && data.region.name ? data.region.name : null,
                        //     'assigned_region_created_time' : data.region && data.region.created_at ? data.region.created_at : null,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        const Account = {};
                        if (arrayBuffer !== null && arrayBuffer !== undefined) {
                            const acnt = {};
                            acnt['account'] = response.data;
                            acnt.account.photo_path = response.data.file_upload.image_path;
                            dispatch(uploadImageToS3(response.data.file_upload.presigned_url, arrayBuffer, acnt, true));
                            if (getState().adminOrSuperAdminReducer.accountsList) {
                                let AccountsList = cloneDeep(getState().adminOrSuperAdminReducer.accountsList);
                                Account['user'] = response.data;
                                if (AccountsList) {
                                    AccountsList.unshift(Account);
                                }
                                return { AccountsList, isLoading: true };
                            }
                        } else {
                            SuccessAlert('Job successfully added.');
                            let ele = document.getElementById('close_duplicate_button');
                            if (ele) {
                                ele.click();
                            }
                            if (getState().adminOrSuperAdminReducer.accountsList) {
                                let AccountsList = cloneDeep(getState().adminOrSuperAdminReducer.accountsList);

                                Account['user'] = response.data;
                                history.push(routes.ACCOUNTS);
                                if (AccountsList) {
                                    AccountsList.unshift(Account);
                                }

                                return { AccountsList, isLoading: false };
                            } else {
                                history.push(routes.ACCOUNTS);
                            }
                        }
                    } else {
                        return { AccountsList: null, isLoading: false };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteAccount = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_ACCOUNT,
            payload: API.deleteAccount(id)
                .then((response) => {
                    let AccountsList = cloneDeep(getState().adminOrSuperAdminReducer.accountsList);
                    let SearchedAccountList = cloneDeep(getState().adminOrSuperAdminReducer.searchedAccountList);
                    let UsersList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);

                    if (response.data.success) {
                        const SearchValue = cloneDeep(getState().form.searchAccountForm.values);
                        const account_rows = getState().adminOrSuperAdminReducer.accounts_rows;
                        dispatch(updateData());
                        SearchValue.active = getState().adminOrSuperAdminReducer.activeJobs
                        dispatch(getAccounts(SearchValue, account_rows));
                        SuccessAlert('The Job is Successfully Deleted');

                        if (SearchedAccountList) {
                            let searchIndex = SearchedAccountList.findIndex(function (u) {
                                return u.user.id === id;
                            });
                            SearchedAccountList.splice(searchIndex, 1);
                        }

                        if (UsersList) {
                            UsersList.map((user) => {
                                user.user.accounts &&
                                    user.user.accounts.map((account, i) => {
                                        if (account.id === id) {
                                            user.user.accounts.splice(i, 1);
                                        }
                                    });
                            });
                        }

                        let index = AccountsList.findIndex(function (u) {
                            return u.user.id === id;
                        });

                        AccountsList.splice(index, 1);
                        return { AccountsList, SearchedAccountList, UsersList };
                    } else {
                        return { AccountsList, SearchedAccountList, UsersList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const onAccountsPageSizeChange = (rows) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.UPDATE_ACCOUNTS_PAGE_SIZE_CHANGE,
        payload: API.onAccountsPageSizeChange(rows)
            .then((response) => {
                if (!response.data.error) {
                    return rows.account_no_of_rows; // SuccessAlert('Succe')
                } else {
                    console.log(response.data.error);
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const editAccountClicked = (account) => {
    history.push(`/accounts/${account.user.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_ACCOUNT_CLICKED,
        payload: account,
    };
};

export const AddJobUsersClicked = (account) => {
    history.push(`/accounts/${account.id ? account.id : account.user.id}/new_account_user`);
    const accounts = [];
    accounts.push(account);
    const user = { accounts };
    return {
        type: AdminOrSuperAdminActionTypes.Add_JOB_USERS_CLICKED,
        payload: { user },
    };
};

export const updateAccount = (account, arrayBuffer, extension) => {
    return (dispatch, getState) => {
        if (arrayBuffer === null || arrayBuffer === undefined) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.UPDATE_ACCOUNT,
                payload: API.updateAccount(account)
                    .then((response) => {
                        if (!response.data.error && !response.data.success === false) {
                            // smartlook analytics
                            // let data = cloneDeep(response.data);
                            // const eventName = 'Edited_Job';
                            // const eventProperties = {
                            //     'mode' : data.mode,
                            //     'job_account_id' : data.id,
                            //     'job_account_number' : data.account_number,
                            //     'job_account_name' : data.name,
                            //     'job_creation' : data.created_at,
                            //     'job_updated' : data.updated_at,
                            //     'job_address' : `${data.address && data.address.formatted_address ? data.address.formatted_address : null}, ${data.address && data.address.zip ? data.address.zip : null}`,
                            //     'job_timezone' : data.timezone,
                            //     'assigned_region_id' : data.region && data.region.id ? data.region.id : null,
                            //     'assigned_region_name' : data.region && data.region.name ? data.region.name : null,
                            //     'assigned_region_created_time' : data.region && data.region.created_at ? data.region.created_at : null,
                            // };
                            // window.smartlook('track', eventName, eventProperties);
                            SuccessAlert('Job Successfully Updated');
                            if (getState().adminOrSuperAdminReducer.accountsList) {
                                let AccountsList = cloneDeep(getState().adminOrSuperAdminReducer.accountsList);
                                const index = AccountsList.findIndex((a) => a.user.id === response.data.id);
                                AccountsList[index]['user'] = response.data;
                                history.push(routes.ACCOUNTS);
                                return AccountsList;
                            } else {
                                history.push(routes.ACCOUNTS);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_ACCOUNTS_PRESIGNED_URL,
                payload: API.getAccountsPreSignedUrl(account, extension)
                    .then((response) => {
                        account.account['photo_path'] = response.data.photo_path;
                        dispatch(uploadImageToS3(response.data.presigned_url, arrayBuffer, account));
                    })
                    .catch((error) => {
                        console.loog(error);
                    }),
            });
        }
    };
};

export const getEditAccount = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_EDIT_ACCOUNT,
        payload: API.getEditAccount(id)
            .then((response) => {
                //  ;
                const account = {};
                account['user'] = response.data.account;
                return account;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const previewAccountUsers = (account) => {
    return {
        type: AdminOrSuperAdminActionTypes.PREVIEW_ACCOUNT_USERS,
        payload: account,
    };
};

export const setCurrentAccount = (account) => {
    return {
        type: AdminOrSuperAdminActionTypes.SET_CURRENT_ACCOUNT,
        payload: account,
    };
};

export const onUpdatePasswordClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.UPDATE_PASSWORD_CLICKED,
    };
};
export const onUserDuplicateModalClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.USER_DUPLICATE_MODAL_CLICKED,
    };
};
export const onAccountDuplicateModalClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.ACCOUNT_DUPLICATE_MODAL_CLICKED,
    };
};
export const onInspectionDuplicateModalClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.INSPECTION_DUPLICATE_MODAL_CLICKED,
    };
};

export const closePasswordModalClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_PASSWORD_MODAL_CLICKED,
    };
};

export const associateAccoutUserClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_ACCOUNT_USER_CLICKED,
    };
};

export const associateAccoutHolidaysClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_ACCOUNT_HOLIDAYS_CLICKED,
    };
};

export const closeAccountModalClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_ACCOUNT_MODAL,
    };
};

export const associateAccountJobsClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_ASSOCIATE_ACCOUNT_JOBS_CLICKED,
    };
};

export const getFilteredAccountUsers = (accountId) => {
    return (dispatch, getState) => {
        // const Users = getState().adminOrSuperAdminReducer.usersList;

        // if (true) {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_FILTERED_ACCOUNTS_USERS,
            payload: API.getAllUsers()
                .then((response) => {
                    const users = [];
                    response.data.users.forEach((u) => {
                        if (!u.accounts) {
                            users.push(u);
                        } else if (u.accounts.filter((a) => a.id === parseInt(accountId)).length === 0) {
                            users.push(u);
                        }
                    });
                    const user_rows = response.data.no_of_rows;
                    return { users, user_rows, usersList: response.data.users };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
        // } else {
        //     let users = [];

        //     Users.forEach((u) => {
        //         if (!u.user.accounts) {
        //             users.push(u)
        //         } else if (u.user.accounts.filter((a) => (a.id === parseInt(accountId))).length === 0) {
        //             users.push(u)
        //         }
        //     });

        //     dispatch({
        //         type: AdminOrSuperAdminActionTypes.UPDATE_FILTERED_ACCOUNTS_USERS,
        //         payload: { users }
        //     })
        // }
    };
};

export const searchAccountUsers = (accountId, values, rows, page) => {
    return (dispatch, getState) => {
        const account_users_row = getState().adminOrSuperAdminReducer.account_users_row;
        dispatch({
            type: AdminOrSuperAdminActionTypes.SEARCH_ACCOUNT_USERS,
            payload: API.getAccountUsersInfoPage(accountId, values, account_users_row ? account_users_row : rows, page)
                .then(response => {
                    if (!response.data.error) {
                        return {
                            // account: { user: response.data.account },
                            account: response.data.account,
                            accountUsersList: response.data.users,
                            account_users_page: response.data.total_no_of_pages,
                            account_users_row: response.data.no_of_rows,
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAccountUsersInfoPage = (accountId, values, account_user_no_of_rows, page, sorted, filtered) => {
    return (dispatch, getState) => {
        const account = getState().adminOrSuperAdminReducer.account;
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS_INFO_PAGE,
            payload: API.getAccountUsersInfoPage(accountId, values, account_user_no_of_rows, page, sorted)
                .then(response => {

                    // return response.data.account;
                    return {
                        account: response.data.account,
                        // accountData:  response.data,
                        accountUsersList: response.data.users,
                        account_users_page: response.data.total_no_of_pages,
                        account_users_row: response.data.no_of_rows,
                        account_users_tot_count: response.data.account_users_tot_count
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getAccountUsers = (accountId, values, account_user_no_of_rows, page, sorted, filtered) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS,
            payload: API.getAccountUsers(accountId, values, account_user_no_of_rows, page, sorted)
                .then((response) => {
                    // return response.data.account;
                    return {
                        account: { user: response.data.account },
                        accountUsersList: response.data.account.users,
                        account_users_page: response.data.total_no_of_pages,
                        account_users_row: response.data.no_of_rows,
                        account_users_tot_count: response.data.account_users_tot_count,
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
    // const Users = getState().adminOrSuperAdminReducer.usersList;
    // if (!Users) {
    //     dispatch({
    //         type: AdminOrSuperAdminActionTypes.GET_ACCOUNT__USERS,
    //         payload: API.getUsers()
    //             .then(response => {
    //                 const accountUsers = [];
    //                 response.data.users.forEach((u) => {
    //                     if (u.user.accounts && (u.user.accounts.filter((c) => (c.id === parseInt(accountId))).length > 0)) {
    //                         accountUsers.push(u)
    //                     }
    //                 });
    //                 const user_rows = response.data.no_of_rows;

    //                 return { accountUsers, user_rows };
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             })
    //     })
    // } else {
    //     let accountUsers = [];

    //     Users.forEach((u) => {
    //         if (u.user.accounts && (u.user.accounts.filter((c) => (c.id === parseInt(accountId))).length > 0)) {
    //             accountUsers.push(u)
    //         }
    //     });

    //     dispatch({
    //         type: AdminOrSuperAdminActionTypes.GET_ALL_ACCOUNT__USERS,
    //         payload: { accountUsers }
    //     })
    // }
};

export const getAccountUsersInfo = (accountId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_ACCOUNT_USERS_INFO,
            payload: API.getAccountUsersInfo(accountId)
                .then((response) => {
                    return {
                        accountUsersList: response.data.users,
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addAccountUsers = (id, body) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_ACCOUNT_USERS,
            payload: API.addAccountUsers(id, body)
                .then((response) => {
                    closeModel();
                    if (!response.data.error) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data.user);
                        // const eventName = 'Created_Jobs_New_User';
                        // const eventProperties = {
                        //     'jobs_user_created_at' : data.created_at,
                        //     'jobs_user_email' : data.email,
                        //     'jobs_user_id' : data.id,
                        //     'jobs_user_name' : `${data.first_name} ${data.last_name}`,
                        //     'jobs_user_phone_number' : data.phone_number,
                        //     'jobs_user_position_name' : data.position && data.position.name ? data.position.name : null,
                        //     'jobs_user_region_name' : data.region && data.region.name ? data.region.name : null,
                        //     'jobs_user_sms_notification' : data.sms_send,
                        //     'jobs_user_contact_name' : data.accounts && data.accounts.map((e)=>{return `${e.first_name} ${e.last_name}`}) ,
                        //     'mode' : data.mode,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        const account_users_row = getState().adminOrSuperAdminReducer.account_users_row;
                        // dispatch(updateData());
                        dispatch(getAccountUsersInfoPage(id, {}, account_users_row, 1));
                        let usersList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);
                        if (usersList && history.location.pathname === `/accounts/${id}/new_account_user`) {
                            usersList.unshift({ user: response.data.user });
                        } else if (usersList) {
                            const index = usersList.findIndex((user) => user.user.id === response.data.user.id);
                            usersList[index] = response.data;
                        }
                        history.push(`/accounts/${id}/account_users?job_users=true`);
                        let accountUsersList = cloneDeep(getState().adminOrSuperAdminReducer.accountUsersList);
                        if (accountUsersList) {
                            accountUsersList = response.data.account.users;

                            return { accountUsersList, usersList };
                        } else {
                            return { accountUsersList, usersList, isLoading: true };
                        }
                    } else {
                        return { isLoding: false };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const submitAssociateAccountHolidays = (accountId, holidaysId) => {
    return (dispatch, getState) => {
        let account = getState().adminOrSuperAdminReducer.account;
        dispatch({
            type: AdminOrSuperAdminActionTypes.SUBMIT_ASSOCUATE_ACCOUNT_HOLIDAYS,
            payload: API.submitAssociateAccountHolidays(accountId, holidaysId)
                .then((response) => {
                    if (!response.data.error) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data.account);
                        // const eventName = 'Jobs_Associated_New_Holidays';
                        // const eventProperties = {
                        //     'job_id' : data.id,
                        //     'job_account_number' : data.account_number,
                        //     'job_name' : data.name,
                        //     'job_created_by' : `${data.first_name} ${data.last_name}`,
                        //     'job_owner_user_id' : data.owner_user_id,
                        //     'job_address' : `${data.address && data.address.formatted_address ? data.address.formatted_address : null}, ${data.address && data.address.zip ? data.address.zip : null}`,
                        //     'job_total_holidays' : data.holidays && data.holidays.length ? data.holidays.length : 0,
                        //     'job_region_name' : data.region.name,
                        //     'job_created_at' : data.created_at,
                        //     'job_updated_at' : data.updated_at,
                        //     'mode' : data.mode,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        account['holidays'] = response.data.account.holidays;
                        closeModel();
                        SuccessAlert('Holiday Added Sucessfully.');
                        return account;
                    }
                })
                .catch((error) => {
                    console.error(error);
                }),
        });
    };
};

export const submitAssociateAccountJobs = (accountId, inspectionIds) => {
    return (dispatch, getState) => {
        let account = getState().adminOrSuperAdminReducer.account;
        dispatch({
            type: AdminOrSuperAdminActionTypes.SUBMIT_ASSOCIATE_ACCOUNT_JOBS,
            payload: API.submitAssociateAccountJobs(accountId, inspectionIds)
                .then((response) => {
                    if (response.data.success) {
                        account['inspections'] = response.data.account.inspection_forms;
                        closeModel();
                        SuccessAlert('Inspection Added Sucessfully.');
                        return account;
                    } else {
                        closeModel();
                        return account;
                    }
                })
                .catch((error) => {
                    console.error(error);
                }),
        });
    };
};

export const getAccountHolidays = ({ params, accountId }) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_ACCOUNT_HOLIDAYS,
            payload: API.getAccountHolidays(params, accountId)
                .then((response) => {
                    if (response.data.success) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteAccountUser = (userId, accountId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_ACCOUNT_USERS,
            payload: API.deleteAccountUser(userId, accountId)
                .then((response) => {
                    let AccountUsersList = cloneDeep(getState().adminOrSuperAdminReducer.accountUsersList);
                    let UsersList = cloneDeep(getState().adminOrSuperAdminReducer.usersList);
                    let SearchedAccountUsersList = cloneDeep(
                        getState().adminOrSuperAdminReducer.searchedAccountUsersList
                    );

                    if (!response.data.error) {
                        const SearchValue = getState().form.searchAccountUsersForm.values;
                        const account_users_row = getState().adminOrSuperAdminReducer.account_users_row;
                        // dispatch(updateData());
                        // dispatch(getAccountUsersInfoPage(accountId, SearchValue, account_users_row, 1))

                        SuccessAlert('User Successfully Deleted');
                        if (SearchedAccountUsersList) {
                            let searchIndex = SearchedAccountUsersList.findIndex(function (u) {
                                return u.id === userId;
                            });
                            SearchedAccountUsersList.splice(searchIndex, 1);
                        }

                        if (UsersList) {
                            let userIndex = UsersList.findIndex(function (cu) {
                                return cu.user.id === userId;
                            });
                            let accountIndex = UsersList[userIndex].user.accounts.findIndex(function (a) {
                                return a.id === parseInt(accountId);
                            });
                            UsersList[userIndex].user.accounts.splice(accountIndex, 1);
                        }

                        let index = AccountUsersList.findIndex(function (u) {
                            return u.id === userId;
                        });

                        AccountUsersList.splice(index, 1);
                        return { AccountUsersList, SearchedAccountUsersList, UsersList };
                    } else {
                        return { AccountUsersList, SearchedAccountUsersList, UsersList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getTickets = (values, ticket_no_of_rows, page, sorted, filtered, queryParams) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_TICKETS,
        payload: API.getTickets(values, ticket_no_of_rows, page ? page : 1, sorted, filtered, queryParams)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        tickets: response.data.tickets,
                        ticket_page: response.data.total_no_of_pages,
                        ticket_no_of_rows: response.data.ticket_no_of_rows,
                        totalTickets: response.data.ticket_page_count,
                        ticketCurPage: page - 1,
                        ticket_rows: ticket_no_of_rows,
                        searchTicketValues: values,
                        ticketIds: response.data.ticketIds
                        // sort_ticket_column: columnName ? columnName : '',
                        // ticket_order: desc !== '' ? desc : ''
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
export const getPriorEscalationTickets = (values, ticket_no_of_rows, page, sorted, filtered) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_PRIOR_TICKETS,
        payload: API.getPriorTickets(values, ticket_no_of_rows, page ? page : 1, sorted, filtered)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        tickets: response.data.tickets,
                        ticket_page: response.data.total_no_of_pages,
                        ticket_no_of_rows: response.data.ticket_no_of_rows,
                        totalTickets: response.data.tickets_tol_count,
                        ticketCurPage: page - 1,
                        ticket_rows: ticket_no_of_rows,
                        searchTicketValues: values,
                        ticketIds: response.data.ticketIds,
                        openTicketCount: response.data.total_open_count,
                        acceptedTicketCount: response.data.total_accepted_count,
                        resolvedTicketCount: response.data.total_resolved_count,
                        // sort_ticket_column: columnName ? columnName : '',
                        // ticket_order: desc !== '' ? desc : ''
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
export const getMyTickets = (values, ticket_no_of_rows, page, sorted, filtered) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_MY_TICKETS,
        payload: API.getMyTickets(values, ticket_no_of_rows, page ? page : 1, sorted, filtered)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        tickets: response.data.tickets,
                        ticket_page: response.data.total_no_of_pages,
                        ticket_no_of_rows: response.data.ticket_no_of_rows,
                        totalTickets: response.data.ticket_page_count,
                        ticketCurPage: page - 1,
                        ticket_rows: ticket_no_of_rows,
                        searchTicketValues: values,
                        ticketIds: response.data.ticketIds
                        // sort_ticket_column: columnName ? columnName : '',
                        // ticket_order: desc !== '' ? desc : ''
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getPreviewScheduledTickets = (values, ticket_no_of_rows, page, sorted, filtered, ticketId) => (dispatch) =>{
    debugger;
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_PREVIEW_SCHEDULED_TICKETS,
        payload: API.getPreviewScheduledTickets(values, ticket_no_of_rows, page ? page : 1, sorted, filtered, ticketId)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        tickets: response.data.tickets,
                        ticket_page: response.data.total_no_of_pages,
                        ticket_no_of_rows: response.data.ticket_no_of_rows,
                        totalTickets: response.data.tickets_tol_count,
                        ticketCurPage: page - 1,
                        ticket_rows: ticket_no_of_rows,
                        previewScheduleSearchTicketValues: values,
                        ticketIds: response.data.ticketIds
                        // sort_ticket_column: columnName ? columnName : '',
                        // ticket_order: desc !== '' ? desc : ''
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
}

export const deleteScheduledTicket = (ticketId) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_SCHEDULED_TICKET,
        payload: API.deleteScheduledTicket(ticketId).then((res) => {
            if (!res.data.error) {
                SuccessAlert('Scheduled Ticket Deleted Successfully.');
                return { ticket_id: ticketId };
            }
        }),
    });

export const getScheduledTickets = (values, ticket_no_of_rows, page, sorted, filtered, isForm) => (dispatch) => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_SCHEDULED_TICKET,
        payload: API.getScheduledTickets(values, ticket_no_of_rows, page ? page : 1, sorted, filtered, isForm)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        tickets: response.data.scheduleTickets,
                        ticket_page: response.data.pages,
                        ticket_no_of_rows: response.data.no_of_rows,
                        totalTickets: response.data.count,
                        scheduleTicketCurPage: page - 1,
                        ticket_rows: ticket_no_of_rows,
                        searchTicketValues: values,
                        ticketIds: response.data.ticketIds
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
}


export const ticketPageClicked = (ticket) => {
    return {
        type: AdminOrSuperAdminActionTypes.TICKET_PAGE_CLICKED,
        payload: ticket,
    };
};

export const scheduleTicketPageClicked = (ticket) => {
    return {
        type: AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_PAGE_CLICKED,
        payload: ticket,
    };
};

export const previewScheduleTicketPageClicked = (ticket) => {
    return {
        type: AdminOrSuperAdminActionTypes.PREVIEW_SCHEDULE_TICKET_PAGE_CLICKED,
        payload: ticket,
    };
};

export const companyPageClicked = (company) => {

    return {
        type: AdminOrSuperAdminActionTypes.COMPANY_PAGE_CLICKED,
        payload: company
    }
}

export const quotePageClicked = (quote) => {
    return {
        type: AdminOrSuperAdminActionTypes.QUOTE_PAGE_CLICKED,
        payload: quote,
    };
};

export const chiPageClicked = (chi) => {

    return {
        type: AdminOrSuperAdminActionTypes.CHI_PAGE_CLICKED,
        payload: chi
    }
}

export const getUserTicketsList = (values, ticket_no_of_rows, page, sorted, filtered) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_TICKETS,
    payload: API.getUserTicketsList(
        values, ticket_no_of_rows, page ? page : 1, sorted, filtered
    )
        .then(response => {

            return {
                tickets: response.data.tickets,
                ticketIds: response.data.ticketIds,
                ticket_page: response.data.total_no_of_pages,
                ticket_no_of_rows: response.data.user_ticket_no_of_rows,
                totalTickets: response.data.ticket_page_count,
                ticketCurPage: page - 1,
                ticket_rows: ticket_no_of_rows,
                searchTicketValues: values,
                dashboardTicketCount: response.data.dashboard_count

                // totalTickets: response.data.
            };
        })
        .catch(error => {
            console.log(error);
        })
})
export const getUserPriorTicketsList = (values, ticket_no_of_rows, page, sorted, filtered) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_USER_TICKETS,
    payload: API.getUserTicketsList(
        values, ticket_no_of_rows, page ? page : 1, sorted, filtered
    )
        .then(response => {

            return {
                tickets: response.data.tickets,
                ticketIds: response.data.ticketIds,
                ticket_page: response.data.total_no_of_pages,
                ticket_no_of_rows: response.data.user_ticket_no_of_rows,
                totalTickets: response.data.tickets_tol_count,
                ticketCurPage: page - 1,
                ticket_rows: ticket_no_of_rows,
                searchTicketValues: values,
                dashboardTicketCount: response.data.dashboard_count,

                // totalTickets: response.data.
            };
        })
        .catch(error => {
            console.log(error);
        })
})
export const getUserMyTicketsList = (values, ticket_no_of_rows, page, sorted, filtered) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_USER_TICKETS,
    payload: API.getUserMyTicketsList(
        values, ticket_no_of_rows, page ? page : 1, sorted, filtered
    )
        .then(response => {

            return {
                tickets: response.data.tickets,
                ticketIds: response.data.ticketIds,
                ticket_page: response.data.total_no_of_pages,
                ticket_no_of_rows: response.data.user_ticket_no_of_rows,
                totalTickets: response.data.tickets_tol_count,
                ticketCurPage: page - 1,
                ticket_rows: ticket_no_of_rows,
                searchTicketValues: values,
                dashboardTicketCount: response.data.dashboard_count

                // totalTickets: response.data.
            };
        })
        .catch(error => {
            console.log(error);
        })
})

export const searchTicket = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        const ticket_rows = getState().adminOrSuperAdminReducer.ticket_no_of_rows;
        // const sort_ticket_column = getState().adminOrSuperAdminReducer.sort_ticket_column;
        // const ticket_order = getState().adminOrSuperAdminReducer.ticket_order;
        if (user.role === userRoles.SUPER_ADMIN) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.SEARCH_TICKETS,
                payload: API.getTickets(values, rows ? rows : ticket_rows, page ? page : 1, sorted)
                    .then((response) => {
                        if (!response.data.error) {
                            return {
                                tickets: response.data.tickets,
                                ticketIds: response.data.ticketIds,
                                ticket_page: response.data.total_no_of_pages,
                                ticket_no_of_rows: response.data.ticket_no_of_rows,
                                totalTickets: response.data.ticket_page_count,
                                searchTicketValues: values,
                                ticket_rows: response.data.ticket_no_of_rows,
                                // sort_ticket_column: columnName ? columnName : '',
                                // ticket_order: desc !== '' ? desc : ''
                            };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.SEARCH_TICKETS,
                payload: API.getUserTicketsList(values, rows ? rows : ticket_rows, page ? page : 1, sorted)
                    .then((response) => {
                        if (!response.data.error) {
                            return {
                                tickets: response.data.tickets,
                                ticket_page: response.data.total_no_of_pages,
                                ticket_no_of_rows: response.data.user_ticket_no_of_rows,
                                totalTickets: response.data.ticket_page_count,
                                searchTicketValues: values,
                                ticket_rows: response.data.ticket_no_of_rows,
                                // sort_ticket_column: columnName ? columnName : '',
                                // ticket_order: desc !== '' ? desc : ''
                            };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        }
    };
};

export const searchPriorEscalationTicket = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        const ticket_rows = getState().adminOrSuperAdminReducer.ticket_no_of_rows;
        // const sort_ticket_column = getState().adminOrSuperAdminReducer.sort_ticket_column;
        // const ticket_order = getState().adminOrSuperAdminReducer.ticket_order;
        dispatch({
            type: AdminOrSuperAdminActionTypes.SEARCH_PRIOR_ESCALATION_TICKETS,
            payload: API.getPriorTickets(values, rows ? rows : ticket_rows, page ? page : 1, sorted)
                .then((response) => {
                    if (!response.data.error) {

                        return {
                            tickets: response.data.tickets,
                            ticketIds: response.data.ticketIds,
                            ticket_page: response.data.total_no_of_pages,
                            ticket_no_of_rows: response.data.ticket_no_of_rows,
                            totalTickets: response.data.tickets_tol_count,
                            searchTicketValues: values,
                            ticket_rows: response.data.ticket_no_of_rows,
                            // sort_ticket_column: columnName ? columnName : '',
                            // ticket_order: desc !== '' ? desc : ''
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const searchMyTicket = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        const ticket_rows = getState().adminOrSuperAdminReducer.ticket_no_of_rows;
        // const sort_ticket_column = getState().adminOrSuperAdminReducer.sort_ticket_column;
        // const ticket_order = getState().adminOrSuperAdminReducer.ticket_order;
        if (user.role === userRoles.SUPER_ADMIN) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.SEARCH_TICKETS,
                payload: API.getMyTickets(values, rows ? rows : ticket_rows, page ? page : 1, sorted)
                    .then((response) => {
                        if (!response.data.error) {
                            return {
                                tickets: response.data.tickets,
                                ticketIds: response.data.ticketIds,
                                ticket_page: response.data.total_no_of_pages,
                                ticket_no_of_rows: response.data.ticket_no_of_rows,
                                totalTickets: response.data.ticket_page_count,
                                searchTicketValues: values,
                                ticket_rows: response.data.ticket_no_of_rows,
                                // sort_ticket_column: columnName ? columnName : '',
                                // ticket_order: desc !== '' ? desc : ''
                            };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.SEARCH_TICKETS,
                payload: API.getUserMyTicketsList(values, rows ? rows : ticket_rows, page ? page : 1, sorted)
                    .then((response) => {
                        if (!response.data.error) {
                            return {
                                tickets: response.data.tickets,
                                ticket_page: response.data.total_no_of_pages,
                                ticket_no_of_rows: response.data.user_ticket_no_of_rows,
                                totalTickets: response.data.ticket_page_count,
                                searchTicketValues: values,
                                ticket_rows: response.data.ticket_no_of_rows,
                                // sort_ticket_column: columnName ? columnName : '',
                                // ticket_order: desc !== '' ? desc : ''
                            };
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        }
    };
};

export const getTicketPresignedUrl = (ticket, extentions, arrayBuffer, idsToDelete, openTicketModal) => {
    return (dispatch, getState) => {
        // const TicketList = cloneDeep(getState().adminOrSuperAdminReducer.ticketsList);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_TICKET_PRESIGNED_URL,
            payload: API.getTicketPresignedUrl(ticket, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    let Ticket = {}
                    Ticket['ticket'] = {}
                    Ticket.ticket['attachment_paths'] = [];
                    Ticket.ticket['id'] = ticket.ticket.id;
                    ticket.ticket['attachment_paths'] = []; //created for edit ticket purpose!
                    response.data.presigned_urls.forEach((p, i) => {

                        if (ticket && ticket.isNewTicket && ticket.isNewTicket == true) {
                            Ticket.ticket.attachment_paths.push(p.attachment_path); // for New Ticket
                        } else {
                            ticket.ticket.attachment_paths.push(p.attachment_path); // for Edit Ticket
                        }

                        dispatch({
                            type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        if (ticket && ticket.isNewTicket && ticket.isNewTicket == true) {
                                            dispatch(updateTicket(Ticket, idsToDelete, null, openTicketModal)); // for New Ticket
                                        } else {
                                            dispatch(updateTicket(ticket, idsToDelete, null, openTicketModal)); // for Edit Ticket
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};
export const getQuotePresignedUrl = (quote_detail, extentions, arrayBuffer, idsToDelete, openQuoteModal) => {
    return (dispatch, getState) => {
        // const TicketList = cloneDeep(getState().adminOrSuperAdminReducer.ticketsList);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_QUOTE_PRESIGNED_URL,
            payload: API.getQuotePresignedUrl(quote_detail, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    let quote = {};
                    quote.quote = {};
                    quote.quote['presigned_urls'] = response.data.presigned_urls;
                    quote.quote['attachments_attributes'] = [];
                    if (response.data.presigned_urls.length > 0 && response.data.presigned_urls) {
                        response.data.presigned_urls.length > 0 &&
                            response.data.presigned_urls.forEach((p, i) => {
                                quote.quote.attachments_attributes.push(p.attachment_path);
                                dispatch({
                                    type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
                                    payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                        .then((response) => {
                                            totalAttachments++;
                                            if (quote.quote.presigned_urls.length === totalAttachments) {
                                                if (quote_detail.isApproved) {
                                                    const userDetail = getUser();
                                                    quote.quote['id'] = quote_detail.id;
                                                    quote.quote['description'] = quote_detail.description;
                                                    quote.quote['total_sale'] = quote_detail.total_sale;
                                                    quote.quote['delete_photo_ids'] = idsToDelete;
                                                    quote.quote['isApprovedQuote'] = quote_detail.isApproved;
                                                    quote.quote['mode'] = userDetail.mode;
                                                } else {
                                                    const userDetail = getUser();
                                                    quote.quote['confirmed_by'] = quote_detail.confirmed.id;
                                                    quote.quote['account_id'] = quote_detail.account.id;
                                                    quote.quote['description'] = quote_detail.description;
                                                    quote.quote['manual'] = quote_detail.manual;
                                                    quote.quote['mode'] = userDetail.mode;
                                                    quote.quote['private_quote'] = quote_detail.manual;
                                                    quote.quote['quote_created_at'] = quote_detail.quote_created_at
                                                        ? quote_detail.quote_created_at
                                                        : quote_detail.created_at;
                                                    quote.quote['total_sale'] = quote_detail.total_sale;
                                                    quote.quote['id'] = quote_detail.id;
                                                    quote.quote['delete_photo_ids'] = idsToDelete;
                                                    // quote.quote['isApprovedQuote'] = quote_detail.quote.isApproved;
                                                    if (quote_detail.status === 'draft') {
                                                        quote.quote['submit_now'] = false;
                                                    }
                                                }
                                                dispatch(updateQuote(quote, null, openQuoteModal, idsToDelete));
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        }),
                                });
                            });
                    } else {
                        const quote = {};
                        quote.quote = quote_detail;
                        quote.quote['delete_photo_ids'] = idsToDelete;
                        delete quote.quote.quote_created_at;
                        delete quote.quote.by_method;
                        delete quote.quote.confirmed_by;
                        delete quote.quote.quote_content;
                        // quote.quote['quote_customer_deleted_ids'] = quote.quote.customer_quote;
                        delete quote.quote.customer_quote;

                        dispatch(updateQuote(quote, null, openQuoteModal));
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addNewTicket = (ticket, arrayBuffer, extentions, openTicketModal) => async (dispatch) => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.ADD_NEW_TICKET,
        payload : API.addNewTicket(ticket).then((response) => {
            if (response.data.success) {
                // smartlook analytics
                let data = cloneDeep(response.data);
    
                const eventName = 'Created_New_Ticket';
                const eventProperties = {
                    'ticket_created_by': `${data.requestor_user?.first_name || ''} ${data.requestor_user?.last_name || ''}`,
                    'ticket_id': data.id,
                    'ticket_status': data.status,
                    'ticket_creation': data.created_at,
                    'job_account_id': data.account?.id || null,
                    'job_account_name': data.account?.name || null,
                };
    
                if (window.REACT_APP_ENV === 'production') {
                    window.smartlook('track', eventName, eventProperties);
                }
    
                if (arrayBuffer.length === 0) {
                    const Inspection = cloneDeep(store.getState().adminOrSuperAdminReducer.inspection);
    
                    if (openTicketModal) {
                        Inspection.inspection_form.sections_attributes.forEach((section) => {
                            section.line_items_attributes.forEach((line) => {
                                if (line.id === response.data.line_item_id) {
                                    line.ticket_created_for_inspection = response.data.ticket_created_for_inspection;
                                }
                            });
                        });
    
                        $("#ticketModalButton").trigger({ type: "click" });
                        dispatch(closePopup());
                    }
    
                    // Google Analytics
                    const Ticket = {};
                    const user = storage.get('user');
                    logEvent(analytics, 'add_new_ticket_web', {
                        user_role: user.role,
                        user_email: user.email,
                        user_name: `${user.first_name} ${user.last_name}`,
                        userId: user.id,
                        ticket_id: response.data.id,
                    });
                    SuccessAlert('Ticket Successfully added');
    
                    dispatch({
                        type: AdminOrSuperAdminActionTypes.ADD_NEW_TICKET_WITH_AXIOS,
                        payload: { inspection: Inspection },
                    });
    
                    if (!openTicketModal) {
                        history.push(routes.TICKETS);
                    }
    
                    return true;
                } else {
                    let ticketValues = {
                        ticket: cloneDeep(response.data),
                        isNewTicket: true,
                    };
                    dispatch(getTicketPresignedUrl(ticketValues, extentions, arrayBuffer, [], openTicketModal));
                }
            } else {
                errorHandler(response.data.message ? response.data.message : "Something went wrong!");
            }
        }).catch((error) => {
            console.error(error)
        }) 
    });
};

export const editTicketClicked = (ticket,projectModule) => {
    if (ticket && ticket.ticketEvent && ticket.ticketEvent.schedule_ticket_id) {
        if(projectModule || projectModule !==undefined){
            history.push({
                pathname: `/tickets/${ticket.id}`,
                state: { isScheduleTicketEdit: true },
                search: '?projectmodule=true',
            });
        }
        else{

            history.push({
                pathname: `/tickets/${ticket.id}`,
                state: { isScheduleTicketEdit: true }
            });
        }
    } else {
        debugger;
        history.push(`/tickets/${ticket.id}`);
    }
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_TICKET_CLICKED,
        payload: ticket,
    };
};

export const getEditTicket = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_EDIT_TICKET,
        payload: API.getEditTicket(id)
            .then((response) => {
                // smartlook analytics
                // let data = cloneDeep(response.data);
                // if(data && data.pegassure_feedback) {
                //     const eventName = 'Preview_Pegassure_Ticket_Info';
                //     const eventProperties = {
                //         'preview_pegassure_ticket_id' : data.id,
                //         'preview_pegassure_ticket_job_name' : data.account && data.account.name ? data.account.name : null,
                //         'preview_pegassure_ticket_status' : data.status,
                //         'preview_pegassure_ticket_subject' : data.subject,
                //         'preview_pegassure_ticket_message' : data.message,
                //         'preview_pegassure_ticket_priority' : data.priority,
                //         'preview_pegassure_ticket_due_on' : data.due_on,
                //         'preview_pegassure_ticket_start_time' : data.start_time,
                //         'preview_pegassure_ticket_created_at' : data.created_at,
                //         'preview_pegassure_ticket_updated_at' : data.updated_at,
                //     };
                //     window.smartlook('track', eventName, eventProperties);
                // } else {
                //     const eventName = 'Preview_Ticket_Info';
                //     const eventProperties = {
                //         'ticket_created_by' : `${data && data.requestor_user && data.requestor_user.first_name ? data.requestor_user.first_name : null} ${data && data.requestor_user && data.requestor_user.last_name ? data.requestor_user.last_name : null}`,
                //         'ticket_id' : data.id,
                //         'ticket_status' : data.status,
                //         'ticket_creation' : data.created_at,
                //         'ticket_updated' : data.updated_at,
                //         'job_account_id' : data && data.account && data.account.id ? data.account.id : null,
                //         'job_account_name' : data && data.account && data.account.name ? data.account.name : null,
                //         'ticket_assigned_to' : `${data && data.user && data.user.first_name ? data.user.first_name : null} ${data && data.user && data.user.last_name ? data.user.last_name : null}`,
                //         'ticket_category' : data && data.category && data.category.name ? data.category.name : null,
                //         'ticket_due_date' : data.due_on,
                //     };
                //     window.smartlook('track', eventName, eventProperties);
                // }
                storage.set('ticketId', response.data.id);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });


export const deleteTicektImages = (idsToDelete, ticket, setEditCommentObject) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_PORTFORLIO_IMAGES,
            payload: API.deletePortfolioImages(idsToDelete)
                .then((response) => {
                    if (!response.data.error) {
                        if (ticket.chi) {
                            SuccessAlert('Image Deleted Successfully');
                            dispatch(getChiPreview(ticket.chi.id))
                            if (setEditCommentObject) {
                                setEditCommentObject(null);
                            }
                        }
                        else {
                            dispatch({
                                type: AdminOrSuperAdminActionTypes.UPDATE_TICKET,
                                payload: API.updateTicket(ticket)
                                    .then(response => {
                                        if (!response.data.error) {
                                            let Ticket = cloneDeep(response.data);
                                            if (Ticket.account) {
                                                if (history.location.pathname === routes.NEW_TICKET) {
                                                    SuccessAlert('Ticket Successfully Added');
                                                } else {
                                                    SuccessAlert('Ticket Successfully Updated');
                                                }
                                                if (getState().adminOrSuperAdminReducer.ticketsList) {

                                                    let TicketsList = cloneDeep(getState().adminOrSuperAdminReducer.ticketsList);
                                                    const index = TicketsList.findIndex(t => t.id === response.data.id);

                                                    TicketsList[index] = response.data;
                                                    history.push(routes.TICKETS);

                                                    return TicketsList;
                                                } else {
                                                    // history.push(routes.TICKETS);
                                                    history.goBack();
                                                    return { TicketsList: null };
                                                }
                                            }
                                            else {
                                                if (getState().adminOrSuperAdminReducer.chi) {

                                                    let TicketsList = cloneDeep(getState().adminOrSuperAdminReducer.chi);
                                                    const index = TicketsList.findIndex(t => t.id === response.data.id);

                                                    TicketsList[index] = response.data;

                                                    return TicketsList;
                                                } else {
                                                    return { TicketsList: null };
                                                }
                                            }

                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                            })
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteQuoteImages = (idsToDelete, quote) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_PORTFORLIO_IMAGES,
            payload: API.deletePortfolioImages(idsToDelete)
                .then((response) => {
                    if (!response.data.error) {
                        dispatch({
                            type: AdminOrSuperAdminActionTypes.UPDATE_QUOTE,
                            payload: API.updateQuote(quote)
                                .then((response) => {
                                    if (!response.data.error) {
                                        if (history.location.pathname === routes.NEW_QUOTES) {
                                            SuccessAlert('Ticket Successfully Added');
                                        } else {
                                            SuccessAlert('Ticket Successfully Updated');
                                        }

                                        if (getState().adminOrSuperAdminReducer.quotesList) {
                                            let QuotesList = cloneDeep(getState().adminOrSuperAdminReducer.quotesList);
                                            const index = QuotesList.findIndex((t) => t.id === response.data.id);

                                            QuotesList[index] = response.data;
                                            history.push(routes.QUOTES);

                                            return QuotesList;
                                        } else {
                                            history.push(routes.QUOTES);
                                            return { QuotesList: null };
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateTicket = (ticket, idsToDelete, setEditCommentObject, openTicketModal) => {
    return (dispatch, getState) => {
        if (idsToDelete && idsToDelete.length !== 0) {
            dispatch(deleteTicektImages(idsToDelete, ticket, setEditCommentObject));
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.UPDATE_TICKET,
                payload: API.updateTicket(ticket)
                    .then(response => {

                        
                        if (!response.data.error) {
                            // smartlook analytics
                            
                            let data = cloneDeep(response.data);
                            debugger;
                            if (ticket && ticket.isScheduleTicketEdit == true) {
                                // const eventName = 'Edited_Schedule_Ticket';
                                // const eventProperties = {
                                //     'edited_ticket_created_by' : `${data.requestor_user && data.requestor_user.first_name ? data.requestor_user.first_name : null} ${data.requestor_user && data.requestor_user.last_name ? data.requestor_user.last_name : null}`,
                                //     'edited_ticket_id' : data.id,
                                //     'edited_ticket_status' : data.status,
                                //     'edited_ticket_description' : data.message,
                                //     'edited_ticket_due_date' : data.due_on,
                                //     'edited_ticket_created_at' : data.created_at,
                                //     'edited_ticket_updated' : data.updated_at,
                                //     'edited_ticket_job_id' : data.account &&data.account.id ? data.account.id : null,
                                //     'edited_ticket_job_name' : data.account && data.account.name ? data.account.name : null,
                                // };
                                // window.smartlook('track', eventName, eventProperties);
                            } else {
                                const eventName = 'Edited_Ticket';
                                const eventProperties = {
                                    'ticket_created_by': `${data.requestor_user && data.requestor_user.first_name ? data.requestor_user.first_name : null} ${data.requestor_user && data.requestor_user.last_name ? data.requestor_user.last_name : null}`,
                                    'ticket_id': data.id,
                                    'ticket_status': data.status,
                                    'ticket_creation': data.created_at,
                                    'ticket_updated': data.updated_at,
                                    'job_account_id': data.account && data.account.id ? data.account.id : null,
                                    'job_account_name': data.account && data.account.name ? data.account.name : null,
                                };
                                if (window.REACT_APP_ENV == 'production') {
                                    window.smartlook('track', eventName, eventProperties);
                                }
                            };
                            let searchTicketValues = getState().adminOrSuperAdminReducer.searchTicketValues;
                            if (history.location.pathname.includes('/priority')) {
                                searchTicketValues['status'] = 'resolved';
                                setTimeout(() => {
                                    history.push(routes.PRIORITY_ESCALATION_TICKETS);
                                }, 500);
                            }
                            // closeModel();
                            const Inspection = cloneDeep(getState().adminOrSuperAdminReducer.inspection);
                            if (openTicketModal) {
                                Inspection.inspection_form.sections_attributes.map((section, seci) => {
                                    section.line_items_attributes.map((line, li) => {
                                        if (line.id === response.data.line_item_id) {
                                            line.ticket_created_for_inspection = response.data.ticket_created_for_inspection;
                                        }
                                    })
                                })
                                $('#ticketModalButton').trigger({ type: 'click' });
                                dispatch(closePopup());
                            }
                           
                            if (setEditCommentObject && (setEditCommentObject.length > 0)) {
                                setEditCommentObject(null);
                            }
                            let Ticket = cloneDeep(response.data);
                            
                            if (Ticket.account) {
                                if (history.location.pathname === routes.NEW_TICKET || history.location.pathname === routes.EDIT_INSPECTION) {
                                    SuccessAlert('Ticket Successfully Added');
                                } else if (history.location.pathname === `/tickets/${ticket.id ? ticket.id : ticket.ticket.id}`) {
                                    SuccessAlert('Ticket Successfully Updated');
                                    const user = storage.get('user')
                                    logEvent(analytics, 'update_ticket_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, ticket_id: response.data.id });
                                } if (history.location.pathname === `/tickets/view_ticket/${ticket.id ? ticket.id : ticket.ticket.id}` && Ticket.status && Ticket.status === TicketStatus.open) {
                                    SuccessAlert('Comment Successfully Added');
                                }
                            } else {
                                SuccessAlert('CHI Ticket Successfully Updated');
                                let ele = document.getElementById('reassign_close_modal')
                                if (ele) {
                                    ele.click()
                                }
                                if (response.data.status === TicketStatus.resolved) {
                                    var serveyList = cloneDeep(getState().adminOrSuperAdminReducer.chiServyList);
                                    serveyList && serveyList.map((s, i) => {
                                        if (s.ticket[0].id === response.data.id) {
                                            s.ticket[0] = response.data;
                                        }
                                    })
                                }
                                history.push(routes.CHI_SERVYS);
                            }
                            if (getState().adminOrSuperAdminReducer.ticketsList) {
                                if (Ticket.account) {
                                    let TicketsList = cloneDeep(getState().adminOrSuperAdminReducer.ticketsList);
                                    if (response.data.status === 'canceled') {
                                        history.goBack();
                                        SuccessAlert('Ticket Successfully Canceled');
                                        return { TicketsList: TicketsList ? TicketsList : ticket.ticket, Ticket, inspection: Inspection };
                                    }
                                    const index = TicketsList.findIndex(t => t.id === Ticket.id);
                                    TicketsList[index] = Ticket;
                                    if (history.location.pathname === `/tickets/view_ticket/${Ticket.id}` && Ticket.status !== 'resolved') {
                                        return { TicketsList: TicketsList ? TicketsList : ticket.ticket, Ticket, inspection: Inspection };
                                    } else {
                                        if (!openTicketModal && !(response.data.subject.includes("escalation"))) {
                                            
                                            const searchParams=new URLSearchParams(window.location.search);
                                             if(searchParams && searchParams.get("projectmodule")){
                                                history.goBack();
                                             }else{
                                                history.push(routes.TICKETS);
                                             }

                                            //if(window.location)
                                            
                                        } else if (response.data.subject.includes("escalation")) {
                                            // history.push(routes.PRIORITY_ESCALATION_TICKETS);
                                        } else if (history.location.pathname.includes('inspections/performInspection')) {
                                            SuccessAlert('Ticket Successfully Added');
                                        }
                                        return { TicketsList: TicketsList ? TicketsList : ticket.ticket, Ticket, inspection: Inspection, searchTicketValues: searchTicketValues };
                                    }
                                } else {
                                    let TicketsList = null;
                                    return { TicketsList: TicketsList ? TicketsList : ticket.ticket, Ticket, inspection: Inspection, searchTicketValues: searchTicketValues }
                                }
                            } else {
                                if (response.data.account) {
                                    let TicketsList = null;
                                    if (ticket && ticket.ticket && !(ticket.ticket.ticket_event_id) && !(response.data.resolved_user)) {
                                        if (response.data.status === 'open' && !(ticket.ticket.attachment_paths)) {
                                            return { TicketsList: TicketsList ? TicketsList : ticket.ticket, Ticket, searchTicketValues: searchTicketValues }
                                        } else {
                                            if (!history.location.pathname.includes('inspections/performInspection')) {
                                                history.goBack();
                                            }
                                            else if (history.location.pathname.includes('inspections/performInspection')) {
                                                SuccessAlert('Ticket Successfully Added');
                                                Ticket = {}
                                                ticket.ticket = null
                                            }
                                            else if (response.data.status === 'canceled') {
                                                SuccessAlert('Ticket Successfully Canceled');
                                            }
                                            return { TicketsList: TicketsList ? TicketsList : ticket.ticket, Ticket, inspection: Inspection, searchTicketValues: searchTicketValues }
                                        }
                                    }
                                    if (history.location.pathname === `/tickets/view_ticket/${ticket.id}` && ticket.status !== 'resolved') {
                                        return { TicketsList: TicketsList ? TicketsList : ticket.ticket, Ticket, searchTicketValues: searchTicketValues }
                                    } else {
                                        if (!openTicketModal && !(response.data.subject.includes("escalation"))) {
                                            // history.push(routes.TICKETS);
                                            if (ticket && ticket.ticket && ticket.ticket.ticket_event_id) {
                                                history.goBack();
                                                const scheduleTicketId = storage.get('scheduleTicketId');
                                                dispatch(getPreviewScheduledTickets([], '', '', [], [], scheduleTicketId))
                                                storage.remove('scheduleTicketId');
                                            }
                                        } else if (response.data.subject.includes("escalation")) {
                                            // history.push(routes.PRIORITY_ESCALATION_TICKETS);

                                        }
                                        //history.push(routes.TICKETS);
                                        return { TicketsList: TicketsList ? TicketsList : [], Ticket, inspection: Inspection, searchTicketValues: searchTicketValues }
                                    }
                                } else {
                                    return { chi: response.data, serveyList: serveyList, searchTicketValues: searchTicketValues }
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
        }
    }
}

export const previewTicketClicked = (ticket, queryParams) => {debugger
    storage.set('ticketId', ticket.id);
    history.push(`/tickets/view_ticket/${ticket.id}`);
    const user = storage.get('user')
    logEvent(analytics, 'preview_ticket_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, ticket_id: ticket.id });
    return {
        type: AdminOrSuperAdminActionTypes.PREVIEW_TICKET,
        payload: queryParams,
    };
};

export const previewSingleTicketClicked = (ticket, isForm) => {
    let windowLocationValue = '';
    if (window.location.href && window.location.href.includes('.com')) {
        windowLocationValue = window.location.href.split('//')[1].split('.com')[1]
    } else {
        windowLocationValue = window.location.href.substring(21)
    }
    if (isForm) {
        history.push({ pathname: `/project-module/ticket/${ticket.id}`, state: { storedRoute: windowLocationValue && windowLocationValue.length > 0 ? windowLocationValue : null } });
        storage.set('scheduleTitle', ticket.title)
        storage.set('scheduleTickekId', ticket.id)
    } else {
        history.push({ pathname: `/schedules/ticket/${ticket.id}`, state: { storedRoute: windowLocationValue && windowLocationValue.length > 0 ? windowLocationValue : null } });
        storage.set('scheduleTitle', ticket.title)
        storage.set('scheduleTickekId', ticket.id)
    }
    return {
        type: AdminOrSuperAdminActionTypes.PREVIEW_SINGLE_TICKET,
        payload: ticket
    };
};

export const priorEscPreviewTicketClicked = (ticket) => {
    storage.set('ticketId', ticket.id);
    history.push(`/tickets/priority/${ticket.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.PRIOR_ESCALATION_PREVIEW_TICKETS,
        // payload: ticket,
    };
};

export const viewPriorTicketStatusClicked = (ticket) => {
    return {
        type: AdminOrSuperAdminActionTypes.VIEW_PRIOR_TICKET_STATUS_CLICKED,
        payload: ticket,
    };
};

export const displayPriorTicketStatusClick = (ticket) => {
    return (dispatch) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DISPLAY_PRIOR_TICKET_STATUS_CLICK,
            payload: API.priorTicketStatusClicked(ticket)
                .then((response) => {
                    if (response.data && response.data.success) {
                        const ticketStatusInfo = cloneDeep(response.data);
                        return { ticketStatusInfo: ticketStatusInfo };
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        });
    };
};

export const cancelTicket = (ticket) => {
    return (dispatch) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.CANCEL_TICKET,
            payload: API.cancelTicket({ ticket })
                .then((response) => {
                    console.log('Cancel Ticket', response);
                })
                .catch((error) => {
                    console.log(error);
                })
        });
    };
};

export const resolveTicket = (ticket, extentions, arrayBuffer, updateCommentId, setEditCommentObject, idsToDelete) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.RESOLVE_TICKET,
            payload: API.resolveTicket(ticket)
                .then(response => {

                    if (response.data.success) {
                        debugger
                        const Ticket = cloneDeep(response.data);
                        let TicketsList = cloneDeep(getState().adminOrSuperAdminReducer.ticketsList);
                        let searchTicketValues = getState().adminOrSuperAdminReducer.searchTicketValues;

                        if (arrayBuffer.length > 0) {
                            if (ticket.chi) {
                                response.data.comments_attributes.forEach((comment, i) => {
                                    const index = ticket.chi.comments_attributes.findIndex(com => com.id === comment.id);
                                    if (index === -1) {
                                        dispatch(getCommentsPresignedUrl(comment, extentions, arrayBuffer, setEditCommentObject, idsToDelete));
                                    } else if (comment.id === updateCommentId) {
                                        dispatch(getCommentsPresignedUrl(comment, extentions, arrayBuffer, setEditCommentObject, idsToDelete));
                                    } else {
                                        dispatch(getCommentsPresignedUrl(comment, extentions, arrayBuffer, setEditCommentObject, idsToDelete));
                                    }
                                })
                            } else {
                                response.data.comments_attributes.forEach((comment, i) => {
                                    const index = ticket.ticket.comments_attributes.findIndex(com => com.id === comment.id);
                                    if (index === -1) {
                                        dispatch(getCommentsPresignedUrl(comment, extentions, arrayBuffer, idsToDelete));
                                    } else if (comment.id === updateCommentId) {
                                        dispatch(getCommentsPresignedUrl(comment, extentions, arrayBuffer, setEditCommentObject, idsToDelete));
                                    }
                                })
                            }
                            if (response.data.status === TicketStatus.resolved) {
                                var serveyList = cloneDeep(getState().adminOrSuperAdminReducer.chiServyList);
                                serveyList && serveyList.map((s, i) => {
                                    if (s.ticket[0].id === response.data.id) {
                                        s.ticket[0] = response.data;
                                    }
                                })
                                // history.goBack();
                                const user = storage.get('user')
                                logEvent(analytics, 'resolve_ticket_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, ticket_id: ticket.ticket.id });
                                SuccessAlert('Ticket Successfully Resolved');
                            }
                            // if (response.data.status === TicketStatus.resolved) {
                            //     history.push(routes.TICKETS);
                            // }
                            return { Ticket, serveyList, isLoading: false, searchTicketValues: searchTicketValues };
                        }
                        else if (idsToDelete && idsToDelete.length > 0) {
                            dispatch(updateTicket(ticket, idsToDelete, setEditCommentObject, " "))
                            const Ticket = cloneDeep(response.data);
                            return { TicketsList: TicketsList ? TicketsList : [], Ticket, isLoading: true, searchTicketValues: searchTicketValues }
                        }
                        else {
                            if (history.location.pathname.includes('/priority') && ticket.ticket.status === 'accepted') {
                                searchTicketValues['status'] = 'accepted';
                                setTimeout(() => {
                                    history.push(routes.PRIORITY_ESCALATION_TICKETS);
                                }, 500);
                            }
                            if (history.location.pathname.includes('/priority') && ticket.ticket.status === 'resolved') {
                                searchTicketValues['status'] = 'resolved';
                                setTimeout(() => {
                                    history.push(routes.PRIORITY_ESCALATION_TICKETS);
                                }, 500);
                            }
                            if (response.data.status === TicketStatus.resolved) {
                                serveyList && serveyList.map((s, i) => {
                                    if (s.ticket[0].id === response.data.id) {
                                        s.ticket[0] = response.data;
                                    }
                                })
                                history.goBack();
                                const user = storage.get('user')
                                logEvent(analytics, 'resolve_ticket_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, ticket_id: ticket.ticket.id });
                                SuccessAlert('Ticket Successfully Resolved');
                            }

                            if (response.data.status === TicketStatus.canceled) {
                                serveyList && serveyList.map((s, i) => {
                                    if (s.ticket[0].id === response.data.id) {
                                        s.ticket[0] = response.data;
                                    }
                                })
                                history.goBack();
                                const user = storage.get('user')
                                logEvent(analytics, 'cancel_ticket_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, ticket_id: ticket.ticket.id });
                                SuccessAlert('Ticket Successfully Canceled');
                            }

                            if (response.data.status === TicketStatus.open) {
                                serveyList && serveyList.map((s, i) => {
                                    if (s.ticket[0].id === response.data.id) {
                                        s.ticket[0] = response.data;
                                    }
                                })
                                // history.goBack();
                                SuccessAlert('Ticket Successfully Accepted');
                            }

                            if (setEditCommentObject) {
                                setEditCommentObject(null);
                            }
                            const Ticket = cloneDeep(response.data);
                            return { Ticket, serveyList, isLoding: true, searchTicketValues: searchTicketValues };
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getCommentsPresignedUrl = (comment, extentions, arrayBuffer, setEditCommentObject, idsToDelete) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_COMMENTS_PRESINGED_URL,
            payload: API.getCommentsPresignedUrl(extentions, comment)
                .then((response) => {
                    debugger
                    let Ticket = cloneDeep(getState().adminOrSuperAdminReducer.ticket);
                    let totalAttachments = 0;

                    Ticket.comments_attributes.map((c) => {
                        c['attachment_paths'] = [];
                        response.data.presigned_urls.map((url, i) => {
                            delete Ticket.attachments_attributes;
                            if (c.id === comment.id) {
                                c['attachment_paths'].push({ photo_path: url.attachment_path });
                                dispatch({
                                    type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
                                    payload: API.uploadImageToS3(url.presigned_url, arrayBuffer[i])
                                        .then(response => {

                                            totalAttachments++;
                                            if (arrayBuffer.length === totalAttachments) {
                                                if (!Ticket.account) {
                                                    const newTicket = {}
                                                    newTicket["comments_attributes"] = Ticket.comments_attributes;
                                                    newTicket["id"] = Ticket.id;
                                                    dispatch(updateTicket({ ticket: newTicket }, idsToDelete, setEditCommentObject,));
                                                }
                                                else {
                                                    const newTicket = {}
                                                    newTicket["comments_attributes"] = Ticket.comments_attributes;
                                                    newTicket["id"] = Ticket.id;
                                                    dispatch(updateTicket({ ticket: newTicket }, idsToDelete, setEditCommentObject,));
                                                }
                                            }
                                        })
                                })
                            }
                            delete Ticket.attachments_attributes;


                        })
                    })
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteTicket = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_TICKET,
            payload: API.deleteTicket(id)
                .then((response) => {
                    if (response.data.success) {
                        const ticket_no_of_rows = getState().adminOrSuperAdminReducer.ticket_no_of_rows;
                        const SearchValue = getState().form.searchTicket.values;
                        dispatch(updateData());
                        // const values = { value: cloneDeep(SearchValue) };
                        // if (values.value.fromDate && values.value.toDate) {
                        //     values.value.fromDate = convertDateToDifferentTZ(values.value.fromDate, 'from');
                        //     values.value.toDate = convertDateToDifferentTZ(values.value.toDate, 'to');
                        //     values.value.sharaeble = queryParams
                        // }
                        // dispatch(searchTicket(values, ticket_no_of_rows))
                        // const user = storage.get('user')
                        // logEvent(analytics, 'delete_ticket_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, ticket_id: id });
                        SuccessAlert('Ticket Successfully Deleted');
                        let TicketsList = cloneDeep(getState().adminOrSuperAdminReducer.ticketsList);
                        let SearchedTicketList = cloneDeep(getState().adminOrSuperAdminReducer.searchTicketsList);

                        if (SearchedTicketList) {
                            let searchIndex = SearchedTicketList.findIndex(function (t) {
                                return t.id === id;
                            });
                            SearchedTicketList.splice(searchIndex, 1);
                        }

                        let searchIndex = TicketsList.findIndex(function (t) {
                            return t.id === id;
                        });
                        TicketsList.splice(searchIndex, 1);
                        return { TicketsList, SearchedTicketList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getInspectionsList = (values, inspection_no_of_rows, page, sorted) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_INSPECTIONS_LIST,
        payload: API.getInspectionsList(values, inspection_no_of_rows, page, sorted)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        inspectionsList: response.data.inspections,
                        inspectionIndex: response.data.inspection_ids,
                        inspection_page: response.data.total_no_of_pages,
                        inspection_no_of_rows: response.data.no_of_rows,
                        totalInspections: response.data.inspections_tol_count,
                        inspectionCurPage: page - 1,
                        inspection_rows: inspection_no_of_rows,
                        searchInspectionValues: values,
                        inspection_mode: values.mode
                        // sort_inspection_column: columnName ? columnName : '',
                        // inspection_order: desc !== '' ? desc : ''
                    }
                }
            })
            .catch(error => {
                console.log(error);
            })
    })

export const getPendingInspectionsList = (values, inspection_no_of_rows, page, sorted) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_PENDING_INSPECTIONS_LIST,
        payload: API.getPendingInspectionsList(values, inspection_no_of_rows, page, sorted)
            .then((response) => {
                if (!response.data.error || !response.data.code) {

                    return {
                        inspectionsList: response.data.inspections,
                        inspectionIndex: response.data.inspection_ids,
                        inspection_page: response.data.total_no_of_pages,
                        inspection_no_of_rows: response.data.no_of_rows,
                        totalInspections: response.data.inspection_page_count,
                        inspectionCurPage: page - 1,
                        inspection_rows: inspection_no_of_rows,
                        searchInspectionValues: values,
                        inspection_mode: values.mode
                        // sort_inspection_column: columnName ? columnName : '',
                        // inspection_order: desc !== '' ? desc : ''
                    }
                }
            })
            .catch(error => {
                console.log(error);
            })
    })

export const getMyInspectionsList = (values, inspection_no_of_rows, page, sorted) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_MYINSPECTIONS_LIST,
        payload: API.getMyInspectionsList(values, inspection_no_of_rows, page, sorted)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        inspectionsList: response.data.inspections,
                        inspectionIndex: response.data.inspection_ids,
                        inspection_page: response.data.total_no_of_pages,
                        inspection_no_of_rows: response.data.no_of_rows,
                        totalInspections: response.data.inspections_tol_count,
                        inspectionCurPage: page - 1,
                        inspection_rows: inspection_no_of_rows,
                        searchInspectionValues: values,
                        inspection_mode: values.mode
                        // sort_inspection_column: columnName ? columnName : '',
                        // inspection_order: desc !== '' ? desc : ''
                    }
                }
            })
            .catch(error => {
                console.log(error);
            })
    })

export const getDashboardInspectionsList = (values, inspection_no_of_rows, page, sorted) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_DASHBOARD_INSPECTIONS_LIST,
        payload: API.getDashboardInspectionsList(values, inspection_no_of_rows, page, sorted)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    debugger;
                    return {
                        inspectionsList: response.data.inspections,
                        inspection_page: response.data.total_no_of_pages,
                        inspection_no_of_rows: response.data.no_of_rows,
                        totalInspections: response.data.inspections_tol_count,
                        // sort_inspection_column: columnName ? columnName : '',
                        // inspection_order: desc !== '' ? desc : ''
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getInspectionFormList = (searchValues, insform_no_of_rows, page, sorted, filtered) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_INSPECTION_FORM_LIST,
        payload: API.getInspectionFormList(searchValues, insform_no_of_rows, page, sorted, filtered)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        inspectionFormsList: response.data.inspection_forms,
                        insform_page: response.data.total_no_of_pages,
                        insform_no_of_rows: insform_no_of_rows,
                        totanInsForms: response.data.count,
                        inspectionFormCurPage: page - 1,
                        ins_form_rows: insform_no_of_rows,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getUserInspcetionsList = (values, inspection_no_of_rows, page, columnName, desc, filtered) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_INSPECTIONS_LIST,
        payload: API.getUserInspcetionsList(
            values,
            inspection_no_of_rows,
            page,
            columnName ? columnName : '',
            desc !== '' ? desc : '',
            filtered
        )
            .then((response) => {
                debugger;
                return {
                    inspectionsList: response.data.inspections,
                    inspection_page: response.data.total_no_of_pages,
                    inspectionIndex: response.data.inspection_ids,
                    inspection_no_of_rows: response.data.no_of_rows,
                    sort_inspection_column: columnName ? columnName : '',
                    inspection_order: desc !== '' ? desc : '',
                    totalInspections: response.data.inspections_tol_count,
                    inspection_rows: inspection_no_of_rows,
                    inspectionCurPage: page - 1,
                    searchInspectionValues: values,
                    inspection_mode: values.mode
                    // sort_inspection_column: columnName ? columnName : '',
                    // inspection_order: desc !== '' ? desc : ''
                }
            })
            .catch(error => {
                console.log(error);
            })
    })
export const getUserMyInspcetionsList = (values, inspection_no_of_rows, page, columnName, desc, filtered) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_MYINSPECTIONS_LIST,
        payload: API.getUserMyInspcetionsList(
            values,
            inspection_no_of_rows,
            page,
            columnName ? columnName : '',
            desc !== '' ? desc : '',
            filtered
        )
            .then((response) => {
                return {
                    inspectionsList: response.data.inspections,
                    inspection_page: response.data.total_no_of_pages,
                    inspectionIndex: response.data.inspection_ids,
                    inspection_no_of_rows: response.data.no_of_rows,
                    sort_inspection_column: columnName ? columnName : '',
                    inspection_order: desc !== '' ? desc : '',
                    totalInspections: response.data.inspections_tol_count,
                    inspection_rows: inspection_no_of_rows,
                    inspectionCurPage: page - 1,
                    searchInspectionValues: values,
                    inspection_mode: values.mode
                    // sort_inspection_column: columnName ? columnName : '',
                    // inspection_order: desc !== '' ? desc : ''
                }
            })
            .catch(error => {
                console.log(error);
            })
    })

export const setInspectionLoadingFalse = () => {
    return {
        type: AdminOrSuperAdminActionTypes.SET_INSPECTION_LOADING_FALSE,
    };
};

export const setInspectionForm = (inspection) => {
    return (dispatch, getState) => {
        // const InspectionFormsList = cloneDeep(getState().adminOrSuperAdminReducer.inspectionFormsList);
        // const index = InspectionFormsList.findIndex(inspection => inspection.inspection_form.id === id);
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_PERFORM_INSPECTION,
            payload: API.performInspection(inspection)
                .then((response) => {
                    if (!response.data.error) {
                        if (getState().adminOrSuperAdminReducer.inspectionsList) {
                            let InspectionsList = cloneDeep(getState().adminOrSuperAdminReducer.inspectionsList);
                            let Inspection = response.data;
                            let inspectionForm = {};
                            // values['inspection_id'] = response.data.id
                            InspectionsList.unshift(response.data);
                            inspectionForm['inspection_form'] = response.data.inspection_form;

                            history.push(`/inspections/performInspection/${response.data.id}`);
                            // dispatch(updateInspection(values));

                            return { InspectionsList, Inspection, inspectionForm };
                        }
                    } else {
                        return {
                            InspectionsList: cloneDeep(getState().adminOrSuperAdminReducer.inspectionsList),
                            Inspection: null,
                            inspectionForm: null,
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateInspection = (inspection) => {

    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_INSPECTION,
            payload: API.updateInspection(inspection)
                .then((response) => {
                    if (!response.data.error) {

                        // smartlook analytics

                        closeModel()
                        $('.modal-backdrop').remove();
                        setTimeout(() => {
                            window.location.reload('false');
                        }, 500);

                        let data = cloneDeep(response.data);

                        if (inspection && inspection.isEdit == true) {
                            const eventName = 'Edited_Inspection_Performed';
                            const eventProperties = {
                                'edited_inspected_by': `${data.inspected_by && data.inspected_by.first_name ? data.inspected_by.first_name : null} ${data.inspected_by && data.inspected_by.last_name ? data.inspected_by.last_name : null}`,
                                'edited_inspection_id': data.id,
                                'edited_inspection_form_id': data.inspection_form && data.inspection_form.id ? data.inspection_form.id : null,
                                'edited_inspection_form_name': data.inspection_form && data.inspection_form.name ? data.inspection_form.name : null,
                                'edited_inspection_job_name': data.account_info && data.account_info.account_name ? data.account_info.account_name : null,
                                'edited_inspection_active_status': data.active_status,
                                'edited_inspection_start_address': data.address && data.address.start_location && data.address.start_location.formatted_address ? data.address.start_location.formatted_address : null,
                                'edited_inspection_end_address': data.address && data.address.end_location && data.address.end_location.formatted_address ? data.address.end_location.formatted_address : null,
                                'edited_inspection_child_form_id': data.child_inspection_form_id,
                                'edited_inspection_created_at': data.created_at,
                                'edited_inspection_start_time': data.start_time,
                                'edited_inspection_end_time': data.end_time,
                                'edited_inspection_updated_at': data.updated_at,
                                'mode': data.mode,
                            };
                            if (window.REACT_APP_ENV == 'production') {
                                window.smartlook('track', eventName, eventProperties);
                            }
                        } else {
                            if ((data && data.active_status == 'completed') || (data && data.active_status == 'pending')) {
                                if (data.active_status == 'completed') {
                                    const eventName = 'Inspection_Performed';
                                    const eventProperties = {
                                        'inspected_by': `${data.inspected_by && data.inspected_by.first_name ? data.inspected_by.first_name : null} ${data.inspected_by && data.inspected_by.last_name ? data.inspected_by.last_name : null}`,
                                        'inspection_id': data.id,
                                        'inspection_form_id': data.inspection_form && data.inspection_form.id ? data.inspection_form.id : null,
                                        'inspection_form_name': data.inspection_form && data.inspection_form.name ? data.inspection_form.name : null,
                                        'inspection_job_name': data.account_info && data.account_info.account_name ? data.account_info.account_name : null,
                                        'inspection_active_status': data.active_status,
                                        'inspection_start_address': data.address && data.address.start_location && data.address.start_location.formatted_address ? data.address.start_location.formatted_address : null,
                                        'inspection_end_address': data.address && data.address.end_location && data.address.end_location.formatted_address ? data.address.end_location.formatted_address : null,
                                        'inspection_child_form_id': data.child_inspection_form_id,
                                        'inspection_created_at': data.created_at,
                                        'inspection_start_time': data.start_time,
                                        'inspection_end_time': data.end_time,
                                        'mode': data.mode,
                                    };
                                    if (window.REACT_APP_ENV == 'production') {
                                        window.smartlook('track', eventName, eventProperties);
                                    }
                                }
                                // else if(data.active_status == 'pending') {
                                //     const eventName = 'Pending_Inspection_Performed';
                                //     const eventProperties = {
                                //         'inspected_by' :`${data.inspected_by && data.inspected_by.first_name ? data.inspected_by.first_name : null} ${data.inspected_by && data.inspected_by.last_name ? data.inspected_by.last_name : null}`,
                                //         'inspection_id' : data.id,
                                //         'inspection_form_id' : data.inspection_form && data.inspection_form.id ? data.inspection_form.id : null,
                                //         'inspection_form_name' : data.inspection_form && data.inspection_form.name ? data.inspection_form.name : null,
                                //         'inspection_job_name' : data.account_info && data.account_info.account_name ? data.account_info.account_name : null,
                                //         'inspection_active_status' : data.active_status,
                                //         'inspection_start_address' : data.address && data.address.start_location && data.address.start_location.formatted_address ? data.address.start_location.formatted_address : null,
                                //         'inspection_end_address' : data.address && data.address.end_location && data.address.end_location.formatted_address ? data.address.end_location.formatted_address : null,
                                //         'inspection_child_form_id' : data.child_inspection_form_id,
                                //         'inspection_created_at' : data.created_at,
                                //         'inspection_start_time' : data.start_time,
                                //         'inspection_end_time' : data.end_time,
                                //         'mode' : data.mode,
                                //     };
                                //     window.smartlook('track', eventName, eventProperties);
                                // }
                            }
                        }
                        let ele = document.getElementById('approve_inspection_id');
                        if (ele) {
                            ele.click();
                        }

                        dispatch(setInspectionLoadingFalse())
                        storage.remove('inspectionId')
                        storage.remove('performInspectionData');
                        const user = storage.get('user')
                        logEvent(analytics, 'update_inspection_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, inspection_id: inspection.inspection.inspection_id });
                        SuccessAlert('Inspection Successfully Updated');

                        if (history.location.pathname.includes('/inspections/pending-inspection')) {
                            history.push(routes.PENDING_INSPECTIONS);

                            let InspectionsList = cloneDeep(getState().adminOrSuperAdminReducer.inspectionsList);
                            let searchIndex = InspectionsList.findIndex(function (i) {
                                return i.id === inspection.inspection.id;
                            });
                            InspectionsList.splice(searchIndex, 1);
                            return { InspectionsList, isLoading: false, isInspectionLoading: false, };
                        } else if (history.location.pathname.includes('/inspections/my-inspection')) {
                            history.push(routes.MY_INSPECTIONS);
                            let InspectionsList = null;
                            return { InspectionsList, isLoading: true, isInspectionLoading: false, };
                        } else {
                            history.push(routes.INSPECTIONS);
                            let InspectionsList = null;
                            return { InspectionsList, isLoading: true, isInspectionLoading: true, };
                        }



                    } else {
                        dispatch(setInspectionLoadingFalse())
                        return { InspectionsList: null, isLoading: false, isInspectionLoading: false, };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updatePendingInspection = (inspection) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_PENDING_INSPECTION,
            payload: API.updatePendingInspection(inspection)
                .then((response) => {
                    if (!response.data.error) {
                        dispatch(setInspectionLoadingFalse())
                        storage.remove('inspectionId')
                        storage.remove('performInspectionData');
                        const user = storage.get('user')
                        logEvent(analytics, 'approved_inspection_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, inspection_id: inspection.id });
                        SuccessAlert('Inspection Successfully Approved');
                        history.push(routes.PENDING_INSPECTIONS);
                        // let InspectionsList = null;
                        let InspectionsList = cloneDeep(getState().adminOrSuperAdminReducer.inspectionsList);
                        let searchIndex = InspectionsList.findIndex(function (i) {
                            return i.id === inspection.id;
                        });
                        InspectionsList.splice(searchIndex, 1);
                        let ele = document.getElementById('approve_inspection_id');
                        // let field = document.getElementById('duplicate_inspection_name')
                        if (ele) {
                            ele.click();
                            // field.value = '';
                        }
                        return { InspectionsList, isLoading: true };
                    } else {
                        dispatch(setInspectionLoadingFalse())
                        return { InspectionsList: null, isLoading: false };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const performInspection = (values, idsToDelete) => {
    return (dispatch, getState) => {
        if (idsToDelete.length > 0) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.DELETE_INSPECTION_FORM_IMAGES,
                payload: API.deletePortfolioImages(idsToDelete)
                    .then((response) => {
                        const Inspection = getState().adminOrSuperAdminReducer.inspection;
                        // values['inspection_id'] = Inspection.id;
                        storage.remove('IdsToDelete');
                        dispatch(updateInspection(values));
                        let InspectionsList = [];
                        // dispatch({
                        //     type: AdminOrSuperAdminActionTypes.ADD_PERFORM_INSPECTION,
                        //     payload: API.performInspection(values)
                        //         .then(response => {
                        //
                        //             if (!response.data.error) {
                        //                 if (getState().adminOrSuperAdminReducer.inspectionsList) {

                        //                     let InspectionsList = cloneDeep(getState().adminOrSuperAdminReducer.inspectionsList);
                        //                     values['inspection_id'] = response.data.id
                        //                     InspectionsList.unshift(response.data);
                        //                     dispatch(updateInspection(values));
                        //                     return InspectionsList
                        //                 } else {
                        //                     values['inspection_id'] = response.data.id
                        //                     dispatch(updateInspection(values));
                        //                 }
                        //             }
                        //         })
                        //         .catch(error => {
                        //             console.log(error);
                        //         })
                        // })
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            let InspectionsList = [];

            const Inspection = getState().adminOrSuperAdminReducer.inspection;
            // values['inspection_id'] = Inspection.id;
            values.inspection['end_time'] = new Date();
            dispatch(updateInspection(values));
            // dispatch({
            //     type: AdminOrSuperAdminActionTypes.ADD_PERFORM_INSPECTION,
            //     payload: API.performInspection(values)
            //         .then(response => {
            //             if (!response.data.error) {
            //                 if (getState().adminOrSuperAdminReducer.inspectionsList) {

            //                     let InspectionsList = cloneDeep(getState().adminOrSuperAdminReducer.inspectionsList);
            //                     values['inspection_id'] = response.data.id
            //                     InspectionsList.unshift(response.data);
            //                     dispatch(updateInspection(values));
            //                     return InspectionsList
            //                 } else {
            //                     values['inspection_id'] = response.data.id
            //                     dispatch(updateInspection(values));
            //                 }
            //             }
            //         })
            //         .catch(error => {
            //             console.log(error);
            //         })
            // })
        }
    };
};

export const updateInspectionImages = (inspectionForm) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FORM_IMAGES,
            payload: API.updateInspectionForm(inspectionForm)
                .then((response) => {
                    if (!response.data.error) {
                        const InspectionForm = {};

                        if (getState().adminOrSuperAdminReducer.inspectionFormsList) {
                            let InspectionFormsList = cloneDeep(
                                getState().adminOrSuperAdminReducer.inspectionFormsList
                            );
                            const index = InspectionFormsList.findIndex(
                                (i) => i.inspection_form.id === response.data.id
                            );

                            InspectionFormsList[index]['inspection_form'] = response.data;

                            if (
                                history.location.pathname !==
                                `/inspections/performInspection/${inspectionForm.inspection_form.id}`
                            ) {
                                // SuccessAlert('InspectionForm Successfully Updated');
                                // history.push(routes.INSPECTIONS);
                            } else {
                                InspectionForm['inspection_form'] = response.data;
                            }

                            return InspectionForm;
                        } else {
                            if (
                                history.location.pathname !==
                                `/inspections/performInspection/${inspectionForm.inspection_form.id}`
                            ) {
                                // history.push(routes.INSPECTIONS);
                            } else {
                                InspectionForm['inspection_form'] = response.data;
                                return InspectionForm;
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateLineItemsPresignedUrl = (
    presigned_urls,
    arrayBuffer,
    lineItemId,
    Inspection,
    sectionIndex,
    lineIndex
) => {
    return (dispatch, getState) => {
        let totalAttachments = [];
        //
        if (lineItemId !== undefined) {

            if (
                !Inspection.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex]
                    .attachment_paths
            ) {
                Inspection.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex][
                    'attachment_paths'
                ] = [];
                Inspection.inspection_form.sections_attributes[sectionIndex].line_items_attributes[lineIndex][
                    'full_attachment_url'
                ] = [];
            }

            presigned_urls.forEach((p, i) => {
                Inspection.inspection_form.sections_attributes[sectionIndex].line_items_attributes[
                    lineIndex
                ].attachment_paths.push({ photo_path: p.attachment_path });

                Inspection.inspection_form.sections_attributes[sectionIndex].line_items_attributes[
                    lineIndex
                ].full_attachment_url.push(p.photo_urls.original);

                dispatch({
                    type: AdminOrSuperAdminActionTypes.UPLOAD_LINE_ITEMS_IMAGE_TO_S3,
                    payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i]).then((response) => {
                        totalAttachments++;
                        let ele = document.getElementById('inspectionNothingButton');
                        if (ele) {
                            ele.click();
                        }
                        if (presigned_urls.length === totalAttachments) {
                            // closeModel();
                            return { Inspection, isLoding: false };
                            // dispatch(updateInspectionImages(InspectionForm));
                        }
                        // else {
                        //     const inspection = cloneDeep(getState().adminOrSuperAdminReducer.inspection);
                        //
                        //     return { Inspection: inspection, isLoding: true }
                        // }
                    }),
                });
            });
        } else {

            if (!Inspection.attachment_paths) {
                Inspection[
                    'attachment_paths'
                ] = [];
                Inspection[
                    'full_attachment_url'
                ] = [];
            }

            presigned_urls.forEach((p, i) => {
                Inspection.attachment_paths.push({ photo_path: p.attachment_path });

                Inspection.full_attachment_url.push(p.photo_urls.original);


                dispatch({
                    type: AdminOrSuperAdminActionTypes.UPLOAD_LINE_ITEMS_IMAGE_TO_S3,
                    payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i]).then((response) => {
                        totalAttachments++;
                        let ele = document.getElementById('inspectionNothingButton');
                        if (ele) {
                            ele.click();
                        }
                        if (presigned_urls.length === totalAttachments) {
                            return { Inspection, isLoding: false };
                        }
                    }),
                });
            });
        }

    };
};

export const saveLineItemsPortfolioImages = (
    arrayBuffer,
    extentions,
    lineItemId,
    sectionIndex,
    lineIndex,
    inspection
) => {
    return (dispatch, getState) => {
        const Inspection = cloneDeep(getState().adminOrSuperAdminReducer.inspection);

        Inspection['inspection_form'] = inspection.inspection_form;
        if (lineItemId !== undefined) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_LINEITEMS_PRESIGNED_URLS,
                payload: API.getLineItemsPresignedUrl(extentions, lineItemId).then((response) => {
                    dispatch(
                        updateLineItemsPresignedUrl(
                            response.data.presigned_urls,
                            arrayBuffer,
                            lineItemId,
                            Inspection,
                            sectionIndex,
                            lineIndex
                        )
                    );
                }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_LINEITEMS_PRESIGNED_URLS,
                payload: API.getNotesPresignedUrl(extentions, Inspection.id).then((response) => {
                    dispatch(
                        updateLineItemsPresignedUrl(
                            response.data.presigned_urls,
                            arrayBuffer,
                            lineItemId,
                            Inspection,
                            sectionIndex,
                            lineIndex
                        )
                    );
                }),
            });
        }

    };
};

export const updateInspectionFormImages = (inspection) => {
    return {
        type: AdminOrSuperAdminActionTypes.UPDATE_INSPECTION_FORM_IMAGES,
        payload: inspection,
    };
};

export const goToPreviewInspection = (inspection) => {
    const user = storage.get('user')
    logEvent(analytics, 'preview_inspection_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, inspection_id: inspection.id });
    // smartlook analytics
    // let data = cloneDeep(inspection);
    // const eventName = 'Preview_Inspection_Info';
    // const eventProperties = {
    //     'preview_inspection_id' : data.id,
    //     'pegasus_representative' : `${data.inspected_by && data.inspected_by.first_name ? data.inspected_by.first_name : null} ${data.inspected_by && data.inspected_by.last_name ? data.inspected_by.last_name : null}`,
    //     'preview_inspection_job_name' : data.account_info && data.account_info.account_name ? data.account_info.account_name : null,
    //     'preview_inspection_start_address' : data.address && data.address.start_location && data.address.start_location.formatted_address ? data.address.start_location.formatted_address : null,
    //     'preview_inspection_end_address' : data.address && data.address.end_location && data.address.end_location.formatted_address ? data.address.end_location.formatted_address : null,
    //     'preview_inspection_status' : data.active_status,
    //     'preview_inspection_form_id' : data.inspection_form && data.inspection_form.id ? data.inspection_form.id : null,
    //     'preview_inspection_form_name' : data.inspection_form && data.inspection_form.name ? data.inspection_form.name : null,
    //     'preview_inspection_child_form_id' : data.child_inspection_form_id,
    //     'preview_inspection_sent_at' : data.pending_inspection_sent_at,
    //     'preview_inspection_qqv_status' : data.qqv_status,
    //     'preview_inspection_start_time' : data.start_time,
    //     'preview_inspection_end_time' : data.end_time,
    //     'preview_inspection.created_at' : data.created_at,
    //     'preview_inspection_updated_at' : data.updated_at,
    //     'mode' : data.mode,
    // };
    // window.smartlook('track', eventName, eventProperties);
    history.push(`/inspections/previewInspection/${inspection.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.SET_INSPECTION,
        payload: inspection,
    };
};

export const goToPendingPreviewInspection = (inspection) => {
    // smartlook analytics
    // let data = cloneDeep(inspection);
    // const eventName = 'Preview_Pending_Inspection_Info';
    // const eventProperties = {
    //     'preview_inspection_id' : data.id,
    //     'pegasus_representative' : `${data.inspected_by && data.inspected_by.first_name ? data.inspected_by.first_name : null} ${data.inspected_by && data.inspected_by.last_name ? data.inspected_by.last_name : null}`,
    //     'preview_inspection_job_name' : data.account_info && data.account_info.account_name ? data.account_info.account_name : null,
    //     'preview_inspection_start_address' : data.address && data.address.start_location && data.address.start_location.formatted_address ? data.address.start_location.formatted_address : null,
    //     'preview_inspection_end_address' : data.address && data.address.end_location && data.address.end_location.formatted_address ? data.address.end_location.formatted_address : null,
    //     'preview_inspection_status' : data.active_status,
    //     'preview_inspection_form_id' : data.inspection_form && data.inspection_form.id ? data.inspection_form.id : null,
    //     'preview_inspection_form_name' : data.inspection_form && data.inspection_form.name ? data.inspection_form.name : null,
    //     'preview_inspection_child_form_id' : data.child_inspection_form_id,
    //     'preview_inspection_sent_at' : data.pending_inspection_sent_at,
    //     'preview_inspection_qqv_status' : data.qqv_status,
    //     'preview_inspection_start_time' : data.start_time,
    //     'preview_inspection_end_time' : data.end_time,
    //     'preview_inspection.created_at' : data.created_at,
    //     'preview_inspection_updated_at' : data.updated_at,
    //     'mode' : data.mode,
    // };
    // window.smartlook('track', eventName, eventProperties);
    history.push(`/inspections/pending-inspection/previewInspection/${inspection.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.SET_INSPECTION,
        payload: inspection,
    };
};

export const goToLatestPreviewInspection = (inspection) => {
    // history.push(`/inspections/previewInspection/${inspection}`);
    return {
        type: AdminOrSuperAdminActionTypes.SET_INSPECTION,
        payload: inspection,
    };
};
export const editInspectionClicked = (inspection) => {
    if (history.location.pathname !== `/inspections/editInspection/${inspection.id}`) {
        history.push({
            pathname: `/inspections/editInspection/${inspection.id}`,
            state: { isEdit: true }
        });
    }
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_INSPECTION_CLICKED,
        payload: {},
    };
};

export const inspectionPageClicked = (inspection) => {
    return {
        type: AdminOrSuperAdminActionTypes.INSPECTION_PAGE_CLICKED,
        payload: inspection,
    };
};

export const ratingPageClicked = (page, rows) => {

    return {
        type: AdminOrSuperAdminActionTypes.RATING_PAGE_CLICKED,
        payload: { page: page, rows: rows }
    }
}

export const getInspection = (id) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_INSPECTION,
    payload: API.getInspection(id)
        .then(response => {

            if (!response.data.error) {
                storage.set('inspectionId', response.data.id);
                return response.data
            }
        })
        .catch(error => {
            console.log(error);
        })
})


export const onInspectionExportClicked = (body) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.EXPORT_INSPECTION,
    payload: API.onInspectionExportClicked(body)
        .then(response => {

            if (response.data.success) {
                SuccessAlert('Please check your email for export results');
            }
        })
        .catch(error => {
            console.log(error);
        })

})


export const resetSearchOrResetValue = () => {
    return {
        type: AdminOrSuperAdminActionTypes.RESET_SEARCH_OR_RESET,
    };
};

export const newInspectionQuoteClicked = (id) => {
    return {
        type: AdminOrSuperAdminActionTypes.NEW_INSPECTION_QUOTE_CLICKED,
        payload: id,
    };
};

export const newInspectionTicketClicked = (id) => {
    return {
        type: AdminOrSuperAdminActionTypes.NEW_INSPECTION_TICKET_CLICKED,
        payload: id,
    };
};

export const signaturePadClicked = (signatureData) => {
    return {
        type: AdminOrSuperAdminActionTypes.SIGNATURE_PAD_CLICKED,
        payload: signatureData,
    };
};

export const reorderDragAndDrop = (inspectionForm) => {
    return {
        type: AdminOrSuperAdminActionTypes.REORDER_INSPECTION_FORM,
        payload: inspectionForm,
    };
};

export const searchInspections = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        const inspection_no_of_rows = getState().adminOrSuperAdminReducer.inspection_no_of_rows;
        const sort_inspection_column = getState().adminOrSuperAdminReducer.sort_inspection_column;
        const inspection_order = getState().adminOrSuperAdminReducer.inspection_order;
        if (user.role === userRoles.SUPER_ADMIN) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_SEARCHED_INSPECTIONS_LIST,
                payload: API.searchInspections(values, inspection_no_of_rows, page ? page : 1, sorted)
                    .then(response => {
                        return {
                            inspectionsList: response.data.inspections,
                            inspectionIndex: response.data.inspection_ids,
                            inspection_page: response.data.total_no_of_pages,
                            inspection_no_of_rows: response.data.no_of_rows,
                            totalInspections: response.data.inspections_tol_count,
                            searchInspectionValues: values,
                            inspection_rows: response.data.no_of_rows,
                            // sort_inspection_column: columnName ? columnName : '',
                            // inspection_order: desc !== '' ? desc : ''
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_SEARCHED_INSPECTIONS_LIST,
                payload: API.searchUserInspection(values, inspection_no_of_rows, page ? page : 1, sorted)
                    .then(response => {
                        return {
                            inspectionsList: response.data.inspections,
                            inspectionIndex: response.data.inspection_ids,
                            inspection_page: response.data.total_no_of_pages,
                            inspection_no_of_rows: response.data.no_of_rows,
                            totalInspections: response.data.inspections_tol_count,
                            inspection_rows: response.data.no_of_rows,
                            searchInspectionValues: values,
                            // sort_inspection_column: columnName ? columnName : '',
                            // inspection_order: desc !== '' ? desc : ''
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        }

    }
}

export const searchPendingInspections = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        const inspection_no_of_rows = getState().adminOrSuperAdminReducer.inspection_no_of_rows;
        const sort_inspection_column = getState().adminOrSuperAdminReducer.sort_inspection_column;
        const inspection_order = getState().adminOrSuperAdminReducer.inspection_order;
        if (user.role === userRoles.SUPER_ADMIN) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_SEARCHED_INSPECTIONS_LIST,
                payload: API.searchPendingInspections(values, inspection_no_of_rows, page ? page : 1, sorted)
                    .then(response => {
                        return {
                            inspectionsList: response.data.inspections,
                            inspectionIndex: response.data.inspection_ids,
                            inspection_page: response.data.total_no_of_pages,
                            inspection_no_of_rows: response.data.no_of_rows,
                            totalInspections: response.data.inspection_page_count,
                            searchInspectionValues: values,
                            inspection_rows: response.data.no_of_rows,
                            // sort_inspection_column: columnName ? columnName : '',
                            // inspection_order: desc !== '' ? desc : ''
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_SEARCHED_INSPECTIONS_LIST,
                payload: API.searchUserPendingInspection(values, inspection_no_of_rows, page ? page : 1, sorted)
                    .then(response => {
                        return {
                            inspectionsList: response.data.inspections,
                            inspectionIndex: response.data.inspection_ids,
                            inspection_page: response.data.total_no_of_pages,
                            inspection_no_of_rows: response.data.no_of_rows,
                            totalInspections: response.data.inspection_page_count,
                            inspection_rows: response.data.no_of_rows,
                            searchInspectionValues: values,
                            // sort_inspection_column: columnName ? columnName : '',
                            // inspection_order: desc !== '' ? desc : ''
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        }

    }
}

export const searchMyInspections = (values, rows, page, sorted) => {
    return (dispatch, getState) => {
        const user = getState().authReducer.user;
        const inspection_no_of_rows = getState().adminOrSuperAdminReducer.inspection_no_of_rows;
        const sort_inspection_column = getState().adminOrSuperAdminReducer.sort_inspection_column;
        const inspection_order = getState().adminOrSuperAdminReducer.inspection_order;
        if (user.role === userRoles.SUPER_ADMIN) {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_SEARCHED_INSPECTIONS_LIST,
                payload: API.searchMyInspections(values, inspection_no_of_rows, page ? page : 1, sorted)
                    .then(response => {
                        return {
                            inspectionsList: response.data.inspections,
                            inspectionIndex: response.data.inspection_ids,
                            inspection_page: response.data.total_no_of_pages,
                            inspection_no_of_rows: response.data.no_of_rows,
                            totalInspections: response.data.inspections_tol_count,
                            searchInspectionValues: values,
                            inspection_rows: response.data.no_of_rows,
                            // sort_inspection_column: columnName ? columnName : '',
                            // inspection_order: desc !== '' ? desc : ''
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        } else {
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_SEARCHED_INSPECTIONS_LIST,
                payload: API.searchUserMyInspection(values, inspection_no_of_rows, page ? page : 1, sorted)
                    .then(response => {
                        return {
                            inspectionsList: response.data.inspections,
                            inspectionIndex: response.data.inspection_ids,
                            inspection_page: response.data.total_no_of_pages,
                            inspection_no_of_rows: response.data.no_of_rows,
                            totalInspections: response.data.inspections_tol_count,
                            inspection_rows: response.data.no_of_rows,
                            searchInspectionValues: values,
                            // sort_inspection_column: columnName ? columnName : '',
                            // inspection_order: desc !== '' ? desc : ''
                        };
                    })
                    .catch((error) => {
                        console.log(error);
                    }),
            });
        }

    }
}

export const kpiResetClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.KPI_RESET_CLICKED,
    }
}

export const deleteInspection = (id) => {
    return (dispatch, getState) => dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_INSPECTION,
        payload: API.deleteInspection(id)
            .then(response => {
                let InspectionsList = cloneDeep(getState().adminOrSuperAdminReducer.inspectionsList);
                let SearchedInspectionsList = cloneDeep(getState().adminOrSuperAdminReducer.searchedInspectionList);
                if (!response.data.error) {
                    const inspection_no_of_rows = getState().adminOrSuperAdminReducer.inspection_no_of_rows;
                    let SearchValue = {}
                    SearchValue.value = (getState().form.searchInspection.values);
                    const page = getState().adminOrSuperAdminReducer.inspectionCurPage + 1;
                    // dispatch(updateData());
                    dispatch(getInspectionsList(SearchValue, inspection_no_of_rows, page))
                    if (SearchedInspectionsList) {
                        let searchIndex = SearchedInspectionsList.findIndex(function (i) {
                            return i.id === id
                        });
                        SearchedInspectionsList.splice(searchIndex, 1);
                    }

                    let index = InspectionsList.findIndex(function (i) {
                        return i.id === id;
                    });

                    InspectionsList.splice(index, 1);
                    const user = storage.get('user')
                    logEvent(analytics, 'delete_inspection_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, inspection_id: id });
                    SuccessAlert('Inspection Successfully deleted');
                    return { InspectionsList, SearchedInspectionsList };
                } else {
                    return { InspectionsList, SearchedInspectionsList };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
};

export const getQuotesCsvReports = (data) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_QUOTES_CSV_REPORTS,
        payload: API.getQuotesCsvReports(data).then((response) => {
            if (!response.data.error || !response.data.code) {
                return {
                    quotesReport: response.data.results,
                    an_account_with_zero_tags_sold: response.data.an_account_with_zero_tags_sold,
                    quotes_created_vs_quotes_approved: response.data.quotes_created_vs_quotes_approved,
                    quotes_tag_created: response.data.quotes_tag_created,
                    quotes_vs_approved_not_approved_by_the_client:
                        response.data.quotes_vs_approved_not_approved_by_the_client,
                    quotes_vs_quotes_rejected_by_admin: response.data.quotes_vs_quotes_rejected_by_admin,
                    tags_confirmed_by_client: response.data.tags_confirmed_by_client,
                };
            }
        }),
    });

export const getAllRegions = (data) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_REGION,
        payload: API.getAllRegions(data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const handleClose = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_MODEL,
        payload: false,
    };
};

export const goToPreviewUser = (user) => {
    history.push(`/user/previewUser/${user.user.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.GO_TO_PREVIEW_USER,
        payload: user,
    };
};

export const handleClickDeleteModel = (deleteObject) => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_DELETE_MODEL,
        payload: deleteObject,
    };
};
export const handleConfirmClickDeleteModel = (deleteObject) => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_CONFIRM_DELETE_MODEL,
        payload: deleteObject,
    };
};
export const handleScheduleClickDeleteModel = (deleteObject) => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_CONFIRM_SCHEDULE_DELETE_MODEL,
        payload: deleteObject,
    };
};
export const ApproveModalClicked = (inspection_id) => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_APPROVE_MODEL,
        payload: inspection_id
    }
}

export const editQuoteClicked = (quote) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_QUOTE_CLICKED,
        payload: quote,
    };
};

export const handleDeleteClose = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_DELETE_CLOSE,
    };
};

export const handleApproveClose = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_APPROVE_CLOSE,
    };
};

export const handleCompleteApproveClose = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_COMPLETE_APPROVE_CLOSE,
    };
};

export const openPopup = () => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_MODEL,
    };
};

export const closePopup = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_MODEL,
    };
};

export const addPosition = (position) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_POSITION,
            payload: API.addPosition(position)
                .then((response) => {
                    let Positions = cloneDeep(getState().adminOrSuperAdminReducer.positionsList);
                    let newPosition = {};
                    if (response.data.success) {
                        SuccessAlert('Position added successfully');
                        closeModel();
                        newPosition['position'] = response.data;
                        Positions.unshift(newPosition);
                        // Positions.sort(function (a, b) {
                        //     if (a.position.name < b.position.name) {
                        //         return -1;
                        //     }
                        //     if (a.position.name > b.position.name) {
                        //         return 1;
                        //     }
                        //     return 0;
                        // });
                        return {
                            positions_count: response.data.positions_count,
                            region_count: response.data.region_count,
                            Positions: Positions,
                        };
                    } else {
                        closeModel();
                        let positions_count = cloneDeep(getState().adminOrSuperAdminReducer.totalPositions);
                        return { Positions: Positions, positions_count: positions_count };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editPositionClicked = (position) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_POSITION_CLICKED,
        payload: position,
    };
};

export const updatePosition = (position, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_POSITION,
            payload: API.updatePosition(position, id)
                .then((response) => {
                    let Positions = cloneDeep(getState().adminOrSuperAdminReducer.positionsList);
                    let updatedPosition = {};
                    if (response.data.success) {
                        SuccessAlert('Position updated successfully');
                        closeModel();
                        // dispatch(getPositions())
                        updatedPosition['position'] = response.data;
                        const index = Positions.findIndex((p) => p.position.id === id);
                        Positions[index] = updatedPosition;

                        // Positions.sort(function (a, b) {
                        //     if (a.position.name < b.position.name) { return -1; }
                        //     if (a.position.name > b.position.name) { return 1; }
                        //     return 0;
                        // })

                        return { Positions: Positions, openModal: false };
                    } else {
                        return { Positions: Positions, openModal: true };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addRegion = (region) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_REGION,
            payload: API.addRegion(region)
                .then((response) => {
                    let newRegion = {};
                    const Regions = cloneDeep(getState().adminOrSuperAdminReducer.regionList);
                    if (response.data.success) {
                        SuccessAlert('Branch added successfully');
                        closeModel();
                        newRegion['region'] = response.data;
                        Regions.unshift(newRegion);
                    } else {
                        closeModel();
                        const Regions = cloneDeep(getState().adminOrSuperAdminReducer.regionList);
                        return Regions;
                    }

                    // Regions.sort(function (a, b) {
                    //     if (a.region.name < b.region.name) {
                    //         return -1;
                    //     }
                    //     if (a.region.name > b.region.name) {
                    //         return 1;
                    //     }
                    //     return 0;
                    // });
                    return Regions;
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editRegionClicked = (region) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_REGION_CLICKED,
        payload: region,
    };
};

export const updateRegion = (region, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_REGION,
            payload: API.updateRegion(region, id)
                .then((response) => {
                    const Regions = cloneDeep(getState().adminOrSuperAdminReducer.regionList);
                    let updatedRegion = {};
                    if (response.data.success) {
                        // dispatch(getAllRegions())
                        SuccessAlert('Region updated successfully');
                        closeModel();
                        updatedRegion['region'] = response.data;
                        const index = Regions.findIndex((r) => r.region.id === id);
                        Regions[index] = updatedRegion;

                        // Regions.sort(function (a, b) {
                        //     if (a.region.name < b.region.name) { return -1; }
                        //     if (a.region.name > b.region.name) { return 1; }
                        //     return 0;
                        // })

                        return { Regions: Regions, openModal: false };
                    } else {
                        return { Regions: Regions, openModal: true };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deletePositon = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_POSITION,
            payload: API.deletePositon(id)
                .then((response) => {
                    let Positions = cloneDeep(getState().adminOrSuperAdminReducer.positionsList);
                    if (response.data.success) {
                        let searchIndex = Positions.findIndex(function (p) {
                            SuccessAlert('Position Deleted Successfully.');
                            return p.position.id === id;
                        });
                        Positions.splice(searchIndex, 1);
                        return {
                            positions_count: response.data.positions_count,
                            region_count: response.data.region_count,
                            positions: Positions,
                        };
                    } else {
                        let PositionCount = cloneDeep(getState().adminOrSuperAdminReducer.totalPositions);
                        let RatingCount = cloneDeep(getState().adminOrSuperAdminReducer.totalRegions);
                        return { positions: Positions, positions_count: PositionCount, region_count: RatingCount };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteRegion = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_REGION,
            payload: API.deleteRegion(id)
                .then((response) => {
                    let Regions = cloneDeep(getState().adminOrSuperAdminReducer.regionList);
                    if (response.data.success) {
                        let searchIndex = Regions.findIndex(function (r) {
                            SuccessAlert('Region Deleted Successfully.');
                            return r.region.id === id;
                        });
                        Regions.splice(searchIndex, 1);
                        return {
                            positions_count: response.data.positions_count,
                            region_count: response.data.region_count,
                            regions: Regions,
                        };
                    } else {

                        let PositionCount = cloneDeep(getState().adminOrSuperAdminReducer.totalPositions);
                        let RatingCount = cloneDeep(getState().adminOrSuperAdminReducer.totalRegions);
                        return { regions: Regions, positions_count: PositionCount, region_count: RatingCount }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getLogBookAreaPreview = (SearchValue, page, limit, sorted, logbookId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_LOGBOOK_AREA_PREVIEW,
            payload: API.getAllLogBookLogsArea(SearchValue, page, limit, sorted, logbookId)
                .then((response) => {
                    if (!response.data.error || response.data.success) {
                        return {
                            logbookArea_List: response.data.logbookLogs,
                            total_LogbookArea: response.data.count,
                            total_LogbookArea_Pages: response.data.pages,
                            searchValues: SearchValue,
                            rows: response.data.no_of_rows,
                            logbookPreviewCurPage: page - 1,
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    }
}

export const getOneLogBookLogsArea = ({ logbookId, logId }) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_LOGBOOK_LOGS_AREA_PREVIEW,
            payload: API.getOneLogBookLogsArea(logbookId, logId)
                .then((response) => {
                    if (response.data.success) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data.logbookLog);
                        // const eventName = 'Preview_Logbook_Logs_Info';
                        // const eventProperties = {
                        //     'logbookLog_id' : data.id,
                        //     'logbookLog_logbook_name' : data.logbook && data.logbook.name ? data.logbook.name : null,
                        //     'logbookLog_reviewed_by' : data.name,
                        //     'logbookLog_status' : data.status,
                        //     'logbookLog_logbook_schedule_at' : data.logbook && data.logbook.schedule_at ? data.logbook.schedule_at : null,
                        //     'logbookLog_comment' : data.comment && data.comment.length ? data.comment : 'No comments',
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        return {
                            logbookLog: response.data.logbookLog
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    }
}


export const previewLogBookAreaQrScanClicked = (scan) => {
    return {
        type: AdminOrSuperAdminActionTypes.PREVIEW_LOGBOOK_AREA_QR_SCAN_CLICKED,
        payload: scan,
    };
};

export const getAllLogbook = (values, size, page, sorted) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_LOGBOOK,
        payload: API.getAllLogbook(values, size, page, sorted)
            .then((response) => {
                if (!response.data.error || response.data.success) {
                    return {
                        total_no_of_pages: response.data.pages,
                        logbookLogs: response.data.logbookLogs,
                        total_reviewed: response.data.total_reviewed,
                        total_pending: response.data.total_pending,
                        totalLogbookLogs: response.data.count,
                        searchValues: values,
                        rows: response.data.no_of_rows,
                        job_rows: size,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const logbookAreaPageClicked = (logs) => {
    return {
        type: AdminOrSuperAdminActionTypes.LOGBOOK_AREA_PAGE_CLICKED,
        payload: logs,
    };
};

export const getAccountAreas = ({ params, id }) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_AREAS,
        payload: API.getAccountAreas(params, id),
    });
export const getAllLogbookArea = (values, size, page, sorted, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_LOGBOOK_AREA,
        payload: API.getAllLogbookArea(values, size, page, sorted, id)
            .then((response) => {
                if (!response.data.error || response.data.success) {
                    return {
                        total_no_of_pages: response.data.total_no_of_pages,
                        logbookLogs: response.data.logbooks,
                        totalLogbookLogs: response.data.count,
                        searchValues: values,
                        logsAreaUrl: response.data.logs_url,
                        rows: response.data.no_of_rows,
                        logbookAreaCurPage: page - 1,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
export const getAreaTypes = () => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_AREA_TYPES,
        payload: API.getAreaTypes(),
    });
export const createArea = ({ body, id }) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.CREATE_AREA,
        payload: API.createArea(body, id).then((response) => {
            if ((response.data.success)) {
                // smartlook analytics
                // let data = cloneDeep(response.data.area);
                // const eventName = 'Created_Jobs_New_Area';
                // const eventProperties = {
                //     'jobs_area_name' : data.name,
                //     'jobs_area_account_id' : data.account && data.account.id ? data.account.id : null,
                //     'jobs_area_account_name' : data.account && data.account.name ? data.account.name : null,
                //     'jobs_area_id' : data.area_id,
                //     'jobs_area_type' : data.area_type && data.area_type.name ? data.area_type.name : null,
                //     'jobs_area_floor_name' : data.floor && data.floor.name ? data.floor.name : null,
                //     'jobs_area_schedule_at' : data.schedule_at,
                //     'jobs_area_schedule_at_utc' : data.schedule_at_utc
                // };
                // window.smartlook('track', eventName, eventProperties);
                history.push(`/accounts/${id}/account_users?job_area=true`)
                SuccessAlert('Area Added Successfully');
            }
        }),
    });
export const deleteArea = ({ areaId, id }) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_AREA,
        payload: API.deleteArea(areaId, id).then((res) => {
            if (!res.data.error) {
                SuccessAlert('Area Deleted Successfully.');
                return { area_id: areaId };
            }
        }),
    });
export const deleteLogbookArea = ({ accountId, logbookId }) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_LOGBOOK_AREA,
        payload: API.deleteLogbookArea(accountId, logbookId).then((res) => {
            if (!res.data.error) {
                SuccessAlert('Logbook Area Deleted Successfully.');
                return { logbook_id: logbookId };
            }
        }),
    });



export const getArea = ({ id, area_id }) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_AREA,
        payload: API.getArea(id, area_id)
    })

export const clearArea = () => dispatch =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.CLEAR_AREA,
    })

export const updateArea = ({ body, id, area_id }) => dispatch =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.UPDATE_AREA,
        payload: API.updateArea(body, id, area_id).then((response) => {
            if (response.data.success) {
                // smartlook analytics
                // let data = cloneDeep(response.data.area);
                // const eventName = 'Edited_Jobs_New_Area';
                // const eventProperties = {
                //     'edited_jobs_area_name' : data.name,
                //     'edited_jobs_area_account_id' : data.account_id,
                //     'edited_jobs_area_account_name' : data.account && data.account.name ? data.account.name : null,
                //     'edited_jobs_area_id' : data.area_id,
                //     'edited_jobs_area_type' : data.area_type && data.area_type.name ? data.area_type.name : null,
                //     'edited_jobs_area_floor_name' : data.floor && data.floor.name ? data.floor.name : null,
                //     'edited_jobs_area_schedule_at' : data.schedule_at,
                //     'edited_jobs_area_schedule_at_utc' : data.schedule_at_utc,
                // };
                // window.smartlook('track', eventName, eventProperties);
                history.push(`/accounts/${id}/account_users?job_area=true`);
                SuccessAlert('Area Updated Successfully.');
            }
        }),
    });

export const importArea = ({ body, id }) => {
    let params = {
        size: 10,
        page: 1
    }
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.IMPORT_AREA,
            payload: API.importArea(body, id).then(response => {
                if (response.data.success) {
                    document.getElementById('closeAreaImportPopup').click();
                    dispatch(getAccountAreas({ params, id }));
                    SuccessAlert('Successfully Imported');
                    return response;
                }
            })
                .catch((error) => {
                    console.log(error);
                }),
        });
    }
}

export const importCheckArea = ({ body, id }) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.IMPORT_CHECK_AREA,
            payload: API.importCheckArea(body, id).then((response) => {
                if (response.data.success) {
                    dispatch(importArea({ body, id }));
                    return { data: response };
                } else {
                    setTimeout(() => {
                        document.getElementById('areaLogsErrorImport').click()
                        document.getElementById('closeAreaImportPopup').click()
                        errorHandler('Unable to import given CSV, Please check downloaded error logs');
                    }, 500);
                    return { error: convertAreaLogsDataToCsvType(response.data.message), errorAvailable: true };
                }
            })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAreaByQR = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_AREA_BY_QR,
        payload: API.getAreaByQR(id),
    });

export const searchHistory = (params, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.SEARCH_HISTORY,
        payload: API.searchHistory(params, id),
    });

export const getAllHistory = (params, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.SEARCH_HISTORY,
        payload: API.getAllHistory(params, id),
    });

export const createAreaTask = (params, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.CREATE_AREA_TASK,
        payload: API.createAreaTask(params, id),
    });

export const getFrontlineList = (params) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_FRONTLINE_LIST,
        payload: API.getFrontlineList(params)
            .then((response) => {
                if (response.data.success) {
                    return {
                        frontlineUserCurPage: params.page - 1,
                        data: response.data,
                        fl_user_rows: response.data.fl_users_no_of_rows,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const searchFrontlineUser = (values) => {
    return (dispatch, getState) => {
        const size = getState().adminOrSuperAdminReducer.fl_user_rows;
        const page = 1;
        const name = values.name ? values.name : '';
        const id = values.id ? values.id : '';

        dispatch({
            type: AdminOrSuperAdminActionTypes.SEARCH_FRONTLINE_USERS,
            payload: API.getFrontlineList({ size, page, name, id })
                .then((response) => {
                    if (!response.data.error) {
                        return {
                            frontlines: response.data.frontlines,
                            total_no_of_pages: response.data.total_no_of_pages,
                            frontLineRows: response.data.no_of_rows,
                            users_count: response.data.users_count,
                            fl_user_rows: response.data.fl_users_no_of_rows,
                            frontlineUserCurPage: 0,
                            // sort_ticket_column: columnName ? columnName : '',
                            // ticket_order: desc !== '' ? desc : ''
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getFrontline = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_FRONTLINE,
        payload: API.getFrontline(id),
    });

export const deleteFrontline = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_FRONTLINE,
        payload: API.deleteFrontline(id),
    });
export const importFrontline = (body) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.IMPORT_FRONTLINE,
        payload: API.importFrontline(body),
    });

export const importCheckFrontline = (body) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.IMPORT_CHECK_FRONTLINE,
        payload: API.importCheckFrontline(body),
    });

export const addAreaType = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_AREA_TYPE,
            payload: API.addAreaType(body)
                .then((response) => {
                    let AreaTypes = cloneDeep(getState().adminOrSuperAdminReducer.areaTypes);
                    let newAreaType = {};
                    if (response.data.success) {
                        SuccessAlert('Area type added successfully');
                        closeModel();
                        newAreaType = response.data.area_type;
                        AreaTypes.unshift(newAreaType);
                        AreaTypes.sort(function (a, b) {
                            if (a.name < b.name) {
                                return -1;
                            }
                            if (a.name > b.name) {
                                return 1;
                            }
                            return 0;
                        });
                        return { area_count: response.data.area_count, AreaTypes: AreaTypes };
                    } else {
                        closeModel();
                        let AreaTypes = cloneDeep(getState().adminOrSuperAdminReducer.areaTypes);
                        let totalAreaTypes = cloneDeep(getState().adminOrSuperAdminReducer.totalAreaTypes);
                        return { AreaTypes: AreaTypes, area_count: totalAreaTypes };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const updateAreaType = (body, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.UPDATE_AREA_TYPE,
        payload: API.updateAreaType(body, id).then((res) => {
            if (res.data.success) {
                closeModel();
                SuccessAlert('Area type updated successfully');
                return res;
            }
        }),
    });

export const updateDeficiency = (body, id) => {
    return (dispatch, getState) => {
        let Deficiencies = getState().adminOrSuperAdminReducer.deficiencies;

        return dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_DEFICIENCY,
            payload: API.updateDeficiency(body, id).then((res) => {
                closeModel();
                if (res.data.success) {
                    closeModel();
                    const index = Deficiencies.findIndex((h) => h.id === res.data.deficiency.id);
                    Deficiencies[index] = res.data.deficiency;
                    SuccessAlert('Deficiency updated successfully');
                    return { deficiencies: Deficiencies };
                }
            }),
        });
    };
};

export const getDeficiency = (id) => {
    return (dispatch, getState) => {
        return dispatch({
            type: AdminOrSuperAdminActionTypes.GET_DEFICIENCY,
            payload: API.getDeficiency(id).then((res) => {
                if (res.data.success) {
                    return { deficiency: res.data.area[0].deficiencies }
                }
            }),
        });
    };
};

export const deleteAreaType = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_AREA_TYPE,
        payload: API.deleteAreaType(id).then((res) => {
            if (res.data.success) {
                SuccessAlert('Area type deleted successfully');
                return { id };
            }
        }),
    });

export const addFloor = (body) => {
    return (dispatch, getState) => {
        let FloorsList = getState().adminOrSuperAdminReducer.totalFloors;

        return dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_FLOOR,
            payload: API.addFloor(body).then((res) => {
                closeModel();
                if (res.data.success) {
                    SuccessAlert('Floor added successfully');
                    return { data: res.data, Floors_list: res.data.floor_count };
                } else {
                    return { Floors_list: FloorsList };
                }
            }),
        });
    };
};

export const addDeficiency = (body) => {
    return (dispatch, getState) => {
        let Deficiencies = getState().adminOrSuperAdminReducer.deficiencies;
        let Deficiency_count = parseInt(getState().adminOrSuperAdminReducer.totalDeficiencies);
        return dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_DEFICIENCY,
            payload: API.addDeficiency(body).then((res) => {
                closeModel();
                if (res.data.success) {
                    SuccessAlert('Deficiency added successfully');
                    Deficiencies.unshift(res.data.deficiency)
                    return { data: res.data, Deficiency_list: Deficiencies, Deficiency_count: Deficiency_count + 1 };
                } else {
                    return { Deficiency_list: Deficiencies, Deficiency_count: Deficiency_count + 1 }
                }
            }),
        });
    };
};


export const getAllDeficiencies = (body) => {
    return (dispatch, getState) => {
        return dispatch({
            type: AdminOrSuperAdminActionTypes.GET_ALL_DEFICIENCIES,
            payload: API.getAllDeficiencies(body).then((res) => {
                closeModel();
                if (res.data.success) {
                    return { data: res.data.deficiency, Deficiency_list: res.data.deficiency_count, floor_count: res.data.floor_count, area_count: res.data.area_count, holiday_count: res.data.holiday_count };
                }
            }),
        });
    };
};

export const updateFloor = (body, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.UPDATE_FLOOR,
        payload: API.updateFloor(body, id).then((res) => {
            closeModel();
            if (res.data.success) {
                SuccessAlert('Floor updated successfully');
                return res;
            }
        }),
    });
export const deleteFloor = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.DELETE_FLOOR,
        payload: API.deleteFloor(id).then((res) => {
            if (res.data.success) {
                SuccessAlert('Floor deleted successfully');
                return { id };
            }
        }),
    });
export const getFloors = (body) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_FLOORS,
        payload: API.getFloors(body),
    });
export const editFloorClicked = (position) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_FLOOR_CLICKED,
        payload: position,
    };
};
export const editAreaTypeClicked = (position) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_AREA_TYPE_CLICKED,
        payload: position,
    };
};
export const editDeficiencyClicked = (deficiency) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_DEFICIENCY_CLICKED,
        payload: deficiency,
    };
};
export const getAllAreaDetails = (token) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_AREA_DETAILS,
        payload: API.getAllAreaDetails(token),
    });

export const getScanAnalyticsData = (params) => {
    return (dispatch, getState) => {
        return dispatch({
            type: AdminOrSuperAdminActionTypes.SCAN_ANALYTICS,
            payload: API.scanAnalytics(params)
                .then((response) => {
                    history.push(routes.REPORTS);
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAllAreaData = (params) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.AREA_DATA,
        payload: API.getAllAreaData(params),
    });

export const nonLoggedInFrontlines = () => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.NONE_LOGGED_IN_FRONTLINE_DATA,
        payload: API.nonLoggedInFrontlineData(),
    });

export const getAllAreaOfAccount = (params, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.AREA_OF_ACCOUNT,
        payload: API.getAllAreaOfAccount(params, id),
    });
export const scheduleStatus = (body, area_id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.SCHEDULE_STATUS,
        payload: API.scheduleStatus(body, area_id),
    });

export const getHolidaysList = () => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_HOLIDAYS_LIST,
            payload: API.getHolidaysList()
                .then((response) => {
                    if (!response.data.error) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getQuotaForQuotes = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_QUOTA_FOR_QUOTES,
            payload: API.getQuotaForQuotes(data)
                .then((response) => {
                    if (!response.data.error) {
                        return response.data.quota_for_quotes;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addNewHoliday = (id, date) => {
    return (dispatch, getState) => {
        let HolidaysList = getState().adminOrSuperAdminReducer.holidaysList;
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_HOLIDAY,
            payload: API.addNewHoliday(id, date)
                .then((response) => {
                    if (response.data.success) {
                        HolidaysList.unshift(response.data);
                        closeModel();
                        SuccessAlert('Holiday Successfully Added.');
                        return {
                            holidaysList: HolidaysList,
                            holiday_count: response.data.holiday_count,
                            openModal: false,
                        };
                    } else {
                        // closeModel();
                        let holiday_count = getState().adminOrSuperAdminReducer.totalHolidays;
                        return { holidaysList: HolidaysList, holiday_count: holiday_count, openModal: true };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const submitCHIReview = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.SUBMIT_CHI_REVIEW,
            payload: API.submitCHIReview(value)
                .then((response) => {
                    storage.remove('chisurveyStarted');
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editHolidayClicked = (holiday) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_HOLIDAY_CLICKED,
        payload: holiday,
    };
};

export const clearDeficiency = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLEAR_DEFICIENCY,
    };
};

export const updateHoliday = (data) => {
    return (dispatch, getState) => {
        let HolidaysList = getState().adminOrSuperAdminReducer.holidaysList;
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_HOLIDAY,
            payload: API.updateHoliday(data)
                .then((response) => {
                    if (!response.data.error) {
                        closeModel();
                        if (getState().adminOrSuperAdminReducer.holidaysList) {
                            // let HolidaysList = cloneDeep(getState().adminOrSuperAdminReducer.holidaysList);
                            const index = HolidaysList.findIndex((h) => h.id === response.data.id);

                            HolidaysList[index] = response.data;
                            SuccessAlert('Holiday Successfully Updated');

                            return { holidaysList: HolidaysList, openModal: false, isEdit: false };
                        }
                    } else {
                        // closeModel();
                        return { holidaysList: HolidaysList, openModal: true, isEdit: true };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteHoliday = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_HOLIDAY,
            payload: API.deleteHoliday(id)
                .then((response) => {
                    let HolidaysList = cloneDeep(getState().adminOrSuperAdminReducer.holidaysList);
                    if (!response.data.error) {
                        let searchIndex = HolidaysList.findIndex(function (h) {
                            return h.id === id;
                        });
                        HolidaysList.splice(searchIndex, 1);
                        SuccessAlert('Holiday Successfully Deleted.');
                        return HolidaysList;
                    } else {
                        return HolidaysList;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAllCHIServyResponse = (chi_no_of_rows, page, date, option, sorted, filtered) => {debugger;
    return (dispatch, getState) => {
        // const chi_no_of_rows = getState().adminOrSuperAdminReducer.chi_no_of_rows;
        // const sort_users_column = getState().adminOrSuperAdminReducer.sort_users_column;
        // const users_order = getState().adminOrSuperAdminReducer.users_order;

        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_ALL_CHI_SERVYS,
            payload: API.getAllCHIServyResponse(chi_no_of_rows, page, date, option, sorted, filtered)
                .then(response => {
                    return {
                        chiServyList: response.data.chiresponses,
                        chi_no_of_rows: chi_no_of_rows,
                        chi_total_count: response.data.total_count,
                        chi_total_no_of_pages: response.data.total_no_of_pages,
                        chi_cur_page: page - 1
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getCHIDashboard = (date, mode, option) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_CHI_DASHBOARD,
            payload: API.getCHIDashboard(date, mode, option)
                .then(response => {
                    return {
                        chiChartData: response.data
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const setInActiveorInactiveAreas = (accountid, ids, value) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.SET_ACTIVE_INACTIVE_AREAS,
            payload: API.setInActiveAreas(accountid, ids, value)
                .then((response) => {
                    if (!response.data.error) {
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAllQRCodes = (ACCOUNT_ID) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_QR_CODES,
        payload: API.getAllQRCodes(ACCOUNT_ID),
    });

export const qqvFeedback = (values) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.QQV_FEEDBACK,
            payload: API.qqvFeedback(values)
                .then((response) => {
                    if (response.data.success) {
                        let inspection = cloneDeep(getState().adminOrSuperAdminReducer.inspection);
                        inspection['feedback'] = response.data.feedback;
                        inspection['feedback_status'] = response.data.feedback_status;
                        return { inspection };
                        // SuccessAlert('Feedback Submitted.');
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        })
    }
}
export const getAccountExportData = (mode) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_ACCOUNT_EXPORT_DATA,
    payload: API.getAccountExportData(mode)
})

export const getUserExportData = () => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_USER_EXPORT_DATA,
    payload: API.getUserExportData()
})

export const makeAccountUserAsOwner = (accountId, userId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.MAKE_ACCOUNT_USER_AS_OWNER,
            payload: API.makeAccountUserAsOwner(accountId, userId)
                .then(response => {
                    if (response.data.success) {
                        const account = getState().adminOrSuperAdminReducer.account;
                        account.owner_user_id = userId;
                        return account;
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }
}

export const accountActiveChanged = () => {
    return {
        type: AdminOrSuperAdminActionTypes.ACCOUNT_ACTIVE_CHANGED,
    };
};
export const usersActiveChanged = () => {
    return {
        type: AdminOrSuperAdminActionTypes.USERS_ACTIVE_CHANGED
    }
}

export const companyActiveChanged = () => {
    return {
        type: AdminOrSuperAdminActionTypes.COMPANY_ACTIVE_CHANGED
    }
}

export const welcomeMailForUser = (userId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.WELCOME_MAIL_FOR_USER,
            payload: API.welcomeMailForUser(userId)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('Welcome Email has been sent');
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const accountPageClicked = (account) => {
    return {
        type: AdminOrSuperAdminActionTypes.ACCOUNT_PAGE_CLICKED,
        payload: account,
    };
};

export const inspectionFormPageClicked = (form) => {
    return {
        type: AdminOrSuperAdminActionTypes.INSPECTION_FORM_PAGE_CLICKED,
        payload: form,
    };
};

export const userPageClicked = (user) => {
    return {
        type: AdminOrSuperAdminActionTypes.USER_PAGE_CLICKED,
        payload: user,
    };
};

export const frontlineUserPageClicked = (user) => {
    return {
        type: AdminOrSuperAdminActionTypes.FRONTLINE_USER_PAGE_CLICKED,
        payload: user
    }
}

export const getAssociatedInspections = (accountId, mode) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_ASSOCIATED_INSPECTIONS,
            payload: API.getAssociatedInspections(accountId, mode)
                .then(response => {
                    if (response.data.success) {
                        let account = cloneDeep(getState().adminOrSuperAdminReducer.account);
                        account['inspection_forms'] = response.data.inspection_forms
                        return account
                        // SuccessAlert('Welcome Email has been sent');
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }
}

export const previewChiClicked = (chi) => {
    history.push(`/chi_survey/preview/${chi.ticket[0].id}`)
    return {
        type: AdminOrSuperAdminActionTypes.CHI_PREVIEW,
        payload: chi
    }
}
export const getChiPreview = (id) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_CHI_PREVIEW,
    payload: API.getEditTicket(id)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log(error);
        })
})

export const viewQuoteStatusClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.VIEW_QUOTE_STATUS_CLICKED,
    };
};

export const getAccountAdminList = () => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ACCOUNT_ADMINLIST,
        payload: API.getAccountAdminList()
            .then(response => {
                return response.data.users;
            })
            .catch(error => {
                console.log(error);
            })
    })
}

export const getInspectionIndex = () => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_INSPECTION_INDEX,
        payload: API.getInspectionIndex()
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    })
}

export const getUserInspectionIndex = () => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_USER_INSPECTION_INDEX,
        payload: API.getUserInspectionIndex()
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    })
}
export const inspectionCSvCliked = (inspection) => dispatch => {
    debugger
    dispatch({
        type: AdminOrSuperAdminActionTypes.INVENTARY_CSV,
        payload: API.inspectionCSvCliked(inspection.id)
            .then(async (response) => {
                const convertedData = await convertInspectionInventaryData(inspection, response.data.data_list, response.data.fields)
                if (!response.data.error) {
                    const user = storage.get('user')
                    logEvent(analytics, 'downloadCSV_inspection_web', { user_role: user.role, user_email: user.email, user_name: `${user.first_name} ${user.last_name}`, userId: user.id, inspection_id: inspection.id });
                    return {
                        convertedData: convertedData,
                        data_list: response.data.data_list,
                        fields: response.data.fields,
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
};



export const inspectionCSvClikedReport = (inspection) => {
    return {
        type: AdminOrSuperAdminActionTypes.SET_INSPECTION,
        payload: inspection,
    };
};

export const getInspectionPdf = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_INSPECTION_PDF,
            payload: API.getInspectionPdf(id)
                .then(async (response) => {
                    if (response.data.success) {
                        SuccessAlert('Please Check Your Mail For Downloaded Result.');
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    }
}
export const getKpiDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_DASHBOARD_DATA,
            payload: API.getKpiDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiInspectionQQvScoreDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_INS_QQV_SCRORE_DASHBOARD_DATA,
            payload: API.getKpiInspectionQQvScoreDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getKpiInspectionQQvRootcaseDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_INS_QQV_ROOTCASE_DASHBOARD_DATA,
            payload: API.getKpiInspectionQQvRootcaseDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiInspectionDeficencyDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_INS_DEFICENCY_DASHBOARD_DATA,
            payload: API.getKpiInspectionDeficencyDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiInspectionDateDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_INS_DATE_DASHBOARD_DATA,
            payload: API.getKpiInspectionDateDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getKpiTopInspectionDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_TOP_INSPECTOR,
            payload: API.getKpiTopInspectionDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiInspectioninAccountDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_INS_ACCOUNT_DASHBOARD_DATA,
            payload: API.getKpiInspectioninAccountDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiInspectionPieChartDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_INS_PIECHART_DASHBOARD_DATA,
            payload: API.getKpiInspectionPieChartDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiInspectioCustomerDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_INS_CUSTOMER_DASHBOARD_DATA,
            payload: API.getKpiInspectioCustomerDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiInspectionQQvDashboardData = (DataType, date, values, quarter) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_INS_QQV_DASHBOARD_DATA,
            payload: API.getKpiInspectionQQvDashboardData(DataType, date, values === '' ? searchKpiValues : values, quarter)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}


export const getAllCompanyList = () => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_COMPANY_LIST,
    payload: API.getAllCompanyList()
        .then(response => {

            return response.data;
        })
        .catch(error => {
            console.log(error);
        })
})

export const getKpiTicketCountDashboardData = (DataType, date, values) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_TICKET_COUNT_DASHBOARD_DATA,
            payload: API.getKpiTicketCountDashboardData(DataType, date, values === '' ? searchKpiValues : values)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiTicketGraphDashboardData = (DataType, date, values) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_TICKET_GRAPH_DASHBOARD_DATA,
            payload: API.getKpiTicketGraphDashboardData(DataType, date, values === '' ? searchKpiValues : values)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiTicketDaysOpenDashboardData = (DataType, date, values) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_TICKET_DAYSOPEN_DASHBOARD_DATA,
            payload: API.getKpiTicketDaysOpenDashboardData(DataType, date, values === '' ? searchKpiValues : values)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiTicketHighUsersDashboardData = (DataType, date, values) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_TICKET_HIGHUSERS_DASHBOARD_DATA,
            payload: API.getKpiTicketHighUsersDashboardData(DataType, date, values === '' ? searchKpiValues : values)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}
export const getKpiTicketJobsDashboardData = (DataType, date, values) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_KPI_TICKET_JOBS_DASHBOARD_DATA,
            payload: API.getKpiTicketJobsDashboardData(DataType, date, values === '' ? searchKpiValues : values)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSearchValues: values
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}


// new kpi inspection


export const getKpiInspectionCount = (values) => {
    return (dispatch, getState) => {
        const kpiSearchValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        if (values.account_id && values.account_id.length < 1) {
            delete values.account_id;
        }
        if (!values.account_id && !values.reset) {
            values['account_id'] = kpiSearchValues.account_id
        }
        if (!values.inspected_by_id && !values.reset) {
            values['inspected_by_id'] = kpiSearchValues.inspected_by_id
        }
        if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && (kpiSearchValues.option === 'month' || kpiSearchValues.option === '90_days') && !values.from) {
            errorHandler('Please enter the dates')
        } else {
            if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && !values.from) {
                values['from'] = kpiSearchValues.from;
                values['to'] = kpiSearchValues.to;
            }
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_KPI_INSPECTION_COUNT,
                payload: API.getKpiInspectionCount(values)
                    .then(response => {
                        return {
                            data: response.data,
                            kpiSearchValues: values
                        };
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
        }

    }
}
export const getKpiAvarageScore = (values) => {
    return (dispatch, getState) => {
        const kpiSearchValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        if (values.account_id && values.account_id.length < 1) {
            delete values.account_id;
        }
        if (!values.account_id && !values.reset) {
            values['account_id'] = kpiSearchValues.account_id
        }
        if (!values.inspected_by_id && !values.reset) {
            values['inspected_by_id'] = kpiSearchValues.inspected_by_id
        }
        if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && (kpiSearchValues.option === 'month' || kpiSearchValues.option === '90_days') && !values.from) {
            errorHandler('Please enter the dates')
            return
        } else {
            if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && !values.from) {
                values['from'] = kpiSearchValues.from;
                values['to'] = kpiSearchValues.to;
            }
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_KPI_INSPECTION_AVERAGESCRORE,
                payload: API.getKpiAvarageScore(values)
                    .then(response => {
                        return {
                            data: response.data,
                            kpiSearchValues: values
                        };
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
        }
    }
}
export const getKpiTopInspection = (values) => {
    return (dispatch, getState) => {
        const kpiSearchValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        if (values.account_id && values.account_id.length < 1) {
            delete values.account_id;
        }
        if (!values.account_id && !values.reset) {
            values['account_id'] = kpiSearchValues.account_id
        }
        if (!values.inspected_by_id && !values.reset) {
            values['inspected_by_id'] = kpiSearchValues.inspected_by_id
        }
        if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && (kpiSearchValues.option === 'month' || kpiSearchValues.option === '90_days') && !values.from) {
            errorHandler('Please enter the dates')
            return
        } else {
            if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && !values.from) {
                values['from'] = kpiSearchValues.from;
                values['to'] = kpiSearchValues.to;
            }
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_KPI_TOP_INSPECTOR,
                payload: API.getKpiTopInspection(values)
                    .then(response => {
                        return {
                            data: response.data,
                            kpiSearchValues: values
                        };
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
        }
    }
}
export const getKpiQualityInspection = (values) => {
    return (dispatch, getState) => {
        const kpiSearchValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        if (values.account_id && values.account_id.length < 1) {
            delete values.account_id;
        }
        if (!values.account_id && !values.reset) {
            values['account_id'] = kpiSearchValues.account_id
        }
        if (!values.inspected_by_id && !values.reset) {
            values['inspected_by_id'] = kpiSearchValues.inspected_by_id
        }
        if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && (kpiSearchValues.option === 'month' || kpiSearchValues.option === '90_days') && !values.from) {
            errorHandler('Please enter the dates')
            return
        } else {
            if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && !values.from) {
                values['from'] = kpiSearchValues.from;
                values['to'] = kpiSearchValues.to;
            }
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_KPI_QUALITY_INSPECTION,
                payload: API.getKpiQualityInspection(values)
                    .then(response => {
                        return {
                            data: response.data,
                            kpiSearchValues: values
                        };
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
        }
    }
}

export const getKpiAverageSectionQuality = (values) => {
    return (dispatch, getState) => {
        const kpiSearchValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        if (values.account_id && values.account_id.length < 1) {
            delete values.account_id;
        }
        if (!values.account_id && !values.reset) {
            values['account_id'] = kpiSearchValues.account_id
        }
        if (!values.inspected_by_id && !values.reset) {
            values['inspected_by_id'] = kpiSearchValues.inspected_by_id
        }
        if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && (kpiSearchValues.option === 'month' || kpiSearchValues.option === '90_days') && !values.from) {
            errorHandler('Please enter the dates')
            return
        } else {
            if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && !values.from) {
                values['from'] = kpiSearchValues.from;
                values['to'] = kpiSearchValues.to;
            }
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_KPI_AVERAGE_SECTION_QUALITY,
                payload: API.getKpiAverageSectionQuality(values)
                    .then(response => {
                        return {
                            data: response.data,
                            kpiSearchValues: values
                        };
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
        }
    }
}

export const getKpiAverageSectionSafety = (values) => {
    return (dispatch, getState) => {
        const kpiSearchValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        if (values.account_id && values.account_id.length < 1) {
            delete values.account_id;
        }
        if (!values.account_id && !values.reset) {
            values['account_id'] = kpiSearchValues.account_id
        }
        if (!values.inspected_by_id && !values.reset) {
            values['inspected_by_id'] = kpiSearchValues.inspected_by_id
        }
        if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && (kpiSearchValues.option === 'month' || kpiSearchValues.option === '90_days') && !values.from) {
            errorHandler('Please enter the dates')
            return
        } else {
            if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && !values.from) {
                values['from'] = kpiSearchValues.from;
                values['to'] = kpiSearchValues.to;
            }
            dispatch({
                type: AdminOrSuperAdminActionTypes.GET_KPI_AVERAGE_SECTION_SAFETY,
                payload: API.getKpiAverageSectionSafety(values)
                    .then(response => {
                        return {
                            data: response.data,
                            kpiSearchValues: values
                        };
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
        }
    }
}


export const getAvgModalData = (values) => {
    return (dispatch, getState) => {
        const kpiSearchValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        if (values.account_id && values.account_id.length < 1) {
            delete values.account_id;
        }
        if (!values.account_id && !values.reset) {
            values['account_id'] = kpiSearchValues.account_id
        }
        if (!values.inspected_by_id && !values.reset) {
            values['inspected_by_id'] = kpiSearchValues.inspected_by_id
        }
        // if(cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && (kpiSearchValues.option === 'month' || kpiSearchValues.option === '90_days')  && !values.from){
        //     errorHandler('Please enter the dates')
        //  return 
        // } else{
        if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && !values.from) {
            values['from'] = kpiSearchValues.from;
            values['to'] = kpiSearchValues.to;
        }
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_AVG_MODAL_DATA,
            payload: API.getAvgModalData(values)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSectionValues: values,
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getQualityInsModalData = (values) => {
    return (dispatch, getState) => {
        const kpiSearchValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        if (values.account_id && values.account_id.length < 1) {
            delete values.account_id;
        }
        if (!values.account_id && !values.reset) {
            values['account_id'] = kpiSearchValues.account_id
        }
        if (!values.inspected_by_id && !values.reset) {
            values['inspected_by_id'] = kpiSearchValues.inspected_by_id
        }
        if (cloneDeep(getState().adminOrSuperAdminReducer.isCustomDate) === true && !values.from) {
            values['from'] = kpiSearchValues.from;
            values['to'] = kpiSearchValues.to;
        }
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_QUALITY_MODAL_DATA,
            payload: API.getQualityInsModalData(values)
                .then(response => {
                    return {
                        data: response.data,
                        kpiSectionValues: values,
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getAverageGraphInspections = (ids) => {
    return (dispatch, getState) => {
        const searchKpiValues = cloneDeep(getState().adminOrSuperAdminReducer.kpiSearchValues);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_AVERAGE_GRAPH_INSPECTIONS,
            payload: API.getAverageGraphInspections(ids)
                .then(response => {
                    return {
                        data: response.data.inspections,
                    };
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

// criteria actions start here


export const getCriteriaList = (data) => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_CRITERIAS_LIST,
        payload: API.getCriteriaList(data)
            .then(response => {
                if (response.data.success) {
                    let ele = document.getElementById('closeCrteriaModal');
                    if (ele) {
                        ele.click();
                    }
                    return { criteries: response.data.criteria, totalCriteries: response.data.criteria_count };
                }
            })
            .catch(error => {
                console.log(error);
            })
    })
}

export const getCriteria = (id) => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_CRITERIA,
        payload: API.getCriteria(id)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    })
}


export const deleteCriteria = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_CRITERIA,
            payload: API.deleteCriteria(id)
                .then(response => {
                    if (response.data.success) {
                        let CriteriaList = cloneDeep(getState().adminOrSuperAdminReducer.criteries);
                        dispatch(getCriteriaList({ hide: CriteriaList[0].hide }))
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}


export const editCriteria = (body, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.EDIT_CRITERIA,
            payload: API.editCriteria(body, id)
                .then(response => {
                    if (response.data.success) {
                        // dispatch(getCriteriaList())

                        if (getState().adminOrSuperAdminReducer.criteries) {
                            let CriteriaList = cloneDeep(getState().adminOrSuperAdminReducer.criteries);
                            const index = CriteriaList.findIndex((c) => c.id === response.data.criteria.id);

                            CriteriaList[index] = response.data.criteria;
                            SuccessAlert('Criteria Successfully Updated');
                            let ele = document.getElementById('closeCriteriaModal');
                            if (ele) {
                                ele.click();
                            }
                            return CriteriaList;
                        }
                    }
                    else {
                        let ele = document.getElementById('closeCriteriaModal');
                        if (ele) {
                            ele.click();
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })

        })
    }
}

export const createCriteries = (body) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.CREATE_CRITERIA,
            payload: API.createCriteries(body)
                .then(response => {
                    if (response.data.success) {
                        let ele = document.getElementById('closeCriteriaModal');
                        if (ele) {
                            ele.click();
                        }
                        if (getState().adminOrSuperAdminReducer.criteries) {
                            let CriteriaList = cloneDeep(getState().adminOrSuperAdminReducer.criteries);

                            CriteriaList.unshift(response.data.criteria);
                            SuccessAlert('Criteria Successfully Updated');
                            let ele = document.getElementById('closeCriteriaModal');
                            if (ele) {
                                ele.click();
                            }
                            return CriteriaList;
                        }
                    } else {
                        if (getState().adminOrSuperAdminReducer.criteries) {
                            let CriteriaList = cloneDeep(getState().adminOrSuperAdminReducer.criteries);
                            let ele = document.getElementById('closeCriteriaModal');
                            if (ele) {
                                ele.click();
                            }
                            return CriteriaList;
                        }

                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const addNewSchedule = (schedule) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_SCHEDULE,
            payload: API.addNewSchedule(schedule)
                .then((response) => {
                    const Account = cloneDeep(getState().adminOrSuperAdminReducer.currentAccount);
                    if (response.data.success) {
                        // smartlook analytics
                        // let data = {...response.data.schedules[0]}
                        // const eventName = 'Created_New_Inspection_Schedule';
                        // const eventProperties = {
                        //     'inspection_schedule_id' : data.id,
                        //     'inspection_schedule_name' : data.title,
                        //     'inspection_schedule_job_name' : data.account && data.account.name ? data.account.name : null,
                        //     'inspection_schedule_job_id' : data.account.id,
                        //     'inspection_schedule_form_id' : data.inspection_form_id,
                        //     'inspection_schedule_created_at' : data.created_at,
                        //     'inspection_schedule_start_date' : data.start_date,
                        //     'inspection_schedule_end_date' : data.end_date,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        SuccessAlert('Schedule successfully added.');
                        debugger
                        if (Account && !isNaN(window.location.href.split('/')[window.location.href.split('/').indexOf('accounts') + 1])) {
                            history.push({pathname : `/accounts/${Account.id}/account_users/schedule-time`, state : {timezone : Account.timezone}});
                        } else {
                            history.push(`/schedules`);
                        }
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};
export const getAllSchedule = (account_id, start_time, end_time) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_SCHEDULE_DATA,
        payload: API.getAllSchedule(account_id, start_time, end_time)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });
export const getScheduleEvents = (startDate, endDate) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_SCHEDULE_EVENTS,
        payload: API.getScheduleEvents(startDate, endDate)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });
export const getSchedule = (id) => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_SCHEDULE,
        payload: API.getSchedule(id)
            .then(response => {
                debugger

                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    })
}
export const getAccountSchedule = (id, startDate, endDate) => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ACCOUNT_SCHEDULE,
        payload: API.getAccountSchedule(id, startDate, endDate)
            .then(response => {
                if (response.data.success) {
                    dispatch(getAccountUsersInfoPage(id));
                }
                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    })
}
// export const deleteSchedule = (id) => dispatch => { 
//     dispatch({
//         type: AdminOrSuperAdminActionTypes.DELETE_SCHEDULE,
//         payload: API.deleteSchedule(id)
//             .then(response => { 
//                 dispatch(getScheduleEvents())
//                 SuccessAlert('Schedule is Successfully Deleted');
//             })
//             .catch(error => {
//                 console.log(error);
//             })
//     })
// }
export const deleteSchedule = (id, account_id, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_SCHEDULE,
            payload: API.deleteSchedule(id)
                .then((response) => {
                    const Account = cloneDeep(getState().adminOrSuperAdminReducer.currentAccount);
                    let getScheduleEventsList = cloneDeep(getState().adminOrSuperAdminReducer.getScheduleEventsList);
                    if (response.data.success) {
                        if (account_id) {
                            dispatch(getAccountSchedule(account_id, startDate, endDate));
                        } else {
                            dispatch(getScheduleEvents(startDate, endDate));
                        }
                        SuccessAlert('Schedule is Successfully Deleted');
                        return { getScheduleEventsList: getScheduleEventsList };
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};
export const deleteEvent = (id, account_id, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_SCHEDULE,
            payload: API.deleteEvent(id)
                .then((response) => {
                    const Account = cloneDeep(getState().adminOrSuperAdminReducer.currentAccount);
                    let getScheduleEventsList = cloneDeep(getState().adminOrSuperAdminReducer.getScheduleEventsList);
                    if (response.data.success) {
                        if (account_id) {
                            dispatch(getAccountSchedule(account_id, startDate, endDate));
                        } else {
                            dispatch(getScheduleEvents(startDate, endDate));
                        }
                        SuccessAlert('Event is Successfully Deleted');
                        return { getScheduleEventsList: getScheduleEventsList };
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};
export const editCriteriaClicked = (criteria) => {
    return {
        type: AdminOrSuperAdminActionTypes.EDIT_CRITERIA_CLICKED,
        payload: criteria,
    };
};
export const onTicketExportClicked = (body) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.EXPORT_INSPECTION,
    payload: API.onTicketExportClicked(body)
        .then(response => {
            if (response.data.success) {
                SuccessAlert('Please check your email for export results');
            }
        })
        .catch(error => {
            console.log(error);
        })

})
export const inspectionsClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.INSPECTIONS_CLICKED,
    };
};

export const ticketsClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.TICKETS_CLICKED,
    };
};

export const pegAssureTabClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.PEGASSURE_TAB_CLICKED,
    };
};

export const resetInspectionSearch = () => {
    return {
        type: AdminOrSuperAdminActionTypes.RESET_INSPECTION_SEARCH,
    };
};
export const resetTicketSearch = () => {
    return {
        type: AdminOrSuperAdminActionTypes.RESET_TICKET_SEARCH,
    };
};

export const checkOwnerInfo = () => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.CHECK_OWNER_INFO,
        payload: API.checkOwnerInfo()
            .then(response => {
                storage.set('isAccountOwner', response.data.owner_status);
                return response.data.owner_status;
            })
            .catch(error => {
                console.log(error);
            })
    })
}
export const markAsCompletedModalClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.COMPLETED_APPROVE_MODAL_CLICKED,
    };
};
export const getLatestInspections = (inspection_form_id, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_LATEST_INSPECTION,
        payload: API.getLatestInspections(inspection_form_id, id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const clearLatestInspection = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLEAR_LATEST_INSPECTION,
    };
};

export const openCameraClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_CAMERA_CLICKED,
    };
};
export const closeCameraClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_CAMERA_CLICKED,
    };
};

export const closeDeficiencyClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CLOSE_DEFICIENCY_CLICKED,
    };
};

export const openDeficiencyClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.OPEN_DEFICIENCY_CLICKED,
    };
};

export const submitFeedbackPresignUrl = (feedback, id, extentions, arrayBuffer) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK_PRESIGNED_URL,
            payload: API.submitFeedbackPresignUrl(feedback, id, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    let Feedback = {}
                    Feedback['feedback'] = {}
                    Feedback.feedback['attachments_attributes'] = [];
                    Feedback.feedback['comment'] = feedback.comment;
                    Feedback.feedback['rating'] = feedback.rating;
                    Feedback.feedback['deficiency_ids'] = feedback.deficiency_ids;

                    // Feedback.feedback['id'] = feedback.feedback.id
                    response.data.presigned_urls.forEach((p, i) => {

                        Feedback.feedback.attachments_attributes.push({ 'photo_path': p.attachment_path })

                        dispatch({
                            type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(submitFeedback(Feedback, id));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};
export const getScheduleMailEvents = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_SCHEDULE_EMAIL_EVENTS,
        payload: API.getScheduleMailEvents(id)
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                console.log(error);
            }),
    });
// export const getScheduleEventDashboard = (start_time, end_time) => dispatch => {
//     dispatch({
//         type: AdminOrSuperAdminActionTypes.GET_EVENT_DASHBOARD_DATA,
//         payload: API.getScheduleEventDashboard(start_time, end_time)
//             .then(response => { 
//                 return response.data;
//             })
//             .catch(error => {
//                 console.log(error);
//         })
// })
// }
export const getScheduleEventDashboard = (DataType, date, schedule_no_of_rows, page, sorted, filtered) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_EVENT_DASHBOARD_DATA,
        payload: API.getScheduleEventDashboard(DataType, date, schedule_no_of_rows, page ? page : 1, sorted, filtered)
            .then((response) => {
                if (!response.data.error || !response.data.code) {
                    return {
                        schedules: response.data.events,
                        schedule_page: response.data.pages,
                        schedule_no_of_rows: schedule_no_of_rows,
                        totalschedules: response.data.total_count,
                        scheduleCurPage: page - 1,
                        schedule_rows: schedule_no_of_rows,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const handleClickScheduleOccurrenceModal = () => {
    return {
        type: AdminOrSuperAdminActionTypes.HANDLE_CLICK_SCHEDULE_OCCURRENCE_MODAL,
    };
};

export const handleClickScheduleOutlookConfirmModal = () => {
    return {
        type: AdminOrSuperAdminActionTypes.HANDLE_CLICK_SCHEDULE_OUTLOOK_CONFIRM_MODAL,
    };
};

export const scheduleHistory = (params, accountId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.SCHEDULE_HISTORY,
            payload: API.scheduleHistory(params, accountId)
                .then((response) => {
                    if (response.data.success) {
                        if (accountId) {
                            dispatch(getAccountUsersInfoPage(accountId));
                        }
                        return {
                            scheduleLogList: response.data.logs,
                            totalPages: response.data.pages,
                            totalListCount: response.data.total_count,
                            scheduleHistoryRows: params.fl_users_no_of_rows,
                            scheduleHistoryCurPage: params.page - 1,
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const scheduleHistoryPageClicked = (data) => {
    return {
        type: AdminOrSuperAdminActionTypes.SCHEDULE_HISTORY_PAGE_CLICKED,
        payload: data
    }
}
export const submitFeedback = (feedback, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.SUBMIT_FEEDBACK,
            payload: API.submitFeedback(feedback, id)
                .then(response => {
                    if (response.data.success) {
                        // let data = cloneDeep(response.data.pegassure_feedback_res);
                        // const eventName = 'Created_Pegassure_Feedback';
                        // const eventProperties = {
                        //     'pegassure_feedback_id' : data.id,
                        //     'pegassure_feedback_type' : data.rating,
                        //     'pegassure_feedback_job_name' : data.accounts.name,
                        //     'pegassure_feedback_area_name' : data.area.name,
                        //     'pegassure_feedback_time' : data.time,
                        //     'pegassure_feedback_created_at' : data.created_at,
                        //     'pegassure_feedback_created_day' : data.days,
                        //     'pegassure_feedback_comments' : data.comment,
                        //     'pegassure_feedback_attachments' : data.attachments == [] ? 'No' : 'Yes',
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        if (feedback.feedback.rating !== 'excellent') {
                            history.push(`/pegassure-thankyou/${id}/service`)
                        }
                    } else {
                        errorHandler(response.data.message)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}

export const getPegassureFeedbacks = (values, feedbacks_no_of_rows, page, sorted, filtered) => (dispatch) =>
    dispatch({

        type: AdminOrSuperAdminActionTypes.GET_PEGASSURE_FEEDBACKS,
        payload: API.getPegassureFeedbacks(values, feedbacks_no_of_rows, page, sorted, filtered)
            .then(response => {
                if (response.data.success) {
                    return {
                        pegassureFeedbacks: response.data.pegassure_feedback,
                        feedbacks_page: response.data.total_no_of_pages,
                        feedbacks_no_of_rows: response.data.count,
                        totalFeedbacks: response.data.total_count,
                        feedbacksCurPage: page - 1,
                        feedbacks_rows: feedbacks_no_of_rows,
                        searchfeedbacksValues: values
                    }
                } else {
                    errorHandler(response.data.message)
                }
            })
            .catch(error => {
                console.log(error);
            })
    })

export const getFeedback = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_FEEDBACK,
            payload: API.getFeedback(id)
                .then(response => {
                    if (response.data.success) {
                        return {
                            feedback: response.data.pegassure_feedback
                        }
                    } else {
                        errorHandler(response.data.message)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        })
    }
}


export const previewFeedback = (feedback) => {
    // smartlook analytics
    // let data = cloneDeep(feedback);
    // const eventName = 'Preview_Pegassure_Feedback_Info';
    // const eventProperties = {
    //     'preview_pegassure_feedback_id' : data.id,
    //     'preview_pegassure_feedback_area_name' : data.area && data.area.name ? data.area.name : null,
    //     'preview_pegassure_feedback_job_name' : data.accounts && data.accounts.name ? data.accounts.name : null,
    //     'preview_pegassure_feedback_rating' : data.rating,
    //     'preview_pegassure_feedback_comments' : data.comment,
    //     'preview_pegassure_feedback_time' : data.time,
    //     'preview_pegassure_feedback_created_at' : data.feedback_created_at        ,
    //     'preview_pegassure_feedback_created_day' : data.day,
    // };
    // window.smartlook('track', eventName, eventProperties);
    history.push(`/feedbacks/previewFeedback/${feedback.id}`);
    return {
        type: AdminOrSuperAdminActionTypes.PREVIEW_FEEDBACK,
        payload: feedback,
    };
};

export const searchPegassureFeedbacks = (values, feedbacks_no_of_rows, page, sorted, filtered) => (dispatch) =>
    dispatch({

        type: AdminOrSuperAdminActionTypes.SEARCH_PEGASSURE_FEEDBACKS,
        payload: API.getPegassureFeedbacks(values, feedbacks_no_of_rows, page, sorted, filtered)
            .then(response => {
                if (response.data.success) {
                    return {
                        pegassureFeedbacks: response.data.pegassure_feedback,
                        feedbacks_page: response.data.total_no_of_pages,
                        feedbacks_no_of_rows: response.data.count,
                        totalFeedbacks: response.data.total_count,
                        feedbacksCurPage: page - 1,
                        feedbacks_rows: feedbacks_no_of_rows,
                        searchfeedbacksValues: values
                    }
                } else {
                    errorHandler(response.data.message)
                }
            })
            .catch(error => {
                console.log(error);
            })
    })


export const createLogbook = (body) => {
    return (dispatch) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.CREATE_LOGBOOK,
            payload: API.createLogbook(body)
                .then((response) => {
                    if (!response.data.error) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data.logbook);
                        // const eventName = 'Created_Jobs_New_Logbook';
                        // const eventProperties = {
                        //     'jobs_logbook_name' : data.name,
                        //     'jobs_logbook_id' : data.id,
                        //     'jobs_logbook_log_number' : data.log_number,
                        //     'jobs_logbook_account_id' : data.account && data.account.id ? data.account.id : null,
                        //     'jobs_logbook_account_name' : data.account && data.account.name ? data.account.name : null,
                        //     'jobs_logbook_area_type' : data.area_type && data.area_type.name ? data.area_type.name : null,
                        //     'jobs_logbook_area_type_id' : data.area_type_id,
                        //     'jobs_logbook_created_at' : data.created_at,
                        //     'jobs_logbook_floor_id' : data.floor_id,
                        //     'jobs_logbook_floor' : data.floor && data.floor.name ? data.floor.name : null,
                        //     'jobs_logbook_schedule_at' : data.schedule_at,
                        //     'jobs_logbook_schedule_at' : data.schedule_at,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        history.push(`/accounts/${response.data.logbook.account_id}/account_users?job_logbook=true`)
                        SuccessAlert('Logbook Added Successfully.');
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        });
    }
}

export const updateLogbook = (body) => {
    return (dispatch) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_LOGBOOK,
            payload: API.updateLogbook(body)
                .then((response) => {
                    if (!response.data.error) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data.logbook);
                        // const eventName = 'Edited_Jobs_New_Logbook';
                        // const eventProperties = {
                        //     'edited_jobs_logbook_name' : data.name,
                        //     'edited_jobs_logbook_id' : data.id,
                        //     'edited_jobs_logbook_log_number' : data.log_number,
                        //     'edited_jobs_logbook_account_id' : data.account && data.account.id ? data.account.id : null,
                        //     'edited_jobs_logbook_account_name' : data.account && data.account.name ? data.account.name : null,
                        //     'edited_jobs_logbook_area_type' : data.area_type && data.area_type.name ? data.area_type.name : null,
                        //     'edited_jobs_logbook_area_type_id' : data.area_type_id,
                        //     'edited_jobs_logbook_created_at' : data.created_at,
                        //     'edited_jobs_logbook_updated_at' : data.updated_at,
                        //     'edited_jobs_logbook_floor_id' : data.floor_id,
                        //     'edited_jobs_logbook_floor' : data.floor && data.floor.name ? data.floor.name : null,
                        //     'edited_jobs_logbook_schedule_at' : data.schedule_at,
                        //     'edited_jobs_logbook_schedule_at' : data.schedule_at,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        history.push(`/accounts/${response.data.logbook.account_id}/account_users?job_logbook=true`)
                        SuccessAlert('Logbook Updated Successfully.');
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        });
    }
}

export const getLogbook = ({ id, area_id }) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_LOGBOOK,
        payload: API.getOneLogBookArea(id, area_id)
            .then((response) => {
                
                return {
                    accountArea: {
                        name: response.data.logbook.name,
                        log_number: response.data.logbook.log_number,
                        days: response.data.logbook.days,
                        schedule_at: response.data.logbook.schedule_at,
                        account_id: response.data.logbook.account_id,
                        floor_id: response.data.logbook.floor_id,
                        area_type_id: response.data.logbook.area_type_id,
                        timeslots_attributes: response.data.logbook.timeslots_attributes,
                        remove_timeslot_ids: response.data.logbook.remove_timeslot_ids,
                        active_status: response.data.logbook.active_status,
                        logbook_time_slot: response.data.logbook.logbook_time_slot,
                        id: response.data.logbook.id,
                        critical :response.data.logbook.critical,
                        labelsId:response.data.logbook.playbook.length ? {label:response.data.logbook.playbook[0].title,value:response.data.logbook.playbook[0]?.id} : "",
                        account : response.data.logbook.account
                    }
                };
            })
            .catch((error) => {
                console.log(error);
            })
    })

export const clearLogbookArea = () => dispatch =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.CLEAR_LOGBOOK_AREA,
    })

export const getLogbookPresignedUrl = (logId,logbookId, extentions, arrayBuffer, createLogbookLog, form) => {
    return (dispatch) => {
        // const TicketList = cloneDeep(getState().adminOrSuperAdminReducer.ticketsList);
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_LOGBOOK_PRESIGNED_URL,
            payload: API.getLogbookPresignedUrl(logId, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    // let Logbook = {}
                    // Logbook['logbook'] = {}
                    // Logbook.logbook['attachment_paths'] = [];
                    // Logbook.logbook['id'] = logId;
                    response.data.presigned_urls.forEach((p, i) => {debugger
                        if(window.location.search === "?quality_rejected=true"){
                            form.logbook_log.attachments.push(p.attachment_path)
                        }else{
                            if(i === 0){
                                form.logbook_log.initial = p.attachment_path;
                            }else {
                                form.logbook_log.attachments.push(p.attachment_path)
                            }
                        }
                        dispatch({
                            type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                         dispatch(createLogbookLog(logId,logbookId, form));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });

                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const createLogbookLog = (logId,logbookId, logBody) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_NEW_TICKET,
            payload: API.createLogbookLog(logId,logbookId, logBody)
                .then((response) => {
                    if (response.data.success) {
                        history.push(routes.LOGBOOK_THANKYOU);
                        debugger
                        if(response.data.logbookLog?.status === "pending"){
                            SuccessAlert('Logbook data has been Rejected successfully');
                        }else{
                            SuccessAlert('Logbook data has been verified successfully');
                        }
                        
                        // smartlook analytics
                        // let data = cloneDeep(response.data.logbookLog);
                        // const eventName = 'Reviewed_Logbook_Logs';
                        // const eventProperties = {
                        //     'logbookLog_id' : data.id,
                        //     'logbookLog_comment' :  data.comment && data.comment.length ? data.comment : 'No comments',
                        //     'logbookLog_reviewed_by' : data.name,
                        //     'logbookLog_status' : data.status,
                        //     'logbookLog_created_at' : data.created_at,
                        //     'logbookLog_logbook_id' : data.logbook_id,
                        //     'logbookLog_logbook_account_id' : data.logbook && data.logbook.account_id ? data.logbook.account_id : null,
                        //     'logbookLog_logbook_name' : data.logbook && data.logbook.name ? data.logbook.name : null,
                        //     'logbookLog_logbook_created_at' : data.logbook && data.logbook.created_at ? data.logbook.created_at : null,
                        //     'logbookLog_logbook_floor_id' : data.logbook && data.logbook.floor_id ? data.logbook.floor_id : null,
                        //     'logbookLog_logbook_log_number' : data.logbook && data.logbook.log_number ? data.logbook.log_number : null,  
                        //     'logbookLog_logbook_schedule_at' : data.logbook && data.logbook.schedule_at ? data.logbook.schedule_at : null,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getAllLoghistoryAreaDetails = (token) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_LOGHISTORY_AREA_DETAILS,
        payload: API.getAllLoghistoryAreaDetails(token),
    });

export const searchLogHistory = (limit, page, id, values) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.SEARCH_LOGHISTORY,
        payload: API.searchLogHistory(limit, page, id, values),
    });

export const updateLogbookSearchValues = (values) => {
    return {
        type: AdminOrSuperAdminActionTypes.UPDATE_SEARCH_LOGBOOK_VALUES,
        payload: values,
    };
}

export const getAllLogbookForFrontline = (values, size, page, sorted) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ALL_LOGBOOK,
        payload: API.getAllLogbookForFrontline(values, size, page, sorted)
            .then((response) => {
                if (!response.data.error || response.data.success) {
                    return {
                        total_no_of_pages: response.data.pages,
                        logbookLogs: response.data.logbookLogs,
                        totalLogbookLogs: response.data.count,
                        total_reviewed: response.data.total_reviewed,
                        total_pending: response.data.total_pending,
                        searchValues: values,
                        rows: response.data.no_of_rows,
                        job_rows: size,
                    };
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const importLogbookArea = ({ body, id }) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.IMPORT_LOGBOOK_AREA,
        payload: API.importLogbookArea(body, id).then((response) => {
            if (response.data.success) {
                dispatch(getAllLogbookArea([], '', '', [], id));
            }
        })
    });

export const importCheckLogbookArea = ({ body, id }) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.IMPORT_CHECK_LOGBOOK_AREA,
            payload: API.importCheckLogbookArea(body, id).then((response) => {
                if (response.data.success) {
                    dispatch(importLogbookArea({ body, id }));
                    setTimeout(() => {
                        document.getElementById('closeLogbookArea').click()
                        SuccessAlert('Successfully Imported');
                    }, 500);
                    return { data: response };
                } else {
                    setTimeout(() => {
                        if (response.data.message) {
                            let res = response.data.message.message ? response.data.message.message : response.data.message;
                            if (!(res.includes("supported"))) {
                                document.getElementById('logbookLogErrorImport').click();
                            }
                        }
                        document.getElementById('closeLogbookArea').click();
                    }, 500);
                    return { error: convertLogbookLogDataToCsvType(response.data.message), errorAvailable: true };
                }
            })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const createNewScheduleTicket = (scheduleTicket, isDeepClean) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.CREATE_NEW_SCHEDULE_TICKET,
            payload: API.createNewScheduleTicket(scheduleTicket, isDeepClean)
                .then((response) => {
                    const Account = cloneDeep(getState().adminOrSuperAdminReducer.currentAccount);
                    if (response.data.success) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data.scheduleTicket);
                        // const eventName = 'Created_New_Ticket_Schedule';
                        // const eventProperties = {
                        //     'ticket_schedule_id' : data.id,
                        //     'ticket_schedule_name' : data.title,
                        //     'ticket_schedule_job_name' : data.account && data.account.name ? data.account.name : null,
                        //     'ticket_schedule_category' : data.category,
                        //     'ticket_schedule_priority' : data.priority,
                        //     'ticket_schedule_total_events' : data.ticket_events && data.ticket_events.length ? data.ticket_events.length : 0,
                        //     'ticket_schedule_account_id' : data.account && data.account.id ? data.account.id : null,
                        //     'ticket_schedule_due_on' : data.due_on,
                        //     'ticket_schedule_created_at' : data.created_at,
                        //     'ticket_schedule_created_by' : data.created_by,
                        //     'ticket_schedule_assigned_to' : data.assigned_to,
                        //     'ticket_schedule_start_date' : data.start_date,
                        //     'ticket_schedule_end_date' : data.end_date,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        SuccessAlert('Schedule Ticket successfully added.');
                        if (isDeepClean) {
                            history.push(routes.PROJECT_MODULE);
                        } else {
                            if (Account && !isNaN(window.location.href.split('/')[window.location.href.split('/').indexOf('accounts') + 1])) {
                                history.push({pathname : `/accounts/${Account.id}/account_users/schedule-time`, state : {timezone : Account.timezone}});
                            } else {
                                history.push(`/schedules`);
                            }
                        }
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getScheduleTicketEvents = (startDate, endDate, id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_SCHEDULE_TICKET_EVENTS,
        payload: API.getScheduleTicketEvents(startDate, endDate, id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const getOneScheduleTicketEventInfo = (id) => dispatch => {
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_ONE_SCHEDULE_TICKET_EVENT_INFO,
        payload: API.getOneScheduleTicketEventInfo(id)
            .then(response => {

                return response.data;
            })
            .catch(error => {
                console.log(error);
            })
    })
};

export const handleOpenScheduleTicketDeleteModal = (deletingScheduleTicketId) => {
    return {
        type: AdminOrSuperAdminActionTypes.HANDLE_OPEN_SCHEDULE_TICKET_DELETE_MODAL,
        payload: deletingScheduleTicketId,
    };
};

export const deleteCurrentScheduleTicketEvent = (id, account_id, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_CURRENT_SCHEDULE_TICKET_EVENT,
            payload: API.deleteCurrentScheduleTicketEvent(id)
                .then((response) => {
                    const Account = cloneDeep(getState().adminOrSuperAdminReducer.currentAccount);
                    let getScheduleTicketEventsList = cloneDeep(getState().adminOrSuperAdminReducer.getScheduleTicketEventsList);
                    if (response.data.success) {
                        if (account_id) {
                            dispatch(getScheduleTicketEvents(startDate, endDate, account_id));
                        } else {
                            dispatch(getScheduleTicketEvents(startDate, endDate));
                        }
                        SuccessAlert('Event is Successfully Deleted');
                        return { getScheduleTicketEventsList: getScheduleTicketEventsList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const deleteEntireScheduleTicketEvents = (id, account_id, startDate, endDate) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS,
            payload: API.deleteEntireScheduleTicketEvents(id)
                .then((response) => {
                    const Account = cloneDeep(getState().adminOrSuperAdminReducer.currentAccount);
                    let getScheduleTicketEventsList = cloneDeep(getState().adminOrSuperAdminReducer.getScheduleTicketEventsList);
                    if (response.data.success) {
                        if (account_id) {
                            dispatch(getScheduleTicketEvents(startDate, endDate, account_id));
                        } else {
                            dispatch(getScheduleTicketEvents(startDate, endDate));
                        }
                        SuccessAlert('Schedule is Successfully Deleted');
                        return { getScheduleTicketEventsList: getScheduleTicketEventsList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getPreviewSingleTicket = (id) => (dispatch) =>
    dispatch({
        type: AdminOrSuperAdminActionTypes.GET_PREVIEW_SINGLE_TICKET,
        payload: API.getPreviewSingleTicket(id)
            .then((response) => {
                // smartlook analytics
                // let data = cloneDeep(response.data);
                // const eventName = 'Preview_Schedule_Single_Ticket_Info';
                // const eventProperties = {
                //     'preview_schedule_ticket_created_by' : `${data && data.requestor_user && data.requestor_user.first_name ? data.requestor_user.first_name : null} ${data && data.requestor_user && data.requestor_user.last_name ? data.requestor_user.last_name : null}`,
                //     'preview_schedule_ticket_id' : data.id,
                //     'preview_schedule_ticket_status' : data.status,
                //     'preview_schedule_ticket_created_at' : data.created_at,
                //     'preview_schedule_ticket_updated_at' : data.updated_at,
                //     'preview_schedule_ticket_job_id' : data && data.account && data.account.id ? data.account.id : null,
                //     'preview_schedule_ticket_job_name' : data && data.account && data.account.name ? data.account.name : null,
                //     'preview_schedule_ticket_assigned_to' : `${data && data.user && data.user.first_name ? data.user.first_name : null} ${data && data.user && data.user.last_name ? data.user.last_name : null}`,
                //     'preview_schedule_ticket_category' : data && data.category && data.category.name ? data.category.name : null,
                //     'preview_schedule_ticket_due_date' : data.due_on,
                // };
                // window.smartlook('track', eventName, eventProperties);
                storage.set('ticketId', response.data.id);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            }),
    });

export const handleDeleteScheduleTicketEvent = (deletingScheduleTicketId) => {
    return {
        type: AdminOrSuperAdminActionTypes.HANDLE_DELETE_SCHEDULE_TICKET_EVENT,
        payload: deletingScheduleTicketId,
    };
};

export const handleDeleteEntireScheduleTicketEvents = (deletingScheduleTicketId) => {
    return {
        type: AdminOrSuperAdminActionTypes.HANDLE_DELETE_ENTIRE_SCHEDULE_TICKET_EVENTS,
        payload: deletingScheduleTicketId,
    };
};

export const getScheduleTicketPresignedUrl = (scheduleTicket, extentions, arrayBuffer, idsToDelete, isDeepClean) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_SCHEDULE_TICKET_PRESIGNED_URL,
            payload: API.getScheduleTicketPresignedUrl(scheduleTicket, extentions)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    scheduleTicket.scheduleTicket.attachments = [];
                    response.data.presigned_urls.forEach((p, i) => {
                        scheduleTicket.scheduleTicket.attachments[i] = {}
                        scheduleTicket.scheduleTicket.attachments[i]['photo_path'] = p.attachment_path
                    });
                    response.data.presigned_urls.map((p, i) => {
                        dispatch({
                            type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(createNewScheduleTicket(scheduleTicket, isDeepClean));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const scheduleTicketHistory = (params, accountId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_HISTORY,
            payload: API.scheduleTicketHistory(params, accountId)
                .then((response) => {
                    if (response.data.success) {
                        if (accountId) {
                            dispatch(getAccountUsersInfoPage(accountId));
                        }
                        return {
                            scheduleTicketLogList: response.data.logs,
                            totalPages: response.data.pages,
                            totalListCount: response.data.total_count,
                            scheduleTicketHistoryRows: params.fl_users_no_of_rows,
                            scheduleTicketHistoryCurPage: params.page - 1,
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const scheduleTicketHistoryPageClicked = (data) => {
    return {
        type: AdminOrSuperAdminActionTypes.SCHEDULE_TICKET_HISTORY_PAGE_CLICKED,
        payload: data
    }
}

export const deletePreviewScheduledTicket = (id, scheduleId) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_PREVIEW_SCHEDULED_TICKET,
            payload: API.deletePreviewScheduledTicket(id)
                .then((response) => {
                    if (!response.data.error) {
                        const ticket_no_of_rows = getState().adminOrSuperAdminReducer.preview_schedule_ticket_no_of_rows;
                        const SearchValue = getState().form.previewScheduleSearchTicket.values;
                        dispatch(updateData());
                        const values = { value: cloneDeep(SearchValue) };
                        if (values.value.fromDate && values.value.toDate) {
                            values.value.fromDate = convertDateToDifferentTZ(values.value.fromDate, 'from');
                            values.value.toDate = convertDateToDifferentTZ(values.value.toDate, 'to');
                        }
                        dispatch(getPreviewScheduledTickets(values, ticket_no_of_rows, 1, [], [], scheduleId))
                        SuccessAlert('Ticket Successfully Deleted');
                        let TicketsList = cloneDeep(getState().adminOrSuperAdminReducer.previewScheduleTicketList);
                        //let SearchedTicketList = cloneDeep(getState().adminOrSuperAdminReducer.searchTicketsList);

                        // if (SearchedTicketList) {
                        //     let searchIndex = SearchedTicketList.findIndex(function (t) {
                        //         return t.id === id;
                        //     });
                        //     SearchedTicketList.splice(searchIndex, 1);
                        // }

                        let searchIndex = TicketsList.findIndex(function (t) {
                            return t.id === id;
                        });
                        TicketsList.splice(searchIndex, 1);
                        return { TicketsList };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const logbookPreviewPageClicked = (logs) => {
    return {
        type: AdminOrSuperAdminActionTypes.LOGBOOK_PREVIEW_PAGE_CLICKED,
        payload: logs,
    };
};
export const customerClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.CUSTOMER_CLICKED,
    };
};

export const getAPIAccess = (values, company_no_of_rows, page, sorted, filtered) => dispatch => dispatch({
    type: AdminOrSuperAdminActionTypes.GET_API_ACCESS,
    payload: API.getAPIAccess(values, company_no_of_rows, page, sorted, filtered)
        .then(response => {
            if (response.data.success) {
                // const companies = response.data.companies.reverse();
                // const companies_rows = response.data.no_of_rows
                return {
                    company_access: response.data.company_access,
                    company_access_page: response.data.pages,
                    company_access_rows: response.data.no_of_rows,
                    company_access_cur_page: page ? page - 1 : 0,
                    companyAccessSearchValues: values,
                    total_company_access: response.data.total_count
                }
            }
        })
        .catch((error) => {
            console.log(error);
        }),
});

export const addNewAPIAccess = (body) => {
    return (dispatch, getState) => {
        let values = {};
        const active = getState().adminOrSuperAdminReducer.activeCompanies
        const page = getState().adminOrSuperAdminReducer.company_access_cur_page
        const rows = getState().adminOrSuperAdminReducer.company_access_rows
        values.active = active;
        dispatch({
            type: AdminOrSuperAdminActionTypes.ADD_API_ACCESS,
            payload: API.addNewAPIAccess(body)
                .then((response) => {
                    if (response.data.success) {
                        closeModel();
                        SuccessAlert('API Access Added Sucessfully.');
                    }
                    dispatch(getAPIAccess(values, rows, page, []))
                    dispatch(cancelCompanyAccessModelClicked())

                })
                .catch((error) => {
                    console.error(error);
                }),
        });
    };
};

export const companyAccessPageClicked = (company) => {

    return {
        type: AdminOrSuperAdminActionTypes.COMPANY_ACCESS_PAGE_CLICKED,
        payload: company
    }
}

export const companyAccessActiveChanged = () => {
    return {
        type: AdminOrSuperAdminActionTypes.COMPANY_ACCESS_ACTIVE_CHANGED
    }
}

export const updateAPIAccess = (body, id) => {
    return (dispatch, getState) => {
        let values = {};
        const active = getState().adminOrSuperAdminReducer.activeCompanies
        const page = getState().adminOrSuperAdminReducer.company_access_cur_page
        const rows = getState().adminOrSuperAdminReducer.company_access_rows
        values.active = active;
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_API_ACCESS,
            payload: API.updateAPIAccess(body, id)
                .then((response) => {
                    if (response.data.success) {
                        closeModel();
                        SuccessAlert('API Access Updated Sucessfully.');
                    }
                    dispatch(getAPIAccess(values, rows, page, []))
                    dispatch(cancelCompanyAccessModelClicked())

                })
                .catch((error) => {
                    console.error(error);
                }),
        });
    };
};

export const getOneAPIAccess = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_ONE_COMPANY_ACCESS,
            payload: API.getOneAPIAccess(id).then(response => {
                if (response.data.success) {
                    debugger
                    return {
                        companyAccess: response.data.companyAccess,
                        editCompanyAccessId: id
                    }
                }
            })
                .catch((error) => {
                    console.log(error);
                }),
        });
    }
}

export const cancelCompanyAccessModelClicked = () => {

    return {
        type: AdminOrSuperAdminActionTypes.CANCEL_COMPANY_ACCESS_MODEL,
    }
}


export const updateCompanyAccessClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.UPDATE_COMPANY_ACCESS_MODEL,
    }
}

export const deleteAPIAccess = (id) => {
    return (dispatch, getState) => {
        let companyAccessList = getState().adminOrSuperAdminReducer.companyAccessList
        dispatch({
            type: AdminOrSuperAdminActionTypes.DELETE_API_ACCESS,
            payload: API.deleteAPIAccess(id)
                .then((response) => {
                    if (response.data.success) {
                        SuccessAlert('API Access Deleted Sucessfully.');
                        let searchIndex = companyAccessList.findIndex(function (t) {
                            return t.id === id;
                        });
                        companyAccessList.splice(searchIndex, 1);
                        return { companyAccessList }
                    }


                })
                .catch((error) => {
                    console.error(error);
                }),
        });
    };
};

export const getChiSurveyQuarterlyCheckChattels = () => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_CHISURVEY_QUARTERLY_CHECK_CHATTELS,
            payload: API.getChiSurveyQuarterlyCheckChattels()
                .then((response) => {
                    if (response.data.success) {
                        if (response.data.chi_survey == false) {
                            storage.remove('chisurveyStarted');
                            history.push('/');
                        } else if (response.data.chi_survey == true) {
                            history.push(routes.CHI_QUARTERLY_SURVEY_CHECK_SCREEN);
                        }
                        return response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const frontlineClicked = () => {
    return {
        type: AdminOrSuperAdminActionTypes.FRONTLINE_CLICKED,
    };
};

export const uploadFrontendUserImageToS3 = ({ url, arrayBuffer, body, id }) => {
    return (dispatch, getState) => {
        return dispatch({
            type: AdminOrSuperAdminActionTypes.UPLOAD_USER_IMAGE_TO_S3,
            payload: API.uploadImageToS3(url, arrayBuffer)
                .then((response) => {
                    if (id) {
                        return dispatch({
                            type: AdminOrSuperAdminActionTypes.UPDATE_FRONTLINE,
                            payload: API.updateFrontline(body, id),
                        });
                    } else {
                        return dispatch({
                            type: AdminOrSuperAdminActionTypes.CREATE_FRONTLINE,
                            payload: API.createFrontline(body),
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const getCreateFLUserProfileImagePresignUrl = (flUserInfo, arrayBuffer, extention) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_CREATE_FLUSER_PROFILE_IMAGE_PRESIGNED_URL,
            payload: API.getFLUserProfileImagePresignUrl(extention)
                .then((response) => {
                    debugger
                    let presigned_url = response.data.presigned_url;
                    flUserInfo.user.photo_path = response.data.photo_path;
                    dispatch({
                        type: AdminOrSuperAdminActionTypes.UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3,
                        payload: API.uploadImageToS3(presigned_url, arrayBuffer)
                            .then((response) => {
                                dispatch(addFrontline(flUserInfo));
                            })
                            .catch((error) => {
                                console.log(error);
                            }),
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const addFrontline = (body) => (dispatch) => {
    let newUser = { ...body }
    if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2).trim() === "1") {
        body.user.phone_number = "+" + newUser.user.phone_number
    } else if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2) !== "+1") {
        body.user.phone_number = "+1 " + newUser.user.phone_number
    }
    return dispatch({
        type: AdminOrSuperAdminActionTypes.CREATE_FRONTLINE,
        payload: API.createFrontline(body)
            .then((response) => {
                if (response.data.success) {
                    // smartlook analytics
                    // let data = cloneDeep(response.data.user);
                    // const eventName = 'Created_New_Frontline_User';
                    // const eventProperties = {
                    //     'fronlineUser_id' : data.id,
                    //     'frontlineUser_employeeId' : data.employee_id,
                    //     'frontlineUser_name' : `${data.first_name} ${data.last_name}`,
                    //     'frontlineUser_job_name' : data.default_account && data.default_account.name ? data.default_account.name : null,
                    //     'frontlineUser_phone_no' : data.phone_number,
                    //     'frontlineUser_email' : data.email,
                    //     'frontlineUser_password' : data.dob_password,
                    //     'frontlineUser_scan_type' : data.scan_type,
                    //     'frontlineUser_created_at' : data.created_at,
                    //     'frontlineUser_mode' : data.mode,
                    // };
                    // window.smartlook('track', eventName, eventProperties);
                    history.push(routes.FRONTLINE_LIST);
                    SuccessAlert('Frontline User Successfully Created');
                    return response;
                }
            })
            .catch((error) => {
                console.log(error);
            }),
    });
};

export const getEditFLUserProfileImagePresignUrl = (flUserInfo, arrayBuffer, extention, id) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_EDIT_FLUSER_PROFILE_IMAGE_PRESIGNED_URL,
            payload: API.getFLUserProfileImagePresignUrl(extention)
                .then((response) => {
                    debugger
                    let presigned_url = response.data.presigned_url;
                    flUserInfo.user.photo_path = response.data.photo_path;
                    dispatch({
                        type: AdminOrSuperAdminActionTypes.UPLOAD_FLUSER_PROFILE_IMAGE_TO_S3,
                        payload: API.uploadImageToS3(presigned_url, arrayBuffer)
                            .then((response) => {
                                dispatch(editFrontline(flUserInfo, id));
                            })
                            .catch((error) => {
                                console.log(error);
                            }),
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};

export const editFrontline = (body, id) => {
    let newUser = { ...body }
    if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2).trim() === "1") {
        body.user.phone_number = "+" + newUser.user.phone_number
    } else if (newUser.user.phone_number && newUser.user.phone_number.slice(0, 2) !== "+1") {
        body.user.phone_number = "+1 " + newUser.user.phone_number
    }

    return (dispatch, getState) => {
        let frontLine = getState().adminOrSuperAdminReducer.frontlineUser;
        dispatch({
            type: AdminOrSuperAdminActionTypes.UPDATE_FRONTLINE,
            payload: API.updateFrontline(body, id)
                .then((response) => {
                    debugger
                    if (response.data.success) {
                        // smartlook analytics
                        // let data = cloneDeep(response.data.user);
                        // const eventName = 'Edited_Frontline_User';
                        // const eventProperties = {
                        //     'edited_fronlineUser_id' : data.id,
                        //     'edited_frontlineUser_employeeId' : data.employee_id,
                        //     'edited_frontlineUser_name' : `${data.first_name} ${data.last_name}`,
                        //     'edited_frontlineUser_job_name' : data.default_account && data.default_account.name ? data.default_account.name : null,
                        //     'edited_frontlineUser_phone_no' : data.phone_number,
                        //     'edited_frontlineUser_email' : data.email,
                        //     'edited_frontlineUser_password' : data.dob_password,
                        //     'edited_frontlineUser_scan_type' : data.scan_type,
                        //     'edited_frontlineUser_created_at' : data.created_at,
                        //     'edited_frontlineUser_updated_at' : data.updated_at,
                        //     'edited_frontlineUser_mode' : data.mode,
                        // };
                        // window.smartlook('track', eventName, eventProperties);
                        history.push(routes.FRONTLINE_LIST);
                        SuccessAlert('Frontline User Successfully Updated');
                        return { frontlineUser: response };
                    } else {
                        dispatch(getFrontline(id));
                        return { frontlineUser: frontLine };
                    }
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };

};

export const handleCloseFrontlineForm = () => {
    history.push(routes.FRONTLINE_LIST);
    return {
        type: AdminOrSuperAdminActionTypes.HANDLE_CLOSE_FRONTLINE_FORM,
    };
};

export const importTicketCSV = ({ body }) => {
    return (dispatch) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.IMPORT_TICKET,
            payload: API.importTicket(body).then((response) => {
                if (response.data.success) {
                    document.getElementById('closeTicketImportPopup').click()
                    SuccessAlert('Successfully Imported');
                    dispatch(getTickets({}, 10, 1))
                    return { data: response };
                } else if (response.data.code === 422) {
                    document.getElementById('closeTicketImportPopup').click()
                } else {
                    setTimeout(() => {
                        document.getElementById('ticketLogsErrorImport').click()
                        document.getElementById('closeTicketImportPopup').click()
                        errorHandler('Unable to import given CSV, Please check downloaded error logs');
                    }, 200);
                    return { error: convertTicketLogsDataToCsvType(response.data.message) };
                }
            })
                .catch((error) => {
                    console.error(error);
                }),
        });
    };
}

export const getCustomerOrInspectorQqvQuestions = (params) => {
    return (dispatch, getState) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.CUSTOMER_FEEDBACK_QUESTION,
            payload: API.getCustomerOrInspectorQqvQuestions(params).then(response => {
                if (response.data.success) {

                    return {
                        question: response.data.question,
                    }
                }
            })
                .catch((error) => {
                    console.log(error);
                }),
        });
    }
}

export const inspectorFeedback = (body) => {
    return (dispatch) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.INSPECTOR_FEEDBACK,
            payload: API.inspectorFeedback(body)
        })
    }
}

export const projectModuleTicketPageClicked = (ticket) => {
    return {
        type: AdminOrSuperAdminActionTypes.PROJECT_MODULE_TICKET_PAGE_CLICKED,
        payload: ticket,
    };
};

export const previewprojectModuleTicketPageClicked = (ticket) => {
    return {
        type: AdminOrSuperAdminActionTypes.PREVIEW_PROJECT_MODULE_TICKET_PAGE_CLICKED,
        payload: ticket,
    };
};

export const removeSignedUrl = (ticket) => {
    return {
        type: AdminOrSuperAdminActionTypes.REMOVE_PRESIGNED_URL,
        payload: [],
    };
};

/* FL User Shift Attestation Question retrieve GET Call */
export const getPerFLUserShiftAttestation = (params) => {
    return (dispatch) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_PER_FLUSER_SHIFT_ATTESTATION,
            payload: API.getPerFLUserShiftAttestation(params)
                .then((response) => {
                    if (response.data.success) {
                        return {
                            data : response.data,
                            language : params.language === 'es' ? true : false
                        };
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const handleWeeklyLimitSet_closeModal = () => {
    debugger;
    return {
        type: AdminOrSuperAdminActionTypes.HANDLE_WEEKLY_LIMIT_SET_CLOSE_MODAL,
    };
};

export const handleWeeklyLimitSet_openModal = () => {
    
    return {
        type: AdminOrSuperAdminActionTypes.HANDLE_WEEKLY_LIMIT_SET_OPEN_MODAL,
    };
};

export const getLogbookLogQuestions = (params) => {
    return (dispatch) => {
        dispatch({
            type: AdminOrSuperAdminActionTypes.GET_LOGBOOK_LOGS_QUESTIONS,
            payload: API.getLogbookLogQuestions(params)
                .then((response) => {
                    if (response.data.success) {
                        return response;
                    };
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const getSingleLogbook = ({ id, area_id })=>
        API.getOneLogBookArea(id, area_id)
            .then((response) => {
                
                return {
                    accountArea: {
                        name: response.data.logbook.name,
                        log_number: response.data.logbook.log_number,
                        days: response.data.logbook.days,
                        schedule_at: response.data.logbook.schedule_at,
                        account_id: response.data.logbook.account_id,
                        floor_id: response.data.logbook.floor_id,
                        area_type_id: response.data.logbook.area_type_id,
                        timeslots_attributes: response.data.logbook.timeslots_attributes,
                        remove_timeslot_ids: response.data.logbook.remove_timeslot_ids,
                        active_status: response.data.logbook.active_status,
                        logbook_time_slot: response.data.logbook.logbook_time_slot,
                        id: response.data.logbook.id,
                        critical :response.data.logbook.critical,
                        playbook:response.data.logbook.playbook.length ? response.data.logbook.playbook : []
                    }
                };
            })
            .catch((error) => {
                console.log(error);
            })

            

            export const updateActiveUserName = (name) => {
                return {
                    type: AdminOrSuperAdminActionTypes.UPDATE_FRONTLINE_ACTIVE_USER_NAME,
                    payload: name
                }
            }

            export const resetFrontlineUser = (name) => {
                return {
                    type: AdminOrSuperAdminActionTypes.RESET_FRONTLINE,
                    payload: name
                }
            }

            export const getPCTPresignedUrl = (ticket, extentions, arrayBuffer, resetForm) => {
                return (dispatch, getState) => {
                    return dispatch({
                        type: AdminOrSuperAdminActionTypes.GET_TICKET_PRESIGNED_URL,
                        payload: API.getPCTPresignedUrl(ticket, extentions)
                            .then((response) => {
                                let totalAttachments = [];
                                const presigned_urls = response.data.presigned_urls;
                                ticket.data['attachment_paths'] = [];

                                response.data.presigned_urls.forEach((p, i) => {
                                        ticket.data.attachment_paths.push(p.attachment_path)
            
                                          dispatch({
                                        type: AdminOrSuperAdminActionTypes.UPLOAD_IMAGE_TO_S3,
                                        payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                            .then((response) => {
                                                totalAttachments++;
                                                if (presigned_urls.length === totalAttachments) {
                                                        dispatch(addPCT(ticket, resetForm));
                                                }
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                            }),
                                    });
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                            }),
                    });
                };
            };


            export const addPCT = (ticket, resetForm) => async (dispatch) => {
              return await dispatch({
                    type: AdminOrSuperAdminActionTypes.ADD_NEW_PCT,
                    payload :API.addPCT(ticket).then((response) => {
                        if (response.data.success) {debugger
                            let ele = document.getElementById('resetPCTImage')
                            if(ele){
                                ele.click()
                            }
                            dispatch(reset(resetForm))
                            dispatch(resetFloor())
                            SuccessAlert('Public Ticket Created Successfully')
                            return response.data
                        }
                    }).catch((error) => {
                        console.error(error)
                    }) 
                });
            };

            export const getinspectorJobOwner = (id) => {
                return (dispatch) => {
                    dispatch({
                        type: AdminOrSuperAdminActionTypes.GET_INSPECTOR_JOB_OWNER,
                        payload: API.getinspectorJobOwner(id)
                            .then((response) => {
                                if (response.data.success) {
                                    storage.set('isJobOwner', Boolean(response.data.data.length))
                                    return {
                                        data : response.data.data
                                    };
                                };
                            })
                            .catch((error) => {
                                console.log(error);
                            }),
                    });
                };
            };
