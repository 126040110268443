import React, { Component } from 'react';
import NewUser from '../../../component/user/NewUser/newUser';
import UpdateUserPasswordModal from '../../../component/user/NewUser/updateUserPasswordModal';
import * as actions from '../../../../redux/actions/index';
import {  address_attributes } from '../../../../utility/constants/constants';
import { connect } from 'react-redux';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { decode, encode } from 'base64-arraybuffer';
import { resetOrientation } from '../../../../utility/utility';
import getDetailAddress from '../../../../utility/getDetailAddress';
import imageCompression from 'browser-image-compression';
import UserDuplicateModal from '../../../component/user/NewUser/userDuplicateModal';
import { animateScroll as scroll } from 'react-scroll'
import ErrorAlert from '../../../../utility/errorHandler/errorAlert';
var EXIF = require('exif-js');

const cloneDeep = require('clone-deep');

class NewUserContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileImageName: '',
            base64: null,
            extension: null,
            address_attributes: isEmpty(this.props.user) ? address_attributes : this.props.user.user.address,
            isAddressEmpty: false,
            isZipEmpty: false,
            currentMode: false,
            clientMode: '',
            updatedMode: '',
            selectedIds: [],
            selectNone: false,
            selectAll: false,
            selectedJobIds: [],
            selectedCriteriaIds: [],
            criteria_delete_ids: [],
        }
        this.inputOpenFileRef = React.createRef();
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddressSelect = this.handleAddressSelect.bind(this);
    }

    onCancelClicked = () => {
        if (this.props.match.params && this.props.match.params.accountId) {
            this.props.history.push(`/accounts/${this.props.match.params.accountId}/account_users?job_users=true`)

        } else {
            this.props.history.goBack();
        }
    }

    async onFileChange(e) {

        let correctBase64 = null;
        let reader = new FileReader();
        let file = e.target.files[0];

        // var orientation = EXIF.getTag(this, "Orientation");
        const orientation = await imageCompression.getExifOrientation(file);
        reader.onloadend = () => {

            resetOrientation(reader.result, orientation, this);

            this.setState({
                extension: file.name.split('.').pop().toLowerCase(),
                profileImageName: file.name,
                // base64: correctBase64,
            })
        }

        if (file) {
            reader.readAsDataURL(file)
        }
        // reader.readAsArrayBuffer(file)
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    handleAddressChange = address => {
        let addressAttributes = { ...this.state.address_attributes };
        addressAttributes.formatted_address = address;

        this.setState({ address_attributes: addressAttributes, isAddressEmpty: false });
    };

    async handleAddressSelect(address) {
        const addressFields = await getDetailAddress(address);
        this.setState({
            address_attributes: addressFields,
            isAddressEmpty: false
        })
    };

    handleInputChange = (e) => {
        console.log(this.state);
        if (e.target.name === 'zip') {
            let addressFields = cloneDeep(this.state.address_attributes);
            addressFields.zip = e.target.value;
            this.setState({
                address_attributes: addressFields,
                isZipEmpty: false
            })
        } else {
            this.setState({
                about_me: e.target.value,
            })
        }
    }

    jobSelected = (id) => {
        let selectedIds = cloneDeep(this.state.selectedJobIds);

        let searchIndex = this.state.selectedJobIds.findIndex(function (s) {
            return s === id
        });
        if (searchIndex === -1) {
            if (this.state.selectedJobIds.length === this.props.accountsList.length - 1) {
                this.setState({
                    selectAll: true,
                })
            } else if (this.state.selectedJobIds.length === 0) {
                this.setState({
                    selectNone: false,
                })
            }
            this.setState({
                selectedJobIds: [...selectedIds, id]
            })
        } else {
            if (this.state.selectedJobIds.length === this.props.accountsList.length) {
                this.setState({
                    selectAll: false,
                })
            } else if (this.state.selectedJobIds.length === 1) {
                this.setState({
                    selectNone: true
                })
            }
            selectedIds.splice(searchIndex, 1);
            this.setState({
                selectedJobIds: selectedIds
            })

        }
    }

    setJobCheckValue = (id) => {
        let searchIndex = this.state.selectedJobIds.findIndex(function (s) {
            return s === id
        });
        if (searchIndex > -1) {
            return true
        } else {
            return false
        }
    }
    setOwnerColor = (id) => {
        let searchIndex;
        this.props.user && this.props.user.user && this.props.user.user.accounts && this.props.user.user.accounts.map((s, i) => {
            if (s.id === id) {
                if (this.props.user.user.accounts[i].owner_user_id === this.props.match.params.userId) {
                    searchIndex = 1
                }
            }
        });
        if (searchIndex > -1) {
            return true
        } else {
            return false
        }
    }

    criteriaSelected = (id) => {
        let selectedIds = cloneDeep(this.state.selectedCriteriaIds);

        let searchIndex = this.state.selectedCriteriaIds.findIndex(function (s) {
            return s === id
        });
        if (searchIndex === -1) {
            this.setState({
                selectedCriteriaIds: [...selectedIds, id]
            })
        } else {
            selectedIds.splice(searchIndex, 1);
            this.setState({
                selectedCriteriaIds: selectedIds
            })

        }
    }

    setCriteriaCheckValue = (id) => {
        let searchIndex = this.state.selectedCriteriaIds.findIndex(function (s) {
            return s === id
        });
        if (searchIndex > -1) {
            return true
        } else {
            return false
        }
    }

    selectAllJobsClicked = (e) => {
        if (e.target.checked) {
            let selectedIds = []
            this.props.accountsList.map(h => {
                return selectedIds.push(h.id)
            })
            this.setState({
                selectedJobIds: selectedIds,
                selectAll: true,
                selectNone: false,
            })
        }
        else {
            this.setState({
                selectedJobIds: [],
                selectAll: false
            })
        }
    }

    selectNoneJobsClicked = (e) => {
        if (e.target.checked) {
            ErrorAlert('User cannot be removed, if they are Job Owner')
            this.setState({
                selectedJobIds: [],
                selectNone: true,
                selectAll: false
            })
        } else {
            this.setState({
                selectNone: false
            })
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user !== prevProps.user) {

            this.setState({
                // address_attributes: this.props.user.user.address,
                profileImageName: '',
                base64: null,
                extension: null,
            })
        }
        if (this.props.accountsList !== prevProps.accountsList) {
            this.setState({
                selectedJobIds: this.props.user && this.props.user.user && this.props.user.user.accounts ? this.props.user.user.accounts.map(acc => acc.id ? acc.id : acc.user.id) : []
            })
        }
        if (this.props.criteries !== prevProps.criteries) {
            this.setState({
                selectedCriteriaIds: this.props.user && this.props.user.user && this.props.user.user.criteria ? this.props.user.user.criteria.map(cri => cri.id ? cri.id : null) : []
            })
        }
        if (prevProps.accountsList !== this.props.accountsList) {
            if (this.props.accountsList) {
                if (this.props.user && this.props.user.user && this.props.user.user.accounts
                    && this.props.user.user.accounts.length === this.props.accountsList.length) {
                    this.setState({
                        selectAll: true
                    })
                } else if (this.props.user && this.props.user.user && !this.props.user.user.accounts) {
                    this.setState({
                        selectNone: true
                    })
                }
            }
        }
    }

    addNewUser = (values) => {debugger
        let user = cloneDeep(values);
        const inventory = []
        if (user.user.inventory_access) {
            let inventory_access = user.user.inventory_access

            if (inventory_access.manager) {
                inventory.push('manager')
            }
            if (inventory_access.fulfillmentManager) {
                inventory.push('fulfillmentManager')
            }
            if (inventory_access.supervisor) {
                inventory.push('supervisor')
            }
            if (inventory_access.approver) {
                inventory.push('approver')
            }

            user.user.inventory_access = inventory
        }
        if (user.user.role === 'client') {
            user.user['mode'] = 'external'
        }
        if (user.user.email) {
            user.user.email = user.user.email.trim()
        }
        // if(values.user.account_wide) {
        //     user.user['account_wide'] = values.user.account_wide;
        // } else {
        //     user.user['account_wide'] = false;
        // }
        let base_photo = null;
        let arrayBuffer = null;
        if (this.state.base64 !== null && this.state.base64 !== '') {
            const image = this.state.base64.split(',');
            base_photo = image[1];
            arrayBuffer = decode(base_photo);
            user.user['ext'] = `.${this.state.extension}`
        }
        if (user.user.mode === 'internal') {
            user.user.qr_notifications = false;
        }

        // if (this.state.address_attributes.formatted_address !== '' || this.state.address_attributes.zip !== '') {
        //     if (this.state.address_attributes.formatted_address !== '') {
        //         if (this.state.address_attributes.zip !== '') {
        //             user.user['address_attributes'] = this.state.address_attributes;
        user.account_ids = this.state.selectedJobIds ? this.state.selectedJobIds.map(a => a) : [];
        user.criteria_ids = this.state.selectedCriteriaIds ? this.state.selectedCriteriaIds.map(a => a) : [];
        user.company_ids = user.company_ids ? user.company_ids.map(a => a.value) : [];

        /* Whenever Client role is selected the payroll and quality review access will be removed */
        if (user.user.role == 'client') {
            if (user?.user?.payroll_access == true) {
                user.user.payroll_access = false
            } else {
                delete user.user.payroll_access
            }

            if (user?.user?.quality_review == true) {
                user.user.quality_review = false
            } else {
                delete user.user.quality_review
            }
        }

        if (this.props.match.params.userId) {
            var i = 0;
            var entry1;
            if (this.props.user.user.criteria) {
                if (this.state.selectedCriteriaIds) {
                    while (i < this.props.user.user.criteria.length) {
                        entry1 = this.props.user.user.criteria[i];
                        if (this.state.selectedCriteriaIds.some(function (entry2) { return entry1.id === entry2; })) {
                            // Found, progress to next
                            ++i;
                        } else {
                            // Not found, store id and splice
                            this.state.criteria_delete_ids.push(this.props.user.user.criteria[i].id)
                            this.props.user.user.criteria.splice(i, 1);
                        }
                    }
                }
            }
            if (this.state.selectedCriteriaIds) {
                user['delete_criteria_ids'] = this.state.criteria_delete_ids;
            } else {
                user['delete_criteria_ids'] = this.props.user.user.criteries.map((a, i) => {
                    return a.id
                });
            }
            user.user['id'] = this.props.match.params.userId;
            this.props.updateUser(user, arrayBuffer, this.state.extension);
        } else {debugger
            // user.user['role'] = 'invitee'
            if (this.props.match.params.companyId) {
                // user.user.role = "inspector";
                this.props.addNewUser(user, arrayBuffer, this.state.extension, this.props.match.params.accountId, this.props.match.params.companyId);
            } else {
                this.props.addNewUser(user, arrayBuffer, this.state.extension, this.props.match.params.accountId, this.props.match.params.companyId);
            }


        }
        //         } else {
        //             this.setState({
        //                 isZipEmpty: true,
        //                 isAddressEmpty: false
        //             })
        //         }
        //     } else {
        //         this.setState({
        //             isAddressEmpty: true,
        //             isZipEmpty: false
        //         })
        //     }
        // } else {
        //     this.setState({
        //         isAddressEmpty: true,
        //         isZipEmpty: true
        //     })
        // }
    }

    componentDidMount() {
        scroll.scrollToTop();
        // if (!this.props.regionList) {
        this.props.getAllRegions({ hide: false });
        this.props.getCriteriaList({
            hide: false,
        })
        // }
        // if (!this.props.positionsList) {
        this.props.getPositions({ hide: false });
        // }
        if (this.props.match.params.userId) {
            if (isEmpty(this.props.user)) {
                this.props.getEditUser(this.props.match.params.userId).then((res) => {
                    if (res.value.user) {
                        this.props.getModeAccounts(res.value.user.mode);
                        this.setState({
                            selectedCriteriaIds: res.value.user && res.value.user.criteria ? res.value.user.criteria.map(cri => cri.id ? cri.id : null) : []
                        })
                    }
                });
            } else {
                this.props.getModeAccounts(this.props.user.user.mode);
            }
        } else if (this.props.match.params.accountId) {
            if (!isEmpty(this.props.account)) {
                this.props.getModeAccounts(this.props.account.mode);
                this.props.AddJobUsersClicked(this.props.account);
                this.setState({ currentMode: true });
            } else {
                this.props.getAccount(this.props.match.params.accountId).then(res => {
                    this.props.getModeAccounts(res.value.user.mode);
                    this.props.AddJobUsersClicked(res.value);
                    this.setState({ currentMode: true });
                })
            }
        }

        // if (!this.props.accountsList) {
        // this.props.getAccounts();
        // }

        // if (!this.props.companyDropdownList) {
        // this.props.getCompaniesDropDownList();
        // }
    }

    componentWillUnmount() {
        this.props.resetObjects();
    }

    getModeAccounts = (mode, from) => {
        this.setState({ updatedMode: mode });
        if (from === 'role') {
            if (mode === 'client') {
                this.setState({ clientMode: "external" });
            } else {
                this.setState({ clientMode: '' });
            }
        } else {
            this.setState({ userSelectedMode: mode });
            this.props.getModeAccounts(mode);
        }

    }
    createDublicateUserForm = (val) => {
        if (val.first_name) {
            let existingUser = cloneDeep(this.props.user.user);
            let user = {};
            let userNotifications = {}
            
            user['user'] = {};
            user['account_ids'] = existingUser.accounts && existingUser.accounts.map(acc => acc.id)
            user['criteria_ids'] = existingUser.criteria && existingUser.criteria.map(acc => acc.id)
            user['user']['first_name'] = val.first_name;
            user['user']['last_name'] = val.last_name;
            user['user']['email'] = val.email;
            user['user']['phone_number'] = val.phone_number;
            user['user']['active_status'] = existingUser.active_status;
            user['user']['position_id'] = existingUser.position.id;
            user['user']['mode'] = existingUser.mode;
            user['user']['region_id'] = existingUser.region_id;
            user['user']['qr_notifications'] = existingUser.qr_notifications;
            user['user']['timezone'] = existingUser.timezone;
            user['user']['role'] = existingUser.role;
            user['user']['enable_preferences'] = existingUser.enable_preferences;
            user['user']['inspection_notifications'] = existingUser.inspection_notifications;
            user['user']['quote_notifications'] = existingUser.quote_notifications;
            user['user']['ticket_notifications'] = existingUser.ticket_notifications;
            user['user']['chi_survey_notifications'] = existingUser.chi_survey_notifications;
            user['user']['form_access'] = existingUser?.form_access;
            user['user']['inventory_access'] = existingUser?.inventory_access;
            user['user']['quality_review'] = existingUser?.quality_review;
            user['user']['safety_access'] = existingUser?.safety_access;
            user['user']['user_permissions'] = {
                unit_access: existingUser?.user_permissions?.unit_access
            };
            if (existingUser?.payroll_access == true) {
                user['user']['payroll_access'] = existingUser.payroll_access;
            }
            // if(existingUser.account_wide) {
            //     user['user']['account_wide'] = existingUser.account_wide;
            // }
            if (existingUser.summaries.daily_hours !== 0 && existingUser.summaries.daily_hours !== undefined) {
                let daily_hours_data = existingUser.summaries.daily_hours.split(' ');
                existingUser.summaries.daily_hours = parseInt(daily_hours_data[0]);
                existingUser.summaries.daily_hours_unit = daily_hours_data[1];
            }
            userNotifications = { ...existingUser.inspections, ...existingUser.summaries, ...existingUser.tickets };
            let arrayBuffer = null;
            let base_photo = null;
            if (this.state.base64 !== null && this.state.base64 !== '') {
                const image = this.state.base64.split(',');
                base_photo = image[1];
                arrayBuffer = decode(base_photo);
                user.user['ext'] = `.${this.state.extension}`
            }
            this.props.addNewUser(user, arrayBuffer, this.state.extension, this.props.match.params.accountId, this.props.match.params.companyId, userNotifications);
            // this.props.emailPreferencesClicked(userNotifications)
        }
    }

    updateUserPassword = (user) => {
        const id = this.props.match.params.userId;
        this.props.updateUserPassword(id, user);
    }

    render() {

        return (
            <>
                <NewUser {...this.props}
                    {...this.state}
                    s3Url={isEmpty(this.props.user) ? '' : this.props.user.user.photo_urls ? this.props.user.user.photo_urls.original : ''}
                    user={this.props.user}
                    positionsList={this.props.positionsList ? this.props.positionsList : []}
                    onSubmit={this.addNewUser}
                    isInspector={this.props.match.params.companyId ? true : false}
                    isEdit={this.props.match.params.userId ? true : false}
                    currentMode={this.state.currentMode}
                    reference={this.inputOpenFileRef}
                    choosePhotoClicked={this.choosePhotoClicked}
                    base64={this.state.base64}
                    onFileChange={this.onFileChange}
                    onCancelClicked={this.onCancelClicked}
                    isLoading={this.props.isLoading}
                    isAddressEmpty={this.state.isAddressEmpty}
                    isZipEmpty={this.state.isZipEmpty}
                    handleAddressChange={this.handleAddressChange}
                    handleInputChange={this.handleInputChange}
                    handleAddressSelect={this.handleAddressSelect}
                    regionList={this.props.regionList ? this.props.regionList : []}
                    address_attributes={this.state.address_attributes}
                    accountsList={this.props.accountsList}
                    companyDropdownList={this.props.companyDropdownList}
                    accountsLoading={this.props.accountsLoading}
                    isAccountLoading={this.props.isAccountLoading}
                    isCompanyPending={this.props.isCompanyPending}
                    getModeAccounts={this.getModeAccounts}
                    clientMode={this.state.clientMode}
                    updatedMode={this.state.updatedMode}
                    onUpdatePasswordClicked={this.props.onUpdatePasswordClicked}
                    userSelectedMode={this.state.userSelectedMode}
                    jobSelected={this.jobSelected}
                    criteriaSelected={this.criteriaSelected}
                    setJobCheckValue={this.setJobCheckValue}
                    setOwnerColor={this.setOwnerColor}
                    setCriteriaCheckValue={this.setCriteriaCheckValue}
                    selectAllJobsClicked={this.selectAllJobsClicked}
                    selectNoneJobsClicked={this.selectNoneJobsClicked}
                    onUserDuplicateModalClicked={this.props.onUserDuplicateModalClicked}
                />
                {this.props.userDuplicateModal ?
                    <UserDuplicateModal
                        {...this.props}
                        {...this.state}
                        reference={this.inputOpenFileRef}
                        choosePhotoClicked={this.choosePhotoClicked}
                        base64={this.state.base64}
                        onFileChange={this.onFileChange}
                        onSubmit={this.createDublicateUserForm}
                    />
                    : ''}
                {this.props.openPasswordModal ? <UpdateUserPasswordModal {...this.props} closePasswordModalClicked={this.props.closePasswordModalClicked} onSubmit={this.updateUserPassword} /> : ''}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.adminOrSuperAdminReducer.requestor,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        passwordIsLoading: state.authReducer.isLoading,
        regionList: state.adminOrSuperAdminReducer.regionList,
        positionsList: state.adminOrSuperAdminReducer.positionsList,
        accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
        companyDropdownList: state.adminOrSuperAdminReducer.companyDropdownList,
        isCompanyPending: state.adminOrSuperAdminReducer.isCompanyPending,
        accountsLoading: state.adminOrSuperAdminReducer.modeAccountsLoading,
        isAccountLoading: state.adminOrSuperAdminReducer.isAccountLoading,
        account: state.adminOrSuperAdminReducer.account,
        openPasswordModal: state.adminOrSuperAdminReducer.openPasswordModal,
        userDuplicateModal: state.adminOrSuperAdminReducer.userDuplicateModal,
        criteries: state.adminOrSuperAdminReducer.criteries,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewUser: (user, arrayBuffer, extension, accountId, companyId, userNotifications) => dispatch(actions.addNewUser(user, arrayBuffer, extension, accountId, companyId, userNotifications)),
        updateUser: (user, arrayBuffer, extension) => dispatch(actions.updateUser(user, arrayBuffer, extension)),
        resetObjects: () => dispatch(actions.resetObjects()),
        getPositions: (data) => dispatch(actions.getPositions(data)),
        getEditUser: (id) => dispatch(actions.getEditUser(id)),
        getAllRegions: (data) => dispatch(actions.getAllRegions(data)),
        // getAccounts: () => dispatch(actions.getDropDownAccounts()),
        getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
        getCompaniesDropDownList: () => dispatch(actions.getCompaniesDropDownList()),
        AddJobUsersClicked: (account) => dispatch(actions.AddJobUsersClicked(account)),
        getAccount: (id) => dispatch(actions.getEditAccount(id)),
        onUpdatePasswordClicked: () => dispatch(actions.onUpdatePasswordClicked()),
        closePasswordModalClicked: () => dispatch(actions.closePasswordModalClicked()),
        updateUserPassword: (id, user) => dispatch(actions.updateUserPassword(id, user)),
        onUserDuplicateModalClicked: () => dispatch(actions.onUserDuplicateModalClicked()),
        emailPreferencesClicked: (user) => dispatch(actions.emailPreferencesClicked(user)),
        getCriteriaList: (data) => dispatch(actions.getCriteriaList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUserContainer);