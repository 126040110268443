import React, { useState, useEffect } from "react";
import { getFormValues, reduxForm } from "redux-form";
import {
  RolesOptions,
  Timezones,
  UserModes,
  usTimeZones
} from "../../../../utility/constants/constants";
import { Input } from "../../UI/InputElement/InputElement";
import { newUserValidator as validate } from "../../../../utility/validator/validator";
import { makeFirstLetterCapital, resetOrientation } from "../../../../utility/utility";
import isEmpty from "../../../../utility/IsEmptyValidator";
import { connect } from "react-redux";
import GooglePlacesAutoComplete from "../../GooglePlacesAutoComplete/googlePlacesAutoComplete";
import UserSkeleton from "./userSkeleton";
import { showConfirmAlert } from "../../../../utility/successAlert/ConfirmAlert";
import * as Scroll from "react-scroll";
import Img from "react-fix-image-orientation";
import ReactTooltip from "react-tooltip";
import { ClapSpinner } from "react-spinners-kit";

var scroller = Scroll.scroller;

const getAccountsDropDown = (props) => {
  if (props.accountsList && props.accountsList.length > 0) {
    return (
      props.accountsList &&
      props.accountsList.map((account) => {
        return {
          value: account.id,
          label: account.name,
        };
      })
    );
  } else {
    return [];
  }
};




const getCompaniesDropDown = (props) => {
  if (props.companyDropdownList && props.companyDropdownList.length > 0) {
    return (
      props.companyDropdownList &&
      props.companyDropdownList.map((company) => {
        return {
          value: company.id,
          label: company.name,
        };
      })
    );
  } else {
    return [];
  }
};

let NewUser = (props) => {
  const [accounts, setAccounts] = useState(getAccountsDropDown(props));
  const [companies, setCompanies] = useState(getCompaniesDropDown(props));
  const [clientMode, setClientMode] = useState(false);
  // const [jobWide, setJobWide] = useState(true);

  useEffect(() => {
    setAccounts(getAccountsDropDown(props));
  }, [props.accountsList]);

  useEffect(() => {
    if (props.clientMode === '') {
      setClientMode(false);
    } else {
      setClientMode(true);
    }
  }, [props.clientMode]);

  const CustModes = [
    {
      id: 'external',
      name: 'External'
    }
  ]

  const RolesOptionsWithoutClient = [
    {
      id: 'admin',
      name: 'Admin'
    },
    {
      id: 'inspector',
      name: 'Inspector'
    },
    , {
      id: 'requestor',
      name: 'Requestor'
    },
  ]
  useEffect(() => {
    setCompanies(getCompaniesDropDown(props));
  }, [props.companyDropdownList]);

  const [correctImage, setCorrectImage] = useState(null);
  const Orientation = () => {
    if (props.base64) {
      resetOrientation(props.base64, 5, function (resetBase64Image) {
        setCorrectImage(resetBase64Image);
      });
    }
    return correctImage;
  };

  const user = props.user;
  const RegionOptions = props.regionList.map((reg) => {
    return {
      id: reg.region.id,
      name: reg.region.name,
    };
  });

  const positionOptions = props.positionsList.map((pl) => {
    return {
      id: pl.position.id,
      name: pl.position.name,
    };
  });

  const TimezoneOptions = props.timezone ? props.timezone : usTimeZones;

  const noAccountOptionsMessage = (a, b) => {
    return "Job not found";
  };

  const noCompanyOptionsMessage = () => {
    return "Customer not found";
  };

  const onModeChange = (e) => {
    props.getModeAccounts(e, 'mode')
    props.change("account_ids", "");
  }


  const defaultRole = props.user && props.user.user && props.user.user.mode ? props.user.user.mode : props.account && props.account.user && props.account.user.mode ? props.account.user.mode : props.user && props.user.user && props.user.user.accounts[0].mode;
  console.log('default', defaultRole, 'syuyius', props.userSelectedMode);

  const { handleSubmit, pristine, reset, submitting, error } = props;
  if (
    ((!props.regionList || props.regionList.length === 0) &&
      (!props.positionsList || props.positionsList.length === 0)) ||
    props.isCompanyPending || props.isAccountLoading
  ) {
    return <UserSkeleton />;
  } else {
    return (
      <main className="app-content wraper_content user_newQuote add_account_modal add_New_Accont newuser">
        <div className="inspection_content_sec appcontent_Inner">
          <div className="container-fluids">
            <div className="update_user">
              <div className="up_password_btn">
                {props.isEdit ? <button
                  type="button"
                  className="btn cst_btn btn_danger"
                  data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#updateUserPasswordModal"
                  onClick={() => props.onUpdatePasswordClicked()}
                >
                  Update Password
                </button> : ''}
              </div>
              <h4 className="h4_title" data-test="new_user_title">
                {props.isEdit ? "Update User" : "Add a New User"}
              </h4>
            </div>
            <form onSubmit={handleSubmit} className="form_content">
              <div className="quote_form user_quota_form">
                <div className="addQuote_form scroll_acc_com">
                  <div className="cst_tab_content">
                    {/* <div className="profile_img_block">
                                            { <div className="circle_img user-pic">
                                                <Input hidden={true} type="file" name="photo_path" onSelect={props.onFileChange} fileRef={props.reference} accept="image/*" />
                                                <div className="user-profile-pic">
                                                    <img id="preview" src={props.base64 ? props.base64 : "../images/icons/user.jpg"} />
                                                </div>
                                            </div>/}
                                            <div className="circle_img user-pic">
                                                <Input hidden={true} type="file" name="photo_path" onSelect={props.onFileChange} fileRef={props.reference} accept="image/*" />
                                                { <input type="file" id="imgupload" style={{ display: 'none' }} /> }
                                                <div className="user-profile-pic"><img style={{ height: '90px', width: '90px', borderRadius: '90px' }} src={props.base64 ? props.base64 : props.s3Url ? props.s3Url : "/customImages/user.png"} /></div>
                                                <span className="user_title">D</span>
                                                <div className="icn_userEdit"><a href="javascript:void (0)"><i className="fa fa-camera" aria-hidden="true"></i></a></div>
                                            </div>
                                            <div className="clearfix"></div>
                                            { <div className="profile-details">
                                                <a href="javascript:void (0)" onClick={props.choosePhotoClicked} id="OpenImgUpload">Change profile image</a>
                                            </div> }
                                            <div className="profile-details">
                                                <a href="javascript:void (0)" onClick={props.choosePhotoClicked}>Change profile image</a>
                                            </div>
                                        </div> */}

                    {/* html@since:24-07-2019*/}
                    <div className="profile_img_block">
                      {props.isEdit ? <> <span data-tip="Create a new copy" style={{ paddingTop: "25px", float: 'right' }} data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#UserDuplicateModal" onClick={() => props.onUserDuplicateModalClicked()} className="icn_lock"><i className="fas fa-copy" style={{ fontSize: 'large', cursor: 'pointer' }}></i></span>
                        <ReactTooltip place="top" type="dark" effect="solid" />
                      </> : null}
                      <div className="circle_img user-pic">
                        <Input
                          dataTest="user_image"
                          hidden={true}
                          type="file"
                          name="photo_path"
                          onSelect={props.onFileChange}
                          fileRef={props.reference}
                          accept="image/*"
                        />
                        <div className="user-profile-pic">
                          <Img
                            style={{
                              height: "90px",
                              width: "90px",
                              borderRadius: "90px",
                            }}
                            src={
                              props.base64
                                ? props.base64
                                : props.s3Url
                                  ? props.s3Url
                                  : "/customImages/user.png"
                            }
                          />
                          <div
                            className="icn_userEdit"
                            onClick={props.choosePhotoClicked}
                          >
                            <a href="javascript:void (0)">
                              <i
                                className="fa fa-camera"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="profile-details">
                        {/* <a href="javascript:void (0)" onClick={props.choosePhotoClicked}>Change profile image</a> */}
                      </div>
                    </div>
                    {/*End Of html@since:24-07-2019*/}
                    {/* <form className="form_content" onSubmit={handleSubmit}> */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label for="email" className="form_title">
                            First Name
                          </label>
                          <Input
                            dataTest="first_name_data"
                            type="text"
                            className="form-control input-modifier"
                            id="email"
                            placeholder=""
                            name="user[first_name]"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form_title">Last Name</label>
                          <Input
                            dataTest="last_name_data"
                            type="text"
                            className="form-control input-modifier"
                            placeholder=""
                            name="user[last_name]"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label for="email" className="form_title">
                            Email
                          </label>
                          <Input
                            dataTest="email_data"
                            type="text"
                            name="user[email]"
                            readOnly={props.isEdit}
                            className="form-control input-modifier"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label name="user[role]" className="form_title">
                            Role
                          </label>
                          {/* {props.isInspector ?
                                                            <input type="text" readOnly={true} value="inspector" className="form-control input-modifier" id="role" placeholder="" name="user[role]" />
                                                            : */}
                          <Input
                            dataTest="role_select_data"
                            name="user[role]"
                            type="select"
                            options={props.user && props.user.user && props.user.user.mode !== 'external' ? RolesOptionsWithoutClient : RolesOptions}
                            onChange={e => props.getModeAccounts(e.target.value, 'role')}
                            className="custom-select input-modifier add_user_select"
                          />
                          {/* disabled={props.isEdit ? true : false} */}
                          {/* } */}
                          {/* <input type="text" style={{ backgroundColor: '#e9ecef' }} readOnly={true} className="form-control input-modifier" placeholder="" value="invitee" /> */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form_title">Position</label>
                          <Input
                            dataTest="position_select_data"
                            name="user[position_id]"
                            type="select"
                            options={positionOptions}
                            className="custom-select input-modifier add_user_select"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form_title">Region</label>
                          <Input
                            dataTest="region_select_data"
                            name="user[region_id]"
                            type="select"
                            options={RegionOptions}
                            className="custom-select input-modifier add_user_select"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-title">Timezone</label>
                          <Input
                            dataTest="timezone_select_data"
                            name="user[timezone]"
                            type="select"
                            options={TimezoneOptions}
                            className="custom-select input-modifier add_user_select"
                          />
                        </div>
                      </div>
                      {props.isEdit || props.currentMode ? (
                        <div className="col-md-4">
                          <div className="form-group select-disable-icon">
                            <label name="user[mode]" className="form_title">
                              Mode
                            </label>
                            <Input
                              dataTest="mode_select_data"
                              name="user[mode]"
                              type="select"
                              options={clientMode ? CustModes : UserModes}
                              onChange={(e) =>
                                props.getModeAccounts(e.target.value, "mode")
                              }
                              className="custom-select input-modifier add_user_select"
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label name="user[mode]" className="form_title">
                              Mode
                            </label>
                            <Input
                              dataTest="mode_select_data"
                              name="user[mode]"
                              type="select"
                              options={clientMode ? CustModes : UserModes}
                              onChange={(e) =>
                                onModeChange(e.target.value)
                              }
                              className="custom-select input-modifier add_user_select"
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form_title">
                            Contact Number
                          </label>
                          <Input
                            type="tel"
                            className="form-control input-modifier"
                            placeholder=""
                            name="user[phone_number]"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="form_title">Notification</label>
                          <div className="checkbox_block radius_checkbox">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <Input
                                type="checkbox"
                                name="user[quote_notifications]"
                                className="custom-control-input"
                                id="customCheck1"
                              />
                              <label
                                className="custom-control-label"
                                for="customCheck1"
                              >
                                Quotes
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <Input
                                type="checkbox"
                                name="user[ticket_notifications]"
                                className="custom-control-input"
                                id="customCheck2"
                              />
                              <label
                                className="custom-control-label"
                                for="customCheck2"
                              >
                                Tickets
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <Input
                                type="checkbox"
                                name="user[inspection_notifications]"
                                className="custom-control-input"
                                id="customCheck3"
                              />
                              <label
                                className="custom-control-label"
                                for="customCheck3"
                              >
                                Inspections
                              </label>
                            </div>

                            {props.updatedMode === 'internal' || (props.user && props.user.user && props.user.user.mode === 'internal') ? '' :
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input
                                  type="checkbox"
                                  name="user[qr_notifications]"
                                  className="custom-control-input"
                                  id="customCheck4"
                                />
                                <label
                                  className="custom-control-label"
                                  for="customCheck4"
                                >
                                  PegAssure
                                </label>
                              </div>}
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <Input
                                type="checkbox"
                                name="user[chi_survey_notifications]"
                                className="custom-control-input"
                                id="customCheck5"
                              />
                              <label
                                className="custom-control-label"
                                for="customCheck5"
                              >
                                CHI Survey
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label className="form_title">Status</label>
                        <Input
                          type="radio"
                          labelClass="custom-control-label"
                          forLable={"customRadio1"}
                          value={"active"}
                          label={"Active"}
                          className="custom-control-input"
                          id="customRadio1"
                          name="user[active_status]"
                        />

                        <Input
                          type="radio"
                          labelClass="custom-control-label"
                          forLable={"customRadio2"}
                          value={"inactive"}
                          label={"Inactive"}
                          className="custom-control-input"
                          id="customRadio2"
                          name="user[active_status]"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-end">
                      {props.formStates && props.formStates.user && props.formStates.user.role === 'client' ? '' : (
                        <div className="col-md-8">
                          <div className="form-group">
                            <label className="form_title">Inventory Access</label>
                            <div className="checkbox_block radius_checkbox">
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input
                                  type="checkbox"
                                  name="user[inventory_access][manager]"
                                  className="custom-control-input"
                                  id="customCheck6"
                                />
                                <label
                                  className="custom-control-label"
                                  for="customCheck6"
                                >
                                  Manager
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input
                                  type="checkbox"
                                  name="user[inventory_access][fulfillmentManager]"
                                  className="custom-control-input"
                                  id="customCheck7"
                                />
                                <label
                                  className="custom-control-label"
                                  for="customCheck7"
                                >
                                  Fulfillment Manager
                                </label>
                              </div>
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input
                                  type="checkbox"
                                  name="user[inventory_access][supervisor]"
                                  className="custom-control-input"
                                  id="customCheck8"
                                />
                                <label
                                  className="custom-control-label"
                                  for="customCheck8"
                                >
                                  Supervisor
                                </label>
                              </div>

                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input
                                  type="checkbox"
                                  name="user[inventory_access][approver]"
                                  className="custom-control-input"
                                  id="customCheck9"
                                />
                                <label
                                  className="custom-control-label"
                                  for="customCheck9"
                                >
                                  Approver
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-md-4">
                        <div className="form-group">
                          <div className="checkbox_block radius_checkbox">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <Input
                                type="checkbox"
                                name="user[enable_preferences]"
                                className="custom-control-input"
                                id="customCheck10"
                              />
                              <label
                                className="custom-control-label"
                                for="customCheck10"
                              >
                                Allow Users to change the Email Preferences
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {props?.formStates?.user?.role === 'client' ? '' : (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="checkbox_block radius_checkbox">
                             {props.formStates && props.formStates.user && props.formStates.user.role === 'admin' ? (
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input type="checkbox" name="user[payroll_access]" className="custom-control-input" id="customCheck11" />
                                <label className="custom-control-label" for="customCheck11"> Give Payroll Access </label>
                              </div>
                             ) : ""} 
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input type="checkbox" name="user[quality_review]" className="custom-control-input" id="customCheck12" />
                                <label className="custom-control-label" for="customCheck12"> Quality Reviewer </label>
                              </div>
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input type="checkbox" name="user[safety_access]" className="custom-control-input" id="customCheck13" />
                                <label className="custom-control-label" for="customCheck13"> Safety Access</label>
                              </div>
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <Input type="checkbox" name="user[user_permissions][unit_access]" className="custom-control-input" id="customCheck14" />
                                <label className="custom-control-label" for="customCheck14"> Unit Management</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {
  clientMode || (props.user.user && props.user.user.role === 'client') ? null :
                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group">
                          <div className="checkbox_block radius_checkbox">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <Input
                                type="checkbox"
                                name="user[account_wide]"
                                className="custom-control-input"
                                id="account_wide"
                              />
                              <label
                                className="custom-control-label"
                                for="account_wide"
                              >
                                Job wide
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
} */}
                    {/* {props.isEdit ? (
                    <div className="row">
                      <div className="col-md-12" style={{padding: '0', marginBottom: '10px'}}>
                        <div className="UD_tabs">
                          <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="account-tab"
                                data-toggle="tab"
                                href="#account"
                                role="tab"
                                aria-controls="account"
                                aria-selected="true"
                              >
                                Jobs
                              </a>
                            </li>
                          </ul>
                          <div
                            className="tab-content profile-tab"
                            id="myTabContent"
                          >
                            <div
                              className="tab-pane fade show active"
                              id="account"
                              role="tabpanel"
                              aria-labelledby="account-tab"
                            >
                              <div className="UD_table table-responsive performance_modal add_account associateUser">

                                <div class="col-lg-12">
                                  <div class="quote_form user_quota_form ticket_form">
                                    <div class="addQuote_form">
                                      <div class="form-group">
                                        <div class="form-group checkbox_cst_grid">
                                          {user.user
                                            ? user.user.accounts
                                              ? user.user.accounts.map(
                                                (a, i) => (
                                                  <div class="checkbox_block radius_checkbox">
                                                    <div class="custom-control custom-checkbox mb-2">
                                                      <label class="">
                                                        {a.name}
                                                      </label>
                                                    </div>
                                                  </div>
                                                )
                                              )
                                              : null
                                            : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="companies"
                              role="tabpanel"
                              aria-labelledby="companies-tab"
                            >
                              <div className="UD_table table-responsive performance_modal add_account associateUser">
                                <div class="col-lg-12">
                                  <div class="quote_form user_quota_form ticket_form">
                                    <div class="addQuote_form">
                                      <div class="form-group">
                                        <div class="form-group checkbox_cst_grid">
                                          {user.user
                                            ? user.user.companies
                                              ? user.user.companies.map(
                                                (c, i) => (
                                                  <div class="checkbox_block radius_checkbox">
                                                    <div class="custom-control custom-checkbox mb-2">
                                                      <label class="">
                                                        {c.name}
                                                      </label>
                                                    </div>
                                                  </div>
                                                )
                                              )
                                              : null
                                            : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}


                    <div className="owner_details">
                      <lable className="form_title font-weight-bold mb-2">Forms Access</lable>
                      <div className="form-group checkbox_cst_grid owner_block_wrap" style={{ display: "flex", flexWrap: "nowrap" }}>

                        {/* Team Management Section */}
                        <div className="checkbox-group">
                          <p >Team Management Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>



                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][team_management][checklist]`}
                                className="custom-control-inputs"
                                id={`user[form_access][team_management][checklist]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][team_management][checklist]`}
                              >
                                Checklist
                              </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][team_management][checklist_log]`}
                                className="custom-control-inputs"
                                id={`user[form_access][team_management][checklist_log]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][team_management][checklist_log]`}
                              >
                                Checklist Log
                              </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][team_management][checklist_supervisor_summary]`}
                                className="custom-control-inputs"
                                id={`user[form_access][team_management][checklist_supervisor_summary]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][team_management][checklist_supervisor_summary]`}
                              >
                                Checklist Supervisor Summary
                              </label>
                            </div>


                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][team_management][checklist_manager]`}
                                className="custom-control-inputs"
                                id={`user[form_access][team_management][checklist_manager]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][team_management][checklist_manager]`}
                              >
                                Checklist Manager Summary
                              </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][team_management][training_log]`}
                                className="custom-control-inputs"
                                id={`user[form_access][team_management][training_log]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][team_management][training_log]`}
                              >
                                Training Log
                              </label>
                            </div>





                          </div>
                        </div>


                        {/* LogBook Module */}


                        <div className="checkbox-group">
                          <p >Logbook Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][logbook][porter_log]`}
                                className="custom-control-inputs"
                                id={`user[form_access][logbook][porter_log]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][logbook][porter_log]`}
                              >
                                Porter Log
                              </label>
                            </div>


                          </div>
                        </div>


                        <div className="checkbox-group">
                          <p >Projects Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][projects][deep_clean_chart]`}
                                className="custom-control-inputs"
                                id={`user[form_access][projects][deep_clean_chart]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][projects][deep_clean_chart]`}
                              >
                                Deep Clean Chart
                              </label>
                            </div>
                          </div>
                        </div>


                        <div className="checkbox-group">
                          <p >Tag Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][tag][request_form]`}
                                className="custom-control-inputs"
                                id={`user[form_access][tag][request_form]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][tag][request_form]`}
                              >
                                Request Form
                              </label>
                            </div>
                          </div>
                        </div>


                        <div className="checkbox-group">
                          <p >Inventory Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>



                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][inventory][bulk_storage_supplies_usage]`}
                                className="custom-control-inputs"
                                id={`user[form_access][inventory][bulk_storage_supplies_usage]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][inventory][bulk_storage_supplies_usage]`}
                              >
                                Bulk Storage Supplies Usage
                              </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][inventory][daily_kitting_safety]`}
                                className="custom-control-inputs"
                                id={`user[form_access][inventory][daily_kitting_safety]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][inventory][daily_kitting_safety]`}
                              >
                                Daily Kitting Safety
                              </label>
                            </div>


                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][inventory][monthly_cost_summary]`}
                                className="custom-control-inputs"
                                id={`user[form_access][inventory][monthly_cost_summary]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][inventory][monthly_cost_summary]`}
                              >
                                Monthly Cost Summary
                              </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][inventory][monthly_usage_report]`}
                                className="custom-control-inputs"
                                id={`user[form_access][inventory][monthly_usage_report]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][inventory][monthly_usage_report]`}
                              >
                                Monthly Usage Report
                              </label>
                            </div>





                          </div>
                        </div>



                        <div className="checkbox-group">
                          <p >Building Profiles Module</p>
                          <div style={{ marginTop: "1rem" }}>
                            <div class="custom_checkbox_wrap flex-grow-1">

                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][building_profiles][building_profile]`}
                                className="custom-control-inputs"
                                id={`user[form_access][building_profiles][building_profile]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][building_profiles][building_profile]`}
                              >
                                Building Profile
                              </label>
                            </div>

                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                name={`user[form_access][building_profiles][job_cards]`}
                                className="custom-control-inputs"
                                id={`user[form_access][building_profiles][job_cards]`}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`user[form_access][building_profiles][job_cards]`}
                              >
                                Job Cards
                              </label>
                            </div>
                          </div>
                        </div>





                      </div>
                    </div>

                    {props.formStates && props.formStates.user && props.formStates.user.inspection_notifications ?
                      <div className="mb-4">
                        <label className="form_title font-weight-bold">
                          Select Criteria
                        </label>
                        <div className='performance_modal add_account associateUser'>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className='quote_form ticket_form' style={{ maxWidth: '100%' }}>
                                <div className="addQuote_form" style={{ overflowY: 'scroll', maxHeight: '500px' }}>
                                  <div className="form-group">
                                    <div className="form-group checkbox_cst_grid">
                                      {props.criteries && props.criteries.map(criteria => {
                                        return (
                                          <div className="checkbox_block radius_checkbox" key={criteria.id}>
                                            <div className="custom-control custom-checkbox mb-2">
                                              <input onChange={() => props.criteriaSelected(criteria.id)} checked={props.setCriteriaCheckValue(criteria.id)} type="checkbox" className="custom-control-input" id={`criteria_ids${criteria.id}`} />
                                              <label className="custom-control-label" for={`criteria_ids${criteria.id}`}>{makeFirstLetterCapital(criteria.name)}</label>
                                            </div>
                                          </div>)
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> : null}


                    <label className="form_title font-weight-bold">
                      Select Jobs
                    </label>
                    <div className='performance_modal add_account associateUser'>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className={props.formStates && props.formStates.user && props.formStates.user.account_wide ? "quote_form ticket_form job_wide_disable" : "quote_form ticket_form"} style={{ maxWidth: '100%' }}>
                            {props.match.params && props.match.params.accountId ?
                              <div className="addQuote_form" style={{ overflowY: 'scroll', maxHeight: '500px', backgroundColor: '#eee' }}>
                                <div className="form-group">
                                  {/* <div className="checkbox_block radius_checkbox">
                                  <div className="custom-control custom-checkbox custom-control-inline">
                                    <input onChange={props.selectAllJobsClicked} checked={props.selectNone ? false : props.selectAll} type="checkbox" className="custom-control-input" id="selectAll"  disabled={props.match.params?.accountId ? true : false}/>
                                    <label className="custom-control-label" for="selectAll">Select All</label>
                                  </div>
                                  <div className="custom-control custom-checkbox custom-control-inline">
                                    <input onChange={props.selectNoneJobsClicked} checked={props.selectAll ? false : props.selectNone} type="checkbox" className="custom-control-input" id="selectNone"  disabled={props.match.params?.accountId ? true : false}/>
                                    <label className="custom-control-label" for="selectNone">Select None</label>
                                  </div>
                                </div> */}

                                  <div className="form-group checkbox_cst_grid">



                                    {props.accountsList && props.accountsList.map(account => {
                                      return (
                                        <div className={props.setOwnerColor(account.id) ? "checkbox_block radius_checkbox other_account" : "checkbox_block radius_checkbox"} key={account.id}>
                                          <div className="custom-control custom-checkbox mb-2">
                                            <input onChange={() => props.jobSelected(account.id)} checked={props.setJobCheckValue(account.id)} type="checkbox" className="custom-control-input" id={`account_ids${account.id}`} disabled={props.match.params?.accountId ? true : false} />
                                            <label className="custom-control-label" for={`account_ids${account.id}`}>{account.name}</label>
                                          </div>
                                        </div>)
                                    })}
                                  </div>
                                </div>
                              </div> :
                              <div className="addQuote_form" style={{ overflowY: 'scroll', maxHeight: '500px' }}>
                                <div className="form-group">
                                  <div className="checkbox_block radius_checkbox">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                      <input onChange={props.selectAllJobsClicked} checked={props.selectNone ? false : props.selectAll} type="checkbox" className="custom-control-input" id="selectAll" disabled={props.match.params?.accountId ? true : false} />
                                      <label className="custom-control-label" for="selectAll">Select All</label>
                                    </div>
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                      <input onChange={props.selectNoneJobsClicked} checked={props.selectAll ? false : props.selectNone} type="checkbox" className="custom-control-input" id="selectNone" disabled={props.match.params?.accountId ? true : false} />
                                      <label className="custom-control-label" for="selectNone">Select None</label>
                                    </div>
                                  </div>

                                  <div className="form-group checkbox_cst_grid">
                                    {props.accountsList && props.accountsList.map(account => {
                                      return (
                                        <div className={props.setOwnerColor(account.id) ? "checkbox_block radius_checkbox other_account" : "checkbox_block radius_checkbox"} key={account.id}>
                                          <div className="custom-control custom-checkbox mb-2">
                                            <input onChange={() => props.jobSelected(account.id)} checked={props.setJobCheckValue(account.id)} type="checkbox" className="custom-control-input" id={`account_ids${account.id}`} disabled={props.match.params?.accountId ? true : false} />
                                            <label className="custom-control-label" for={`account_ids${account.id}`}>{account.name}</label>
                                          </div>
                                        </div>)
                                    })}
                                  </div>
                                </div>
                              </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      data-test="submit_button"
                      type="submit"
                      disabled={props.isLoading || props?.accountsLoading || (!props.isEdit && pristine) ? true : false}
                      className={
                        props.isLoading || props?.accountsLoading 
                          ? "btn cst_btn btn_danger mr-2 btn-wait"
                          : "btn cst_btn btn_danger mr-2"
                      }
                    >
                      {props.isLoading ||  props?.accountsLoading ? "" : "Submit"}{" "}
                    </button>
                    <button
                      data-test="cancel_button"
                      type="button"
                      className="btn btn-outline-secondary cst_btn"
                      onClick={() => props.onCancelClicked()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }
};

const mapStateToProps = (state) => {
  const defaultFormAccess = {
    "team_management": {
      "checklist": false,
      "checklist_log": false,
      "checklist_supervisor_summary": false,
      "checklist_manager": false,
      "training_log": false,
    },
    "logbook": {
      "porter_log": false
    },
    "tag": {
      "request_form": false
    },
    "projects": {
      "deep_clean_chart": false
    },
    "inventory": {
      "bulk_storage_supplies_usage": false,
      "daily_kitting_safety": false,
      "monthly_cost_summary": false,
      "monthly_usage_report": false
    },
    "building_profiles": {
      "building_profile": false,
      "job_cards": false
    },

  }
  let user = {};
  let SelectedAccounts = [];
  let SelectedCompanies = [];



  const getAccountId = (accounts) => {
    return accounts.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  };
  if (isEmpty(state.adminOrSuperAdminReducer.requestor)) {
    // if ()
    user = {
      active_status: "active",
      form_access: defaultFormAccess
    };
  } else if (
    state.adminOrSuperAdminReducer.requestor.user.accounts &&
    !state.adminOrSuperAdminReducer.requestor.user.first_name
  ) {
    SelectedAccounts = getAccountId(
      state.adminOrSuperAdminReducer.requestor.user.accounts
        ? state.adminOrSuperAdminReducer.requestor.user.accounts
        : []
    );
    user = {
      active_status: "active",
      mode: state.adminOrSuperAdminReducer.requestor.user.accounts[0].mode ? state.adminOrSuperAdminReducer.requestor.user.accounts[0].mode : state.adminOrSuperAdminReducer.requestor.user.accounts[0].user.mode,
    };
  } else {
    const getCompanyId = (companies) => {
      return companies.map((c) => ({
        value: c.id,
        label: c.name,
      }));
    };
    SelectedAccounts = getAccountId(
      state.adminOrSuperAdminReducer.requestor.user.accounts
        ? state.adminOrSuperAdminReducer.requestor.user.accounts
        : []
    );
    SelectedCompanies = getCompanyId(
      state.adminOrSuperAdminReducer.requestor.user.companies
        ? state.adminOrSuperAdminReducer.requestor.user.companies
        : []
    );

    const getInventory = (values) => {
      let selectedInventory = {}
      values.find(item => {
        if (item === 'manager') {
          selectedInventory.manager = true
        }
        if (item === 'fulfillmentManager') {
          selectedInventory.fulfillmentManager = true
        }
        if (item === 'supervisor') {
          selectedInventory.supervisor = true
        }
        if (item === 'approver') {
          selectedInventory.approver = true
        }
      })
      return selectedInventory
    }
    user = {
      first_name: state.adminOrSuperAdminReducer.requestor.user.first_name,
      last_name: state.adminOrSuperAdminReducer.requestor.user.last_name,
      region_id: state.adminOrSuperAdminReducer.requestor.user.region
        ? state.adminOrSuperAdminReducer.requestor.user.region.id
        : "",
      position_id: state.adminOrSuperAdminReducer.requestor.user.position
        ? state.adminOrSuperAdminReducer.requestor.user.position.id
        : "",
      role: state.adminOrSuperAdminReducer.requestor.user.role,
      email: state.adminOrSuperAdminReducer.requestor.user.email,
      inspection_notifications:
        state.adminOrSuperAdminReducer.requestor.user.inspection_notifications,
      quote_notifications:
        state.adminOrSuperAdminReducer.requestor.user.quote_notifications,
      ticket_notifications:
        state.adminOrSuperAdminReducer.requestor.user.ticket_notifications,
      chi_survey_notifications:
        state.adminOrSuperAdminReducer.requestor.user.chi_survey_notifications,
      qr_notifications:
        state.adminOrSuperAdminReducer.requestor.user.qr_notifications,
      active_status:
        state.adminOrSuperAdminReducer.requestor.user.active_status,
      enable_preferences:
        state.adminOrSuperAdminReducer.requestor.user.enable_preferences,
      timezone: state.adminOrSuperAdminReducer.requestor.user.timezone,
      mode: state.adminOrSuperAdminReducer.requestor.user.mode,
      phone_number: state.adminOrSuperAdminReducer.requestor.user.phone_number,
      inventory_access: getInventory(
        state.adminOrSuperAdminReducer.requestor.user.inventory_access ?
          state.adminOrSuperAdminReducer.requestor.user.inventory_access : []
      ),
      form_access: { ...defaultFormAccess, ...state.adminOrSuperAdminReducer.requestor.user.form_access },
      payroll_access: state.adminOrSuperAdminReducer.requestor.user.payroll_access,
      quality_review: state.adminOrSuperAdminReducer.requestor.user.quality_review,
      safety_access : state.adminOrSuperAdminReducer.requestor.user.safety_access,
      user_permissions : state.adminOrSuperAdminReducer.requestor.user.user_permissions,
      // account_wide: state.adminOrSuperAdminReducer.requestor.user.account_wide ? state.adminOrSuperAdminReducer.requestor.user.account_wide : ''
    };
  }

  return {

    initialValues: {
      user,
      account_ids: SelectedAccounts,
      company_ids: SelectedCompanies,
    },
    formStates: getFormValues('newUserForm')(state)
  };
};

NewUser = reduxForm({
  form: "newUserForm",
  validate,
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewUser);

NewUser = connect(mapStateToProps)(NewUser);

export const scrollToFirstError = (errors) => {
  let scroolToError = null;

  if (errors.user.first_name) {
    scroolToError = `user[first_name]`;
  } else if (errors.user.last_name) {
    scroolToError = `user[last_name]`;
  } else if (errors.user.role) {
    scroolToError = `user[role]`;
  } else if (errors.user.email) {
    scroolToError = `user[email]`;
  } else if (errors.user.position) {
    scroolToError = `user[position]`;
  } else if (errors.user.region_id) {
    scroolToError = `user[region_id]`;
  } else if (errors.user.mode) {
    scroolToError = `user[mode]`;
  }

  scroller.scrollTo(`${scroolToError}`, {
    duration: 500,
    delay: 1,
    isDynamic: true,
    smooth: true,
    // containerId: 'ContainerElementID',
    offset: -100,
  });
};

export default NewUser;

// export default reduxForm({
//     form: 'newUserFormForm',
//     validate
// })(NewUser);