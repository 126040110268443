import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../../../utility/constants/constants';
import {
    CustomNoDataComponent,
    trimString,
    makeFirstLetterCapital,
    convertDateTimezone,
    convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType, convertDateToDifferentTZ, convertDateFormatV6, converShiftScheduleTime
} from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../../utility/storage';
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1, tzTimeConversionTool_V1, tzTimeConversionToolwithAbb_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
var moment = require('moment');

let ShiftScheduleTable = (props) => {

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchShiftSchedule && state.form.searchShiftSchedule.values ? state.form.searchShiftSchedule.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.shiftSchedule_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.shiftScheduleList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.shiftScheduleList_no_of_rows);
    const isShiftScheduleList = useSelector(state => state.TimeTrackerModReducer.isShiftScheduleList);
    const shiftScheduleListCurPage = useSelector(state => state.TimeTrackerModReducer.shiftScheduleListCurPage);
    const SearchOrResetShiftScheduleListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetShiftScheduleListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const data = props.shiftScheduleList && props.shiftScheduleList.map((value) => {
        return {
            shift_date: value && value.start_date && value.end_date ? `${tzDateConverterTool_V1(value.start_date)} − ${tzDateConverterTool_V1(value.end_date)}` : '−',
            shift_time: value && value.start_time && value.end_time ? `${tzTimeConversionToolwithAbb_V1(value.start_time, value.start_date.split('T')[0])} − ${tzTimeConversionToolwithAbb_V1(value.end_time, value.start_date.split('T')[0])}` : '−',
            accountName: value && value.accounts ? value.accounts.map((item, i) => makeFirstLetterCapital(`${item.name}${value.accounts.length === i + 1 ? '' : ', '}`)) : '−',
            createdBy: value && value.createdBy ? `${makeFirstLetterCapital(value.createdBy.first_name)} ${makeFirstLetterCapital(value.createdBy.last_name)}` : '-',
            assignedTo: value && value?.assignedTo?.first_name ? `${makeFirstLetterCapital(value?.assignedTo?.first_name)} ${makeFirstLetterCapital(value?.assignedTo?.last_name)}` : '-',
            actions:
                <>
                    {/* <span className="mr_20 account_edit_icn" onClick={() => props.handleEditShiftClicked(value.id)}><i class="fa fa-pencil" aria-hidden="true" ></i></span> */}
                    <span className="mr_20 account_edit_icn" onClick={() => props.previewSingleShiftScheduleClicked(value)}><i class="fas fa-eye" aria-hidden="true" ></i></span>
                    <span className="mr_20 account_edit_icn" onClick={() => props.handleFrequentTimeTracker_openDeleteModal(value.id)}><i class="fa fa-trash" aria-hidden="true"></i></span>
                </>
        }
    })

    const columns = [
        {
            Header: <><span> Job </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'accountName',
            show: props.shiftScheduleList.length !== 0,
        },
        {
            Header: <><span> Shift Date</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'shift_date',
            show: props.shiftScheduleList.length !== 0,
        },
        {
            Header: <><span> Shift Timing</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'shift_time',
            show: props.shiftScheduleList.length !== 0,
        },
        {
            Header: <><span> Created by </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'createdBy',
            show: props.shiftScheduleList.length !== 0,
        },
        {
            Header: <><span> Assigned to </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'assignedTo',
            show: props.shiftScheduleList.length !== 0,
        },
        {
            Header: '',
            accessor: 'actions',
            width: 135,
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (shiftScheduleListCurPage !== tablePageNew.current) {
        const shiftId = storage.get('shiftScheduleId')
        if (shiftId) {
            tablePageNew.current = shiftScheduleListCurPage
        } else {
            tablePageNew.current = 0;
        }
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.shiftScheduleListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetShiftScheduleListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isShiftScheduleList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : shiftScheduleListCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if (No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                const userName = SearchValue.user ? SearchValue.user : null;
                const accountName = SearchValue.job ? SearchValue.job : null;
                const from = SearchValue.fromDate ? convertDateToDifferentTZ(SearchValue.fromDate, 'from') : null;
                const to = SearchValue.toDate ? convertDateToDifferentTZ(SearchValue.toDate, 'to') : null;
                props.getShiftSchedule({ page, limit, order, userName, accountName, from, to, orderBy }, values)
                storage.remove('shiftScheduleId')
            }
        }
    }

    return (
        SearchOrResetShiftScheduleListClicked && props.isShiftScheduleList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <ReactTable
                data={data}
                loading={props.isShiftScheduleList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={shiftScheduleListCurPage}
                page={shiftScheduleListCurPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.shiftScheduleList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isShiftScheduleList, 'No Shift Schedule List Found')}
                LoadingComponent={() =>
                    isShiftScheduleList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log(column);
                    return {
                        className: 'react-table-tr-element',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />
    )
}

export default ShiftScheduleTable;