import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
//import '../../../../../styles/custom.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeFirstLetterCapital, CustomNoDataComponent, getUser } from '../../../../utility/utility';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import '../../../../../node_modules/react-table-v6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import * as actions from '../../../../redux/actions/index';
import { userRoles } from '../../../../utility/constants/constants';
import ImageLoader from 'react-imageloader';
import { FLOOR_NO } from "../../../../utility/constants/constants";
import ReactTooltip from 'react-tooltip';
import { FieldTimeOutlined } from '@ant-design/icons';

const FrontlineListTable = (props) => {
  const [pageSize, setPageSize] = useState(10);
  const [openModel, setOpen] = useState(false);

  const dispatch = useDispatch();
  const SearchValue = useSelector(state => state.form.searchFrontlineListForm.values);
  const DateGenerate = useSelector(state => state.adminOrSuperAdminReducer.dataGenerated);
  const SearchOrResetFrontLineUsersClicked = useSelector(state => state.adminOrSuperAdminReducer.SearchOrResetFrontLineUsersClicked);
  const No_of_pages = useSelector(state => state.adminOrSuperAdminReducer.total_no_of_pages);
  const No_of_rows = useSelector(state => state.adminOrSuperAdminReducer.fl_user_rows);
  const frontlineUserPage = useSelector(state => state.adminOrSuperAdminReducer.frontlineUserCurPage);
  // const fl_user_rows = useSelector(state => state.adminOrSuperAdminReducer.fl_user_rows);
  function preloader() {
    return <img style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} src="/images/gif/giphy.gif" />;
  }

  const data = props?.frontlineList?.map(frontline => {

    return {
      image:
        frontline.photo_urls && frontline.photo_urls.small ?
          <ImageLoader
            imgProps={{ style: { height: '35px', width: '35px', borderRadius: '50px', float: 'left' } }}
            style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }}
            src={frontline.photo_urls.small}
            wrapper={React.createFactory('div')}
            preloader={preloader}>
            Image load failed!
          </ImageLoader>
          :
          <img src="/customImages/user.png" style={{ height: '35px', width: '35px', borderRadius: '50px', float: 'left' }} />
      // <img style={{ height: '35px', width: '35px', borderRadius: '50px' }} src={user.photo_urls.original ? user.photo_urls.original : "/customImages/user.png"} />
      ,
      employee_id: frontline.employee_id,
      account: frontline.default_account ? frontline.default_account.name : "",
      name: `${frontline.first_name} ${frontline.last_name}`,
      dob: frontline.dob_password,
      actions:
        <>

          <ReactTooltip place="left" type="dark" effect="solid" />
          {props && props.user && props.user.role && (props.user.role == userRoles.SUPER_ADMIN) ? (
            <span className="mr_20 account_edit_icn" onClick={() => props.history.push(`/frontlines/${frontline.id}`)}><i class="fa fa-pencil" aria-hidden="true" data-test="frontline_user_edit"></i></span>
          ) : null}
          <span data-tip="View Availability Calendar" className="mr_20 account_edit_icn" onClick={() =>{props.frontlineUserActiveNameChange(`${frontline.first_name} ${frontline.last_name}-`);props.viewAvailabilityCalendar(frontline.id)}} data-test="account_trash"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i></span>
          <span data-tip="View Shift Calendar" className="mr_20 account_edit_icn" onClick={() => {props.frontlineUserActiveNameChange(`${frontline.first_name} ${frontline.last_name}-`);props.viewShiftCalendar(frontline.id)}} data-test="account_trash"><i class="fa fa-calendar-o" aria-hidden="true"></i></span>
          <span data-tip="View Timesheet List" className="mr_20 account_edit_icn shiftSwapIcon" onClick={() => { props.frontlineUserActiveNameChange(`${frontline.first_name} ${frontline.last_name}-`);props.viewTimesheetListScreenClicked(frontline.id) }} data-test="account_trash"><FieldTimeOutlined style={{ fontSize: '24px' }} /></span>
          <span data-tip="View Shift Attendance Dashboard" className="mr_20 account_edit_icn" onClick={() => {props.frontlineUserActiveNameChange(`${frontline.first_name} ${frontline.last_name}-`);props.viewShiftAttendanceDashboardCalendar(frontline.id)}} data-test="account_trash"><i class="fa fa-calendar-times-o" aria-hidden="true"></i></span>
          <span data-tip="View Shifts" className="mr_20 account_edit_icn" onClick={() => {props.frontlineUserActiveNameChange(`${frontline.first_name} ${frontline.last_name}-`);props.viewShifts(frontline.id)}} data-test="account_trash"><i class="fas fa-hourglass-half" aria-hidden="true"></i></span>

        </>
    }
  })


  const columns = [
    {
      Header: '',
      accessor: 'image', // String-based value accessors!,
      width: 55
    },
    {
      Header: <><span>EMP ID / Username</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'employee_id', // String-based value accessors!,
      show: props?.frontlineList?.length !== 0,
    },
    {
      Header: <><span>Job</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'account', // String-based value accessors!,
      show: props?.frontlineList?.length !== 0,
    },
    {
      Header: <><span>Full Name</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'name', // String-based value accessors!,
      show: props?.frontlineList?.length !== 0,
    },
    {
      Header: <><span>Password</span><FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /> </>,
      accessor: 'dob',
      show: props?.frontlineList?.length !== 0,
      width: 350
    },
    {
      Header: " ",
      accessor: "actions",
      show: props?.frontlineList?.length !== 0,
      width: 200
    }
  ]

  const [tablePage, setTablePage] = useState(0)
  const tablePageNew = React.useRef()
  if (frontlineUserPage !== tablePageNew.current) {
    tablePageNew.current = frontlineUserPage;
  }
  const setTablePageFn = (page) => {
    setTablePage(page)
    props.frontlineUserPageClicked(page)
    tablePageNew.current = page;
  }

  const fetchData = (state, instance) => {
    if (SearchOrResetFrontLineUsersClicked) {
      dispatch(actions.resetSearchOrResetValue())
    } else {
      if (!DateGenerate) {
        state.page = 2;
        const size = state.pageSize;
        const fl_users_no_of_rows = state.pageSize;
        let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : frontlineUserPage + 1;
        if (No_of_rows != fl_users_no_of_rows) {
          page = 1;
          setTablePageFn(0)
        }
        const sorted = state.sorted;
        //const filtered = state.filtered;
        const name = SearchValue.name ? SearchValue.name : '';
        const id = SearchValue.id ? SearchValue.id : '';
        //const email = SearchValue && SearchValue.email ? SearchValue.email.replace('+', "%2B") : ""
        props.getFrontlineList({ size, page, sorted, name, id, fl_users_no_of_rows: No_of_rows });
      }
    }
    // }
  }
  const handleConfirm = (confirm) => {
    setOpen(false);
    //props.deleteArea(currentArea.id, props.match.params.accountId)
  };
  return (
    SearchOrResetFrontLineUsersClicked && props.isLoading ?
      <div className="loader_btn_block">
        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
      </div>
      :
      <> <ReactTable
        data={data}
        loading={props.isLoading}
        defaultPageSize={No_of_rows ? No_of_rows : pageSize}
        minRows={0}
        manual
        onFetchData={fetchData}
        pages={No_of_pages}
        gotoPage={frontlineUserPage}
        page={frontlineUserPage}
        onPageChange={(page) => { setTablePageFn(page) }}
        onPageSizeChange={(e) => setPageSize(e)}
        showPaginationTop={true}
        showPagination={props?.frontlineList?.length !== 0}
        columns={columns}
        NoDataComponent={() => CustomNoDataComponent(props.isLoading, 'No Frontline Found')}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              borderTop: 'none',
              borderBottom: 'none',
              borderRight: 'none',
              textAlign: 'center',
              color: '#566975',
              fontSize: '14px',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              float: 'left',
            }
          }
        }
        }
        LoadingComponent={() =>
          props.isLoading ?
            <div style={{
              display: "block",
              position: "absolute",
              left: 0,
              height: '100%',
              right: 0,
              background: "rgba(255,255,255,0.8)",
              transition: "all .3s ease",
              top: 0,
              bottom: 0,
              textAlign: "center"
            }}
              className="loader_btn_block">
              <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div> : null}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            className: 'react-table-tr-element',
            style: {
              border: '1px solid #c7cdd1',
              transition: '.3s ease-in-out',
              width: '100%',
              marginTop: '5px',
              float: 'left'
            }
          }

        }}
        getTdProps={(state, rowInfo, column, instance) => {

          if (column.id == 'image') {
            return {
              style: {
                marginTop: '0px'
              }
            }
          } else {
            return {
              className: 'react-td-element',
            }
          }
        }
        }
        getProps={() => {
          return {
            style: {
              border: 'none'
            }
          }
        }}
      />
        <Dialog
          open={openModel}

          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ fontSize: '13', color: '#06080f' }}>
            <h3 style={{ color: '#ab385e' }}>Are you sure?</h3>
          </DialogTitle>

          <DialogActions>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => handleConfirm(true)} disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} id="delete" type="delete">{props.isLoading ? "" : "Delete"}</button>
            <button style={{ minHeight: '36px', minWidth: '80px' }} onClick={() => setOpen(false)} className={"btn cst_btn btn-outline-secondary"} id="cancel" type="cancel">Cancel</button>
          </DialogActions>
        </Dialog>

      </>
  )
}

export default FrontlineListTable;