import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { closeModel, getUser } from '../../../utility/utility';
import { routes } from '../../../utility/constants/constants';
import { reset } from 'redux-form';
import isEmpty from '../../../utility/IsEmptyValidator';
import $ from "jquery";
import { validateAccountUsers } from '../../../utility/validator/submit';

import FrontlineListPage from '../../component/Frontline';
import storage from '../../../utility/storage';
import { animateScroll as scroll } from 'react-scroll'


class FrontlineList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentAssosciatedUserEmail: '',
      accountErrorMessage: ''
    }
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

 newFrontlineClicked = () =>{
        this.props.resetObjects();
        this.props.history.push(routes.ADD_FRONTLINE);
 }

  searchFrontlineUserClicked = (values) => {
    if (values.id) {
      values.id = values.id.trim()
    }
    if (values.name) {
      values.name = values.name.trim()
    }
    this.props.searchFrontlineUser(values);
  };


  componentWillUnmount() {
    //this.props.resetObjects();
  }

  componentDidUpdate(prevProps, prevState) {
    const user = storage.get('user');
    if (user) {
      if (prevProps.user.selected_mode !== user.selected_mode) {
        this.props.frontlineUserPageClicked(0)
      }
    }
  }

  resetClicked = () => {
    const size = this.props.frontLineRows
    const page = 1;
    this.props.reset('searchFrontlineListForm');
    this.props.frontlineUserPageClicked(0)
    this.props.getFrontlineList({ size, page });
  }

  viewAvailabilityCalendar = (id) => {
    const user = getUser();
    this.props.history.push(
      { pathname: `/calendar/availability/${id}`, })
  }

  viewShiftCalendar = (id) => {
    const user = getUser();
    this.props.history.push(
      { pathname: `/calendar/shift/${id}`, })
  }

  viewTimesheetListScreenClicked = (flUserId) => {
    this.props.history.push({ pathname: `/time-sheet-list/${flUserId}` });
  }

  viewShiftAttendanceDashboardCalendar = (flUserId) => {
    this.props.history.push({ pathname: `/attendance-dashboard/${flUserId}` });
  }

  viewShifts = (flUserId) => {
    this.props.history.push({ pathname: `/fl-user-shift/${flUserId}` });
  }

  render() {

    return (
      <FrontlineListPage
        {...this.props}
        resetClicked={this.resetClicked}
        onSubmit={this.searchFrontlineUserClicked}
        newFrontlineClicked={this.newFrontlineClicked}
        viewAvailabilityCalendar={this.viewAvailabilityCalendar}
        viewShiftCalendar={this.viewShiftCalendar}
        viewTimesheetListScreenClicked={this.viewTimesheetListScreenClicked}
        viewShiftAttendanceDashboardCalendar={this.viewShiftAttendanceDashboardCalendar}
        viewShifts={this.viewShifts}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    frontlineList: state.adminOrSuperAdminReducer.frontlineList,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    isFrontline: state.adminOrSuperAdminReducer.isFrontline,
    frontLineRows: state.adminOrSuperAdminReducer.frontLineRows,
    frontlineUserCurPage: state.adminOrSuperAdminReducer.frontlineUserCurPage,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetObjects: () => dispatch(actions.resetObjects()),
    getFrontlineList: (params) => dispatch(actions.getFrontlineList(params)),
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    handleClickDeleteModel: (frontline) => dispatch(actions.handleClickDeleteModel(frontline)),
    deleteFrontline: (id) => dispatch(actions.deleteFrontline(id)),
    importFrontline: (body) => dispatch(actions.importFrontline(body)),
    importCheckFrontline: (body) => dispatch(actions.importCheckFrontline(body)),
    reset: (searchFrontlineListForm) => dispatch(reset(searchFrontlineListForm)),
    searchFrontlineUser: (values) => dispatch(actions.searchFrontlineUser(values)),
    frontlineUserPageClicked: (page) => dispatch(actions.frontlineUserPageClicked(page)),
    frontlineUserActiveNameChange:(name)=> dispatch(actions.updateActiveUserName(name))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(FrontlineList);