import React, { useEffect, useState } from 'react';
import storage from '../../../../utility/storage';
import { tzDateConverterTool_V4, tzTimeConverterTool_V3, tzUTCDateTimeConverterTool_V1 } from '../../../../utility/TimezoneOperations/timezoneUtility';
import { calculateTotalSeconds, convertSecondsToTime, getNarrowOfClockInTimegap, getNarrowOfMealbreakTimegap, getOrdinalNumber } from '../../../../utility/utility';
import MealBreakConcludingModal from './MealBreakConcludingModal';
import { ClapSpinner } from 'react-spinners-kit';
import { useHistory } from 'react-router-dom';
import { List } from 'antd';
import { useTimer } from "use-timer";
import { useSelector } from 'react-redux';
import errorHandler from '../../../../utility/errorHandler/errorHandler';
var momentTZ = require('moment-timezone');

let ClockInClockOutScreenComponent = (props) => {

    const history = useHistory();
    let formatTimerUnits = unit => unit.toString().padStart(2, '0');
    const [initialTimer, setInitialTimer] = useState(0)
    console.log("initialTimer", initialTimer);
    

    let currentUser = storage.get('user');

    let isClockInEngaged = storage.get('isClockInEngaged') ? storage.get('isClockInEngaged') : false; // For handling the clock in start clicked or not
    let envReloadEngaged = storage.get('envReloadOccurs') ? storage.get('envReloadOccurs') : false; // For handling the page reload
    let currentFeedGapId = storage.get('currentFeedGapId') ? storage.get('currentFeedGapId') : null; // For handling the meal break id
    const clockInCheckedInTime = useSelector(state => state.TimeTrackerModReducer.clockInCheckedInTime)

    useEffect(() => {

        const handleTimeTravel = () => {

                let calculatedTotSeconds = null;
                if (clockInCheckedInTime) {
                    if (props?.clockedInShiftData?.breaks?.length > 0) {
                        let totalMealBreakDuration = 0;
                        props.clockedInShiftData.breaks.forEach((item) => {
                            if(item.start && !item.end){
                                storage.set('isMealBreakInitiated', true);
                            }
                            if (item.start && item.end) {
                                const startTime = new Date(item.start);
                                const endTime = new Date(item.end);
                                const duration = endTime - startTime;
                                totalMealBreakDuration += duration;
                            }
                        });
                        const { seconds, minutes, hours } = getNarrowOfClockInTimegap(clockInCheckedInTime, totalMealBreakDuration);
                        calculatedTotSeconds = calculateTotalSeconds(hours, minutes, seconds);
                    } else {
                        const { seconds, minutes, hours } = getNarrowOfClockInTimegap(clockInCheckedInTime);
                        calculatedTotSeconds = calculateTotalSeconds(hours, minutes, seconds);
                    }
                }
                if(!storage.get('isMealBreakInitiated')){
                    setInitialTimer(calculatedTotSeconds)
                }
                
                const { hours, minutes, seconds } = convertSecondsToTime(calculatedTotSeconds);
                localStorage.setItem('clockInTimer', JSON.stringify({ seconds, minutes, hours }));
                if(!storage.get('isMealBreakInitiated')){
                    localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));
                }
                
            }
            if (isClockInStarted == true) {
                handleTimeTravel();
            }

    }, [history, clockInCheckedInTime]);
    

    let [isClockInStarted, setIsClockInStarted] = useState(isClockInEngaged);
    let [envReloadOccurs, setEnvReloadOccurs] = useState(envReloadEngaged);

    let [feedgaptime, setFeedGapTime] = useState({ feedHour: 0, feedMinute: 0, feedSecond: 0 });
    let [isFeedGapSprinted, setIsFeedGapSprinted] = useState(false);
    let [concludeFeedGap, setConcludeFeedGap] = useState(null);
    const [accountId, setAccountId] = useState("");

    const { isClockInShiftAvailable, clockedInShiftData, isShiftMealBreakTriggered, triggerClockInTimer } = props;

    const formatTime = (time) => {
        return {
          hours: Math.floor(time / 3600), // Convert to hours (integer)
          minutes: Math.floor((time % 3600) / 60), // Convert to minutes (integer)
          seconds: time % 60, // Get remaining seconds (integer)
        };
      };

      const formatTimer = (time) => {
        const hours = Math.floor(time / 3600).toString().padStart(2, "0");
        const minutes = Math.floor((time % 3600) / 60).toString().padStart(2, "0");
        const seconds = (time % 60).toString().padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
      };

      const { time, start, pause, reset } = useTimer({
        initialTime: initialTimer,
        timerType: "INCREMENTAL",
      });


    /* RETRIEVE THE CLOCK-IN TIMER FROM LOCALSTORAGE & INTEGRATE IT INTO LIVE STREAM */
    useEffect(() => {
        if (isClockInStarted == true) {
            let storedTime = null;
    
            if (isClockInStarted == true && storage.get('isMealBreakInitiated')) {
                storedTime = localStorage.getItem('chronometer');
            } else {
                storedTime = localStorage.getItem('clockInTimer');
            }
            
            if (storedTime && isClockInStarted) {
                const parsedTime = JSON.parse(storedTime);
                if (parsedTime) {
                    const { seconds, minutes, hours } = parsedTime;
                    setInitialTimer(calculateTotalSeconds(hours, minutes, seconds)) 
                }
            }
        }
    }, [])
   


    /* TRIGGER CLOCK-IN TIMER FOR LIVE STREAM TEMPLATE */
    useEffect(() => {
        if (isClockInStarted == true  && !storage.get('isMealBreakInitiated')) {  //&& isFeedGapSprinted == false
            start();
        }else{
            pause();
        }
    }, [start, isClockInStarted, envReloadOccurs]);



    useEffect(() =>{
        const initialClockIn = storage.get('initialClockIn')
        if (clockedInShiftData?.check_int_at && initialClockIn) {
            storage.set('initialClockIn', false)
            storage.set('isClockInEngaged', true);
            const { seconds, minutes, hours } = formatTime(time)
            localStorage.setItem('clockInTimer', JSON.stringify({ seconds, minutes, hours }));
            localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));
            start();
            setIsClockInStarted(true);
        }
    },[triggerClockInTimer])

    const handleClockIn = () => {
         
            const seperateId = accountId.split(",")
            const values = {
                shift_detail_id : seperateId[1],
                account_id : seperateId[0]
            }
             props.handleShiftClockCheckIn(values, setAccountId);
    };

        /* Trigger the Meal Break Conclude Modal */
        const handleFeedGapEndModalTrigger = (intervalItem) => {
            setConcludeFeedGap(intervalItem);
            // openModal()
           props.handleFrequentTimeTracker_openModal();
           setTimeout(() => {
            document.getElementById('triggerMealModal').click();
           }, 100)
        }

    const handleFeedGapStart = (intervalItem, mealStatus, totalBreaks, index) => {

        if(totalBreaks.length > 1){
            if(totalBreaks[0].start && totalBreaks[0].end === null && index == 1){
                errorHandler('Meal Break is in progress. You are not allowed to check in to another meal break yet.')
                return
            }
        }

        if (mealStatus === 'start') {
            pause();
            storage.set('isMealBreakInitiated', true);
            props.handleShiftMealBreakCheckIn(intervalItem, intervalItem.id,clockedInShiftData?.id,"MealIn");

            let storedTime = localStorage.getItem('clockInTimer');
            const parsedTime = JSON.parse(storedTime);
            if (parsedTime) {
                const { seconds, minutes, hours } = parsedTime;
                localStorage.setItem('chronometer', JSON.stringify({ seconds, minutes, hours }));
            }

        } else if(mealStatus === 'end') {
            handleFeedGapEndModalTrigger(intervalItem)
        }
    };


    const handleClockOut = () => {

        if(storage.get('isMealBreakInitiated')){
            errorHandler('Meal Break is in progress. You are not allowed to clock out yet.')
            return
        }
            
        let setData = { check_out_at: null, addresses_attributes: {}, };
        let fetchedCurrDateTime = momentTZ(new Date()).format('MM-DD-YYYYTHH:mm:ss').split('T');
        let checkOutTime = tzUTCDateTimeConverterTool_V1(fetchedCurrDateTime[0], fetchedCurrDateTime[1], 'none');

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setData.addresses_attributes.latitude = latitude;
                    setData.addresses_attributes.longitude = longitude;
                    localStorage.setItem('clkOutTime', JSON.stringify(setData));
                },
                (error) => {
                    console.error('Error getting location:', error.message);
                }
            );
        }

        storage.set('inHabitancyRecord', 'alpha');
        storage.remove('isTimeTravel');
        props.handleCheckOutRecogonization(clockedInShiftData.id, checkOutTime, clockedInShiftData)

    }

    const handleFeedGapEnd = (intervalItem) => {
       
            storage.remove('isMealBreakInitiated');
            props.handleFrequentTimeTracker_closeModal()
            props.handleShiftMealBreakCheckIn(intervalItem, intervalItem.id,clockedInShiftData?.id,"MealOut");
        
    };
    

    const handleJobChange = (e) => {
       setAccountId(e.target.value)
    }
    const checkPendingJobs = (jobs) => {
        const filterJobs =  jobs?.shiftDetailJobs?.filter((item) => item.status === "pending")
        return filterJobs
     }

    const handleNextJob = () => {
        if(storage.get('isMealBreakInitiated')){
            errorHandler('Meal Break is in progress. You are not allowed to switch jobs yet.')
            return
        }
        const seperateId = accountId.split(",")
            const values = {
                shift_detail_id : seperateId[1],
                account_id : seperateId[0]
            }
             props.handleShiftNextJob(values, setAccountId);
    }

    const findCurrentJob = (jobs) => {
        const currentjob = jobs?.shiftDetailJobs?.find((item) => item.status === "clockedIn")
        if(currentjob){
            return currentjob?.account?.name
        }else {
            return '-'
        }
    }

    const findCurrentJobId = (jobs) => {
        const currentjob = jobs?.shiftDetailJobs?.find((item) => item.status === "clockedIn")
        if(currentjob){
            return currentjob?.account?.id
        }
    }




    if (props.isMealPenaltyLoader) {
        return (
            <div className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluids">
                        <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>

                <div className="app-content wraper_content user_newQuote add_account_modal add_New_Accont add_newAccount">
                    <div className="inspection_content_sec appcontent_Inner">
                        <div className="container-fluids">
                            <div className="quote_form ticket_form" style={{ maxWidth: '1000px' }}>
                              {/* <button  id='startTimerAfterMeal' style={{display: "none"}} onClick={startTimer}></button> */}
                              <button data-test="meal-break" style={{display: "none"}} id='triggerMealModal' type="button" className='clkIn_mealbreak_triggerButton' data-toggle="modal" data-target="#concludeMealBreak" data-backdrop="static" data-keyboard="false"></button>
                                <div className="row">

                                    {!(isClockInStarted) && !(isFeedGapSprinted) && !clockedInShiftData?.check_int_at ? (
                                        <>
                                            <div className="col-sm-12 text-center"> <h4 className="" > Clock In/Out </h4></div>
                                            <div className="col-md-12 text-center mt-4">
                                                <span style={{cursor : "default"}}  type='button'className={"btn cst_btn btn_danger FLH_button w-50 clkIn_startTimer"} >{"Clock In"}</span>
                                            </div>
                                            <div className="col-md-4 text-center mt-5" style={{marginLeft : '345px'}} >
                                            <select name="status" type="select"  placeholder="Select Status" className="custom-select input-modifier add_user_select" onChange={(e) => handleJobChange(e)} >
                                             <option  value="" >Select Job</option>
                                               {clockedInShiftData?.shiftDetailJobs?.filter((item) => item.status === "pending").map((item, i) => (
                                                <option key={i} value={`${item.account_id},${item.shift_detail_id}`} >{item.account?.name}</option>
                                               ))} 
                                            </select>
                                            </div>
                                            <div className="col-md-12 text-center mt-4">
                                            <button type='button' disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait " : "btn cst_btn btn_danger "} onClick={handleClockIn}>{props.isLoading ? "" : "Submit"}</button>
                                            </div>
                                        </>
                                    ) : null}


                                    {/* Normal Clock In ClockOut Timer UI */}
                                    {clockedInShiftData?.check_int_at && clockedInShiftData?.status === "clockedIn" && !(isFeedGapSprinted)  ? (
                                        <>
                                            <div className="col-md-12 text-center mt-5">

                                                <h3 className="card-title text-color-iridium swapUserName mt-0 mb-0" style={{ fontSize: '40px' }}> Hi {currentUser && currentUser.first_name + ' ' + currentUser.last_name} ! </h3>
                                                {clockedInShiftData && (
                                                    <h5 className="ml-lg-0 order-1 order-lg-2 text-muted mt-3">{`You are Clocked into the system at ${tzTimeConverterTool_V3(clockedInShiftData.check_int_at)} on ${tzDateConverterTool_V4(clockedInShiftData.check_int_at)}`}</h5>
                                                )}

                                                {/* Clock In Timer */}
                                                <div className='d-flex justify-content-center mt-5'>
                                                    <div className='clkIn_timerset1'>
                                                        <div style={{ fontWeight: 'bolder' }}>
                                                            {/* <span>{formatTimerUnits(hours)}</span>:<span>{formatTimerUnits(minutes)}</span>:<span>{formatTimerUnits(seconds)}</span> */}
                                                            <span>{formatTimer(time + initialTimer)}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Current Shift and Tickets Button */}
                                                <div className="row mt-5">
                                                    <div className='width_100' style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <button data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger min_width_50"} onClick={() => { props.handleMyShiftClick(); }} > {props.isLoading ? "" : "Current Shifts"} </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <button data-test="submit_button" type="button" disabled={props.isLoading} className={props.isLoading ? "btn cst_btn btn_danger btn-wait w-25" : "btn cst_btn btn_danger min_width_50"} onClick={() => { props.handleMyTicketsClick(findCurrentJobId(clockedInShiftData)); }} > {props.isLoading ? "" : "Current Tickets"} </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Meal Break Starting Icon and Buttons */}
                                                <div className='row mt-5'>
                                                <div className='width_100' style={{ display: 'flex', justifyContent: 'center' }}>
                                                    {clockedInShiftData && clockedInShiftData.breaks && clockedInShiftData.breaks.map((intervalItem, index, totalBreaks) => (
                                                         
                                                            <div key={index} className="col-md-4">
                                                                <button disabled={intervalItem.start && intervalItem.end ? true : false} id='mealBreakTrigger' key={intervalItem.id} type="button" className='btn cst_btn btn_danger min_width_50' onClick={() => handleFeedGapStart(intervalItem, intervalItem.start == null ? 'start'  : intervalItem.end == null ? 'end' : null, totalBreaks, index)}>
                                                               {intervalItem.start == null ? 'Start'  : intervalItem.end == null ? 'End' : null} Meal Break - {clockedInShiftData.breaks.length > 1 ? index + 1 : null}
                                                                </button>
                                                                {intervalItem.start ? <p className='clkIn_mealbreak_timerText mt-3'> {`${intervalItem.start ? tzTimeConverterTool_V3(intervalItem.start) : '-'} − ${intervalItem.end ? tzTimeConverterTool_V3(intervalItem.end) : '-'}`} </p> : 
                                                                <p className='clkIn_mealbreak_timerText mt-3'> {`${tzTimeConverterTool_V3(intervalItem.expected_start)} − ${tzTimeConverterTool_V3(intervalItem.expected_end)}`} </p>}
                                                                
                                                            </div>
                                                    ))}
                                                </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}


                                    {/* Meal Break Timer UI */}
                                    {isClockInStarted && isFeedGapSprinted && (
                                        <>
                                            <div className="col-md-12 text-center mt-2">

                                                <div className="col-sm-12 text-center mb-5"> <h3 className="btn cst_btn warning_button w-25" style={{ borderRadius: '2rem' }}> Meal Break </h3></div>

                                                {clockedInShiftData && clockedInShiftData.breaks && clockedInShiftData.breaks.map((intervalItem, index) => (
                                                    intervalItem.id == currentFeedGapId ? (
                                                        <div key={index}>
                                                            <h6 className="mt-0 font-weight-bold mb-2 text-muted"> Meal Break Time </h6>
                                                            <h5 className="ml-lg-0 font-weight-bold order-1 order-lg-2 text-dark mt-2" style={{ fontSize: '15px' }}>
                                                                {`${tzTimeConverterTool_V3(intervalItem.expected_start)} − ${tzTimeConverterTool_V3(intervalItem.expected_end)}`}
                                                            </h5>
                                                        </div>
                                                    ) : null
                                                ))}

                                                {/* Clock In Timer with Meal Break Timer */}
                                                <div className='d-flex justify-content-center mt-5'>
                                                    <div className='clkIn_timerset1'>
                                                        <div style={{ color: '#ffffffad', fontWeight: 'bold' }}>
                                                            {/* <span>{formatTimerUnits(hours)}</span>:<span>{formatTimerUnits(minutes)}</span>:<span>{formatTimerUnits(seconds)}</span> */}
                                                        </div>
                                                        <div style={{ fontSize: '50px', fontWeight: 'bold' }}>
                                                            <span>{formatTimerUnits(feedgaptime.feedHour)}:{formatTimerUnits(feedgaptime.feedMinute)}:{formatTimerUnits(feedgaptime.feedSecond)}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Meal Break Ending Icon and Button */}
                                                <div className='mt-5' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                    {clockedInShiftData && clockedInShiftData.breaks && clockedInShiftData.breaks.map((intervalItem, index) => {
        
                                                    return(
                                                        intervalItem.end == null && intervalItem.id == currentFeedGapId ? (

                                                            <div key={index} className="">
                                                                <button data-test="meal-break" type="button" className='clkIn_mealbreak_triggerButton' data-toggle="modal" data-target="#concludeMealBreak" data-backdrop="static" data-keyboard="false">
                                                                    <div style={{ backgroundColor: '#222222eb', borderRadius: '50%', width: '60px', height: '60px' }}>
                                                                        <div className='d-flex justify-content-center p-3'>
                                                                            <img src="/images/icons/icn_mealbreak_white_32.png" className="app-menu__icon" />
                                                                        </div>
                                                                    </div>
                                                                    <p className='clkIn_mealbreak_timerText mt-2'> End meal break </p>
                                                                </button>
                                                            </div>

                                                        ) : null
                                                    )})}
                                                </div>

                                            </div>
                                        </>
                                    )}

                                    {/* ClockOut Button UI and Move on to next job */}
                                    {!isFeedGapSprinted && clockedInShiftData?.check_int_at ? (
                                        
                                        <div className='col-md-12 text-center mt-5'>
                                      <h5 className=' fw-bold'>You are currently clocked in to {findCurrentJob(clockedInShiftData)}</h5>
                                      <br />  
                                      <List
                                                itemLayout="horizontal"
                                                dataSource={clockedInShiftData?.shiftDetailJobs}
                                                bordered={true}
                                                
                                                renderItem={(item, index) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                    title={item.account.name}
                                                    description={`${item.start_at ? tzTimeConverterTool_V3(item.start_at) : '_'} − ${item.end_at ? tzTimeConverterTool_V3(item.end_at) : '_'}`}
                                                    />
                                                </List.Item>
                                                )}
                                            />
                                    </div>
                                    ) : null
                                    }
                                    {
                                        isFeedGapSprinted ? null : (
                                            <>
                                            {clockedInShiftData?.check_int_at ? (
                                               <>
                                               {clockedInShiftData?.shiftDetailJobs?.length > 1 && (
                                                <>
                                                <div className="col-md-12 text-center mt-5">
                                                     <button style={{cursor:'default'}}  type='button'className={"btn cst_btn btn_danger FLH_button w-40 moveOnToNextJob"} >{"Move on to next Job"}</button>
                                                 </div>
                                                 <div className="col-md-4 text-center mt-4" style={{marginLeft : '345px'}} >
                                                 <select name="status" type="select" value={accountId} isClockInStarted placeholder="Select Status" className="custom-select input-modifier add_user_select" onChange={(e) => handleJobChange(e)} >
                                                  <option  value="" >Select Job</option>
                                                    {clockedInShiftData?.shiftDetailJobs?.map((item, i) => (
                                                     <option key={i} value={`${item.account_id},${item.shift_detail_id}`} disabled={item.status !=="pending"} >{item.account?.name}</option>
                                                    ))} 
                                                 </select>
                                                 </div>
                                                 <div className="col-md-12 text-center mt-4">
                                                 <button  type='button' disabled={props.isNextJobLoading || !checkPendingJobs(clockedInShiftData).length || !accountId} className={props.isNextJobLoading ? "btn cst_btn btn_danger btn-wait " : "btn cst_btn btn_danger "} onClick={handleNextJob}>{props.isNextJobLoading ? "" : "Submit"}</button>
                                                 </div>
                                                </>
                                               )}
                                                 <div className="col-md-12 d-flex justify-content-center mt-5">
                                                     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                         <button  type='button' className='btn cst_btn btn_danger min_width_50' onClick={() => { handleClockOut(); }}>
                                                         Proceed to Clock Out
                                                         </button>
                                                     </div>
                                                 </div>
                                             
                                               </>
                                            ) : null}
                                            </>
                                      
                                        )
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {props.isTimeTrackerModalOpen ? (
                    <MealBreakConcludingModal
                        {...props}
                        mealBreakItem={concludeFeedGap}
                        handleFeedGapEnd={handleFeedGapEnd}
                    />
                 ) : null} 

            </>
        );
    }

};

export default ClockInClockOutScreenComponent;